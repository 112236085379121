import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  getSidePar,
  getSideYardage,
} from "../../../post-score/helpers/post-hbh-score-helper";
import ratingType from "../../../../shared/variables/rating-type";

class ViewAvgHbhScoreTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teeSetInfo: { values: {} },
      avgScoresTotals: {},
      plusMinusParTotals: {},
    };
  }

  componentDidMount() {
    this.setState({
      teeSetInfo: this.getTeeSetTotals(this.props.teeSet),
      avgScoresTotals: this.getAvgSideScores(this.props.avgScores),
      plusMinusParTotals: this.getPlusMinusParTotals(this.props.avgScores),
    });
  }

  getTotalYardValue() {
    const { side } = this.props;
    const { teeSetInfo } = this.state;
    return side === ratingType.BACK
      ? teeSetInfo.values.totalYards
      : getSideYardage(teeSetInfo, side);
  }

  getTotalParValue() {
    const { side } = this.props;
    const { teeSetInfo } = this.state;
    return side === ratingType.BACK
      ? teeSetInfo.values.totalPar
      : getSidePar(teeSetInfo, side);
  }

  getTotalAvgScoreValue() {
    const { side } = this.props;
    const { avgScoresTotals } = this.state;
    return side === ratingType.BACK
      ? avgScoresTotals.totalAvg
      : this.getSideAvg(avgScoresTotals, side);
  }

  getSideAvg(avgScoresTotals, side) {
    if (side === ratingType.FRONT) return avgScoresTotals.frontAvg;
    else return avgScoresTotals.backAvg;
  }

  getAvgSideScores(avgScores) {
    let result = {
      frontAvg: 0,
      backAvg: 0,
      totalAvg: 0,
    };
    avgScores.forEach((hole) => {
      if (hole.hole_number < 10) result.frontAvg += hole.average_score;
      else result.backAvg += hole.average_score;
    });
    result.totalAvg = result.frontAvg + result.backAvg;
    result.frontAvg = (Math.round(result.frontAvg * 100) / 100).toFixed(2);
    result.backAvg = (Math.round(result.backAvg * 100) / 100).toFixed(2);
    result.totalAvg = (Math.round(result.totalAvg * 100) / 100).toFixed(2);

    return result;
  }

  getSidePlusMinusTotal(plusMinusParTotals, side) {
    if (side === ratingType.FRONT) return plusMinusParTotals.frontTotal;
    else return plusMinusParTotals.backTotal;
  }

  getPlusMinusParTotals(avgScores) {
    let result = {
      frontTotal: 0,
      backTotal: 0,
      total: 0,
    };

    avgScores.forEach((hole) => {
      if (hole.hole_number < 10) result.frontTotal += hole.plus_minus_par;
      else result.backTotal += hole.plus_minus_par;
    });

    result.total = result.frontTotal + result.backTotal;

    let isFrontPositive = result.frontTotal >= 0;
    let isBackPositive = result.backTotal >= 0;
    let isTotalPositive = result.total >= 0;

    result.total = result.total.toFixed(2);
    result.frontTotal = result.frontTotal.toFixed(2);
    result.backTotal = result.backTotal.toFixed(2);

    result.total = isTotalPositive ? "+" + result.total : result.total;
    result.frontTotal = isFrontPositive
      ? "+" + result.frontTotal
      : result.frontTotal;
    result.backTotal = isBackPositive
      ? "+" + result.backTotal
      : result.backTotal;

    return result;
  }

  getTeeSetTotals(teeSetInfo) {
    let result = {
      totalYards: teeSetInfo.TotalYardage,
      frontYardage: 0,
      backYardage: 0,
      frontPar: 0,
      backPar: 0,
      totalPar: 0,
    };

    teeSetInfo.Holes.forEach((hole) => {
      if (hole.Number < 10) {
        result.frontYardage += hole.Length;
        result.frontPar += hole.Par;
      } else {
        result.backYardage += hole.Length;
        result.backPar += hole.Par;
      }

      result.totalPar += hole.Par;
    });

    return { values: result };
  }

  getSideHoles(teeSet, avgScores, side) {
    let holes = teeSet.Holes.filter((hole) =>
      side === ratingType.FRONT ? hole.Number < 10 : hole.Number >= 10
    );
    let avg_per_hole = avgScores.filter((hole) =>
      side === ratingType.FRONT ? hole.hole_number < 10 : hole.hole_number >= 10
    );
    holes.forEach((hole, i) => {
      hole.AvgScore = avg_per_hole[i].average_score;
      hole.PlusMinusPar = avg_per_hole[i].plus_minus_par;
    });
    return holes;
  }

  render() {
    const { teeSet, side, showSideTotalsColumn, avgScores } = this.props;
    const { teeSetInfo, avgScoresTotals, plusMinusParTotals } = this.state;
    const holes = this.getSideHoles(teeSet, avgScores, side);
    const scrollClasses = side == "Back" ? `${side} LastScoreTable` : side;
    return (
      <div className="data-table__wrapper">
        <div
          className={`data-table__scroll ${
            showSideTotalsColumn ? scrollClasses : "Front"
          }`}
          id={`post-hbh-score-table-${side}`}
        >
          <table className={"data-table scores " + side}>
            <thead>{this.renderHeader(holes)}</thead>
            <tbody>
              {this.renderRow(
                "YARDS",
                holes.map((hole, key) => <td key={key}>{hole.Length}</td>),
                getSideYardage(teeSetInfo, side),
                this.getTotalYardValue()
              )}
              {this.renderRow(
                "PAR",
                holes.map((hole, key) => <td key={key}>{hole.Par}</td>),
                getSidePar(teeSetInfo, side),
                this.getTotalParValue()
              )}
              {this.renderRow(
                <span>
                  STROKE <br /> INDEX
                </span>,
                holes.map((hole, key) => <td key={key}>{hole.Allocation}</td>)
              )}
              {this.renderRow(
                "AVG. SCORE",
                holes.map((hole, key) => (
                  <td key={key}>
                    {(Math.round(hole.AvgScore * 100) / 100).toFixed(2)}{" "}
                  </td>
                )),
                this.getSideAvg(avgScoresTotals, side),
                this.getTotalAvgScoreValue()
              )}
              {this.renderRow(
                "+/- PAR",
                holes.map((hole, key) => {
                  let diff = hole.PlusMinusPar;
                  let isPositive = diff >= 0;
                  diff = diff.toFixed(2);
                  let output = isPositive ? "+" + diff : diff;

                  return <td key={key}>{output}</td>;
                }),
                this.getSidePlusMinusTotal(plusMinusParTotals, side),
                plusMinusParTotals.total
              )}
            </tbody>
          </table>
        </div>
        <div className="fixed-shadow" />
      </div>
    );
  }

  renderHeader = (holes) => {
    const { showSideTotalsColumn, sideTotalsColumnTitle, showTotalsColumn } =
      this.props;

    return (
      <tr>
        <th className="fixed start">Hole</th>
        {holes.map((hole, key) => (
          <th key={key}>{hole.Number}</th>
        ))}
        {showSideTotalsColumn && (
          <th className={this.getSideTotalsClass()}>{sideTotalsColumnTitle}</th>
        )}
        {showTotalsColumn && <th className="fixed end">TOTAL</th>}
      </tr>
    );
  };

  renderRow = (title, holes, sideTotals = "", totals = "") => {
    const { showSideTotalsColumn, showTotalsColumn } = this.props;
    return (
      <tr>
        <td className="fixed start">{title}</td>
        {holes}
        {showSideTotalsColumn && (
          <td className={this.getSideTotalsClass()}>{sideTotals}</td>
        )}
        {showTotalsColumn && <td className="fixed end">{totals}</td>}
      </tr>
    );
  };

  getSideTotalsClass = () => {
    return this.props.showTotalsColumn ? "fixed pre-end" : "fixed end";
  };
}

ViewAvgHbhScoreTable.defaultProps = {
  sideTotalsColumnTitle: "",
  autoFocusScore: false,
  lastTable: false,
};

ViewAvgHbhScoreTable.propTypes = {
  teeSet: PropTypes.object.isRequired,
  avgScores: PropTypes.array.isRequired,
  side: PropTypes.string.isRequired,
  showTotalsColumn: PropTypes.bool.isRequired,
  showSideTotalsColumn: PropTypes.bool.isRequired,
  sideTotalsColumnTitle: PropTypes.string.isRequired,
};

export default ViewAvgHbhScoreTable;
