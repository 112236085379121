import { isEmpty } from "lodash";
import moment from "moment";

export const getAddressDisplayFields = (fields, suffix = "\n") => {
  const value = fields.filter(Boolean).join(", ");
  if (!isEmpty(value)) {
    return `${value}${suffix}`;
  }
  return null;
};

export const hasAddress = (address) => {
  if (!address) return false;
  if (
    !isEmpty(address.street_1) ||
    !isEmpty(address.street_2) ||
    !isEmpty(address.city) ||
    !isEmpty(address.state_name) ||
    !isEmpty(address.zip) ||
    !isEmpty(address.country_name)
  ) {
    return true;
  }
  return false;
};

export const hasSecondaryAddress = (profile) => {
  if (!profile?.secondary_address) return false;
  if (
    !isEmpty(profile?.secondary_address?.street_1) ||
    !isEmpty(profile?.secondary_address?.street_2) ||
    !isEmpty(profile?.secondary_address?.city) ||
    !isEmpty(profile?.secondary_address?.state_name) ||
    !isEmpty(profile?.secondary_address?.zip) ||
    !isEmpty(profile?.secondary_address?.country_name)
  ) {
    return true;
  }
  return false;
};

export const profileWithSecondaryAddress = (profile) => ({
  ...profile,
  hasSecondaryAddress: hasSecondaryAddress(profile),
});

export const readDateFromApi = (date) => {
  if (!date) return undefined;
  return new Date(`${date}/2020`);
};

export const writeDateForApi = (date) => {
  if (!date) return undefined;
  return moment(date).format("YYYY-MM-DD");
};

export const defaultAddressesFormValues = (
  primaryAddress,
  secondaryAddress
) => {
  return {
    country: primaryAddress.country
      ? {
          value: primaryAddress.country,
          label: primaryAddress.country_name,
        }
      : undefined,
    state: primaryAddress.state
      ? {
          value: primaryAddress.state,
          label: primaryAddress.state_name,
        }
      : undefined,
    street_1: primaryAddress.street_1,
    street_2: primaryAddress.street_2,
    city: primaryAddress.city,
    zip: primaryAddress.zip,
    active_from: readDateFromApi(primaryAddress.active_from),
    active_to: readDateFromApi(primaryAddress.active_to),

    secondary_country: secondaryAddress.country
      ? {
          value: secondaryAddress.country,
          label: secondaryAddress.country_name,
        }
      : undefined,
    secondary_state: secondaryAddress.state
      ? {
          value: secondaryAddress.state,
          label: secondaryAddress.state_name,
        }
      : undefined,
    secondary_street_1: secondaryAddress.street_1,
    secondary_street_2: secondaryAddress.street_2,
    secondary_city: secondaryAddress.city,
    secondary_zip: secondaryAddress.zip,
    secondary_active_from: readDateFromApi(secondaryAddress.active_from),
    secondary_active_to: readDateFromApi(secondaryAddress.active_to),
  };
};
