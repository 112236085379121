import GoogleAnalytics from "./google-analytics";

class Tracking {
  initialize = () => GoogleAnalytics.initialize(process.env.REACT_APP_GA_ID);

  pageViewEvent = (url = window.location.pathname + window.location.search) =>
    GoogleAnalytics.pageViewEvent(url);
}

export default new Tracking();
