import jobStatus from "../../../shared/variables/job-status";

import {
  CONFIRM_EMAIL_CHANGE,
  CONFIRM_EMAIL_CHANGE_SUCCESS,
  CONFIRM_EMAIL_CHANGE_FAILED,
} from "../actions";

const initialState = {
  status: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type }) => {
  switch (type) {
    case CONFIRM_EMAIL_CHANGE:
      return {
        ...state,
        status: jobStatus.PROCESSING,
      };
    case CONFIRM_EMAIL_CHANGE_SUCCESS: {
      return {
        ...state,
        status: jobStatus.SUCCESS,
      };
    }
    case CONFIRM_EMAIL_CHANGE_FAILED: {
      return {
        ...state,
        status: jobStatus.FAILED,
      };
    }
    default:
      return state;
  }
};
