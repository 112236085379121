import React, { Component, Fragment } from "react";
import PromoBanner from "../../../../shared/components/banners/PromoBanner";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import GolferApi from "../../../../services/golfer-api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchGolferProfile } from "../../../profile/actions";

class MembershipAutoRenewal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_user_ghin: undefined,
      checked: [],
      showWarningModal: false,
      showInfoModal: false,
      selected_club: undefined,
      selected_affiliation: undefined,
    };
  }

  onConfirmTurnOff = () => {
    var payload = [];
    payload.golfer_id = this.state.current_user_ghin;
    payload.affiliation_id = this.state.selected_affiliation;
    payload.data = { club_affiliation: { auto_renew_enabled: false } };
    GolferApi.disableAutoRenewal(payload)
      .then(() => {
        this.props.fetchGolferProfile();
      })
      .catch((error) => {
        console.log(error);
      });
    const new_checked = this.state.checked;
    new_checked[this.state.selected_club] = false;
    this.setState({
      showWarningModal: false,
      checked: new_checked,
    });
  };

  onCancel = () => {
    this.setState({ showWarningModal: false });
  };

  onConfirmInfo = () => {
    this.setState({ showInfoModal: false });
  };

  onClick = (event) => {
    if (this.state.checked[event.target.id]) {
      this.setState({
        showWarningModal: true,
        selected_club: event.target.id,
        selected_affiliation: event.target.getAttribute("data-affiliation"),
      });
    } else {
      this.setState({
        showInfoModal: true,
      });
    }
  };

  componentDidMount() {
    const { memberships, currentUser } = this.props;
    const ojr_memberships = Object.values(memberships).filter(
      (item) => item.ojr_membership
    );
    const renewal_dict = ojr_memberships.reduce((acc, membership) => {
      acc[membership.club_id] = membership.auto_renew_enabled;
      return acc;
    }, {});
    this.setState({
      checked: renewal_dict,
      current_user_ghin: currentUser.ghin_number,
    });
  }

  render() {
    const { memberships } = this.props;
    const ojr_memberships = Object.values(memberships).filter(
      (item) => item.ojr_membership
    );
    return (
      <Fragment>
        <div className="container has-adds">
          <section className="golfer_lookup_section">
            <PromoBanner />
            <div className="section_header">
              <h1>Membership Auto-Renewal</h1>
            </div>

            <div className="profile-page__head">
              {ojr_memberships.map((membership) => (
                <div
                  key={JSON.stringify(membership)}
                  className="membership-item"
                >
                  <div className="p-row">
                    <div className="l-col">
                      <div
                        className="col is-full"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <input
                          type="checkbox"
                          checked={
                            this.state.checked[membership.club_id] || false
                          }
                          onChange={this.onClick}
                          className="regular"
                          id={membership.club_id}
                          data-affiliation={membership.club_affiliation_id}
                          name={membership.club_id}
                        />
                        <label
                          htmlFor={membership.club_id}
                          style={{
                            fontWeight: 400,
                            fontSize: 18,
                            color: "black",
                          }}
                        >
                          {membership.club_name}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <YesNoModal
              onConfirm={this.onConfirmTurnOff}
              onAbort={this.onCancel}
              isOpen={this.state.showWarningModal}
              isYesVisible={true}
              isNoVisible={true}
              yesTitle="Turn off"
              noTitle="Cancel"
            >
              <p>
                Turning off the automatic renewal of your current membership is
                final, and you will not be charged for a renewal membership
                term.
              </p>
            </YesNoModal>
            <YesNoModal
              onConfirm={this.onConfirmInfo}
              isOpen={this.state.showInfoModal}
              isYesVisible={true}
              isNoVisible={false}
              yesTitle="Ok"
            >
              <p>Automatic Renewal cannot be turned back on.</p>
            </YesNoModal>
          </section>
          <div className="box-footer">
            If you enrolled in automatic renewal of your current membership at
            the time of your initial registration, then you may turn off the
            automatic renewal of your membership at any time by deselecting the
            [Automatic Renewal] checkbox above. Turning off automatic renewal of
            your membership is final, and you will be unable to turn on the
            renewal option after you have turned it off. To avoid incurring
            charges for a renewal membership term, you must turn off automatic
            renewal before your current membership ends. If you turn off
            automatic renewal, you will be required to manually register for
            membership again on or near your initial purchase anniversary, at
            which time you may enroll in automatic renewal of that new
            membership term.
          </div>
          <br></br>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authReducer, profileReducer }) => ({
  currentUser: authReducer.loginReducer.user,
  memberships: profileReducer.golferProfileFetchReducer.golferMemberships,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchGolferProfile }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipAutoRenewal);
