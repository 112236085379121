import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";

class ConfirmDeleteStatsModal extends Component {
  render() {
    const { isOpen, onConfirm, onClose } = this.props;
    return (
      <YesNoModal
        reversedOrder
        reversedHighlight
        onConfirm={onConfirm}
        onAbort={onClose}
        isOpen={isOpen}
        yesTitle="YES"
        noTitle="NO"
      >
        <p>Are you sure you want to delete all stats?</p>
      </YesNoModal>
    );
  }
}

ConfirmDeleteStatsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmDeleteStatsModal;
