import { checkApiError } from "../../auth/actions";

export const FETCH_ABOUT_DETAILS = "FETCH_ABOUT_DETAILS";
export const FETCH_ABOUT_DETAILS_FAILED = "FETCH_ABOUT_DETAILS_FAILED";
export const FETCH_ABOUT_DETAILS_SUCCESS = "FETCH_ABOUT_DETAILS_SUCCESS";

export const fetchAboutDetails = (associationId) => ({
  type: FETCH_ABOUT_DETAILS,
  payload: { associationId },
});

export function fetchAboutDetailsFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_ABOUT_DETAILS_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchAboutDetailsSuccess = (payload) => ({
  type: FETCH_ABOUT_DETAILS_SUCCESS,
  payload,
});
