import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Navigate from "../../../shared/navigation/navigate";

class NotFound extends Component {
  render() {
    return (
      <Fragment>
        <br />
        <br />
        <p style={{ textAlign: "center" }}>
          <p>Page Not Found!</p>
          <button
            className="btn lnk blue no-pad"
            onClick={() => Navigate.toPath("/profile")}
            role="link"
          >
            Home
          </button>
        </p>
        <br />
        <br />
      </Fragment>
    );
  }
}

NotFound.propTypes = {
  location: PropTypes.object.isRequired,
};

export default NotFound;
