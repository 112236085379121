import { Formik } from "formik";
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  initialValues,
  validationSchema,
  validateFields,
} from "../../validations/round-setup-hbh-score-validation";
import CourseHandicap from "./CourseHandicap";
import Submit from "../Shared/RoundSetup/Submit";
import { routes } from "../../../../shared/routes/index";
import SharedRoundSetupForm from "../Shared/RoundSetup/SharedRoundSetupForm";
import { getUserGhinNo } from "../../../../shared/helpers/user-helper";
import { validateManualRatings } from "../../validations/shared-round-setup-form-validation";
import { initialValuesWithHomeCourse } from "../../helpers/round-setup-helper";
import { hasEighTeenHoles } from "../../helpers/round-setup-helper";
import numberOfHoles from "../../../../shared/variables/number-of-holes";
import SupportingCalculationApi from "../../../../services/supporting-calculation-api";
import moment from "moment";
import PostScoreApiErrorsModal from "../Shared/Modals/PostScoreApiErrorsModal";
import { sameAsCurrentDate } from "../../../../shared/helpers/date-helper";
class RoundSetupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevManualCourseRating: undefined,
      prevManualSlopeRating: undefined,
      courseHandicap: this.props.courseHandicap,
      postScoreErrorsModalOpen: false,
    };
    this.fetchCourseHandicap = this.fetchCourseHandicap.bind(this);
    this.onDatePlayedChanged = this.onDatePlayedChanged.bind(this);
  }

  render() {
    const { course, isManualCourse } = this.props;
    const { postScoreErrorsModalOpen } = this.state;
    const hasEighTeen = hasEighTeenHoles(course, isManualCourse);
    const nrOfHoles = !hasEighTeen
      ? numberOfHoles.NINE
      : numberOfHoles.EIGHTEEN;
    const values = initialValuesWithHomeCourse(
      { ...initialValues, nrOfHoles },
      course.CourseId
    );
    return (
      <>
        <Formik
          onSubmit={this.onSubmit}
          validate={(values) =>
            validateFields(values, this.props.isManualCourse)
          }
          initialValues={values}
          validationSchema={validationSchema}
        >
          {(options) => this.renderForm(options)}
        </Formik>
        <PostScoreApiErrorsModal
          errors={[
            "Date played is outside of the active score posting season for this golf course",
          ]}
          isOpen={postScoreErrorsModalOpen}
          onClose={() => this.setState({ postScoreErrorsModalOpen: false })}
        />
      </>
    );
  }

  renderForm(options) {
    const {
      course,
      openInfoModal,
      alertNoTees,
      isManualCourse,
      manualCourseHandicap,
      resetManualCourseHandicap,
      alertTeeNoHoles,
      useScaling,
      hasHandicapIndex,
    } = this.props;
    const hasEighTeen = hasEighTeenHoles(course, isManualCourse);
    return (
      <div className="form columns" id="post-score-form">
        <SharedRoundSetupForm
          selectCoursePath={routes.postScoreHbhSelectCourse.path}
          onManualRatingsChange={(values) =>
            this.updateManualCourseHandicap(options, values)
          }
          onDatePlayedChange={(date) => this.onDatePlayedChanged(date)}
          isManualRatingsEnabled={false}
          trialGolfer={this.props.trialGolfer}
          isManualCourse={isManualCourse}
          course={course}
          hasEighTeen={hasEighTeen}
          hasTenSeventen={useScaling && hasHandicapIndex && hasEighTeen}
          options={options}
          alertNoTees={alertNoTees}
          alertTeeNoHoles={alertTeeNoHoles}
          onResetForm={() => resetManualCourseHandicap()}
        />
        <CourseHandicap
          options={options}
          trialGolfer={this.props.trialGolfer}
          isManualCourse={isManualCourse}
          courseHandicap={this.state.courseHandicap}
          manualCourseHandicap={manualCourseHandicap}
          openInfoModal={openInfoModal}
        />
        <Submit options={options} title="Enter Hole-By-Hole Score" />
      </div>
    );
  }

  // Format dateString: "month/day". Eg: "11/15"
  // Returns an array containing day and month numbers
  getDayMonthNumbersFromString = (dateString) => {
    let dateStrings = dateString.split("/");
    let dayNumber = parseInt(dateStrings[1]);
    let monthNumber = parseInt(dateStrings[0]);

    return [dayNumber, monthNumber];
  };

  // Returns true if selected date is in between
  // the start and end date of the given season.
  // Returns false otherwise
  validateDatePlayed = (season, selectedDate) => {
    let startDateString = season.SeasonStartDate;
    let endDateString = season.SeasonEndDate;

    let [startDayNumber, startMonthNumber] =
      this.getDayMonthNumbersFromString(startDateString);
    let [endDayNumber, endMonthNumber] =
      this.getDayMonthNumbersFromString(endDateString);

    // Returns day number, not full date.
    let selectedDay = selectedDate.getDate();
    // For months counting starts at 0
    let selectedMonth = selectedDate.getMonth() + 1;

    if (selectedMonth < endMonthNumber && selectedMonth > startMonthNumber) {
      // The selected day does not matter if month is in between.
      return true;
    } else if (selectedMonth == endMonthNumber && selectedDay <= endDayNumber) {
      return true;
    } else if (
      selectedMonth == startMonthNumber &&
      selectedDay >= startDayNumber
    ) {
      return true;
    }

    return false;
  };

  onSubmit = (values, { setSubmitting }) => {
    let season = this.props.course.Season;
    const isActiveAllYear = season.IsAllYear;
    // Validate date only if the season is NOT active all year
    // If season is active all year, there is not point to validate date.
    if (
      !isActiveAllYear &&
      !this.validateDatePlayed(season, values.datePlayed)
    ) {
      this.setState({ postScoreErrorsModalOpen: true });
      return;
    }
    this.setValuesBeforeSubmit(values);
    setSubmitting(false);
    this.props.onSubmit(values);
  };

  setValuesBeforeSubmit = (values) => {
    const { course, isManualCourse } = this.props;

    values.isManualCourse = isManualCourse;
    values.isManualRating = isManualCourse || values.isManualRating;
    values.courseId = isManualCourse ? "" : course.CourseId;
    values.tees = values.tees.value;
    values.manualCourseName = isManualCourse
      ? values.manualCourseName
      : course.CourseName;
  };

  fetchCourseHandicap = (date) => {
    const { course } = this.props;
    let current_object = this;
    SupportingCalculationApi.getCourseHandicaps(
      getUserGhinNo(),
      course.CourseId,
      moment(date).format("YYYY-MM-DD")
    ).then(function (response) {
      current_object.setState({ courseHandicap: response.data });
    });
  };

  onDatePlayedChanged = (date) => {
    this.fetchCourseHandicap(date);

    if (!sameAsCurrentDate(date)) {
      this.props.openHbhNotSameDayModal();
    }
  };

  updateManualCourseHandicap = (options, ratings) => {
    if (!options.values.isManualRating && !this.props.isManualCourse) return;

    const { fetchManualCourseHandicap } = this.props;
    let errors = {};

    validateManualRatings(
      {
        manualCourseRating: Number(ratings.manualCourseRating),
        manualSlopeRating: Number(ratings.manualSlopeRating),
        nrOfHoles: options.values.nrOfHoles,
      },
      errors
    );

    if (!errors.manualCourseRating && !errors.manualSlopeRating) {
      fetchManualCourseHandicap(
        getUserGhinNo(),
        ratings.manualCourseRating,
        ratings.manualSlopeRating,
        options.values.nrOfHoles
      );
    }
  };
}

RoundSetupForm.propTypes = {
  course: PropTypes.object.isRequired,
  courseHandicap: PropTypes.object.isRequired,
  manualCourseHandicap: PropTypes.object.isRequired,
  fetchManualCourseHandicap: PropTypes.func.isRequired,
  resetManualCourseHandicap: PropTypes.func.isRequired,
  isManualCourse: PropTypes.bool.isRequired,
  openInfoModal: PropTypes.func.isRequired,
  alertNoTees: PropTypes.func.isRequired,
  alertTeeNoHoles: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  useScaling: PropTypes.bool.isRequired,
  hasHandicapIndex: PropTypes.bool.isRequired,
};

export default RoundSetupForm;
