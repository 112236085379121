import { checkApiError } from "../../auth/actions";

export const FETCH_MY_COURSES_FAILED = "FETCH_MY_COURSES_FAILED";
export const FETCH_MY_COURSES_SUCCESS = "FETCH_MY_COURSES_SUCCESS";
export const FETCH_MY_COURSES = "FETCH_MY_COURSES";
export const ADD_COURSE = "ADD_COURSE";
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS";
export const ADD_COURSE_FAILED = "ADD_COURSE_FAILED";
export const REMOVE_COURSE = "REMOVE_COURSE";
export const REMOVE_COURSE_SUCCESS = "REMOVE_COURSE_SUCCESS";
export const HIDE_COURSE_SUCCESS = "HIDE_COURSE_SUCCESS";
export const REMOVE_COURSE_FAILED = "REMOVE_COURSE_FAILED";
export const CHANGE_COURSES_ORDER = "CHANGE_COURSES_ORDER";
export const CHANGE_COURSES_ORDER_SUCCESS = "CHANGE_COURSES_ORDER_SUCCESS";
export const CHANGE_COURSES_ORDER_FAILED = "CHANGE_COURSES_ORDER_FAILED";
export const RESET_ADDED_COURSES = "RESET_ADDED_COURSES";

export const fetchMyCourses = () => ({
  type: FETCH_MY_COURSES,
});

export const fetchMyCoursesSuccess = (payload) => ({
  type: FETCH_MY_COURSES_SUCCESS,
  payload,
});

export function fetchMyCoursesFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_MY_COURSES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const addCourse = (course_id) => {
  return {
    type: ADD_COURSE,
    payload: { course_id },
  };
};

export const addCourseSuccess = (payload) => ({
  type: ADD_COURSE_SUCCESS,
  payload,
});

export function addCourseFailed(error) {
  return (dispatch) => {
    dispatch({ type: ADD_COURSE_FAILED });
    dispatch(checkApiError(error));
  };
}

export const hideCourseSuccess = (payload) => ({
  type: HIDE_COURSE_SUCCESS,
  payload,
});

export const removeCourse = (course_id) => {
  return {
    type: REMOVE_COURSE,
    payload: { course_id },
  };
};

export const removeCourseSuccess = (payload) => ({
  type: REMOVE_COURSE_SUCCESS,
  payload,
});

export function removeCourseFailed(error) {
  return (dispatch) => {
    dispatch({ type: REMOVE_COURSE_FAILED });
    dispatch(checkApiError(error));
  };
}

export const changeCoursesOrder = (courses) => ({
  type: CHANGE_COURSES_ORDER,
  payload: { courses: courses },
});

export const changeCoursesOrderSuccess = (payload) => ({
  type: FETCH_MY_COURSES_SUCCESS,
  payload,
});

export function changeCoursesOrderFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_MY_COURSES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const resetAddedCourses = () => ({
  type: RESET_ADDED_COURSES,
});
