import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DefaultCustomSelect from "../../../../shared/components/selects/DefaultCustomSelect";
import {
  handicapCalculatorRemoveGolfer,
  fetchCalculatorGolferHandicap,
  fetchCalculatorGolferTee,
  setGolferSelectedTee,
  removeInitialTeeSetId,
} from "../../actions";
import { getDisplayScore } from "../../../../shared/helpers/ui-helper";
import {
  getGolferId,
  getHandicap,
  getUserGhinNo,
  getUserGenderAcronym,
} from "../../../../shared/helpers/user-helper";
import Loader from "../../../../shared/components/loaders/Loader";
import noOfHoles from "../../../../shared/variables/number-of-holes";
import teeSetSide from "../../../../shared/variables/tee-set-side";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import { stringToFloat } from "../../../../shared/helpers/ui-helper";
import HandicapInfoModal from "../../../../shared/components/modals/HandicapInfoModal";
import { has18And9HolesTees } from "../../../../shared/helpers/scores-helper";
import PlayingHandicapModal from "../../../../shared/components/modals/PlayingHandicapModal";
import ShotsOffModal from "../../../../shared/components/modals/ShotsOffModal";
import { getPlayingHandicap } from "../../../../shared/helpers/handicap-calculator-helper";

class GolferItem extends Component {
  state = {
    selectedTee: null,
    courseHandicap: "-",
    showConfirmRemoveModal: false,
    courseHandicapModalOpen: false,
    playingHandicapModalOpen: false,
    shotsOffModalOpen: false,
    recentPlayedTeeSetId: false,
  };

  componentDidMount() {
    const {
      golfer,
      course,
      fetchCalculatorGolferTee,
      fetchCalculatorGolferHandicap,
    } = this.props;
    fetchCalculatorGolferTee(course.CourseId, golfer);
    if (!golfer.manual_golfer_id) {
      fetchCalculatorGolferHandicap(getGolferId(golfer));
    }
  }

  persistSelectedTee(selectedTee, manually) {
    const { golfer } = this.props;
    const currentUserId = Number(getUserGhinNo());
    const teeSetId = this.getTeeIdFromValue(selectedTee.value);
    const userSelectedTee = String(this.props.userSelectedTee);
    this.props.setGolferSelectedTee(getGolferId(golfer), teeSetId);
    if (
      manually &&
      this.props.initialTeeSetId &&
      getGolferId(golfer) === currentUserId
    ) {
      this.changeGolfersTeeSetIdToCurrentUserSelectedTeeSetId(
        currentUserId,
        teeSetId,
        userSelectedTee
      );
    }
  }

  changeGolfersTeeSetIdToCurrentUserSelectedTeeSetId(
    currentUserId,
    teeSetId,
    userSelectedTee
  ) {
    setTimeout(() => {
      this.props.golfers.forEach((g) => {
        if (
          Number(g.ghin_number) !== currentUserId &&
          g.gender === getUserGenderAcronym() &&
          userSelectedTee === g.selected_tee_set
        ) {
          this.props.setGolferSelectedTee(getGolferId(g), teeSetId);
        }
      });
      this.props.removeInitialTeeSetId();
    }, 100);
  }

  handleChange = (selectedTee, manually = true) => {
    this.persistSelectedTee(selectedTee, manually);
    this.setState({
      selectedTee,
      courseHandicap: this.getHandicapFromValue(selectedTee),
    });
  };

  onClickRemove = () => this.setState({ showConfirmRemoveModal: true });

  onCloseConfirmModal = () => this.setState({ showConfirmRemoveModal: false });

  onConfirmRemove = () => {
    this.onCloseConfirmModal();
    const { handicapCalculatorRemoveGolfer, golfer } = this.props;
    handicapCalculatorRemoveGolfer(getGolferId(golfer));
  };

  getHandicapFromValue = (tee) =>
    tee.value.substring(0, tee.value.indexOf("_"));

  getTeeIdFromValue = (v) => v.substring(v.indexOf("_") + 1, v.length);

  getSelectOption = (tee, side, hideTeeSide = false) => {
    const hasRatings = tee.ratings.some((r) => r.tee_set_side === side);
    if (!hasRatings) {
      return {
        value: "",
        label: (
          <div className="calculator-selected-tee">
            <div style={{ color: "#808080" }}>Select Tees</div>
          </div>
        ),
      };
    }
    const sideLabel = hideTeeSide
      ? ""
      : `(${side === teeSetSide.Front ? "Front" : "Back"})`;
    const name =
      side === teeSetSide.All18 ? tee.name : `${tee.name} ${sideLabel}`;
    const rating = tee.ratings.filter((t) => t.tee_set_side === side)[0];
    const par = rating.par && rating.par > 0 ? ` / ${rating.par}` : "";
    return {
      value: `${rating.course_handicap_display}_${tee.tee_set_id}_${side}`,
      label: (
        <div>
          <span>{name}</span>
          <span className="right calculator-tee-option-ratings padding-left">{`${stringToFloat(
            rating.course_rating,
            1
          )} / ${rating.slope_rating} ${par}`}</span>
        </div>
      ),
    };
  };

  optionsHeader = (withPar) => ({
    value: "",
    label: (
      <div>
        <span>Tees</span>
        <span className="right">C.R. / Slope{withPar ? " / Par" : ""}</span>
      </div>
    ),
    isDisabled: "yes",
  });

  orderTees = (tees) =>
    tees
      .filter((t) => t.ratings && t.ratings.length > 0)
      .sort((first, second) => {
        const firstRating = first.ratings.find(
          (r) => r.tee_set_side === teeSetSide.All18
        );
        const secondRating = second.ratings.find(
          (r) => r.tee_set_side === teeSetSide.All18
        );
        if (firstRating && secondRating) {
          return (
            parseFloat(secondRating.CourseRating || 0) -
            parseFloat(firstRating.CourseRating || 0)
          );
        }

        if (!firstRating && !secondRating) {
          return 0;
        }

        return firstRating ? -1 : 1;
      });

  shouldDisplayParHeader = (tees) =>
    tees.some((a) => a.ratings.some((r) => r.par > 0));

  getSelectOptions = () => {
    const { golfer, numberOfHoles } = this.props;
    const tees = [];
    if (golfer.tee_sets) {
      const orderedTees = this.orderTees(golfer.tee_sets);
      tees.push(this.optionsHeader(this.shouldDisplayParHeader(orderedTees)));
      if (numberOfHoles === noOfHoles.EIGHTEEN) {
        const array = has18And9HolesTees(orderedTees)
          ? orderedTees.filter((t) => t.holes_number === 18)
          : orderedTees;
        array.forEach((teeSet) => {
          tees.push(this.getSelectOption(teeSet, teeSetSide.All18));
        });
      } else {
        orderedTees.forEach((teeSet) => {
          const hasFront = teeSet.ratings.some(
            (r) => r.tee_set_side === teeSetSide.Front
          );
          if (hasFront) {
            tees.push(
              this.getSelectOption(
                teeSet,
                teeSetSide.Front,
                teeSet.holes_number !== 18
              )
            );
          }
          const hasBack = teeSet.ratings.some(
            (r) => r.tee_set_side === teeSetSide.Back
          );
          if (hasBack) {
            tees.push(this.getSelectOption(teeSet, teeSetSide.Back));
          }
        });
      }
    }
    return tees;
  };

  getSelectedTee() {
    const { golfer } = this.props;
    const { selectedTee } = this.state;
    if (!golfer.selected_tee_set || !golfer.tee_sets) return selectedTee;

    const tee = golfer.selected_tee_set.toString().split("_");
    const teeSet = golfer.tee_sets.filter(
      (t) => t.tee_set_id === parseInt(tee[0], 10)
    )[0];
    if (!teeSet) return selectedTee;
    const getSelectOption = this.getSelectOption(
      teeSet,
      tee[1],
      teeSet.holes_number !== 18
    );
    return getSelectOption;
  }

  getTeeSetIdFromValue(s) {
    var p = s.indexOf("_") + "_".length;
    return s.substring(p, s.indexOf("_", p));
  }

  getRecentPlayedTeeSet() {
    const teeSetId = new URLSearchParams(window.location.search).get(
      "teeSetId"
    );
    const recentPlayedTeeSet = this.getSelectOptions().filter(
      (x) => this.getTeeSetIdFromValue(x.value) === teeSetId
    );

    if (
      recentPlayedTeeSet &&
      recentPlayedTeeSet.length > 0 &&
      !this.state.recentPlayedTeeSetId
    ) {
      return recentPlayedTeeSet[0];
    }
    return null;
  }

  getTeeSetDefaultSelectedValue(selectedTee) {
    const recentPlayedTeeSet = this.getRecentPlayedTeeSet();
    if (recentPlayedTeeSet && !this.state.recentPlayedTeeSetId) {
      setTimeout(() => {
        this.handleChange(recentPlayedTeeSet, false);
      }, 200);
      this.setState({ recentPlayedTeeSetId: true });
      return recentPlayedTeeSet;
    }

    return {
      value: selectedTee && selectedTee.value ? selectedTee.value : "",
      label: (
        <div className="calculator-selected-tee">
          {selectedTee && selectedTee.label ? (
            selectedTee.label
          ) : (
            <div style={{ color: "#808080" }}>Select Tees</div>
          )}
        </div>
      ),
    };
  }

  renderSelect(selectedTee) {
    const { golfer } = this.props;
    const value = this.getTeeSetDefaultSelectedValue(selectedTee);

    const showLoader = !golfer.tee_sets || (golfer && golfer.fetch_tees);
    return (
      <Fragment>
        {showLoader && <Loader noPadding />}
        {!showLoader && (
          <DefaultCustomSelect
            value={value}
            onChange={this.handleChange}
            placeholder="Select Tees"
            title="Select Tees"
            options={this.getSelectOptions()}
            className="default_select full_on_phone smallwidth calculator-tee-dropdown"
          />
        )}
      </Fragment>
    );
  }

  renderHandicapIndex() {
    const { golfer } = this.props;
    if (golfer.fetch_handicap) return <Loader noPadding />;
    return (
      <div className="tbl-cell text-center handicap-index">
        <span className="cap" data-cap="H.I." />
        {getDisplayScore(getHandicap(golfer))}
      </div>
    );
  }

  render() {
    const { golfer, allowanceValues, allowance, manualGolfersReference } =
      this.props;
    const { showConfirmRemoveModal, courseHandicap } = this.state;
    let handicapDisplay = courseHandicap;
    const selectedTee = this.getSelectedTee();
    if (selectedTee) handicapDisplay = this.getHandicapFromValue(selectedTee);

    const firstName = golfer.first_name;
    const lastName = golfer.last_name;
    const suffix = golfer.suffix;
    return (
      <Fragment>
        <div className="tbl-row tbl--handicap-calc">
          <div className="tbl-cell grow golfer-name-cell">
            <span className="cap" data-cap="Name" />
            {golfer.localUser === undefined && (
              <button className="ic_c_minus" onClick={this.onClickRemove} />
            )}
            {`${firstName} ${lastName} ${suffix || ""}`}
          </div>
          <div className="tbl-cell grow tees-select">
            <span className="cap" data-cap="Tees" />
            {this.renderSelect(selectedTee)}
          </div>
          {this.renderHandicapIndex()}
          <div className="tbl-cell text-center course-handicap">
            <span className="cap" data-cap="C.H.">
              <button
                onClick={() => this.setState({ courseHandicapModalOpen: true })}
                className="btn blank"
                style={{ padding: "0" }}
              >
                <i
                  style={{ color: "#00365f", fontSize: "22px" }}
                  className="material-icons-outlined"
                >
                  info
                </i>
              </button>
            </span>
            {handicapDisplay}
          </div>
          <div className="tbl-cell text-center playing-handicap">
            <span className="cap" data-cap="P.H.">
              <button
                onClick={() =>
                  this.setState({ playingHandicapModalOpen: true })
                }
                className="btn blank"
                style={{ padding: "0" }}
              >
                <i
                  style={{ color: "#00365f", fontSize: "22px" }}
                  className="material-icons-outlined"
                >
                  info
                </i>
              </button>
            </span>
            {getPlayingHandicap(
              allowanceValues,
              allowance,
              golfer,
              manualGolfersReference
            )}
          </div>
          <div className="tbl-cell text-center shots-off">
            <span className="cap" data-cap="S.O.">
              <button
                onClick={() => this.setState({ shotsOffModalOpen: true })}
                className="btn blank"
                style={{ padding: "0" }}
              >
                <i
                  style={{ color: "#00365f", fontSize: "22px" }}
                  className="material-icons-outlined"
                >
                  info
                </i>
              </button>
            </span>
            {getPlayingHandicap(
              allowanceValues,
              allowance,
              golfer,
              manualGolfersReference,
              "shots_off"
            )}
          </div>
        </div>
        <YesNoModal
          isOpen={showConfirmRemoveModal}
          onAbort={this.onCloseConfirmModal}
          onConfirm={this.onConfirmRemove}
          className="modal-dialog"
          reversedOrder={true}
        >
          <p>Are you sure you want to remove golfer?</p>
        </YesNoModal>
        <HandicapInfoModal
          isOkVisible={true}
          isOpen={this.state.courseHandicapModalOpen}
          onAbort={() => this.setState({ courseHandicapModalOpen: false })}
        />
        <PlayingHandicapModal
          isOkVisible={true}
          isOpen={this.state.playingHandicapModalOpen}
          onAbort={() => this.setState({ playingHandicapModalOpen: false })}
        />
        <ShotsOffModal
          isOkVisible={true}
          isOpen={this.state.shotsOffModalOpen}
          onAbort={() => this.setState({ shotsOffModalOpen: false })}
        />
      </Fragment>
    );
  }
}

GolferItem.propTypes = {
  golfer: PropTypes.shape({}).isRequired,
  course: PropTypes.object.isRequired,
  userSelectedTee: PropTypes.string,
  initialTeeSetId: PropTypes.any,
  numberOfHoles: PropTypes.string.isRequired,
  handicapCalculatorRemoveGolfer: PropTypes.func.isRequired,
  fetchCalculatorGolferHandicap: PropTypes.func.isRequired,
  fetchCalculatorGolferTee: PropTypes.func.isRequired,
  setGolferSelectedTee: PropTypes.func.isRequired,
  removeInitialTeeSetId: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  golfers: PropTypes.array.isRequired,
  manualGolfersReference: PropTypes.array,
};

GolferItem.defaultProps = {
  userSelectedTee: undefined,
  initialTeeSetId: undefined,
  manualGolfersReference: [],
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handicapCalculatorRemoveGolfer,
      fetchCalculatorGolferHandicap,
      fetchCalculatorGolferTee,
      setGolferSelectedTee,
      removeInitialTeeSetId,
    },
    dispatch
  );

const mapStateToProps = ({ handicapCalculatorReducer }) => ({
  initialTeeSetId:
    handicapCalculatorReducer.calculatorGolfersReducer.initialTeeSetId,
  userSelectedTee:
    handicapCalculatorReducer.calculatorGolfersReducer.userSelectedTee,
  allowanceValues: handicapCalculatorReducer.playingHandicapsReducer.handicaps,
  golfers: handicapCalculatorReducer.calculatorGolfersReducer.golfers,
  manualGolfersReference:
    handicapCalculatorReducer.calculatorGolfersReducer.manualGolfersReference,
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(GolferItem);
