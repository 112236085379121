import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isUserLoggedIn } from "../../../../shared/helpers/user-helper";
import { completeLogout } from "../../../auth/actions";
import { bindActionCreators } from "redux";
import UserMenu from "../UserMenu/UserMenu";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import Logo from "./Logo";
import MainContentLink from "./MainContentLink";

class Header extends Component {
  logout = () => {
    this.props.completeLogout();
  };

  render() {
    const { user, isLoggedIn } = this.props;
    return (
      <Fragment>
        <header>
          <div className="header__container">
            <MainContentLink />
            <HamburgerMenu isLoggedIn={isLoggedIn} logout={this.logout} />
            <Logo />
            <img
              className="header-sentry-logo"
              src="/img/logos/sentry_01.png"
              alt="Sentry"
            />
            {isLoggedIn() ? (
              <UserMenu user={user} logout={() => this.logout()} />
            ) : null}
          </div>
        </header>
      </Fragment>
    );
  }
}

Header.propTypes = {
  isLoggedIn: PropTypes.func.isRequired,
  completeLogout: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = ({ authReducer }) => ({
  user: authReducer.loginReducer.user,
  isLoggedIn: isUserLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ completeLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
