import React, { Component } from "react";

class Copyright extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <div className="copyright__container">
        <p>
          &copy; {`${year}`} United States Golf Association. All Rights
          Reserved.
        </p>
      </div>
    );
  }
}

export default Copyright;
