import React, { Component } from "react";
import { isUserLoggedIn } from "../../../../shared/helpers/user-helper";
import { routes } from "../../../../shared/routes/index";

class Logo extends Component {
  handleNavigation = () =>
    isUserLoggedIn() ? routes.profile.navigate() : routes.login.navigate();

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.handleNavigation();
    }
  };

  render() {
    return (
      <span
        role="link"
        tabIndex={0}
        className="usga_logo border-focus"
        onKeyDown={this.onKeyDown}
        onClick={() => this.handleNavigation()}
        aria-label={"USGA, Golf Handicap Information Network"}
      >
        golf handicap <br /> information network
      </span>
    );
  }
}

export default Logo;
