export const SET_PASSWORD = "SET_PASSWORD";
export const SET_PASSWORD_FAILED = "SET_PASSWORD_FAILED";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";

export const setPassword = (payload) => ({
  type: SET_PASSWORD,
  payload,
});

export const setPasswordFailed = (payload) => ({
  type: SET_PASSWORD_FAILED,
  payload,
});

export const setPasswordSuccess = (payload) => ({
  type: SET_PASSWORD_SUCCESS,
  payload,
});
