import React, { Component } from "react";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import PropTypes from "prop-types";

class UnableToFindGolferModal extends Component {
  render() {
    const { isOpen, onClose } = this.props;
    return (
      <YesNoModal
        onConfirm={onClose}
        onAbort={onClose}
        isOpen={isOpen}
        isYesVisible={true}
        isNoVisible={false}
        yesTitle="Close"
      >
        <p>Unable to find golfer.</p>
      </YesNoModal>
    );
  }
}

UnableToFindGolferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UnableToFindGolferModal;
