import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

class ProductAlertModal extends Component {
  componentDidMount = () => Modal.setAppElement("body");

  render() {
    const { visible, onClose, alert } = this.props;
    return (
      <Modal
        isOpen={visible}
        onRequestClose={onClose}
        contentLabel="Modal"
        portalClassName="custom__modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={false}
      >
        <Fragment>
          <div className="modal__container very-narrow">
            <div className="modal__head">
              <div className="modal__icon" />
              <h2 className="modal__title">Important Alert</h2>
            </div>

            <div className="modal__body">
              <div className={"row"}>
                <div className={"col"}>
                  <p>
                    <strong>{alert?.message_title}</strong>
                  </p>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col"}>
                  <p>{alert?.message_body}</p>
                </div>
              </div>
              <div className="row no-margin">
                <div className="col is-1-of-2 push-right">
                  <button
                    type={"submit"}
                    onClick={onClose}
                    className="btn fill another-red"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </Modal>
    );
  }
}

ProductAlertModal.propTypes = {
  alert: PropTypes.shape({}),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

ProductAlertModal.defaultProps = {
  alert: undefined,
  visible: false,
};

export default ProductAlertModal;
