import jobStatus from "../../../shared/variables/job-status";

import {
  CHECK_ALL_FETCH_DONE,
  FETCH_CHILD_SCORES,
  FETCH_CHILD_SCORES_SUCCESS,
  FETCH_CHILD_SCORES_DONE,
  FETCH_CHILD_SCORES_FAILED,
  FETCH_MAXIMUM_HOLE_SCORES,
  FETCH_MAXIMUM_HOLE_SCORES_SUCCESS,
  FETCH_MAXIMUM_HOLE_SCORES_FAILED,
  FETCH_SCORE_COURSE_DETAILS,
  FETCH_SCORE_COURSE_DETAILS_SUCCESS,
  FETCH_SCORE_COURSE_DETAILS_FAILED,
  FETCH_CHILD_SCORES_RESET,
} from "../actions";

const initialState = {
  scores: [],
  jobStatus: jobStatus.NOT_STARTED,
};

const scoresWitNode = (scores, node, nodeName, scoreId) => {
  return scores.map((score) =>
    score.id === scoreId ? { ...score, [nodeName]: node } : score
  );
};

const checkForJobStatus = (scores) => {
  if (scores && scores.length === 2) {
    const score1 = scores[0];
    const score2 = scores[1];
    if (
      score1.courseDetails &&
      score1.maximumScores &&
      score2.courseDetails &&
      score2.maximumScores
    ) {
      return jobStatus.SUCCESS;
    }
  }
  return jobStatus.PROCESSING;
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHILD_SCORES:
      return {
        ...state,
        scores: [],
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_MAXIMUM_HOLE_SCORES:
    case FETCH_SCORE_COURSE_DETAILS:
      return {
        ...state,
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_CHILD_SCORES_SUCCESS:
      return {
        ...state,
        scores: payload,
      };
    case FETCH_MAXIMUM_HOLE_SCORES_SUCCESS:
      return {
        ...state,
        scores: scoresWitNode(
          state.scores,
          payload.maximumScores,
          "maximumScores",
          payload.scoreId
        ),
      };
    case FETCH_SCORE_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        scores: scoresWitNode(
          state.scores,
          payload.courseDetails,
          "courseDetails",
          payload.scoreId
        ),
      };
    case CHECK_ALL_FETCH_DONE:
      return {
        ...state,
        jobStatus: checkForJobStatus(state.scores),
      };
    case FETCH_CHILD_SCORES_FAILED:
    case FETCH_MAXIMUM_HOLE_SCORES_FAILED:
    case FETCH_SCORE_COURSE_DETAILS_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    case FETCH_CHILD_SCORES_DONE:
      return {
        ...state,
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_CHILD_SCORES_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
