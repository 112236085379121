import React, { Component } from "react";
import PropTypes from "prop-types";
import CourseListItem from "./CourseListItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { changeCoursesOrder, fetchTees } from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class CoursesList extends Component {
  state = {
    expandedId: "",
  };
  onToggleExpand = (courseId) => {
    this.setState((prevState) => {
      return {
        expandedId: prevState.expandedId === courseId ? "" : courseId,
      };
    });
    this.props.fetchTees(courseId);
  };

  onToggleExpandCancel = () => {
    this.setState({ expandedId: "" });
  };

  updateCourses = () => {
    const { courses, changeCoursesOrder } = this.props;
    changeCoursesOrder(courses);
  };

  render() {
    return (
      <DragDropContext
        onDragEnd={(param) => {
          const { courses } = this.props;
          const srcI = param.source.index;
          if (param.destination) {
            const desI = param.destination.index;
            courses.splice(desI, 0, courses.splice(srcI, 1)[0]);
            this.updateCourses();
            if (this.state.expandedId) {
              const expId = this.state.expandedId;
              this.onToggleExpandCancel();
              this.onToggleExpand(expId);
            }
          }
        }}
      >
        <ul className="lookup__list">
          <Droppable droppableId="droppable-1">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {this.props.courses.map((course, key) => (
                  <Draggable
                    key={key}
                    draggableId={"draggable-" + key}
                    index={key}
                  >
                    {(provided) => (
                      <CourseListItem
                        innerRef={provided.innerRef}
                        provided={provided}
                        key={key}
                        course={course}
                        searchMode={this.props.searchMode}
                        onToggleExpand={this.onToggleExpand}
                        onToggleExpandCancel={this.onToggleExpandCancel}
                        expanded={this.state.expandedId === course.course_id}
                        updateCourses={this.updateCourses}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </ul>
      </DragDropContext>
    );
  }
}

CoursesList.propTypes = {
  courses: PropTypes.array.isRequired,
  navLinkPath: PropTypes.string.isRequired,
};

const mapStateToProps = ({ selectMyCoursesReducer }) => {
  return {
    courses: selectMyCoursesReducer.myCoursesFetchReducer.courses,
    fetchCoursesStatus:
      selectMyCoursesReducer.myCoursesFetchReducer.fetchCoursesStatus,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeCoursesOrder, fetchTees }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CoursesList);
