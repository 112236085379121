import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  handicapCalculatorAddGolfer,
  handicapCalculatorHideAlert,
  handicapCalculatorRemoveGolfer,
} from "../../actions";
import { getDisplayScore } from "../../../../shared/helpers/ui-helper";
import {
  getHandicap,
  getGolferId,
  getUserGhinNo,
} from "../../../../shared/helpers/user-helper";
import { userExistInGolfers } from "../../../../shared/helpers/handicap-calculator-helper";

class GolfersListItem extends Component {
  onClickAdd = () => {
    const { handicapCalculatorAddGolfer, handicapCalculatorHideAlert, golfer } =
      this.props;
    handicapCalculatorAddGolfer(golfer);
    setTimeout(() => {
      handicapCalculatorHideAlert();
    }, 2000);
  };

  onClickRemove = () => {
    const { handicapCalculatorRemoveGolfer, golfer } = this.props;
    const golferId = getGolferId(golfer);
    const userId = parseInt(getUserGhinNo(), 10);
    if (golferId !== userId) handicapCalculatorRemoveGolfer(golferId);
  };

  render() {
    const { golfer } = this.props;
    const golferExist = userExistInGolfers(getGolferId(golfer));
    return (
      <Fragment>
        <li key={getGolferId(golfer)}>
          <div className="item">
            {golferExist ? (
              <button
                className="btn-ic ic_check"
                onClick={this.onClickRemove}
              />
            ) : (
              <button className="btn-ic ic_c_plus" onClick={this.onClickAdd} />
            )}
            <span className="details">
              <span className="name">
                {`${golfer.first_name} ${golfer.last_name} ${
                  golfer.suffix || ""
                }`}
              </span>
              <span className="club">{golfer.club_name}</span>
            </span>
            <span className="index">
              {getDisplayScore(getHandicap(golfer))}
            </span>
          </div>
        </li>
      </Fragment>
    );
  }
}

GolfersListItem.propTypes = {
  golfer: PropTypes.shape({}).isRequired,
  handicapCalculatorAddGolfer: PropTypes.func.isRequired,
  handicapCalculatorRemoveGolfer: PropTypes.func.isRequired,
  handicapCalculatorHideAlert: PropTypes.func.isRequired,
  golfers: PropTypes.array.isRequired,
};

const mapStateToProps = ({ handicapCalculatorReducer }) => ({
  golfers: handicapCalculatorReducer.calculatorGolfersReducer.golfers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handicapCalculatorAddGolfer,
      handicapCalculatorHideAlert,
      handicapCalculatorRemoveGolfer,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GolfersListItem);
