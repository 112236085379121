import { checkApiError } from "../../auth/actions";

export const FETCH_GOLFER_SCORES = "FETCH_GOLFER_SCORES";
export const FETCH_GOLFER_SCORES_FAILED = "FETCH_GOLFER_SCORES_FAILED";
export const FETCH_GOLFER_SCORES_SUCCESS = "FETCH_GOLFER_SCORES_SUCCESS";

export const fetchGolferScores = (ghinNumber) => ({
  type: FETCH_GOLFER_SCORES,
  payload: { ghinNumber },
});

export function fetchGolferScoresFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_GOLFER_SCORES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchGolferScoresSuccess = (payload) => ({
  type: FETCH_GOLFER_SCORES_SUCCESS,
  payload,
});
