import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserGenderAcronym } from "../../../../shared/helpers/user-helper";
import ManuallyForm from "./Components/ManuallyForm";
import {
  handicapCalculatorAddGolfer,
  handicapCalculatorHideAlert,
} from "../../actions";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import { Helmet } from "react-helmet";
import {
  H_CALC_MAN_INPUT_TITLE,
  H_CALC_MAN_INPUT_DESC,
} from "../../../../shared/variables/meta-seo";

class AllGolfers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forms: [this.generateEmptyGolfer()],
      formsCopy: [this.generateEmptyGolfer()],
      deleteForm: undefined,
    };
  }

  generateEmptyGolfer() {
    const golferGender = getUserGenderAcronym();
    return {
      manual_golfer_id: Math.floor(Date.now()),
      touched: false,
      valid: false,
      gender: {
        value: golferGender,
        label: golferGender === "M" ? "Male" : "Female",
      },
    };
  }

  addNewForm = () => {
    setTimeout(() => {
      const { forms, formsCopy } = this.state;
      forms.push(this.generateEmptyGolfer());
      formsCopy.push(this.generateEmptyGolfer());
      this.setState({ forms, formsCopy });
    }, 50);
  };

  onDelete = (deleteForm) => this.setState({ deleteForm });

  onConfirmDelete = () => {
    const { forms, deleteForm, formsCopy } = this.state;
    this.setState({
      forms: forms.filter(
        (f) => f.manual_golfer_id !== deleteForm.manual_golfer_id
      ),
      formsCopy: formsCopy.filter(
        (f) => f.manual_golfer_id !== deleteForm.manual_golfer_id
      ),
      deleteForm: undefined,
    });
  };

  onChangeFormValue = (form) => {
    const formsCopy = this.state.formsCopy.map((f) => {
      return f.manual_golfer_id === form.manual_golfer_id
        ? { ...f, ...form }
        : f;
    });
    this.setState({ formsCopy });
  };

  addGolfersToHC = () => {
    const { handicapCalculatorAddGolfer, handicapCalculatorHideAlert } =
      this.props;

    const { formsCopy } = this.state;
    const validGolfers = formsCopy.filter((f) => f.valid);
    validGolfers.forEach((golfer) => {
      const handicapIndex = Number(golfer.handicapIndex).toFixed(1);
      handicapCalculatorAddGolfer({
        ...golfer,
        first_name: "",
        last_name: golfer.name,
        gender: golfer.gender.value,
        handicap_index: handicapIndex,
        handicap_index_display: handicapIndex,
        display: handicapIndex,
      });
    });
    this.setState({
      forms: [this.generateEmptyGolfer()],
      formsCopy: [this.generateEmptyGolfer()],
    });
    setTimeout(() => {
      handicapCalculatorHideAlert();
    }, 2000);
  };

  getAddButtonState() {
    const { formsCopy } = this.state;
    const touchedGolfers = formsCopy.filter((f) => f.touched);
    const validGolfers = formsCopy.filter((f) => f.valid);
    return (
      touchedGolfers?.length === validGolfers?.length &&
      touchedGolfers?.length > 0
    );
  }

  render() {
    const { forms, deleteForm } = this.state;
    const buttonState = this.getAddButtonState();
    return (
      <Fragment>
        <Helmet>
          <title>{H_CALC_MAN_INPUT_TITLE}</title>
          <meta name="description" content={H_CALC_MAN_INPUT_DESC} />
        </Helmet>
        <div className="panel">
          {forms.map((form, index) => (
            <ManuallyForm
              key={form.manual_golfer_id}
              form={form}
              addButton={index === forms.length - 1}
              addNewForm={this.addNewForm}
              onDelete={this.onDelete}
              onChangeFormValue={this.onChangeFormValue}
            />
          ))}
          <div className="row">
            <div className="col auto h-centered" style={{ marginBottom: 30 }}>
              <button
                disabled={!buttonState}
                onClick={this.addGolfersToHC}
                type="submit"
                className="btn fill cardinal"
              >
                Add Golfers
              </button>
            </div>
          </div>
        </div>
        <YesNoModal
          reversedOrder
          isOpen={deleteForm !== undefined}
          onAbort={() => this.setState({ deleteForm: undefined })}
          onConfirm={this.onConfirmDelete}
        >
          <p>Are you sure you want to remove golfer?</p>
        </YesNoModal>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handicapCalculatorAddGolfer,
      handicapCalculatorHideAlert,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AllGolfers);
