import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Cell } from "recharts";
import {
  normalizeFloat,
  normalizeFloatRounded,
  renderSuperChartPercentage,
  stringToFloat,
} from "../../helpers/ui-helper";

const COLORS = ["#0088ce", "#00466e", "#ca243e"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.75;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent * 100 > 5) {
    return (
      <Fragment>
        <text
          className="putting__label"
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "middle" : "middle"}
          dominantBaseline="central"
        >
          <tspan className="label-value">
            {`${normalizeFloatRounded(normalizeFloat(percent, 100))}`}
          </tspan>
          <tspan baselineShift="super" className="label-percent">
            {"%"}
          </tspan>
        </text>
      </Fragment>
    );
  }
};

class PuttingChart extends Component {
  getPieData() {
    const data = [];
    const { statistics } = this.props;
    data.push({
      name: "Group A",
      value: normalizeFloat(statistics.one_putt_or_better_percent),
    });
    data.push({
      name: "Group B",
      value: normalizeFloat(statistics.two_putt_percent),
    });
    data.push({
      name: "Group C",
      value: normalizeFloat(statistics.three_putt_or_worse_percent),
    });
    return data;
  }

  handleNaScore = (score) =>
    score < 0.0000001 || isNaN(score) ? "N/A" : score;

  renderParSLabel = (parField) =>
    this.handleNaScore(stringToFloat(this.props.statistics[parField]));

  render() {
    const { statistics, showEmptyFilter } = this.props;
    let twoPutOrBetter =
      statistics.two_putt_or_better_percent ||
      statistics.one_putt_or_better_percent + statistics.two_putt_percent;

    if (
      statistics.two_putt_or_better_percent == null &&
      statistics.two_putt_percent == null &&
      statistics.one_putt_or_better_percent == null
    ) {
      twoPutOrBetter = null;
    }
    const pieData = this.getPieData();
    const emptyClass = showEmptyFilter ? "blackWhite blurred" : "";
    return (
      <Fragment>
        <div className={`putting ${emptyClass}`}>
          <h3 className="putting_chart-title">Putting Summary</h3>
          <div className="putting__container">
            <PieChart width={200} height={200}>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius="100%"
                startAngle={90}
                endAngle={-360}
                fill="#8884d8"
                dataKey="value"
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>

            <div className="putting-mid">
              <div className="putting-mid__percentage">
                {twoPutOrBetter == null
                  ? "N / A"
                  : renderSuperChartPercentage(
                      normalizeFloat(twoPutOrBetter, 100)
                    )}
              </div>
              <div className="putting-mid__label ">
                <center>
                  2 Putts Or <br /> Better
                </center>
              </div>
            </div>
          </div>

          <ul className="putting-legend">
            <li>
              <span className="putting-legend__color light-blue" />1 Putt or
              Better
            </li>
            <li>
              <span className="putting-legend__color dark-blue" />2 Putts
            </li>
            <li>
              <span className="putting-legend__color light-red" />3 Putts or
              Worse
            </li>
          </ul>
        </div>
      </Fragment>
    );
  }
}

PuttingChart.propTypes = {
  statistics: PropTypes.shape({}),
  showEmptyFilter: PropTypes.bool,
};

PuttingChart.defaultProps = {
  statistics: {
    pars_percent: 0.2,
    par3s_average: 3.24,
    par4s_average: 4.101,
    par5s_average: 5.501,
    bogeys_percent: 0.2,
    double_bogeys_percent: 0.06,
    birdies_or_better_percent: 0.44,
    triple_bogeys_or_worse_percent: 0.1,
  },
  showEmptyFilter: false,
};

export default PuttingChart;
