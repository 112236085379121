import React, { Component } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { overlayModalStyle } from "../../../../shared/helpers/ui-helper";
import { routes } from "../../../../shared/routes/index";

class AuthErrorModal extends Component {
  componentDidMount = () => Modal.setAppElement("body");

  onContactAssociation = () =>
    routes.contactUs.navigate("howCan=Provide email address");

  onForgotLogin = () => routes.forgotLogin.navigate();

  onCreateProfile = () => routes.createProfile.navigate();

  renderCTAButton() {
    const { errorBody } = this.props;
    if (errorBody?.display_contact_association_button) {
      return (
        <button
          className="btn outline blue background-focus2"
          onClick={this.onContactAssociation}
          aria-label="Contact Association"
        >
          CONTACT ASSOCIATION
        </button>
      );
    }

    if (errorBody?.display_forgot_login_information_button) {
      return (
        <button
          className="btn outline blue background-focus2"
          onClick={this.onForgotLogin}
          aria-label="Forgot Login Information"
        >
          FORGOT LOGIN INFORMATION
        </button>
      );
    }

    if (errorBody?.display_create_profile_button) {
      return (
        <button
          className="btn outline blue background-focus2"
          onClick={this.onCreateProfile}
          aria-label="Create Profile"
        >
          Create Profile
        </button>
      );
    }
  }

  render() {
    const { visible, onClose, onOk, errorBody } = this.props;
    return (
      <Modal
        isOpen={visible}
        style={overlayModalStyle}
        className="modal-dialog"
        contentLabel={errorBody?.top_line}
      >
        <div className="modal-content">
          <button className="modal_close" onClick={onClose} aria-label="Close">
            ✕
          </button>
          <div className="modal-body">
            <div className="content">
              <h3>{errorBody?.top_line}</h3>
              <p>{errorBody?.body_line1}</p>
              {!!errorBody?.body_line2 && (
                <>
                  <br />
                  <p>{errorBody?.body_line2}</p>
                </>
              )}
            </div>
            <div className="actions centered">
              {this.renderCTAButton()}
              <button
                className="btn fill cardinal background-focus"
                onClick={onOk}
                aria-label="Ok"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

AuthErrorModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  errorBody: PropTypes.shape({}),
};

AuthErrorModal.defaultProps = {
  errorBody: undefined,
};

export default AuthErrorModal;
