import ratingType from "../../../shared/variables/rating-type";
import scoreType from "../../../shared/variables/score-type";
import { belongHomeCoureses } from "../../../shared/helpers/home-courses-helper";
import {
  getBackPar,
  getFrontPar,
  getTotalPar,
} from "../validations/post-hbh-score-calculation";

export const getParDisplay = (teeSet, side, preChar) => {
  if (!teeSet || !teeSet.Holes.length || !side) return "";

  switch (side) {
    case ratingType.FRONT:
      return `${preChar}${getFrontPar(teeSet)}`;
    case ratingType.BACK:
      return `${preChar}${getBackPar(teeSet)}`;
    default:
      return `${preChar}${getTotalPar(teeSet)}`;
  }
};

export const getPostScoreApiErrors = (errors) => {
  if (errors) {
    let errorsMsg = [];
    if ("front9_adjusted" in errors || "back9_adjusted" in errors) {
      Object.keys(errors).forEach((error) => {
        const err =
          error.split("_")[0][0].toUpperCase() + error.split("_")[0].slice(1);
        const errorMrg = `${err} score ${errors[error][0]}`;
        errorsMsg.push(errorMrg);
      });
    } else if (errors.tee_set) {
      errorsMsg.push(errors.tee_set[0]);
    } else {
      Object.values(errors)[0].forEach((errorChild) => {
        errorsMsg.push("Adjusted gross score " + errorChild + ".");
      });
    }

    return errorsMsg;
  }
  return [];
};

export const initialValuesWithHomeCourse = (initialValues, courseId) => {
  const flag = belongHomeCoureses(courseId);
  return {
    ...initialValues,
    scoreType: flag ? scoreType.HOME : scoreType.AWAY,
  };
};

export const hasEighTeenHoles = (course, isManualCourse) => {
  if (isManualCourse) return true;
  if (course && course.TeeSets && course.TeeSets.length === 0) return true;
  return (
    course && course.TeeSets && course.TeeSets.some((r) => r.HolesNumber === 18)
  );
};

export const hasGenderTeeSets = (course, gender) =>
  course &&
  course.TeeSets &&
  course.TeeSets.length > 0 &&
  course.TeeSets.some((t) => t.Gender === gender);

export const hasGenderSlopeRating = (course, gender) =>
  course &&
  course.TeeSets &&
  course.TeeSets.some(
    (t) => t.Gender === gender && t.Ratings.some((r) => r.SlopeRating)
  );
