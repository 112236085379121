import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import DefaultCustomSelect from "../../../../shared/components/selects/DefaultCustomSelect";
import { selectClub } from "../../actions";
import { inputSearchDelay } from "../../../../shared/helpers/ui-helper";

class HeaderFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      selectedClub: undefined,
    };
    this.inputTimeout = 0;
  }

  getSelectOptionsClubs = () => {
    const { clubs } = this.props;
    return clubs.map((club) => ({
      value: club.Club,
      label: club.ClubName,
    }));
  };

  handleChangeClub = (selectedClub) => {
    this.props.selectClub(selectedClub.value);
    this.setState({ selectedClub });
  };

  handleChangeSearch = ({ target: { value } }) => {
    this.setState({ filter: value });
    if (this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.props.onSearch(value);
    }, inputSearchDelay);
  };

  render() {
    const { clubs } = this.props;
    const selectOptions = clubs.map((club) => ({
      value: club.id,
      label: club.club_name,
    }));
    const { selectedClub, filter } = this.state;
    return (
      <Fragment>
        <div className="section_header filters expand">
          <div className="form-group">
            <DefaultCustomSelect
              onChange={this.handleChangeClub}
              value={selectedClub || selectOptions[0]}
              options={selectOptions}
              title="Select Club"
              className="default_select full_on_phone minwidth"
              aria-label="Club"
            />
          </div>
          <div className="form-group">
            <div className="search-container">
              <input
                value={filter}
                onChange={this.handleChangeSearch}
                type="text"
                name="search"
                id="search"
                className="search"
                placeholder="ENTER NAME OR GHIN #"
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

HeaderFilter.propTypes = {
  selectClub: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  clubs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

HeaderFilter.defaultProps = {
  selectClub: undefined,
};

const mapStateToProps = ({ golfersReducer }) => ({
  clubs: golfersReducer.clubsReducer.clubs,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ selectClub }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderFilter);
