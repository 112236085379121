import {
  FETCH_GOLFER_PROFILE_HANDICAP_LOWHI,
  fetchGolferProfileHandicapLowhiSuccess,
  fetchGolferProfileHandicapLowhiFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";
import { getUser } from "../../../shared/helpers/user-helper";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_GOLFER_PROFILE_HANDICAP_LOWHI))
    .pipe(switchMap(() => getGolferHandicap()));

const getGolferHandicap = () =>
  from(GolferApi.getHandicapHistoryLowhi(getUser()))
    .pipe(
      map((response) =>
        fetchGolferProfileHandicapLowhiSuccess(response.data.golfers)
      )
    )
    .pipe(catchError(() => of(fetchGolferProfileHandicapLowhiFailed())));
