import MyCard from "../../screens/my-card/components/MyCard";
import DefaultLayout from "../components/layouts/DefaultLayout";

export default {
  myCard: {
    path: "/my-card",
    component: MyCard,
    exact: false,
    rootPath: true,
    authRequired: true,
    layout: DefaultLayout,
  },
};
