import allCoursesFetchReducer from "./all-courses-fetch";
import myCoursesFetchReducer from "./my-courses-fetch";
import teesFetchReducer from "./tees-fetch";
import countriesFetchReducer from "../../select-course/reducers/countries-fetch";
import { combineReducers } from "redux";

const reducers = combineReducers({
  allCoursesFetchReducer,
  myCoursesFetchReducer,
  teesFetchReducer,
  countriesFetchReducer,
});

export default reducers;
