const fetchInterval = 2 * 3600; // 2 hours
const showTimeAfterHide = 1 * 24 * 3600; // 1 day

export const shouldFetch = (lastFetch = undefined) => {
  const timestamp = Math.floor(Date.now() / 1000);
  if (!lastFetch || (lastFetch && timestamp > lastFetch + fetchInterval)) {
    return true;
  }
  return false;
};

export const shouldShowAlert = (alerts = [], hiddenAt = undefined) => {
  if (alerts.length === 0) return false;
  const timestamp = Math.floor(Date.now() / 1000);
  if (hiddenAt && timestamp < hiddenAt + showTimeAfterHide) {
    return false;
  }
  return true;
};
