import { combineEpics } from "redux-observable";
import statsScoresEpic from "./stats-scores-fetch";
import statsCombinedScoresEpic from "./stats-combined-scores-fetch";
import fetchCombinedScoresEpic from "./fetch-combined-scores";
import postCombinedScoresEpic from "./post-combined-scores";
import fetchAverageScores from "./stats-average-scores-fetch";
import getAverageScorePerHole from "./view-average-score-per-hole";

export default combineEpics(
  statsScoresEpic,
  statsCombinedScoresEpic,
  fetchCombinedScoresEpic,
  postCombinedScoresEpic,
  fetchAverageScores,
  getAverageScorePerHole
);
