import React, { Component } from "react";
import PropTypes from "prop-types";
import { changeCoursesOrder } from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CoursesListItem from "../../select-course/components/CoursesListItem";

class MyCoursesListItems extends Component {
  render = () => (
    <ul className="item-list__body">
      {this.props.courses.map((course, key) => (
        <CoursesListItem
          key={key}
          course={course}
          navLink={this.props.navItemLink.replace(
            ":courseId",
            course.course_id || course.CourseID
          )}
        />
      ))}
    </ul>
  );
}

MyCoursesListItems.propTypes = {
  courses: PropTypes.array.isRequired,
};

const mapStateToProps = ({ selectMyCoursesReducer }) => {
  return {
    courses:
      selectMyCoursesReducer.allCoursesFetchReducer.courses.length !== 0
        ? selectMyCoursesReducer.allCoursesFetchReducer.courses
        : selectMyCoursesReducer.myCoursesFetchReducer.courses,
    fetchCoursesStatus:
      selectMyCoursesReducer.allCoursesFetchReducer.courses.length !== 0
        ? selectMyCoursesReducer.allCoursesFetchReducer.fetchCoursesStatus
        : selectMyCoursesReducer.myCoursesFetchReducer.fetchCoursesStatus,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeCoursesOrder }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyCoursesListItems);
