import { combineReducers } from "redux";
import contactAssociationsReducer from "./contact-associations-fetch";
import contactProfileFetch from "./contact-profile-fetch";

const reducers = combineReducers({
  contactAssociationsReducer,
  contactProfileFetch,
});

export default reducers;
