import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";

class AdjustScoreInfoModal extends Component {
  render() {
    const { isOpen, onAbort } = this.props;
    return (
      <YesNoModal
        onAbort={onAbort}
        isOpen={isOpen}
        isYesVisible={false}
        isNoVisible={false}
        contentLabel="Adjusted Gross Score"
      >
        <h3>Adjusted Gross Score</h3>
        <p>
          Adjusted score determined by the maximum number of strokes you can
          post on a hole based on your Course Handicap.
        </p>
      </YesNoModal>
    );
  }
}

AdjustScoreInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAbort: PropTypes.func.isRequired,
};

export default AdjustScoreInfoModal;
