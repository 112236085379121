import teeSetSide from "../variables/tee-set-side";
import ratingType from "../variables/rating-type";

export const convertTeeSetSideToRatingType = (side) => {
  switch (side) {
    case teeSetSide.Total:
      return ratingType.TOTAL;
    case teeSetSide.All18:
      return ratingType.TOTAL;
    case teeSetSide.Front:
      return ratingType.FRONT;
    case teeSetSide.Back:
      return ratingType.BACK;
    default:
      return "";
  }
};
