import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import productAlertReducer from "./product-alert-fetch";

const reducers = combineReducers({
  productAlertReducer: persistReducer(
    {
      key: "productAlertReducer",
      storage: storage,
    },
    productAlertReducer
  ),
});

export default reducers;
