import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import GraphView from "../ScoreHistory/GraphView";

class GraphListHeader extends Component {
  state = {
    isGraphVisible: false,
  };

  toggleGraph = () => {
    this.setState((state) => ({
      isGraphVisible: !state.isGraphVisible,
    }));
  };

  renderGraph() {
    const { scores } = this.props;
    const { isGraphVisible } = this.state;
    return (
      <Fragment>
        <div className="pre-table__head">
          <button
            className={isGraphVisible ? "graph-view active" : "graph-view"}
            onClick={this.toggleGraph}
          >
            <span className="hide-on-phone">Graph view</span>
            <i className="material-icons-outlined icon">equalizer</i>
            <i className="material-icons-outlined open">expand_more</i>
            <i className="material-icons-outlined close">expand_less</i>
          </button>
        </div>
        {isGraphVisible && (
          <div className="pre-table__body">
            <GraphView scores={scores.slice(0)} defaultScoresPerPage={9} />
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const { hideGraph, scores9Holes } = this.props;
    return (
      <div className="pre-card__table">
        {!hideGraph && this.renderGraph()}
        <div className="pre-table__foot">
          <div className="round-with-advanced-stats">
            <span className="tiny">Round with advanced stats </span>
            <div className="btn comp">
              <i className="material-icons-outlined btn-in">trending_up</i>
            </div>
          </div>
          {scores9Holes.length > 0 && (
            <div className="round-with-advanced-stats">
              <span className="tiny">9-Hole Score waiting to be combined </span>
              <div className="legend9Holes" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

GraphListHeader.propTypes = {
  scores: PropTypes.array.isRequired,
  scores9Holes: PropTypes.array.isRequired,
  hideGraph: PropTypes.bool,
};

GraphListHeader.defaultProps = {
  hideGraph: false,
};

export default GraphListHeader;
