import golferProfileFetchReducer from "./golfer-profile-fetch";
import golferAssociationsFetchReducer from "./golfer-associations-fetch";
import golferProfileHandicapFetchReducer from "./golfer-profile-handicap-fetch.js";
import golferProfileHandicapLowhiFetchReducer from "./golfer-profile-handicap-lowhi-fetch";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";

const reducers = combineReducers({
  golferProfileFetchReducer: persistReducer(
    {
      key: "golferProfile",
      storage: storage,
    },
    golferProfileFetchReducer
  ),
  golferAssociationsFetchReducer: persistReducer(
    {
      key: "golferAssociations",
      storage: storage,
    },
    golferAssociationsFetchReducer
  ),
  golferProfileHandicapFetchReducer,
  golferProfileHandicapLowhiFetchReducer,
});

export default reducers;
