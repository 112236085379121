import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "./YesNoModal";

class MissedGreens extends Component {
  render() {
    const { isOpen, onAbort, isOkVisible } = this.props;
    return (
      <YesNoModal
        onAbort={onAbort}
        onConfirm={onAbort}
        isOpen={isOpen}
        isYesVisible={isOkVisible}
        isNoVisible={false}
        yesTitle="Ok"
      >
        <p>
          This percentage is based on Missed Greens in which no direction missed
          was indicated.
        </p>
      </YesNoModal>
    );
  }
}

MissedGreens.defaultProps = {
  isOkVisible: true,
};

MissedGreens.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAbort: PropTypes.func.isRequired,
  isOkVisible: PropTypes.bool,
};

export default MissedGreens;
