import {
  DELETE_ROUND_STATS,
  postEditRoundStatsFailed,
  postEditRoundStatsSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";

export default (action$) =>
  action$
    .pipe(ofType(DELETE_ROUND_STATS))
    .pipe(switchMap(({ payload }) => deleteRoundStats(payload)));

const deleteRoundStats = (payload) =>
  from(ScoresApi.deleteRoundStats(payload.score.id, payload.params))
    .pipe(map((response) => postEditRoundStatsSuccess(response.data.scores[0])))
    .pipe(catchError((error) => of(postEditRoundStatsFailed(error))));
