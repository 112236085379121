import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class ContentListSubHeader extends Component {
  render() {
    const { additionalData } = this.props;
    return (
      <Fragment>
        <div className="add-info__row">
          <div className="add-info__cell">
            # of Scores: {additionalData?.total_count || "0"}
          </div>
          <div className="add-info__cell">
            High: {additionalData?.highest_score || "-"}
          </div>
          <div className="add-info__cell">
            Low: {additionalData?.lowest_score || "-"}
          </div>
          <div className="add-info__cell">
            Avg.: {additionalData?.average || "-"}
          </div>
        </div>
      </Fragment>
    );
  }
}

ContentListSubHeader.propTypes = {
  additionalData: PropTypes.shape({}),
};

ContentListSubHeader.defaultProps = {
  additionalData: undefined,
};

export default ContentListSubHeader;
