import React, { Component, Fragment } from "react";
import FollowingList from "../golfers/components/Following";
import GolfersListItem from "./GolfersListItem";
import { Helmet } from "react-helmet";
import {
  GL_FOLLOWING_TITLE,
  GL_FOLLOWING_DESC,
} from "../../shared/variables/meta-seo";
export default class Following extends Component {
  renderItem = (golfer, key) => <GolfersListItem key={key} golfer={golfer} />;

  render() {
    const notFoundText = (
      <Fragment>
        <p>You are not following any golfers.</p>
        <br />
        <p>Select the star next to a golfer&rsquo;s name to follow.</p>
      </Fragment>
    );
    return (
      <Fragment>
        <Helmet>
          <title>{GL_FOLLOWING_TITLE}</title>
          <meta name="description" content={GL_FOLLOWING_DESC} />
        </Helmet>
        <FollowingList
          renderItem={this.renderItem}
          notFoundText={notFoundText}
          sortCriteria="last_name"
        />
      </Fragment>
    );
  }
}
