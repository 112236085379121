import jobStatus from "../../../shared/variables/job-status";
import {
  HIDE_PRODUCT_ALERT,
  RESET_PRODUCT_ALERT,
  FETCH_PRODUCT_ALERT,
  FETCH_PRODUCT_ALERT_SUCCESS,
  FETCH_PRODUCT_ALERT_FAILED,
} from "../actions";

const initialState = {
  alerts: [],
  lastFetch: undefined,
  hiddenAt: undefined,
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PRODUCT_ALERT:
      return {
        ...state,
        alerts: [],
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_PRODUCT_ALERT_SUCCESS:
      return {
        ...state,
        alerts: payload,
        lastFetch: Math.floor(Date.now() / 1000),
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_PRODUCT_ALERT_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    case HIDE_PRODUCT_ALERT:
      return {
        ...state,
        hiddenAt: Math.floor(Date.now() / 1000),
      };
    case RESET_PRODUCT_ALERT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
