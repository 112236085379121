import Stats from "../../screens/stats/components/Stats";
import ScoreHistory from "../../screens/stats/components/ScoreHistory/ScoreHistory";
import StatsCharts from "../../screens/stats/components/Stats/Stats";
import EditCombinedScores from "../../screens/stats/components/CombinedScores/EditCombinedScores";
import Navigate from "../navigation/navigate";
import DefaultLayout from "../components/layouts/DefaultLayout";
import ViewAvgHbhScore from "../../screens/stats/components/ScoreHistory/ViewAvgHbhScore";
import PostTotalScoreSummary from "../../screens/post-score/components/PostTotalScoreSummary/PostTotalScoreSummary";
import PostHbhScoreSummary from "../../screens/post-score/components/PostHbhScoreSummary/PostHbhScoreSummary";

export default {
  stats: {
    path: "/stats",
    component: Stats,
    exact: false,
    rootPath: true,
    authRequired: true,
    layout: DefaultLayout,
    navigate: () => Navigate.toPath("/stats"),
  },
  statsScoreHistory: {
    path: "/stats/score-history",
    component: ScoreHistory,
    exact: false,
    rootPath: false,
    authRequired: true,
    layout: DefaultLayout,
  },
  statsCharts: {
    path: "/stats/charts",
    component: StatsCharts,
    exact: false,
    rootPath: false,
    authRequired: true,
    layout: DefaultLayout,
    navigate: (score = undefined, onlyView = false) =>
      Navigate.toPath({
        pathname: "/stats/charts",
        state: { score, onlyView },
      }),
  },
  statsEditCombinedScore: {
    path: "/stats/edit-combined-score",
    component: EditCombinedScores,
    exact: false,
    rootPath: false,
    authRequired: true,
    layout: DefaultLayout,
    navigate: (score = undefined, onlyView = false) =>
      Navigate.toPath({
        pathname: "/stats/edit-combined-score",
        state: { score, onlyView },
      }),
  },
  viewAvgScorePerHole: {
    path: "/stats/view-avg-score-per-hole",
    component: ViewAvgHbhScore,
    exact: false,
    rootPath: false,
    authRequired: true,
    layout: DefaultLayout,
    navigate: (golferId, selectedClub) =>
      Navigate.toPath({
        pathname: "/stats/view-avg-score-per-hole",
        state: { golferId, selectedClub },
      }),
  },
  roundSummaryTotal: {
    path: "/stats/total-round-summary",
    component: PostTotalScoreSummary,
    exact: false,
    rootPath: false,
    authRequired: true,
    layout: DefaultLayout,
    navigate: (score = undefined, courseDetails = undefined) =>
      Navigate.toPath({
        pathname: "/stats/total-round-summary",
        state: { score: score, courseDetails: courseDetails },
      }),
  },
  roundSummaryHbh: {
    path: "/stats/hbh-round-summary",
    component: PostHbhScoreSummary,
    exact: false,
    rootPath: false,
    authRequired: true,
    layout: DefaultLayout,
    navigate: (
      score = undefined,
      isAdvStatsOn = undefined,
      courseDetails = undefined
    ) =>
      Navigate.toPath({
        pathname: "/stats/hbh-round-summary",
        state: {
          score: score,
          courseDetails: courseDetails,
          isAdvStatsOn: isAdvStatsOn,
        },
      }),
  },
};
