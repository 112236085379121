import {
  FETCH_MINORS,
  fetchMinorsSuccess,
  fetchMinorsFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$.pipe(ofType(FETCH_MINORS)).pipe(switchMap(() => action()));

const action = () =>
  from(GolferApi.fetchMinors())
    .pipe(map((response) => fetchMinorsSuccess(response.data)))
    .pipe(catchError(() => of(fetchMinorsFailed())));
