import React, { Component, Fragment } from "react";
import HamburgerMenuLoggedLinks from "./HamburgerMenuLoggedLinks";
import {
  active,
  hiddenOnTabletsAndAbove,
} from "../../../../shared/helpers/ui-helper";
import PropTypes from "prop-types";
import HamburgerMenuNotLoggedLinks from "./HamburgerNotLoggedLinks";

class HamburgerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: false };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState((state) => ({
      isToggleOn: !state.isToggleOn,
    }));
  }

  render() {
    const { isLoggedIn, logout } = this.props;
    return (
      <Fragment>
        <div
          className={`menu-ham__container ${hiddenOnTabletsAndAbove(
            !isLoggedIn()
          )}`}
        >
          <button
            onClick={this.toggleMenu}
            className={`menu-ham__trigger border-focus ${active(
              this.state.isToggleOn
            )}`}
            aria-labelledby="ham-button-info"
            aria-expanded={this.state.isToggleOn}
          >
            <span />
            <span />
            <span />
          </button>
          <div className={`menu-ham__content ${active(this.state.isToggleOn)}`}>
            <p id="ham-button-info" className="invisible">
              {this.state.isToggleOn
                ? "Close Hamburger Menu"
                : "Open Hamburger Menu"}
            </p>
            {isLoggedIn() ? (
              <HamburgerMenuLoggedLinks
                onClick={() => this.toggleMenu()}
                logout={logout}
                isToggleOn={this.state.isToggleOn}
              />
            ) : (
              <HamburgerMenuNotLoggedLinks onClick={() => this.toggleMenu()} />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

HamburgerMenu.propTypes = {
  isLoggedIn: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default HamburgerMenu;
