import allCoursesFetchEpic from "./all-courses-fetch";
import myCoursesFetchEpic from "./my-courses-fetch";
import addMyCourseEpic from "./add-course";
import removeMyCourseEpic from "./remove-course";
import teesFetchEpic from "./tees-fetch";
import addTeeEpic from "./add-tee";
import changeCoursesOrder from "./change-courses-order";
import countriesFetchEpic from "../../select-course/epics/countries-fetch";
import { combineEpics } from "redux-observable";

export default combineEpics(
  allCoursesFetchEpic,
  countriesFetchEpic,
  myCoursesFetchEpic,
  addMyCourseEpic,
  removeMyCourseEpic,
  teesFetchEpic,
  addTeeEpic,
  changeCoursesOrder
);
