import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

class CoursesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedState: this.defaultOption };
  }

  defaultOption = {
    value: {},
    label: "State or Country",
  };

  delimiter = {
    label: (
      <div style={{ width: "100%", height: "1px", background: "#CACACA" }} />
    ),
    isDisabled: "yes",
  };

  handleChange = (selection) => {
    this.setState({ selectedState: selection });
    this.props.onSelect(selection.value);
  };

  getOptions = (countries) => {
    let result = [this.defaultOption];

    countries.forEach((country) => {
      result = result.concat(this.getStates(country));
      result.push(this.delimiter);
    });

    return result;
  };

  getStates = (country) =>
    country.states.map((state) => ({
      value: { country: country.crs_code, courseCode: state.course_code },
      label: state.name,
    }));

  render = () => (
    <div className="item-list__actions">
      <label className="regular black">Filter by</label>
      <Select
        className={"default_select"}
        classNamePrefix={"ds"}
        value={this.state.selectedState}
        onChange={this.handleChange}
        options={this.getOptions(this.props.countries)}
      />
    </div>
  );
}

CoursesFilter.propTypes = {
  countries: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CoursesFilter;
