import { checkApiError } from "../../auth/actions";

export const FETCH_CONTACT_ASSOCIATIONS = "FETCH_CONTACT_ASSOCIATIONS";
export const FETCH_CONTACT_ASSOCIATIONS_FAILED =
  "FETCH_CONTACT_ASSOCIATIONS_FAILED";
export const FETCH_CONTACT_ASSOCIATIONS_SUCCESS =
  "FETCH_CONTACT_ASSOCIATIONS_SUCCESS";

export const fetchContactAssociations = () => ({
  type: FETCH_CONTACT_ASSOCIATIONS,
});

export function fetchContactAssociationsFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_CONTACT_ASSOCIATIONS_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchContactAssociationsSuccess = (payload) => ({
  type: FETCH_CONTACT_ASSOCIATIONS_SUCCESS,
  payload,
});
