import {
  FETCH_MANUAL_COURSE_HANDICAP,
  fetchManualCourseHandicapFailed,
  fetchManualCourseHandicapSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import SupportingCalculationApi from "../../../services/supporting-calculation-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_MANUAL_COURSE_HANDICAP))
    .pipe(switchMap(({ payload }) => fetchManualCourseHandicap(payload)));

const fetchManualCourseHandicap = (payload) =>
  from(
    SupportingCalculationApi.getManualCourseHandicaps(
      payload.golferId,
      payload.courseRating,
      payload.slopeRating,
      payload.nrOfHoles
    )
  )
    .pipe(map((response) => fetchManualCourseHandicapSuccess(response.data)))
    .pipe(catchError((error) => of(fetchManualCourseHandicapFailed(error))));
