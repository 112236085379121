import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "./YesNoModal";

class HandicapInfoModal extends Component {
  render() {
    const { isOpen, onAbort, isOkVisible, title, message, htmlMessage } =
      this.props;
    return (
      <YesNoModal
        onAbort={onAbort}
        onConfirm={onAbort}
        isOpen={isOpen}
        isYesVisible={isOkVisible}
        isNoVisible={false}
        yesTitle="Ok"
      >
        <h3>{title}</h3>
        {message.split("\n").map((str) => (
          <p style={{ whiteSpace: "pre-line" }} key={str}>
            {str}
          </p>
        ))}
        {htmlMessage && (
          <div dangerouslySetInnerHTML={{ __html: htmlMessage }} />
        )}
      </YesNoModal>
    );
  }
}

HandicapInfoModal.defaultProps = {
  isOkVisible: false,
  title: "Your Course Handicap™",
  message:
    "Indicates the number of strokes you need to play to Par for the selected tees and to adjust hole scores for handicap purposes.",
};

HandicapInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAbort: PropTypes.func.isRequired,
  isOkVisible: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
};

export default HandicapInfoModal;
