/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderButton from "../../../../shared/components/buttons/LoaderButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { patchMyProfile } from "../../actions";
import jobStatus from "../../../../shared/variables/job-status";

const FIELD = "phone_number";
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
class Phone extends Component {
  state = {
    expanded: false,
  };

  validationSchema = Yup.object({
    phone_number: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
  });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { patchStatus, patchError } = nextProps;
    if (this.props.activeField === FIELD) {
      if (
        patchStatus !== this.props.patchStatus &&
        patchStatus === jobStatus.FAILED
      ) {
        this.props.onShowErrorModal(patchError);
      }

      if (
        patchStatus !== this.props.patchStatus &&
        patchStatus === jobStatus.SUCCESS
      ) {
        this.onToggleExpand();
      }
    }
  }

  onToggleExpand = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
    this.formOptions.resetForm();
  };

  onToggleExpandJustOnEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.onToggleExpand();
    }
  };

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    const { profile } = this.props;
    this.props.patchMyProfile({
      club_id: profile.club_id,
      ghin: profile.id,
      activeField: FIELD,
      data: {
        golfer: {
          [FIELD]: values.phone_number,
        },
      },
    });
  };

  renderForm(options) {
    return (
      <div className="p-row">
        <div className="l-col blank"></div>
        <div className="r-col">
          <div className="row">
            <div className="col">
              <label>Phone Number</label>
              <input
                type="text"
                className="large2"
                id="phone_number"
                value={options.values.phone_number}
                name="phone_number"
                onBlur={options.handleBlur}
                onChange={options.handleChange}
                aria-label="Enter Phone Number"
              />
              <span className="error">
                {options.touched.phone_number
                  ? options.errors.phone_number
                  : ""}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                onClick={this.onToggleExpand}
                className="btn blank zero-pad blue"
              >
                Cancel
              </button>
            </div>
            <div className="col">
              <LoaderButton
                type="submit"
                className="btn fill cardinal"
                onClick={options.handleSubmit}
                disabled={!options.isValid}
                loading={String(
                  this.props.patchStatus === jobStatus.PROCESSING &&
                    this.props.activeField === FIELD
                )}
              >
                Save Changes
              </LoaderButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { expanded } = this.state;
    const { profile } = this.props;
    return (
      <div className="accordion-item">
        <div
          className={
            "accordion-item__head " +
            (expanded ? "is-open" : "") +
            "border-focus"
          }
          onClick={this.onToggleExpand}
          tabIndex="0"
          role="button"
          onKeyDown={this.onToggleExpandJustOnEnter}
        >
          <div className="p-row">
            <div className="l-col">
              <span className="accordion-item__label">Phone Number</span>
            </div>
            <div className="r-col">
              <div className="accordion-item__preview">
                {profile.phone_number}
              </div>
            </div>
          </div>
        </div>
        <div className={"accordion-item__body " + (expanded ? "is-open" : "")}>
          <Formik
            initialValues={{
              phone_number: profile.phone_number,
            }}
            onSubmit={this.onSubmit}
            validationSchema={this.validationSchema}
          >
            {(options) => {
              this.formOptions = options;
              return this.renderForm(options);
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ myProfileReducer }) => ({
  patchError: myProfileReducer.patchProfileReducer.error,
  patchStatus: myProfileReducer.patchProfileReducer.status,
  activeField: myProfileReducer.patchProfileReducer.activeField,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ patchMyProfile }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Phone);
