import {
  filterGhinMemberships,
  hasActiveMembership,
  hasTrialMembership,
} from "../../../shared/helpers/user-helper";
import jobStatus from "../../../shared/variables/job-status";
import {
  GOLFER_PROFILE_FETCH,
  GOLFER_PROFILE_FETCH_FAILED,
  GOLFER_PROFILE_FETCH_SUCCESS,
  GOLFER_PROFILE_LOGOUT,
  APPEND_LOGO_TO_MEMBERSHIPS,
} from "../actions";

const initialState = {
  golferProfile: undefined,
  golferMemberships: [],
  fetchGolferProfileStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GOLFER_PROFILE_FETCH:
      return {
        ...state,
        fetchGolferProfileStatus: jobStatus.PROCESSING,
      };
    case GOLFER_PROFILE_FETCH_FAILED:
      return {
        ...state,
        fetchGolferProfileStatus: jobStatus.FAILED,
      };
    case GOLFER_PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        golferProfile: payload[0],
        golferMemberships: filterGhinMemberships(payload),
        golferActive: hasActiveMembership(payload),
        trialGolfer: hasTrialMembership(payload),
        fetchGolferProfileStatus: jobStatus.SUCCESS,
      };
    case APPEND_LOGO_TO_MEMBERSHIPS:
      return {
        ...state,
        golferMemberships: payload,
      };
    case GOLFER_PROFILE_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
