import jobStatus from "../../../shared/variables/job-status";

import {
  MY_PROFILE_PATCH,
  MY_PROFILE_PATCH_SUCCESS,
  MY_PROFILE_PATCH_FAILED,
} from "../actions";

const initialState = {
  status: jobStatus.NOT_STARTED,
  error: undefined,
  activeField: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MY_PROFILE_PATCH:
      return {
        ...state,
        status: jobStatus.PROCESSING,
        activeField: payload.activeField,
        error: undefined,
      };
    case MY_PROFILE_PATCH_SUCCESS: {
      return {
        ...state,
        status: jobStatus.SUCCESS,
        error: payload?.warning_message,
        activeField: undefined,
      };
    }
    case MY_PROFILE_PATCH_FAILED: {
      return {
        ...state,
        status: jobStatus.FAILED,
        error: payload.errors,
        activeField: undefined,
      };
    }
    default:
      return state;
  }
};
