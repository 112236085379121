import { checkApiError } from "../../auth/actions";
import { decrypt } from "../../../shared/helpers/encoder";

export const FETCH_FOLLOWED_GOLFERS = "FETCH_FOLLOWED_GOLFERS";
export const FETCH_FOLLOWED_GOLFERS_FAILED = "FETCH_FOLLOWED_GOLFERS_FAILED";
export const FETCH_FOLLOWED_GOLFERS_SUCCESS = "FETCH_FOLLOWED_GOLFERS_SUCCESS";
export const FOLLOW_GOLFER = "FOLLOW_GOLFER";
export const FOLLOW_GOLFER_SUCCESS = "FOLLOW_GOLFER_SUCCESS";
export const FOLLOW_GOLFER_FAILED = "FOLLOW_GOLFER_FAILED";
export const UN_FOLLOW_GOLFER = "UN_FOLLOW_GOLFER";
export const UN_FOLLOW_GOLFER_SUCCESS = "UN_FOLLOW_GOLFER_SUCCESS";
export const UN_FOLLOW_GOLFER_FAILED = "UN_FOLLOW_GOLFER_FAILED";
export const RESET_FOLLOWED_GOLFERS = "RESET_FOLLOWED_GOLFERS";

export const fetchFollowedGolfers = (sortCriteria = undefined) => ({
  type: FETCH_FOLLOWED_GOLFERS,
  payload: { sortCriteria },
});

export function fetchFollowedGolfersFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_FOLLOWED_GOLFERS_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchFollowedGolfersSuccess = (payload, sortCriteria = null) => {
  const ordered =
    payload && sortCriteria
      ? payload.sort((a, b) => a[sortCriteria].localeCompare(b[sortCriteria]))
      : payload;

  return {
    type: FETCH_FOLLOWED_GOLFERS_SUCCESS,
    payload: ordered,
  };
};

export const followGolfer = (followed_golfer_id, club_id) => ({
  type: FOLLOW_GOLFER,
  payload: { followed_golfer_id: decrypt(followed_golfer_id), club_id },
});

export const followGolferSuccess = (payload) => ({
  type: FOLLOW_GOLFER_SUCCESS,
  payload,
});

export function followGolferFailed(error) {
  return (dispatch) => {
    dispatch({ type: FOLLOW_GOLFER_FAILED });
    dispatch(checkApiError(error));
  };
}

export const unFollowGolfer = (followed_golfer_id, club_id) => ({
  type: UN_FOLLOW_GOLFER,
  payload: { followed_golfer_id: decrypt(followed_golfer_id), club_id },
});

export const unFollowGolferSuccess = (payload) => ({
  type: UN_FOLLOW_GOLFER_SUCCESS,
  payload,
});

export function unFollowGolferFailed(error) {
  return (dispatch) => {
    dispatch({ type: UN_FOLLOW_GOLFER_FAILED });
    dispatch(checkApiError(error));
  };
}

export const resetFollowedGolfers = () => ({
  type: RESET_FOLLOWED_GOLFERS,
});
