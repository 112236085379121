import { checkApiError } from "../../auth/actions";

export const FETCH_PLAYING_HANDICAPS = "FETCH_PLAYING_HANDICAPS";
export const FETCH_PLAYING_HANDICAPS_FAILED = "FETCH_PLAYING_HANDICAPS_FAILED";
export const FETCH_PLAYING_HANDICAPS_SUCCESS =
  "FETCH_PLAYING_HANDICAPS_SUCCESS";

export const fetchPlayingHandicaps = (golfers) => ({
  type: FETCH_PLAYING_HANDICAPS,
  payload: { golfers },
});

export function fetchPlayingHandicapsFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_PLAYING_HANDICAPS_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchPlayingHandicapsSuccess = (payload) => ({
  type: FETCH_PLAYING_HANDICAPS_SUCCESS,
  payload,
});
