import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { getScoreStatsType } from "../../../shared/helpers/stats-helper";
import ChartType from "../../../shared/variables/score-chart-type";
import {
  normalizeFloatRounded,
  renderChartPercentage,
  renderSuperChartPercentage,
  stringToFloat,
} from "../../helpers/ui-helper";

import MissedGreens from "../modals/MissedGreensModal";
import PuttingChart from "../charts/PuttingChart";

class AdvancedStats extends Component {
  state = {
    showMissedGreensModal: false,
  };

  toggleMissedGreensModal = () => {
    this.setState((prevState) => ({
      showMissedGreensModal: !prevState.showMissedGreensModal,
    }));
  };

  closeMissedGreensModal = () =>
    this.setState({ showMissedGreensModal: false });

  render() {
    const {
      statistics,
      showEmptyStats,
      showEmptyFilter,
      golferActive,
      onAddStatsToRound,
      renderAdvancedStatsButton,
    } = this.props;
    if (
      getScoreStatsType(statistics) !== ChartType.ADVANCED_STATS &&
      !showEmptyStats
    ) {
      return (
        golferActive && (
          <div className="advanced-stats-panel" style={{ alignSelf: "center" }}>
            <div className="centered-item">
              <button
                onClick={() => onAddStatsToRound()}
                className="btn outline blue"
              >
                ADD STATS TO ROUND
              </button>
            </div>
          </div>
        )
      );
    }

    const emptyClass = showEmptyFilter ? "blackWhite blurred" : "";
    const missedGeneralApproachShotAccuracyPercent = normalizeFloatRounded(
      statistics.missed_general_approach_shot_accuracy_percent,
      100
    );
    return (
      <Fragment>
        <div className={`advanced-stats-panel ${emptyClass}`}>
          <div className="head-stats__container">
            <div className="head-stat__item putts-box">
              <span className="head-stat__label">Putts</span>
              <span className="head-stat__value">
                {this.props.puttsPrecision !== undefined
                  ? stringToFloat(
                      statistics.putts_total,
                      this.props.puttsPrecision
                    )
                  : normalizeFloatRounded(statistics.putts_total)}
              </span>
            </div>
            <div className="head-stat__item gir-box">
              <PuttingChart statistics={statistics} />
            </div>
          </div>
          <div className="head-stats__container">
            <div className="head-stat__item putts-box">
              <span className="head-stat__label">
                Up &<br />
                Downs
              </span>
              <span className="head-stat__value">
                {statistics.up_and_downs_total == null
                  ? "N/A"
                  : this.props.upAndDownsPrecision !== undefined
                  ? stringToFloat(
                      statistics.up_and_downs_total,
                      this.props.upAndDownsPrecision
                    )
                  : normalizeFloatRounded(statistics.up_and_downs_total)}
              </span>
            </div>
            <div className="head-stat__item gir-box">
              <div className="gir-chart">
                <h3 className="gir_chart-title">Approach Shot Accuracy</h3>
                <div className="gir-chart__body">
                  <span className="gir-chart-label north">
                    {renderSuperChartPercentage(
                      normalizeFloatRounded(
                        statistics.missed_long_approach_shot_accuracy_percent,
                        100
                      )
                    )}{" "}
                    Long
                  </span>
                  <span className="gir-chart-label east">
                    {renderSuperChartPercentage(
                      normalizeFloatRounded(
                        statistics.missed_right_approach_shot_accuracy_percent,
                        100
                      )
                    )}{" "}
                    Right
                  </span>
                  <span className="gir-chart-label south">
                    {renderSuperChartPercentage(
                      normalizeFloatRounded(
                        statistics.missed_short_approach_shot_accuracy_percent,
                        100
                      )
                    )}{" "}
                    Short
                  </span>
                  <span className="gir-chart-label west">
                    {renderSuperChartPercentage(
                      normalizeFloatRounded(
                        statistics.missed_left_approach_shot_accuracy_percent,
                        100
                      )
                    )}{" "}
                    Left
                  </span>
                  <div className="gir-chart-circle">
                    <span>GIR</span>
                    <strong>
                      {renderSuperChartPercentage(
                        normalizeFloatRounded(statistics.gir_percent, 100)
                      )}
                    </strong>
                  </div>
                  <span className="gir-chart-line horizontal"></span>
                  <span className="gir-chart-line vertical"></span>
                </div>
                {missedGeneralApproachShotAccuracyPercent > 1 ? (
                  <div className="gir-chart__footer">
                    {renderSuperChartPercentage(
                      missedGeneralApproachShotAccuracyPercent
                    )}
                    &nbsp; Missed (Other)
                    <button
                      onClick={this.toggleMissedGreensModal}
                      className="info-modal-trigger"
                      aria-label="Info"
                    >
                      i
                    </button>
                  </div>
                ) : (
                  <div className="gir-chart__footer"></div>
                )}
              </div>
            </div>
          </div>
          <div className="static-umbrella">
            <h4 className="static-umbrella__title">Driving Accuracy</h4>
            <div className="static-umbrella__container">
              <div className="umbrella layer-one red" />
              <div className="umbrella layer-two blue" />
              <div className="umbrella layer-three red" />

              <div className="umbrella__separator one" />
              <div className="umbrella__separator two" />
              <div className="static-umbrella__labels">
                <div className="static-umbrella__label left">
                  <span className="label__title">Missed Left</span>
                  <i className="material-icons-outlined">arrow_back</i>
                  <span className="label__percentage">
                    {renderSuperChartPercentage(
                      normalizeFloatRounded(statistics.missed_left_percent, 100)
                    )}
                  </span>
                </div>
                <div className="static-umbrella__label mid">
                  <span className="label__title">Fairway Hit</span>
                  <span className="label__percentage">
                    {renderSuperChartPercentage(
                      normalizeFloatRounded(
                        statistics.fairway_hits_percent,
                        100
                      )
                    )}
                  </span>
                </div>
                <div className="static-umbrella__label right">
                  <span className="label__title">Missed Right</span>
                  <i className="material-icons-outlined">arrow_forward</i>
                  <span className="label__percentage">
                    {renderSuperChartPercentage(
                      normalizeFloatRounded(
                        statistics.missed_right_percent,
                        100
                      )
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="static-umbrella__footer">
              <div className="footer-item">
                {renderChartPercentage(
                  normalizeFloatRounded(statistics.missed_short_percent, 100)
                )}
                Missed Short
              </div>
              <div className="footer-item">
                {renderChartPercentage(
                  normalizeFloatRounded(statistics.missed_long_percent, 100)
                )}
                Missed Long
              </div>
            </div>
          </div>
          <div className="row-action">
            {golferActive &&
              renderAdvancedStatsButton &&
              renderAdvancedStatsButton()}
          </div>
        </div>
        <MissedGreens
          isOpen={this.state.showMissedGreensModal}
          onAbort={this.closeMissedGreensModal}
          className="modal-dialog"
          appElement={document.getElementById("root")}
          isNoVisible={false}
          yesTitle="Ok"
        ></MissedGreens>
      </Fragment>
    );
  }
}

AdvancedStats.defaultProps = {
  showEmptyStats: false,
  showEmptyFilter: false,
  onAddStatsToRound: () => {},
  statistics: {
    gir_percent: 0.5,
    putts_total: 36,
    missed_left_percent: 0.29,
    missed_long_percent: 0.0,
    fairway_hits_percent: 0.5,
    missed_right_percent: 0.14,
    missed_short_percent: 0.07,
    missed_left_approach_shot_accuracy_percent: 0.15,
    missed_right_approach_shot_accuracy_percent: 0.15,
    missed_long_approach_shot_accuracy_percent: 0.1,
    missed_short_approach_shot_accuracy_percent: 0.1,
    missed_general_approach_shot_accuracy_percent: 0,
    one_putt_or_better_percent: 0.11,
    two_putt_percent: 0.72,
    three_putt_or_worse_percent: 0.17,
    up_and_downs_total: 2.5,
  },
  courseId: null,
  renderAdvancedStatsButton: null,
};

AdvancedStats.propTypes = {
  statistics: PropTypes.shape({}),
  showEmptyStats: PropTypes.bool,
  showEmptyFilter: PropTypes.bool,
  onAddStatsToRound: PropTypes.func.isRequired,
  courseId: PropTypes.string,
  renderAdvancedStatsButton: PropTypes.func,
};

export default AdvancedStats;
