import {
  RECENT_SCORES_FETCH,
  fetchRecentScoresSuccess,
  fetchRecentScoresFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";

export default (action$) =>
  action$
    .pipe(ofType(RECENT_SCORES_FETCH))
    .pipe(switchMap(({ payload }) => fetchRecentScores(payload)));

const fetchRecentScores = (payload) =>
  from(ScoresApi.getRecentScores(payload.offset, payload.limit, true))
    .pipe(map((response) => fetchRecentScoresSuccess(response.data.scores)))
    .pipe(catchError((error) => of(fetchRecentScoresFailed(error))));
