import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import jobStatus from "../../../../../shared/variables/job-status";
import { resendVerification } from "../../../actions";
import AuthErrorModal from "../../common/AuthErrorModal";
import LoaderButton from "../../../../../shared/components/buttons/LoaderButton";

class SuccessBody extends Component {
  state = {
    failedModalOpen: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { postStatus } = nextProps;
    if (
      postStatus !== this.props.postStatus &&
      postStatus === jobStatus.FAILED
    ) {
      this.setState({ failedModalOpen: true });
    }
  }

  onResendVerification = () =>
    this.props.resendVerification(this.props.userProperties);

  render() {
    const { failedModalOpen } = this.state;
    const { message, onBackToLogin, postStatus, error } = this.props;
    const link = <a href="/contact-us">Golf Association</a>;

    return (
      <div className="create-profile">
        <div className="success-icon" />
        <div className="success-title">{message}</div>
        <button className="btn fill cardinal center" onClick={onBackToLogin}>
          BACK TO LOG IN
        </button>
        <br />
        <LoaderButton
          suffix="-reverse"
          className="btn outline blue center"
          onClick={this.onResendVerification}
          disabled={postStatus === jobStatus.PROCESSING}
          loading={String(postStatus === jobStatus.PROCESSING)}
        >
          RESEND LINK TO EMAIL
        </LoaderButton>

        <div className="field-place-holder" />
        <div className="bottom-link">
          If you no longer have access to your email address or did not receive
          the link, please contact your Club or {link}.
        </div>
        <AuthErrorModal
          errorBody={error}
          visible={failedModalOpen}
          onClose={() => this.setState({ failedModalOpen: false })}
          onOk={() => this.setState({ failedModalOpen: false })}
        />
      </div>
    );
  }
}

SuccessBody.propTypes = {
  message: PropTypes.string.isRequired,
  onBackToLogin: PropTypes.func.isRequired,
  userProperties: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ authReducer }) => ({
  postStatus: authReducer.resendVerificationReducer.status,
  successMessage: authReducer.resendVerificationReducer.successMessage,
  error: authReducer.resendVerificationReducer.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resendVerification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SuccessBody);
