import {
  FETCH_COURSES,
  fetchCoursesFailed,
  fetchCoursesRecentScoresSuccess,
  fetchCoursesRecentScoresFailed,
  fetchCoursesSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import CoursesApi from "../../../services/courses-api";
import { isEmpty } from "../../../shared/helpers/ui-helper";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_COURSES))
    .pipe(
      switchMap(({ payload }) =>
        isEmptySearch(payload)
          ? fetchRecentCourses(payload)
          : fetchCourses(payload)
      )
    );

const fetchCourses = (payload) =>
  from(
    CoursesApi.getCourses(payload.searchPhrase, payload.country, payload.state)
  )
    .pipe(map((r) => fetchCoursesSuccess(r.data.courses)))
    .pipe(catchError((error) => of(fetchCoursesFailed(error))));

const fetchRecentCourses = () =>
  from(CoursesApi.getMostRecentCourses())
    .pipe(map((r) => fetchCoursesRecentScoresSuccess(r.data.courses)))
    .pipe(catchError((error) => of(fetchCoursesRecentScoresFailed(error))));

const isEmptySearch = (payload) => isEmpty(payload.searchPhrase);
