import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_GOLFER_PROFILE_HANDICAP_LOWHI,
  FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_SUCCESS,
  FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_FAILED,
} from "../actions";

const initialState = {
  golfer: undefined,
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_GOLFER_PROFILE_HANDICAP_LOWHI:
      return {
        ...state,
        golfer: {},
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_SUCCESS:
      return {
        ...state,
        golfer: payload,
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    default:
      return state;
  }
};
