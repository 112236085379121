import React, { Component } from "react";
import LoaderButton from "../../../../../shared/components/buttons/LoaderButton";

class SubmitButtons extends Component {
  render() {
    const { canSubmit, onSubmit, onCancel, status } = this.props;
    return (
      <>
        <br />
        <div className="row">
          <div className="col">
            <button onClick={onCancel} className="btn blank zero-pad blue">
              Cancel
            </button>
          </div>
          <div className="col">
            <LoaderButton
              className="btn fill cardinal"
              onClick={onSubmit}
              disabled={!canSubmit}
              loading={String(status)}
            >
              Save Changes
            </LoaderButton>
          </div>
        </div>
      </>
    );
  }
}

export default SubmitButtons;
