import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { emailChangeInformation, confirmEmailChange } from "../../actions";
import DisplayStatusModal from "../common/DisplayStatusModal";
import LoaderButton from "../../../../shared/components/buttons/LoaderButton";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import Loader from "../../../../shared/components/loaders/Loader";
import Navigate from "../../../../shared/navigation/navigate";
import jobStatus from "../../../../shared/variables/job-status";
import { Formik } from "formik";
import * as Yup from "yup";

class ConfirmEmailChange extends Component {
  state = {
    changeStatus: 0,
    changeStatusMessage: 0,
    showPassword: false,
    new_email: undefined,
    golfer_id: undefined,
  };

  validationSchema = Yup.object({
    password: Yup.string().required("Password Required"),
  });

  componentDidMount() {
    const token = this.props?.match?.params?.token;
    const params = new URLSearchParams(this.props?.location?.search);

    this.setState({ new_email: params.get("email") });
    this.setState({ golfer_id: params.get("golfer_id") });

    if (token) {
      this.props.emailChangeInformation({
        token,
        golfer_id: params.get("golfer_id"),
      });
    } else {
      this.redirectToHome();
    }
  }

  redirectToHome = () => Navigate.toPath("/");

  componentDidUpdate(prevProps) {
    const prevChangeStatus = prevProps.confirmEmailChangeStatus;
    const changeStatus = this.props.confirmEmailChangeStatus;

    if (prevChangeStatus === changeStatus) return;

    if (changeStatus === jobStatus.FAILED)
      this.setState({
        changeStatus: 1,
        changeStatusMessage: "The email change token is invalid.",
      });

    if (changeStatus === jobStatus.SUCCESS)
      this.setState({
        changeStatus: 2,
        changeStatusMessage: "You have successfully changed your email.",
      });
  }

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);

    this.props.confirmEmailChange({
      golfer_id: this.state.golfer_id,
      user_password: values.password,
      token: this.props?.match?.params?.token,
      new_email: this.state.new_email,
    });
  };

  onToggleShowPassword = () =>
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));

  renderUnderlineLink = (link, title) => (
    <a href={link} className="link" target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  );

  renderForm(options, user, golfer) {
    const short_form = user.email == golfer.email;
    return (
      <Fragment>
        <div className="create_form">
          {!short_form && (
            <div>
              <label className="disable">Golfer</label>
              <div className="row">
                <div className="col is-full">
                  <label className="black" htmlFor="golferName">
                    Golfer Name
                  </label>
                  <input
                    type="text-disabled"
                    defaultValue={golfer.name}
                    readOnly
                  />
                  <span className="error">
                    {options.touched.name ? options.errors.name : ""}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col is-full">
                  <label className="black" htmlFor="golferEmail">
                    Golfer Email
                  </label>
                  <input
                    type="text-disabled"
                    defaultValue={golfer.email}
                    readOnly
                  />
                  <span className="error">
                    {options.touched.name ? options.errors.name : ""}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col is-full">
                  <label className="black" htmlFor="golferNewEmail">
                    Golfer New Email
                  </label>
                  <input
                    type="text-disabled"
                    defaultValue={this.state.new_email}
                    readOnly
                  />
                  <span className="error">
                    {options.touched.name ? options.errors.name : ""}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div>
            <label className="disable">User</label>
            <div className="row">
              <div className="col is-full">
                <label className="black" htmlFor="name">
                  Name
                </label>
                <input type="text-disabled" defaultValue={user.name} readOnly />
                <span className="error">
                  {options.touched.name ? options.errors.name : ""}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col is-full">
                <label className="black" htmlFor="name">
                  Email
                </label>
                <input
                  type="text-disabled"
                  defaultValue={user.email}
                  readOnly
                />
                <span className="error">
                  {options.touched.email ? options.errors.email : ""}
                </span>
              </div>
            </div>
            {short_form && (
              <div className="row">
                <div className="col is-full">
                  <label className="black" htmlFor="new_email">
                    New Email
                  </label>
                  <input
                    type="text-disabled"
                    defaultValue={this.state.new_email}
                    readOnly
                  />
                  <span className="error">
                    {options.touched.new_email ? options.errors.new_email : ""}
                  </span>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col is-full">
                <label className="black" htmlFor="password">
                  Password
                </label>
                <div className="show_password_wrapper">
                  <input
                    type={this.state.showPassword ? "text" : "password"}
                    id="password"
                    value={options.values.password}
                    name="password"
                    onBlur={options.handleBlur}
                    onChange={options.handleChange}
                  />
                  {options.values.password &&
                    options.values.password.length > 0 && (
                      <div className="show_password">
                        <button
                          onClick={this.onToggleShowPassword}
                          className="btn lnk no-underline show_button"
                        >
                          {this.state.showPassword ? "HIDE" : "SHOW"}
                        </button>
                      </div>
                    )}
                </div>
                <span className="error">
                  {options.touched.password ? options.errors.password : ""}
                </span>
              </div>
            </div>
          </div>
          <br />
        </div>

        <br />
        <br />
        <div className="row">
          <div className="col is-2-of-3 h-centered">
            <LoaderButton
              type="submit"
              className="btn fill cardinal"
              onClick={options.handleSubmit}
              disabled={!options.isValid}
              loading={String(
                this.props.confirmEmailChangeStatus === jobStatus.PROCESSING
              )}
            >
              FINISH
            </LoaderButton>
          </div>
        </div>
      </Fragment>
    );
  }

  renderContainer() {
    const { user, golfer } = this.props;
    return (
      <div className="auth__container">
        <div className="create-profile">
          {
            <Formik
              onSubmit={this.onSubmit}
              validationSchema={this.validationSchema}
            >
              {(options) => this.renderForm(options, user, golfer)}
            </Formik>
          }
        </div>
      </div>
    );
  }

  render() {
    const { changeStatus, changeStatusMessage } = this.state;
    const { emailChangeInfoStatus, emailChangeInfoError } = this.props;
    return (
      <Fragment>
        <div className="page_container create-profile-page">
          <div className="box-content">
            <h1>Confirm Email Change</h1>
          </div>
          <div className="box-panel center">
            {emailChangeInfoStatus !== jobStatus.SUCCESS ? (
              <Loader />
            ) : (
              this.renderContainer()
            )}
          </div>
        </div>
        <DisplayStatusModal
          status={changeStatus}
          statusMessage={changeStatusMessage}
          onOk={() =>
            this.setState({
              changeStatus: 0,
            })
          }
        />
        <YesNoModal
          onConfirm={this.redirectToHome}
          onAbort={this.redirectToHome}
          isOpen={!!emailChangeInfoError}
          isYesVisible={true}
          isNoVisible={false}
          yesTitle="Ok"
        >
          <p>{emailChangeInfoError}</p>
        </YesNoModal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authReducer }) => ({
  confirmEmailChangeStatus: authReducer.confirmEmailChangeReducer?.status,
  emailChangeInfoStatus: authReducer.emailChangeInformationReducer?.status,
  user: authReducer.emailChangeInformationReducer?.user,
  golfer: authReducer.emailChangeInformationReducer?.golfer,
  emailChangeInfoError:
    authReducer.emailChangeInformationReducer?.emailChangeError,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ emailChangeInformation, confirmEmailChange }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailChange);
