import jobStatus from "../../../shared/variables/job-status";
import {
  ADD_COURSE_SUCCESS,
  FETCH_MY_COURSES,
  FETCH_MY_COURSES_FAILED,
  FETCH_MY_COURSES_SUCCESS,
  HIDE_COURSE_SUCCESS,
  REMOVE_COURSE_SUCCESS,
  ADD_TEE_SUCCESS,
  CHANGE_COURSES_ORDER,
  CHANGE_COURSES_ORDER_SUCCESS,
  CHANGE_COURSES_ORDER_FAILED,
  RESET_ADDED_COURSES,
} from "../actions";

const initialState = {
  courses: [],
  fetchCoursesStatus: jobStatus.NOT_STARTED,
  searchMode: false,
  showAddAlert: false,
  addedCourses: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MY_COURSES:
      return {
        ...state,
        fetchCoursesStatus: jobStatus.PROCESSING,
      };
    case FETCH_MY_COURSES_FAILED:
      return {
        ...state,
        fetchCoursesStatus: jobStatus.FAILED,
      };
    case FETCH_MY_COURSES_SUCCESS:
      return {
        ...state,
        courses: payload,
        fetchCoursesStatus: jobStatus.SUCCESS,
      };
    case ADD_COURSE_SUCCESS:
      return {
        ...state,
        courses: [
          ...state.courses,
          {
            id: payload.id,
            course_id: payload.crs_course_id,
            tee_id: payload.crs_tee_set_id,
          },
        ],
        fetchCoursesStatus: jobStatus.SUCCESS,
        showAddAlert: true,
        addedCourses: [
          ...state.addedCourses,
          {
            id: payload.id,
            course_id: payload.crs_course_id,
            tee_id: payload.crs_tee_set_id,
          },
        ],
      };
    case HIDE_COURSE_SUCCESS:
      return {
        ...state,
        showAddAlert: false,
      };
    case REMOVE_COURSE_SUCCESS:
      return {
        ...state,
        courses: state.courses.filter(
          (c) =>
            c.course_id.toString() !== payload.crs_course_id.toString() ||
            c.id?.toString() !== payload.id.toString()
        ),
        fetchCoursesStatus: jobStatus.SUCCESS,
        addedCourses: state.addedCourses.filter(
          (c) =>
            c.course_id.toString() !== payload.crs_course_id.toString() ||
            c.id?.toString() !== payload.id.toString()
        ),
        showAddAlert: false,
      };
    case ADD_TEE_SUCCESS:
      return {
        ...state,
        courses: state.courses.map((c) => {
          if (c.course_id.toString() === payload.crs_course_id.toString()) {
            c.tee_id = payload.crs_tee_set_id;
          }
          return c;
        }),
        fetchCoursesStatus: jobStatus.SUCCESS,
      };
    case CHANGE_COURSES_ORDER:
      return {
        ...state,
        fetchCoursesStatus: jobStatus.PROCESSING,
      };
    case CHANGE_COURSES_ORDER_SUCCESS:
      return {
        ...state,
        courses: payload,
        fetchCoursesStatus: jobStatus.SUCCESS,
      };
    case CHANGE_COURSES_ORDER_FAILED:
      return {
        ...state,
        fetchCoursesStatus: jobStatus.FAILED,
      };
    case RESET_ADDED_COURSES:
      return {
        ...state,
        addedCourses: [],
        showAddAlert: false,
        searchMode: false,
      };
    default:
      return state;
  }
};
