import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ManualCourse from "./ManualCourse";
import SelectedCourse from "./SelectedCourse";
import ManualRatings from "./ManualRatings";
import NrOfHoles from "./NrOfHoles";
import Tees from "./Tees";
import ScoreType from "./ScoreType";
import DatePlayed from "./DatePlayed";

class SharedRoundSetupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nrManualRatingFocused: 0,
    };
  }

  componentDidUpdate() {
    const isManualRating = this.props.options.values.isManualRating;
    if (isManualRating === true && this.state.nrManualRatingFocused < 2) {
      console.log("kjshdf");
      document.querySelector("input[name=manualCourseRating]").focus();
      this.setState((prevState) => ({
        nrManualRatingFocused: prevState.nrManualRatingFocused + 1,
      }));
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderCourse()}
        {this.renderManualRatingsWithManualCourse()}
        {this.renderNrOfHoles()}
        {this.renderTees()}
        {this.renderManualRatingsWithSelectedCourse()}
        {this.renderScoreType()}
        {this.renderDatePlayed()}
      </Fragment>
    );
  }

  renderCourse = () => {
    const { isManualCourse, selectCoursePath, options, course } = this.props;
    return isManualCourse ? (
      <ManualCourse options={options} selectCoursePath={selectCoursePath} />
    ) : (
      <SelectedCourse course={course} selectCoursePath={selectCoursePath} />
    );
  };

  renderManualRatingsWithManualCourse = () => {
    const { isManualCourse, options, onManualRatingsChange } = this.props;
    return isManualCourse ? (
      <ManualRatings options={options} onChange={onManualRatingsChange} />
    ) : null;
  };

  renderNrOfHoles = () => {
    const { options, hasEighTeen, hasTenSeventen } = this.props;
    return (
      <NrOfHoles
        options={options}
        onChange={() => this.resetForm(options)}
        hasEighTeen={hasEighTeen}
        hasTenSeventen={hasTenSeventen}
      />
    );
  };

  renderTees = () => {
    const {
      isManualCourse,
      alertNoTees,
      alertTeeNoHoles,
      options,
      course,
      isManualRatingsEnabled,
    } = this.props;
    return !isManualCourse ? (
      <Tees
        isManualRatingsEnabled={isManualRatingsEnabled}
        trialGolfer={this.props.trialGolfer}
        options={options}
        tees={course.TeeSets}
        alertNoTees={alertNoTees}
        alertTeeNoHoles={alertTeeNoHoles}
        setManualRating={(value) => this.setManualRating(options, value)}
      />
    ) : null;
  };

  renderManualRatingsWithSelectedCourse = () => {
    const { options, onManualRatingsChange } = this.props;
    return options.values.isManualRating === true ? (
      <ManualRatings options={options} onChange={onManualRatingsChange} />
    ) : null;
  };

  renderScoreType = () => {
    const { options, hasEighTeen } = this.props;
    return <ScoreType options={options} hasEighTeen={hasEighTeen} />;
  };

  renderDatePlayed = () => {
    const { options, onDatePlayedChange } = this.props;
    return onDatePlayedChange != undefined ? (
      <DatePlayed
        options={options}
        onDatePlayedChange={(date) => onDatePlayedChange(date)}
      />
    ) : (
      <DatePlayed options={options} />
    );
  };

  resetForm = (options) => {
    // options.setFieldValue("isManualRating", false, true);

    if (!this.props.isManualCourse) {
      options.setFieldValue("manualCourseRating", "", true);
      options.setFieldValue("manualSlopeRating", "", true);
      options.setFieldTouched("manualCourseRating", false, true);
      options.setFieldTouched("manualSlopeRating", false, true);
    }

    this.props.onResetForm();
  };

  setManualRating = (options, value) =>
    options.setFieldValue("isManualRating", value, true);
}

SharedRoundSetupForm.defaultProps = {
  onManualRatingsChange: () => {},
  alertTeeNoHoles: () => {},
};

SharedRoundSetupForm.propTypes = {
  selectCoursePath: PropTypes.string.isRequired,
  isManualCourse: PropTypes.bool.isRequired,
  course: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  alertNoTees: PropTypes.func.isRequired,
  alertTeeNoHoles: PropTypes.func.isRequired,
  onResetForm: PropTypes.func.isRequired,
  onManualRatingsChange: PropTypes.func.isRequired,
  isManualRatingsEnabled: PropTypes.bool.isRequired,
  hasEighTeen: PropTypes.bool.isRequired,
  hasTenSeventen: PropTypes.bool.isRequired,
};

export default SharedRoundSetupForm;
