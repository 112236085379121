import BaseApi from "./base-api";
import { getGolferId } from "../shared/helpers/api-helper";

class EndOfYearCampaignApi extends BaseApi {
  apiUrl = `${this.baseUrl}/api/v1`;

  getGolferYearEndSummary = (golfer_id) => {
    const id = golfer_id === undefined ? getGolferId() : golfer_id;
    const url = `${this.apiUrl}/golfers/${id}/year_end_summary.json?`;
    return this.httpGet(url);
  };
}

export default new EndOfYearCampaignApi();
