import React, { Component } from "react";
import PropTypes from "prop-types";
import { renderValidationError } from "../../../../../shared/helpers/ui-helper";

class ManualRatings extends Component {
  render() {
    const { options } = this.props;
    return (
      <div className="row">
        <div className="col is-2-of-4">
          <label className="regular black" htmlFor="front-9">
            Course Rating
          </label>
          <input
            id="manualCourseRating"
            name="manualCourseRating"
            className="large centered-text"
            type="text"
            value={options.values.manualCourseRating}
            onBlur={(event) => {
              this.normalizeCourseRating(event, options);
              options.setFieldTouched(event.target.name, true, true);
            }}
            onChange={(event) => {
              this.normalizeCourseRating(event, options);
              options.setFieldTouched(event.target.name, false, true);
            }}
          />
          {renderValidationError(options, "manualCourseRating")}
        </div>
        <div className="col is-2-of-4">
          <label className="regular black" htmlFor="front-9">
            Slope
          </label>
          <input
            id="manualSlopeRating"
            name="manualSlopeRating"
            className="large centered-text"
            type="text"
            value={options.values.manualSlopeRating}
            onBlur={(event) => {
              this.normalizeSlopeRating(event, options);
              options.setFieldTouched(event.target.name, true, true);
            }}
            onChange={(event) => {
              this.normalizeSlopeRating(event, options);
              options.setFieldTouched(event.target.name, false, true);
            }}
          />
          {renderValidationError(options, "manualSlopeRating")}
        </div>
      </div>
    );
  }

  normalizeCourseRating = (event, options) => {
    let value = event.target.value.match(/[1-9]\d*[.]?[\d]?/g);
    value = value ? value[0] : null;

    event.target.value = value;
    options.handleChange(event);

    this.props.onChange({
      manualCourseRating: value,
      manualSlopeRating: options.values.manualSlopeRating,
    });
  };

  normalizeSlopeRating = (event, options) => {
    let value = event.target.value.match(/(?!0)\d{1,3}/);
    value = value ? value[0] : null;

    event.target.value = value;
    options.handleChange(event);

    this.props.onChange({
      manualCourseRating: options.values.manualCourseRating,
      manualSlopeRating: value,
    });
  };
}

ManualRatings.propTypes = {
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ManualRatings;
