import React, { Component } from "react";
import YesNoModal from "./YesNoModal";

class ScaledScoresInfoModal extends Component {
  render() {
    const { isOpen, onClose } = this.props;
    return (
      <YesNoModal
        isOpen={isOpen}
        onConfirm={onClose}
        onAbort={onClose}
        className="modal-dialog"
        isNoVisible={false}
        yesTitle="Ok"
      >
        <p>
          This score received an 18-hole Score Differential based on the number
          of holes played and an expected Score Differential for the holes not
          played.
        </p>
      </YesNoModal>
    );
  }
}

export default ScaledScoresInfoModal;
