import {
  MY_PROFILE_FETCH,
  fetchMyProfileSuccess,
  fetchMyProfileFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(MY_PROFILE_FETCH))
    .pipe(switchMap(({ payload }) => action(payload)));

const action = (payload) =>
  from(GolferApi.fetchMyProfile(payload))
    .pipe(map((response) => fetchMyProfileSuccess(response.data)))
    .pipe(
      catchError((error) => of(fetchMyProfileFailed(error.response?.data)))
    );
