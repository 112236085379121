import { createEpicMiddleware } from "redux-observable";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "../reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore } from "redux-persist";
import epics from "../epics";
import thunk from "redux-thunk";

const epicMiddleware = createEpicMiddleware();

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(epicMiddleware, thunk))
);

export const persistor = persistStore(store);

epicMiddleware.run(epics);
