import { decrypt, encrypt } from "./encoder";

const RM_KEY = "rm_token";

export const rememberMe = (payload) => {
  if (!payload) return;
  if (payload.remember_me) {
    localStorage.setItem(RM_KEY, encrypt(JSON.stringify(payload)));
  } else {
    localStorage.setItem(RM_KEY, undefined);
  }
};

export const getRememberMe = () => {
  const payload = localStorage.getItem(RM_KEY);
  if (!payload) return;

  try {
    return JSON.parse(decrypt(payload));
  } catch (e) {
    return undefined;
  }
};
