import {
  FOLLOW_GOLFER,
  followGolferSuccess,
  followGolferFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import FollowApi from "../../../services/follow-api";

export default (action$) =>
  action$
    .pipe(ofType(FOLLOW_GOLFER))
    .pipe(switchMap(({ payload }) => followGolfer(payload)));

const followGolfer = (payload) =>
  from(FollowApi.followGolfer(payload.followed_golfer_id, payload.club_id))
    .pipe(
      map((response) => followGolferSuccess(response.data.following_golfer))
    )
    .pipe(catchError((error) => of(followGolferFailed(error))));
