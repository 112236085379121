import {
  FETCH_MANUALLY_CALCULATOR,
  fetchManuallyCalculatorSuccess,
  fetchManuallyCalculatorFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import SupportingCalculationApi from "../../../services/supporting-calculation-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_MANUALLY_CALCULATOR))
    .pipe(switchMap(({ payload }) => fetchCalculatorCourse(payload)));

const fetchCalculatorCourse = (payload) =>
  from(
    SupportingCalculationApi.getManualCourseHandicap(
      payload.handicapIndex,
      payload.courseRating,
      payload.slopeRating,
      payload.par,
      payload.handicapAllowance
    )
  )
    .pipe(map((response) => fetchManuallyCalculatorSuccess(response.data)))
    .pipe(catchError((error) => of(fetchManuallyCalculatorFailed(error))));
