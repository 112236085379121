import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { fetchCountries, fetchFollowedGolfers } from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GolfersList from "../GolfersList";
import jobStatus from "../../../../shared/variables/job-status";
import Loader from "../../../../shared/components/loaders/Loader";
import HeaderFilter from "./HeaderFilter";

class AllGolfers extends Component {
  headerFilterRef = React.createRef();

  componentDidMount() {
    this.props.fetchCountries();
    this.props.fetchFollowedGolfers();
  }

  onClickNextPage = () => {
    if (this.headerFilterRef && this.headerFilterRef.current) {
      this.headerFilterRef.current.getNextPage();
    }
  };

  render() {
    const { golfers, fetchCountriesStatus, fetchGolfersStatus, hasNextPage } =
      this.props;

    if (fetchCountriesStatus === jobStatus.PROCESSING) return <Loader />;
    return (
      <Fragment>
        <div className="panel">
          <HeaderFilter ref={this.headerFilterRef} />
          {fetchGolfersStatus === jobStatus.PROCESSING &&
          golfers.length === 0 ? (
            <Loader />
          ) : (
            <GolfersList golfers={golfers} renderItem={this.props.renderItem} />
          )}
        </div>
        {fetchGolfersStatus === jobStatus.PROCESSING ? (
          golfers.length !== 0 ? (
            <Loader />
          ) : null
        ) : (
          hasNextPage && (
            <div className="row">
              <div className="col auto h-centered">
                <button
                  className="btn outline blue"
                  onClick={this.onClickNextPage}
                >
                  Load More
                </button>
              </div>
            </div>
          )
        )}
      </Fragment>
    );
  }
}

AllGolfers.propTypes = {
  fetchCountries: PropTypes.func.isRequired,
  fetchFollowedGolfers: PropTypes.func.isRequired,
  golfers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchCountriesStatus: PropTypes.string.isRequired,
  fetchGolfersStatus: PropTypes.string.isRequired,
  renderItem: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ golfersReducer }) => ({
  fetchCountriesStatus: golfersReducer.countriesReducer.jobStatus,
  golfers: golfersReducer.golfersReducer.golfers,
  hasNextPage: golfersReducer.golfersReducer.hasNextPage,
  fetchGolfersStatus: golfersReducer.golfersReducer.jobStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchCountries, fetchFollowedGolfers }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AllGolfers);
