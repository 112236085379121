import React, { Component } from "react";
import PropTypes from "prop-types";
import numberOfHoles from "../../../../shared/variables/number-of-holes";

class PostScoreSubmit extends Component {
  render = () => (
    <div className="row">
      <div className="col auto h-centered">
        <span data-tip data-for={"disabled-message"}>
          <button
            type="submit"
            disabled={this.isDisabled()}
            className="btn fill cardinal cardinal"
            onClick={
              this.isInvalidScore(this.props.options)
                ? this.triggerAlert
                : this.props.options.handleSubmit
            }
          >
            Post Score
          </button>
        </span>
      </div>
    </div>
  );

  isInvalidScore = (options) => {
    return options && Object.entries(options?.errors).length !== 0;
  };

  triggerAlert = () => {
    this.props.alertInvalidScore(this.props.options?.errors?.holes);
  };

  isDisabled = () =>
    Object.entries(this.props.options.errors).length !== 0 &&
    this.props.options.values.nrOfHoles === numberOfHoles.NINE;
}

PostScoreSubmit.propTypes = {
  options: PropTypes.object.isRequired,
  alertInvalidScore: PropTypes.func.isRequired,
};

export default PostScoreSubmit;
