import { combineEpics } from "redux-observable";
import {
  FETCH_STATS_SCORES,
  FETCH_STATS_SCORES_NEXT,
  fetchStatsScoresSuccess,
  fetchStatsScoresFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";

export const fetchStatsScores = (action$) =>
  action$
    .pipe(ofType(FETCH_STATS_SCORES))
    .pipe(switchMap(({ payload }) => getGolferScores(payload)));

export const fetchStatsScoresNext = (action$) =>
  action$
    .pipe(ofType(FETCH_STATS_SCORES_NEXT))
    .pipe(switchMap(({ payload }) => getGolferScores(payload)));

const getGolferScores = (payload) =>
  from(
    ScoresApi.getStatsScores(
      payload.filter,
      payload.offset,
      payload.onlyStats,
      false,
      payload.extraParams
    )
  )
    .pipe(
      map((response) =>
        fetchStatsScoresSuccess(
          response.data,
          payload.onlyStats,
          payload.filter
        )
      )
    )
    .pipe(catchError((error) => of(fetchStatsScoresFailed(error))));

export default combineEpics(fetchStatsScores, fetchStatsScoresNext);
