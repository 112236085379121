import {
  FETCH_GOLFER,
  fetchGolferSuccess,
  fetchGolferFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_GOLFER))
    .pipe(switchMap(({ payload }) => getGolfer(payload)));

const golferFromClub = (golfers, clubId) => {
  if (!clubId) return golfers[0];
  const golfer = golfers.filter((g) => g.club_id === parseInt(clubId, 10));
  return golfer[0] || golfers[0];
};

const getGolfer = (payload) =>
  from(GolferApi.findGolfer(payload.ghin_number))
    .pipe(
      map((response) =>
        fetchGolferSuccess(
          golferFromClub(response.data.golfers, payload.clubId)
        )
      )
    )
    .pipe(catchError((error) => of(fetchGolferFailed(error))));
