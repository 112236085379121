import React, { Component } from "react";

class BackTopButton extends Component {
  componentDidMount() {
    this.prev = window.scrollY;
    window.addEventListener("scroll", (e) => this.hScroll(e));
  }

  hScroll = (e) => {
    const window = e.currentTarget;

    try {
      let scrollPos = window.pageYOffset;
      const snav = document.querySelector(".s-navigation");
      const back_top = document.getElementById("back-top-button");
      const hamMenu = document.querySelector(".menu-ham__content");
      const userMenu = document.querySelector(".user-menu__content");
      const menusOpened =
        hamMenu.classList.contains("is-active") ||
        (userMenu != null && userMenu.classList.contains("is-active"));
      if (scrollPos > 100 && !menusOpened) {
        snav.classList.add("is-scrolled-down");
      } else {
        snav.classList.remove("is-scrolled-down");
      }
      if (scrollPos > 800) {
        back_top.classList.remove("hidden");
      } else {
        back_top.classList.add("hidden");
      }

      if (this.prev > window.scrollY) {
        snav.classList.remove("is-scrolled-down");
      } else if (this.prev < window.scrollY) {
        //  GOING DOWN
        //  DO NOTHING
      }
      this.prev = window.scrollY;

      // eslint-disable-next-line no-empty
    } catch {}
  };

  goBackTop = () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  render = () => (
    <button
      id="back-top-button"
      className="hidden"
      title="Go to top"
      onClick={this.goBackTop}
    />
  );
}

export default BackTopButton;
