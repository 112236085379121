import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { addTee, hideCourseSuccess } from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Navigate from "../../../shared/navigation/navigate";
import { routes } from "../../../shared/routes";

class TeeListItem extends Component {
  onClickAdd = () => {
    const { tee, courseId, addTee, extraParam, hideCourseSuccess } = this.props;
    addTee(courseId, tee.TeeSetRatingId);
    setTimeout(() => {
      hideCourseSuccess();
    }, 2000);
    const searchPhrase = extraParam?.split("=")[1];
    if (extraParam?.includes("searchPhrase")) {
      Navigate.toPath(
        `${routes.addCourses.path}?searchPhrase=` +
          `${searchPhrase}&redirected=true`
      );
    } else {
      Navigate.toPath(`${routes.addCourses.path}`);
    }
  };

  render() {
    const { tee } = this.props;
    return (
      <Fragment>
        <li key={tee.TeeSetRatingId}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span className="item my-courses" onClick={this.onClickAdd}>
            <div className="item details my-courses">
              <span className="details">
                <span className="tee">{tee.TeeSetRatingName}</span>
              </span>
              {!this.props.trialGolfer && (
                <span className="index">
                  {tee.Ratings[0].CourseRating.toFixed(1)}/
                  {tee.Ratings[0].SlopeRating}/{tee.TotalPar}
                </span>
              )}
            </div>
          </span>
        </li>
      </Fragment>
    );
  }
}

TeeListItem.propTypes = {
  tee: PropTypes.object.isRequired,
  addTee: PropTypes.func.isRequired,
  hideCourseSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    courseId: ownProps.courseId,
    courses: ownProps.courses,
    tees: state.selectMyCoursesReducer.teesFetchReducer.tees,
    extraParam: ownProps.extraParam,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addTee, hideCourseSuccess }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TeeListItem);
