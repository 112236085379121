import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import Navigate from "../../../../shared/navigation/navigate";
import { routes } from "../../../../shared/routes";

class NoActiveTeesModal extends Component {
  onClose = () => {
    const { onClose } = this.props;
    onClose();
    Navigate.goBack();
  };

  onConfirm = () => {
    routes.manuallyHandicapCalculator.navigate();
  };

  render() {
    const { isOpen } = this.props;
    return (
      <YesNoModal
        onConfirm={this.onConfirm}
        onAbort={this.onClose}
        isNoVisible={false}
        isOpen={isOpen}
        yesTitle="Manually Calculate Course Handicap"
      >
        <p>This Course does not have any active or rated tees.</p>
      </YesNoModal>
    );
  }
}

NoActiveTeesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NoActiveTeesModal;
