import { getGolferId } from "../../../shared/helpers/user-helper";
import {
  initCalculator,
  addTeesToGolfer,
  addValuesToGolfer,
  addSelectedTeeToGolfer,
  changeSelectedTeeByHole,
  changeSelectedTeeGolfersByHole,
  checkGolfersTeeSetsFetch,
  addIncreaseIdToManualGolfers,
} from "../../../shared/helpers/handicap-calculator-helper";
import noOfHoles from "../../../shared/variables/number-of-holes";
import {
  HANDICAP_CALCULATOR_GOLFERS_INIT,
  HANDICAP_CALCULATOR_GOLFERS_ADD,
  HANDICAP_CALCULATOR_GOLFERS_REMOVE,
  HANDICAP_CALCULATOR_GOLFERS_HIDE_ALERT,
  RESET_HANDICAP_CALCULATOR,
  FETCH_CALCULATOR_GOLFER_TEE,
  FETCH_CALCULATOR_GOLFER_TEE_SUCCESS,
  FETCH_CALCULATOR_GOLFER_TEE_FAILED,
  HANDICAP_CALCULATOR_SET_GOLFER_TEE,
  FETCH_CALCULATOR_GOLFER_HANDICAP,
  FETCH_CALCULATOR_GOLFER_HANDICAP_SUCCESS,
  FETCH_CALCULATOR_GOLFER_HANDICAP_FAILED,
  HANDICAP_CALCULATOR_SET_NUMBER_OF_HOLES,
  RESET_ADDED_GOLFERS_COUNT,
  REMOVE_ALL_GOLFERS,
  SET_HANDICAP_ALLOWANCE,
  SET_INITIAL_TEE_SET_ID,
  REMOVE_INITIAL_TEE_SET_ID,
  ADD_GOLFERS_MANUAL_ID_REFERENCE,
} from "../actions";

const initialState = {
  golfers: [],
  manualGolfersReference: [],
  courseId: undefined,
  userSelectedTee: undefined,
  numberOfHoles: noOfHoles.EIGHTEEN,
  showSuccesAlert: false,
  addedGolfers: [],
  allowance: "100",
  golfersTeeSetsFetchComplete: false,
  initialTeeSetId: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HANDICAP_CALCULATOR_GOLFERS_ADD:
      return {
        ...state,
        golfers: [...state.golfers, payload.golfer],
        addedGolfers: [...state.addedGolfers, payload.golfer],
        showSuccesAlert: true,
      };
    case HANDICAP_CALCULATOR_GOLFERS_INIT:
      return {
        ...state,
        golfers: initCalculator(
          state.courseId,
          payload.courseId,
          payload.golfer,
          state.golfers
        ),
        courseId: payload.courseId,
        userSelectedTee:
          state.courseId !== payload.courseId ? null : state.userSelectedTee,
        golfersTeeSetsFetchComplete: false,
        allowance: initialState.allowance,
      };
    case HANDICAP_CALCULATOR_GOLFERS_REMOVE:
      return {
        ...state,
        golfers: state.golfers.filter(
          (g) => getGolferId(g) !== payload.golferId
        ),
        addedGolfers: state.addedGolfers.filter(
          (g) => getGolferId(g) !== payload.golferId
        ),
      };
    case HANDICAP_CALCULATOR_GOLFERS_HIDE_ALERT:
      return {
        ...state,
        showSuccesAlert: false,
      };
    case HANDICAP_CALCULATOR_SET_GOLFER_TEE:
      return {
        ...state,
        golfers: addSelectedTeeToGolfer(
          payload.golfer,
          payload.teeId,
          state.golfers,
          payload.userSelectedTee
        ),
        userSelectedTee: payload.userSelectedTee || state.userSelectedTee,
      };
    case HANDICAP_CALCULATOR_SET_NUMBER_OF_HOLES:
      return {
        ...state,
        numberOfHoles: payload.numberOfHoles,
        golfers: changeSelectedTeeGolfersByHole(
          state.golfers,
          payload.numberOfHoles
        ),
        userSelectedTee: changeSelectedTeeByHole(
          state.userSelectedTee,
          payload.numberOfHoles
        ),
      };
    case FETCH_CALCULATOR_GOLFER_TEE:
      return {
        ...state,
        golfersTeeSetsFetchComplete: false,
        golfers: addValuesToGolfer(
          payload.golferId,
          state.golfers,
          ["fetch_tees"],
          [true]
        ),
      };
    case FETCH_CALCULATOR_GOLFER_TEE_FAILED:
      return {
        ...state,
        golfers: addValuesToGolfer(
          payload.golferId,
          state.golfers,
          ["fetch_tees"],
          [false]
        ),
      };
    case FETCH_CALCULATOR_GOLFER_TEE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const golfers = addTeesToGolfer(
        payload.tee_sets,
        payload.golferId,
        state.golfers,
        payload.gender,
        state.userSelectedTee
      );
      return {
        ...state,
        golfers: golfers,
        golfersTeeSetsFetchComplete: checkGolfersTeeSetsFetch(golfers),
      };
    case FETCH_CALCULATOR_GOLFER_HANDICAP:
      return {
        ...state,
        golfers: addValuesToGolfer(
          payload.golferId,
          state.golfers,
          ["fetch_handicap"],
          [true]
        ),
      };
    case FETCH_CALCULATOR_GOLFER_HANDICAP_FAILED:
      return {
        ...state,
        golfers: addValuesToGolfer(
          payload.golferId,
          state.golfers,
          ["fetch_handicap"],
          [false]
        ),
      };
    case FETCH_CALCULATOR_GOLFER_HANDICAP_SUCCESS:
      return {
        ...state,
        golfers: addValuesToGolfer(
          payload.golferId,
          state.golfers,
          ["display", "handicap_index", "fetch_handicap"],
          [payload.handicap, payload.handicap, false]
        ),
      };
    case RESET_HANDICAP_CALCULATOR:
      return {
        ...state,
        ...initialState,
      };
    case RESET_ADDED_GOLFERS_COUNT:
      return {
        ...state,
        addedGolfers: [],
      };
    case REMOVE_ALL_GOLFERS:
      return {
        ...state,
        golfers: state.golfers.filter(
          (g) => getGolferId(g) === payload.golferId
        ),
      };
    case SET_HANDICAP_ALLOWANCE:
      return {
        ...state,
        allowance: payload.allowance,
      };
    case SET_INITIAL_TEE_SET_ID:
      return {
        ...state,
        initialTeeSetId: payload.initialTeeSetId,
      };
    case REMOVE_INITIAL_TEE_SET_ID:
      return {
        ...state,
        initialTeeSetId: undefined,
      };
    case ADD_GOLFERS_MANUAL_ID_REFERENCE:
      return {
        ...state,
        manualGolfersReference: addIncreaseIdToManualGolfers(state.golfers),
      };
    default:
      return state;
  }
};
