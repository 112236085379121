import React from "react";
import ScoreType from "../variables/stats-score-type";
import { stringToFloatWithoutZero } from "./ui-helper";
import { getScoreStatsType } from "../helpers/stats-helper";
import ChartType from "../variables/score-chart-type";
import ScoreAverageType from "../variables/stats-average-score-type";

export const getPccFromScoreAdjustments = (score) => {
  if (score.adjustments && score.adjustments.length > 0) {
    const pcc = score.adjustments.filter((s) => s.type === "pcc");
    if (pcc.length > 0) {
      return pcc[0].display;
    }
  }
  return "-";
};

export const getAdjustmentsInfoMessage = (score) => {
  if (score.adjustments && score.adjustments.length > 0) {
    const esr = score.adjustments.filter((s) => s.type === "esr");
    const committee = score.adjustments.filter((s) => s.type === "committee");
    if (esr.length > 0 && committee.length > 0) {
      const esrValue = esr[0].display;
      const committeeValue = committee[0].display;
      return `A ${esrValue} Exceptional Score Reduction and a ${committeeValue} Committee Adjustment have been applied to this score differential.`;
    }
    if (esr.length > 0) {
      const value = esr[0].display;
      return `A ${value} Exceptional Score Reduction has been applied to this score differential.`;
    }
    if (committee.length > 0) {
      const value = committee[0].display;
      return `A ${value} Committee Adjustment has been applied to this score differential.`;
    }
  }
  return null;
};

export const getAdjustmentsButton = (score, onClick) => {
  if (
    score.adjustments &&
    score.adjustments.length > 0 &&
    score.adjusted_scaled_up_differential !== 999
  ) {
    const containEsrOrCommittee = score.adjustments.filter(
      (a) => a.type === "committee" || a.type === "esr"
    );
    if (containEsrOrCommittee.length > 0) {
      return (
        <div style={{ paddingLeft: 5 }}>
          <button
            onClick={onClick}
            className="info-modal-trigger"
            aria-label="Open info dialog"
          >
            !
          </button>
        </div>
      );
    }
  }
  return null;
};

export const getDifferentialButton = (score, onClick) => {
  if (
    score.adjusted_scaled_up_differential === 999 &&
    score.number_of_played_holes === 9
  ) {
    return (
      <div style={{ paddingLeft: 5 }}>
        <button
          onClick={onClick}
          className="info-modal-trigger"
          aria-label="Open info dialog"
        >
          !
        </button>
      </div>
    );
  }

  return null;
};

export const getDifferentialInfoMessage = () => {
  return (
    <p>
      This score currently has no Score Differential, but will receive an
      18-hole Score Differential once enough scores have been posted (54 holes)
      to establish a Handicap Index.
    </p>
  );
};

export const getCourseDisplayValueForCScore = (display, separator = "\n") => {
  const value = display.split("/");
  return `${value[0] ? value[0].trim() : ""}${separator}${
    value[1] ? value[1].trim() : ""
  }`;
};

export const getDifferential = (score, useScaling) => {
  if (!useScaling) return score.differential;

  let finalDiff =
    score.number_of_played_holes === 18 || score.scaled_up_differential === null
      ? score.differential
      : score.adjusted_scaled_up_differential === 999
      ? "-"
      : score.adjusted_scaled_up_differential;

  if (finalDiff === "-") {
    return finalDiff;
  }

  return finalDiff.toFixed(1);
};

const filter18HoleScores = (scores) =>
  scores.filter((score) => {
    return score.adjusted_scaled_up_differential === null;
  });

export const getMinScore = (scores) => {
  const filteredScores = filter18HoleScores(scores);

  if (filteredScores.length === 0) return "-";

  return filteredScores.reduce(
    (min, s) => (s.adjusted_gross_score < min ? s.adjusted_gross_score : min),
    filteredScores[0].adjusted_gross_score
  );
};
export const getMaxScore = (scores) => {
  const filteredScores = filter18HoleScores(scores);

  if (filteredScores.length === 0) return "-";

  return filteredScores.reduce(
    (max, s) => (s.adjusted_gross_score > max ? s.adjusted_gross_score : max),
    filteredScores[0].adjusted_gross_score
  );
};

export const getAvgScores = (scores) => {
  const filteredScores = filter18HoleScores(scores);

  if (filteredScores.length === 0) return "-";

  const avg =
    filteredScores.reduce((sume, el) => sume + el.adjusted_gross_score, 0) /
    filteredScores.length;
  return stringToFloatWithoutZero(avg) || "-";
};

export const getAdditionalData = (data, filter) => {
  if (
    filter === ScoreType.BY_COURSE ||
    filter === ScoreType.RECENT_AND_REVISION_SCORES ||
    filter === ScoreType.REVISION
  ) {
    const scores = data && data.scores ? data.scores : [];
    return {
      total_count: scores.length,
      highest_score: getMaxScore(scores),
      lowest_score: getMinScore(scores),
      average: getAvgScores(scores),
    };
  } else {
    const { highest_score, lowest_score, average, total_count } = data;
    return {
      highest_score,
      lowest_score,
      average,
      total_count,
    };
  }
};

export const isEligibleForDeletion = (score) =>
  score.score_type === "C" &&
  score.statistics &&
  getScoreStatsType(score.statistics) === ChartType.ADVANCED_STATS;

export const has18And9HolesTees = (tees) => {
  const has18HolesTees = tees.some(
    (t) => t.HolesNumber === 18 || t.holes_number === 18
  );
  const has9HolesTees = tees.some(
    (t) => t.HolesNumber === 9 || t.holes_number === 9
  );
  return has18HolesTees && has9HolesTees;
};

export const getScoreAverageFilter = (scoreType) => {
  switch (scoreType) {
    case ScoreType.RECENT_AND_REVISION_SCORES:
      return ScoreAverageType.RECENT_AND_REVISION_SCORES;
    case ScoreType.YEAR:
      return ScoreAverageType.YEAR;
    case ScoreType.PAST_YEAR:
      return ScoreAverageType.PAST_YEAR;
    case ScoreType.BY_COURSE:
    case ScoreType.BY_COURSE_ID_AND_TEE_SET_ID:
      return ScoreAverageType.SCORES_BY_COURSE_OR_TEE;
    case ScoreType.MONTHS_12:
      return ScoreAverageType.PAST_12MONTH_SCORES;
    case ScoreType.TWO_YEARS_AGO:
      return ScoreAverageType.TWO_YEARS_AGO;
    default:
      return ScoreAverageType.COMPETITION_SCORES;
  }
};
