import Profile from "../../screens/profile/components/Profile";
import ProfileHandicapHistory from "../../screens/profile/components/ProfileHandicapHistory";
import Navigate from "../navigation/navigate";
import DefaultLayout from "../components/layouts/DefaultLayout";
import MyProfile from "../../screens/my-profile/components/MyProfile";
import MembershipAutoRenewal from "../../screens/my-profile/components/Components/MembershipAutoRenewal";
import { GHIN_REWIND_YEAR } from "../../screens/end-of-year-campaign/year-end-constants";

export default {
  profile: {
    path: "/profile",
    component: Profile,
    exact: false,
    rootPath: true,
    authRequired: true,
    layout: DefaultLayout,
    navigate: () => Navigate.toPath("/profile"),
  },
  profileHistory: {
    path: "/profile-handicap-history",
    component: ProfileHandicapHistory,
    exact: false,
    rootPath: true,
    authRequired: true,
    layout: DefaultLayout,
    navigate: () => Navigate.toPath("/profile-handicap-history"),
  },
  myProfile: {
    path: "/my-profile",
    component: MyProfile,
    exact: false,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
    navigate: () => Navigate.toPath("/my-profile"),
  },
  membershipAutoRenewal: {
    path: "/membership-auto-renewal",
    component: MembershipAutoRenewal,
    exact: false,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
    navigate: () => Navigate.toPath("/membership-auto-renewal"),
  },
  endOfYear: {
    path: "/" + GHIN_REWIND_YEAR + "-overview",
    component: Profile,
    exact: false,
    rootPath: true,
    authRequired: true,
    layout: DefaultLayout,
    navigate: () => Navigate.toPath("/" + GHIN_REWIND_YEAR + "-overview"),
  },
};
