/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../../shared/components/loaders/Loader";
import jobStatus from "../../../shared/variables/job-status";
import { fetchMyProfile } from "../actions";
import YesNoModal from "../../../shared/components/modals/YesNoModal";

import Addresses from "./Components/Addresses";
import Email from "./Components/Email";
import Memberships from "./Components/Memberships";
import MembershipAutoRenewalButton from "./Components/MembershipAutoRenewalButton";
import Phone from "./Components/Phone";
import { Helmet } from "react-helmet";
import {
  MY_PROFILE_TITLE,
  MY_PROFILE_DESC,
} from "../../../shared/variables/meta-seo";
import MyCoursesButton from "../../my-courses/components/MyCoursesButton";

class MyProfile extends Component {
  state = {
    patchError: undefined,
  };

  componentDidMount() {
    const { currentUser } = this.props;
    this.props.fetchMyProfile({
      ghin: currentUser.ghin_number,
      club_id: currentUser.club_id,
    });
  }

  onCloseErrorModal = () => this.setState({ patchError: undefined });

  onShowErrorModal = (patchError) => this.setState({ patchError });

  renderContent() {
    const { profile, memberships, golferActive } = this.props;
    return (
      <>
        <Memberships profile={profile} />
        <div className="profile-page__body">
          <div className="accordion-container">
            {!profile.is_under_13 && (
              <Email
                profile={profile}
                onShowErrorModal={this.onShowErrorModal}
              />
            )}
            <Addresses
              profile={profile}
              onShowErrorModal={this.onShowErrorModal}
            />
            <Phone profile={profile} onShowErrorModal={this.onShowErrorModal} />
            {memberships.some((item) => item.ojr_membership) && (
              <MembershipAutoRenewalButton />
            )}
            {golferActive && <MyCoursesButton />}
          </div>
        </div>
      </>
    );
  }

  render() {
    const { profileFetchStatus } = this.props;
    const { patchError } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>{MY_PROFILE_TITLE}</title>
          <meta name="description" content={MY_PROFILE_DESC} />
        </Helmet>
        <div className="page_container">
          <section>
            <div className="box-content">
              <h1>My Profile</h1>
              {profileFetchStatus === jobStatus.SUCCESS ? (
                this.renderContent()
              ) : (
                <Loader />
              )}
            </div>
          </section>
        </div>
        <YesNoModal
          onConfirm={this.onCloseErrorModal}
          onAbort={this.onCloseErrorModal}
          isOpen={!!patchError}
          isYesVisible={true}
          isNoVisible={false}
          yesTitle="Ok"
        >
          <p>{patchError}</p>
        </YesNoModal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  myProfileReducer,
  authReducer,
  profileReducer,
}) => ({
  profile: myProfileReducer.myProfileReducer.profile,
  profileFetchStatus: myProfileReducer.myProfileReducer.status,
  currentUser: authReducer.loginReducer.user,
  memberships: profileReducer.golferProfileFetchReducer.golferMemberships,
  golferActive: profileReducer.golferProfileFetchReducer.golferActive,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchMyProfile }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
