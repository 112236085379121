import jobStatus from "../../../shared/variables/job-status";
import {
  POST_EDIT_ROUND_STATS,
  POST_EDIT_ROUND_STATS_FAILED,
  POST_EDIT_ROUND_STATS_SUCCESS,
  POST_EDIT_ROUND_STATS_RESET,
} from "../actions";

const initialState = {
  editedScore: undefined,
  postEditRoundStatsStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case POST_EDIT_ROUND_STATS:
      return {
        ...state,
        postEditRoundStatsStatus: jobStatus.PROCESSING,
      };
    case POST_EDIT_ROUND_STATS_SUCCESS:
      return {
        ...state,
        editedScore: payload,
        postEditRoundStatsStatus: jobStatus.SUCCESS,
      };
    case POST_EDIT_ROUND_STATS_FAILED:
      return {
        ...state,
        postEditRoundStatsStatus: jobStatus.FAILED,
      };
    case POST_EDIT_ROUND_STATS_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
