import {
  EMAIL_CHANGE_INFORMATION,
  emailChangeInformationSuccess,
  emailChangeInformationFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(EMAIL_CHANGE_INFORMATION))
    .pipe(switchMap(({ payload }) => action(payload)));

const action = (payload) => {
  const golfer_id = payload.golfer_id;
  payload = {
    token: payload.token,
  };

  return from(GolferApi.emailChangeInformation(golfer_id, payload))
    .pipe(map((response) => emailChangeInformationSuccess(response.data)))
    .pipe(
      catchError((error) =>
        of(emailChangeInformationFailed(error.response?.data))
      )
    );
};
