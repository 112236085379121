import { combineReducers } from "redux";
import storage from "redux-persist/es/storage";
import { persistReducer } from "redux-persist";
import calculatorCourseFetchReducer from "./calculator-course-fetch";
import calculatorManuallyFetchReducer from "./calculator-manually-fetch";
import calculatorGolfersReducer from "./calculator-golfers";
import playingHandicapsReducer from "./playing-handicaps-fetch";

const reducers = combineReducers({
  calculatorCourseFetchReducer: persistReducer(
    {
      key: "calculatorCourseFetchReducer",
      storage: storage,
    },
    calculatorCourseFetchReducer
  ),
  calculatorGolfersReducer: persistReducer(
    {
      key: "calculatorGolfersReducer",
      storage: storage,
    },
    calculatorGolfersReducer
  ),
  calculatorManuallyFetchReducer,
  playingHandicapsReducer,
});

export default reducers;
