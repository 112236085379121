import React, { Component } from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";

class LoadMore extends Component {
  render() {
    const { nextPage, offset, fetchStatus, onLoadMore } = this.props;
    if (fetchStatus) return <Loader noPadding />;
    if (nextPage > 1 || offset > 0) {
      return (
        <div className="row">
          <div className="col auto h-centered">
            <button onClick={onLoadMore} className="btn outline blue">
              Load More
            </button>
          </div>
        </div>
      );
    }
    return null;
  }
}

LoadMore.propTypes = {
  onLoadMore: PropTypes.func.isRequired,
  nextPage: PropTypes.number,
  offset: PropTypes.number,
  fetchStatus: PropTypes.bool,
};

LoadMore.defaultProps = {
  nextPage: 1,
  offset: 0,
  fetchStatus: false,
};

export default LoadMore;
