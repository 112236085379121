export const FORGOT_LOGIN = "FORGOT_LOGIN";
export const FORGOT_LOGIN_FAILED = "FORGOT_LOGIN_FAILED";
export const FORGOT_LOGIN_SUCCESS = "FORGOT_LOGIN_SUCCESS";

export const forgotLogin = (payload) => ({
  type: FORGOT_LOGIN,
  payload,
});

export const forgotLoginFailed = (payload) => ({
  type: FORGOT_LOGIN_FAILED,
  payload,
});

export const forgotLoginSuccess = (payload) => ({
  type: FORGOT_LOGIN_SUCCESS,
  payload,
});
