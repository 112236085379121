import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { getUserGender } from "../../../../../shared/helpers/user-helper";
import numberOfHoles from "../../../../../shared/variables/number-of-holes";
import ratingType from "../../../../../shared/variables/rating-type";
import Select from "react-select";
import { manualRating } from "../../../variables/post-score-variables";
import {
  isTeeHolesInfoAvailable,
  isTeeHolesAllocationAvailable,
} from "../../../helpers/post-hbh-score-helper";
import {
  isEmpty,
  stringToFloat,
} from "../../../../../shared/helpers/ui-helper";
import { getParDisplay } from "../../../helpers/round-setup-helper";
import { defaultTeesValue } from "../../../validations/shared-round-setup-form-validation";
import { has18And9HolesTees } from "../../../../../shared/helpers/scores-helper";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";

class Tees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recentPlayedTeeSetId: false,
      manuallyInputWarningModalIsOpen: false,
      manuallyInputValue: null,
    };
  }

  manualRatingOption = {
    value: manualRating,
    label: "manually",
  };

  getOptionsHeader = (withPar) => ({
    value: "",
    label: "header",
    withPar: withPar,
    isDisabled: "yes",
  });

  render() {
    const teeSetId = new URLSearchParams(window.location.search).get(
      "teeSetId"
    );
    const recentPlayedTeeSet = this.getOptions().filter(
      (x) => x.value.TeeSetRatingId === Number(teeSetId)
    );
    let value = this.props.options.values.tees;
    if (
      recentPlayedTeeSet &&
      recentPlayedTeeSet.length > 0 &&
      !this.state.recentPlayedTeeSetId
    ) {
      value = recentPlayedTeeSet[0];
      setTimeout(() => {
        this.onChange(recentPlayedTeeSet[0]);
      }, 200);
      this.setState({ recentPlayedTeeSetId: true });
    }

    return (
      <Fragment>
        <YesNoModal
          isOpen={this.state.manuallyInputWarningModalIsOpen}
          onAbort={() =>
            this.setState({
              manuallyInputWarningModalIsOpen: false,
            })
          }
          onConfirm={() => {
            this.onChange(this.state.manuallyInputValue);
            this.setState({
              manuallyInputWarningModalIsOpen: false,
              manuallyInputValue: null,
            });
          }}
          yesTitle="Continue"
          noTitle="Cancel"
        >
          <p>
            The published Ratings for the available tee sets are the active
            ratings. If there is a tee set that is not rated, please contact
            your Club or Association for assistance.
          </p>
        </YesNoModal>
        <div className="row">
          <div className="col is-full">
            <label className="regular black">Tees</label>
            <div>
              <Select
                className={"default_select tees_select svg-color"}
                classNamePrefix={"ds"}
                value={value}
                onChange={(selection) => {
                  if (selection.value === "manual-rating") {
                    this.setState({
                      manuallyInputWarningModalIsOpen: true,
                      manuallyInputValue: selection,
                    });
                  } else {
                    this.onChange(selection);
                  }
                }}
                formatOptionLabel={this.getFormatOptionLabel}
                options={this.getOptions()}
                aria-label="Select Tee"
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  orderTees = (tees) =>
    tees.sort((first, second) => {
      const firstRating = first.Ratings.find(
        (r) => r.RatingType === ratingType.TOTAL
      );
      const secondRating = second.Ratings.find(
        (r) => r.RatingType === ratingType.TOTAL
      );

      if (firstRating && secondRating) {
        return (
          parseFloat(secondRating.CourseRating || 0) -
          parseFloat(firstRating.CourseRating || 0)
        );
      }

      if (!firstRating && !secondRating) {
        return 0;
      }

      return firstRating ? -1 : 1;
    });

  shouldDisplayParHeader = (tees) =>
    !tees.some((teeSet) => !teeSet || !teeSet.Holes || !teeSet.Holes.length);

  getOptions = () => {
    const { tees, options, isManualRatingsEnabled } = this.props;
    const gender = getUserGender();
    const filteredTees = tees.filter((t) => t.Gender === gender);
    this.orderTees(filteredTees);

    let result = [
      this.getOptionsHeader(this.shouldDisplayParHeader(filteredTees)),
    ];

    const multipleHolesTees = has18And9HolesTees(filteredTees);
    switch (options.values.nrOfHoles) {
      case numberOfHoles.EIGHTEEN:
      case numberOfHoles.TEN_SEVENTEEN:
        result = result.concat(
          this.getEighteenHoleOptions(filteredTees, multipleHolesTees)
        );
        break;
      case numberOfHoles.NINE:
        result = result.concat(
          this.getNineHoleOptions(filteredTees, multipleHolesTees)
        );
        break;
      default:
        break;
    }

    if (isManualRatingsEnabled === true) {
      result.push(this.manualRatingOption);
    }

    this.handleSelectedOption(result);

    return result;
  };

  getFormatOptionLabel = (option) => {
    if (option.label === "header") {
      return (
        <div>
          <span>Tees</span>
          {!this.props.trialGolfer && (
            <span className="right">
              C.R. / Slope{option.withPar ? " / Par" : ""}
            </span>
          )}
        </div>
      );
    } else if (option.label === "Select Tees") {
      return option.label;
    } else if (option.label === "manually") {
      return <div>Manually Input Course Rating/Slope</div>;
    } else {
      return (
        <div>
          <span>{option.label}</span>
          {!this.props.trialGolfer && (
            <span className="right">
              {`${option.courseRating} / ${option.slopeRating} ${option.par}`}
            </span>
          )}
        </div>
      );
    }
  };

  getEighteenHoleOptions = (tees, has18And9HolesTees) => {
    const array = has18And9HolesTees
      ? tees.filter((t) => t.HolesNumber === 18)
      : tees;
    return array
      .map((t) => {
        const rating = t.Ratings.find((r) => r.RatingType === ratingType.TOTAL);
        if (!rating) {
          return null;
        }
        return {
          value: {
            ...rating,
            TeeSetRatingId: t.TeeSetRatingId,
            Holes: t.Holes,
            IsShorter: t.IsShorter,
          },
          label: t.TeeSetRatingName,
          courseRating: stringToFloat(rating.CourseRating, 1),
          slopeRating: rating.SlopeRating,
          par: getParDisplay(t, rating.RatingType, "/ "),
        };
      })
      .filter((item) => item !== null);
  };

  getNineHoleOptions = (tees) => {
    let result = [];
    tees.forEach((t) => {
      const ratings = t.Ratings.filter(
        (r) => r.RatingType !== ratingType.TOTAL && this.teeHasRating(r)
      );
      ratings.forEach((rating) => {
        result.push({
          value: {
            ...rating,
            TeeSetRatingId: t.TeeSetRatingId,
            Holes: t.Holes,
            IsShorter: t.IsShorter,
          },
          label: `${t.TeeSetRatingName}${
            t.HolesNumber === 18 ? ` (${rating.RatingType})` : ""
          }`,
          nineHoles: true,
          courseRating: stringToFloat(rating.CourseRating, 1),
          slopeRating: rating.SlopeRating,
          par: getParDisplay(t, rating.RatingType, "/ "),
        });
      });
    });

    return result;
  };

  handleSelectedOption = (teeOptions) => {
    const { options } = this.props;

    if (
      isEmpty(options.values.tees.value) ||
      options.values.tees.value === manualRating
    )
      return;

    if (
      options.values.nrOfHoles === numberOfHoles.NINE &&
      options.values.tees.value.RatingType === ratingType.TOTAL
    ) {
      this.handleEighteenToNineHolesSelection(teeOptions);
    } else if (
      options.values.nrOfHoles !== numberOfHoles.NINE &&
      options.values.tees.value.RatingType !== ratingType.TOTAL
    ) {
      this.handleNineToEighteenHolesSelection(teeOptions);
    }
  };

  handleEighteenToNineHolesSelection = (teeOptions) => {
    const { options } = this.props;

    const targetOption = teeOptions.find(
      (x) =>
        x.value.TeeSetRatingId === options.values.tees.value.TeeSetRatingId &&
        (x.value.RatingType === ratingType.FRONT ||
          x.value.RatingType === ratingType.BACK)
    );

    options.setFieldValue(
      "tees",
      targetOption && this.teeHasRating(targetOption.value)
        ? targetOption
        : defaultTeesValue,
      true
    );
  };

  handleNineToEighteenHolesSelection = (teeOptions) => {
    const { options } = this.props;

    const targetOption = teeOptions.find(
      (x) =>
        x.value.TeeSetRatingId === options.values.tees.value.TeeSetRatingId &&
        x.value.RatingType === ratingType.TOTAL
    );
    if (targetOption) {
      options.setFieldValue("tees", targetOption, true);
    }
  };

  onChange = (selection) => {
    const { options, alertTeeNoHoles, isManualRatingsEnabled } = this.props;
    if (
      !isManualRatingsEnabled &&
      (this.shallAlertTeeNoHoles(selection, options.values.nrOfHoles) ||
        this.shallAlertTeeNoAllocation(selection))
    ) {
      alertTeeNoHoles(selection.value.TeeSetRatingId);
      options.setFieldValue("tees", defaultTeesValue, true);
    } else {
      options.setFieldValue("tees", selection, true);
    }
    this.setManualRating(selection);
  };

  teeHasRating = (tee) =>
    tee && tee.BogeyRating && tee.SlopeRating && tee.CourseRating;

  setManualRating = (selection) =>
    selection.value === manualRating
      ? this.props.setManualRating(true)
      : this.props.setManualRating(false);

  shallAlertTeeNoHoles = (selection, nrOfHoles) =>
    !isTeeHolesInfoAvailable(selection.value, nrOfHoles);

  shallAlertTeeNoAllocation = (selection) =>
    !isTeeHolesAllocationAvailable(selection.value);
}

Tees.propTypes = {
  tees: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  setManualRating: PropTypes.func.isRequired,
  alertNoTees: PropTypes.func.isRequired,
  alertTeeNoHoles: PropTypes.func.isRequired,
  isManualRatingsEnabled: PropTypes.bool.isRequired,
};

export default Tees;
