import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_FOLLOWED_GOLFERS,
  FETCH_FOLLOWED_GOLFERS_SUCCESS,
  FETCH_FOLLOWED_GOLFERS_FAILED,
  FOLLOW_GOLFER_SUCCESS,
  UN_FOLLOW_GOLFER_SUCCESS,
  RESET_FOLLOWED_GOLFERS,
} from "../actions";

const initialState = {
  golfers: [],
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FOLLOWED_GOLFERS:
      return {
        ...state,
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_FOLLOWED_GOLFERS_SUCCESS:
      return {
        ...state,
        golfers: payload,
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_FOLLOWED_GOLFERS_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    case FOLLOW_GOLFER_SUCCESS:
      return {
        ...state,
        golfers: [
          ...state.golfers,
          {
            id: payload.followed_golfer_id,
            club_id: payload.club_id,
          },
        ],
      };
    case UN_FOLLOW_GOLFER_SUCCESS:
      return {
        ...state,
        golfers: state.golfers.filter(
          (g) =>
            g.id.toString() !== payload.followed_golfer_id.toString() ||
            g.club_id.toString() !== payload.club_id.toString()
        ),
      };
    case RESET_FOLLOWED_GOLFERS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
