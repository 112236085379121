import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "../../../../shared/helpers/ui-helper";
import { getUserGenderAcronym } from "../../../../shared/helpers/user-helper";
import { manualRating } from "../../variables/post-score-variables";
import ratingType from "../../../../shared/variables/rating-type";

class CourseHandicap extends Component {
  render = () =>
    this.hasToRender() &&
    !this.props.trialGolfer && (
      <div className="row">
        <div className="col">
          <div className="info relative">
            <span>Your Course Handicap is</span>
            <strong className="large">
              {this.getCourseHandicapValue(this.props.options)}
            </strong>
            <sup className="trigger-container">
              <button onClick={this.props.openInfoModal} className="btn blank">
                <i className="material-icons-outlined">info</i>
              </button>
            </sup>
          </div>
        </div>
      </div>
    );

  getCourseHandicapValue = () => {
    const { options, isManualCourse } = this.props;
    return options.values.isManualRating || isManualCourse
      ? this.getManualCourseHandicapValue()
      : this.getSelectedCourseHandicapValue(options);
  };

  getSelectedCourseHandicapValue = (options) => {
    const availableTees = this.props.courseHandicap.tee_sets;
    const selectedTees = options.values.tees.value;

    const teeSet = availableTees.find(
      (x) =>
        x.gender === getUserGenderAcronym() &&
        x.tee_set_id === selectedTees.TeeSetRatingId
    );

    const teeSetSide = teeSet.ratings?.find(
      (x) => x.tee_set_side === this.normalizeTeeSetSide(selectedTees)
    );

    return teeSetSide ? teeSetSide.course_handicap_display : undefined;
  };

  getManualCourseHandicapValue = () =>
    this.props.manualCourseHandicap.manual_course_handicap.course_handicap;

  hasToRender = () => {
    const { options, isManualCourse } = this.props;
    return options.values.isManualRating || isManualCourse
      ? this.isManualRatingReceived()
      : this.isTeeSelected(options);
  };

  isTeeSelected = (options) => {
    const teesValue = options.values.tees.value;
    return !isEmpty(teesValue) && teesValue !== manualRating;
  };

  isManualRatingReceived = () =>
    this.props.manualCourseHandicap.manual_course_handicap;

  normalizeTeeSetSide = (selectedTee) => {
    switch (selectedTee.RatingType) {
      case ratingType.TOTAL:
        return "All 18";
      case ratingType.FRONT:
        return "F9";
      case ratingType.BACK:
        return "B9";
      default:
        return "";
    }
  };
}

CourseHandicap.propTypes = {
  courseHandicap: PropTypes.object.isRequired,
  openInfoModal: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  isManualCourse: PropTypes.bool.isRequired,
  manualCourseHandicap: PropTypes.object.isRequired,
};

export default CourseHandicap;
