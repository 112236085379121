import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import numberOfHoles from "../../../../../shared/variables/number-of-holes";

class NrOfHoles extends Component {
  render() {
    const { options, onChange, hasEighTeen, hasTenSeventen } = this.props;
    return (
      <div className="row">
        <div className="col is-full">
          <label className="regular black">Number of Holes Played</label>
          <div className="radio_container inline is-full">
            {hasEighTeen && (
              <Fragment>
                <input
                  className="pill"
                  type="radio"
                  name="nrOfHoles"
                  id="18-holes"
                  value={numberOfHoles.EIGHTEEN}
                  checked={options.values.nrOfHoles === numberOfHoles.EIGHTEEN}
                  onChange={(e) => {
                    options.handleChange(e);
                    onChange(numberOfHoles.EIGHTEEN);
                  }}
                />
                <label htmlFor="18-holes">18 Holes</label>
              </Fragment>
            )}
            <Fragment>
              <input
                className="pill"
                type="radio"
                name="nrOfHoles"
                id="9-holes"
                value={numberOfHoles.NINE}
                checked={
                  options.values.nrOfHoles === numberOfHoles.NINE ||
                  !hasEighTeen
                }
                onChange={(e) => {
                  options.handleChange(e);
                  onChange(numberOfHoles.NINE);
                }}
              />
              <label htmlFor="9-holes">9 Holes</label>
            </Fragment>
            {hasTenSeventen && (
              <Fragment>
                <input
                  className="pill"
                  type="radio"
                  name="nrOfHoles"
                  id="10-17-holes"
                  value={numberOfHoles.TEN_SEVENTEEN}
                  checked={
                    options.values.nrOfHoles === numberOfHoles.TEN_SEVENTEEN
                  }
                  onChange={(e) => {
                    options.handleChange(e);
                    onChange(numberOfHoles.TEN_SEVENTEEN);
                  }}
                />
                <label htmlFor="10-17-holes">10-17 Holes</label>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

NrOfHoles.propTypes = {
  options: PropTypes.object.isRequired,
  hasEighTeen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NrOfHoles;
