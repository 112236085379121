import {
  FETCH_ASSOCIATIONS,
  fetchAssociationsFailed,
  fetchAssociationsSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import AssociationsApi from "../../../services/associations-api";

export default (action$) =>
  action$.pipe(ofType(FETCH_ASSOCIATIONS)).pipe(switchMap(getAssociations));

const getAssociations = () =>
  from(AssociationsApi.getAssociations())
    .pipe(
      map((response) => fetchAssociationsSuccess(response.data.associations))
    )
    .pipe(catchError((error) => of(fetchAssociationsFailed(error))));
