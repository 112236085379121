import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";

class PostScoreApiErrorsModal extends Component {
  render() {
    const { isOpen, onClose, errors } = this.props;
    return (
      <YesNoModal
        onConfirm={onClose}
        onAbort={onClose}
        isNoVisible={false}
        isOpen={isOpen}
        yesTitle="OK"
      >
        {errors.map((error, key) => (
          <p key={key}>{error}</p>
        ))}
      </YesNoModal>
    );
  }
}
PostScoreApiErrorsModal.defaultProps = {
  errors: [],
};

PostScoreApiErrorsModal.propTypes = {
  errors: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PostScoreApiErrorsModal;
