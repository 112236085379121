import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { routes } from "../../../../shared/routes/index";

class HamburgerMenuNotLoggedLinks extends Component {
  render() {
    const { onClick } = this.props;
    return (
      <Fragment>
        <ul className="menu__links">
          <li>
            <NavLink
              className="menu__link border-focus"
              onClick={onClick}
              activeClassName="is-active"
              to={routes.ghinAssociations.path}
            >
              <span>GHIN Associations</span>
            </NavLink>
          </li>
          <li>
            <a
              className="menu__link border-focus"
              onClick={onClick}
              target="_blank"
              href="https://www.usga.org/content/usga/home-page/Handicap-ghin/GHIN-Sentry.html"
              rel="noopener noreferrer"
            >
              <span>About GHIN</span>
            </a>
          </li>
          <li>
            <NavLink
              className="menu__link border-focus"
              onClick={onClick}
              activeClassName="is-active"
              to={routes.contactUs.path}
            >
              <span>
                Contact&nbsp;
                <span aria-hidden="true">US</span>
                <span className="visually-hidden">us</span>
              </span>
            </NavLink>
          </li>
          <li>
            <a
              className="menu__link border-focus"
              onClick={onClick}
              target="_blank"
              href="https://www.usga.org/content/usga/home-page/get-handicap.html?utm_source=ghin&utm_medium=web&utm_campaign=gah"
              rel="noopener noreferrer"
            >
              <span>Get A Handicap Index®</span>
            </a>
          </li>
          <li>
            <a
              className="menu__link border-focus"
              onClick={onClick}
              target="_blank"
              href="http://www.usga.org/handicapping/ghin-faqs.html"
              rel="noopener noreferrer"
            >
              <span>FAQs</span>
            </a>
          </li>
        </ul>
      </Fragment>
    );
  }
}

HamburgerMenuNotLoggedLinks.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default HamburgerMenuNotLoggedLinks;
