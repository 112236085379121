import {
  getUserState,
  getUserCountry,
} from "../../../shared/helpers/user-helper";
import { checkApiError } from "../../auth/actions";

export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const FETCH_COUNTRIES_FAILED = "FETCH_COUNTRIES_FAILED";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const SELECT_COUNTRY = "SELECT_COUNTRY";
export const SELECT_STATE = "SELECT_STATE";

export const fetchCountries = () => ({
  type: FETCH_COUNTRIES,
});

export function fetchCountriesFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_COUNTRIES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchCountriesSuccess = (countries) => {
  return {
    type: FETCH_COUNTRIES_SUCCESS,
    payload: {
      countries,
      userState: getUserState(),
      userCountry: getUserCountry(),
    },
  };
};

export const selectCountry = (code) => ({
  type: SELECT_COUNTRY,
  payload: { code, userState: getUserState() },
});

export const selectState = (payload) => ({
  type: SELECT_STATE,
  payload,
});
