import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "./YesNoModal";

class HandicapInfoModal extends Component {
  render() {
    const { isOpen, onAbort, isOkVisible } = this.props;
    return (
      <YesNoModal
        onAbort={onAbort}
        onConfirm={onAbort}
        isOpen={isOpen}
        isYesVisible={isOkVisible}
        isNoVisible={false}
        yesTitle="Ok"
      >
        <h3>Playing Handicap</h3>
        <p>
          It represents the number of strokes a player gives or receives for the
          round being played and determined from the unrounded Course Handicap
          adjusted for any Handicap Allowances or format of play.
        </p>
      </YesNoModal>
    );
  }
}

HandicapInfoModal.defaultProps = {
  isOkVisible: false,
};

HandicapInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAbort: PropTypes.func.isRequired,
  isOkVisible: PropTypes.bool,
};

export default HandicapInfoModal;
