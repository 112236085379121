import {
  getGolferId,
  getGolferGender,
} from "../../../shared/helpers/user-helper";
import { checkApiError } from "../../auth/actions";

export const FETCH_CALCULATOR_GOLFER_TEE = "FETCH_CALCULATOR_GOLFER_TEE";
export const FETCH_CALCULATOR_GOLFER_TEE_FAILED =
  "FETCH_CALCULATOR_GOLFER_TEE_FAILED";
export const FETCH_CALCULATOR_GOLFER_TEE_SUCCESS =
  "FETCH_CALCULATOR_GOLFER_TEE_SUCCESS";
export const FETCH_CALCULATOR_REMOVE_COURSE = "FETCH_CALCULATOR_REMOVE_COURSE";

export const fetchCalculatorGolferTee = (courseId, golfer) => ({
  type: FETCH_CALCULATOR_GOLFER_TEE,
  payload: {
    golfer,
    courseId,
    golferId: getGolferId(golfer),
    manualGolferId: golfer.manual_golfer_id,
    handicapIndex: golfer.handicap_index,
  },
});

export function fetchCalculatorGolferTeeFailed(error, golfer) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CALCULATOR_GOLFER_TEE_FAILED,
      payload: { golferId: getGolferId(golfer) },
    });
    dispatch(checkApiError(error));
  };
}

export const fetchCalculatorGolferTeeSuccess = (tee_sets, golfer) => ({
  type: FETCH_CALCULATOR_GOLFER_TEE_SUCCESS,
  payload: {
    tee_sets,
    golferId: getGolferId(golfer),
    gender: getGolferGender(golfer),
  },
});
