import React, { Component } from "react";

class Title extends Component {
  render = () => (
    <div className="box-head">
      <h3 className="box-title">Round Summary</h3>
    </div>
  );
}

export default Title;
