import React, { Component } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { overlayModalStyle } from "../../../../shared/helpers/ui-helper";
import { acceptTerms } from "../../actions";
import LoaderButton from "../../../../shared/components/buttons/LoaderButton";
import jobStatus from "../../../../shared/variables/job-status";

class AcceptTermsModal extends Component {
  state = {
    termsAccepted: false,
    showError: false,
  };

  componentDidMount = () => Modal.setAppElement(this.props.appElementName);

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { postStatus } = nextProps;
    if (
      postStatus !== this.props.postStatus &&
      postStatus === jobStatus.FAILED
    ) {
      this.setState({ showError: true });
    }

    if (
      postStatus !== this.props.postStatus &&
      postStatus === jobStatus.SUCCESS
    ) {
      this.props.onAccept();
    }
  }

  onToggleTerms = () =>
    this.setState((prevState) => ({
      termsAccepted: !prevState.termsAccepted,
      showError: false,
    }));

  onAccept = () => this.props.acceptTerms();

  render() {
    const { visible, onCancel } = this.props;
    const { termsAccepted, showError } = this.state;
    return (
      <Modal
        isOpen={visible}
        style={overlayModalStyle}
        className="modal-dialog"
      >
        <div className="modal-content">
          <button className="modal_close" onClick={onCancel}>
            ✕
          </button>
          <div className="modal-body">
            <div className="content">
              <div
                className="col is-full"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={this.onToggleTerms}
                  className="regular"
                  id="accept_terms"
                  name="accept_terms"
                />
                <label
                  htmlFor="accept_terms"
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    color: "black",
                  }}
                >
                  I have read and agree to the{" "}
                  {this.renderUnderlineLink(
                    "https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-privacy-policy.html",
                    "Privacy Policy"
                  )}{" "}
                  and{" "}
                  {this.renderUnderlineLink(
                    "https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-terms-of-service.html",
                    "Terms of Service"
                  )}
                  .
                </label>
              </div>
              {showError && (
                <div className="error" style={{ fontSize: 16 }}>
                  You need to agree to the Privacy Policy and Terms of Service
                  before continuing.
                </div>
              )}
            </div>
            <div className="actions centered">
              <button className="btn blank blue" onClick={this.props.onCancel}>
                Cancel
              </button>
              <LoaderButton
                className="btn fill cardinal"
                onClick={this.onAccept}
                disabled={!termsAccepted}
                loading={String(this.props.postStatus === jobStatus.PROCESSING)}
              >
                Continue
              </LoaderButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderUnderlineLink = (link, title) => (
    <a href={link} className="link" target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  );
}

AcceptTermsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

const mapStateToProps = ({ authReducer }) => ({
  postStatus: authReducer.acceptTermsReducer.status,
  error: authReducer.acceptTermsReducer.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ acceptTerms }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTermsModal);
