import React, { Component, Fragment } from "react";
import EditTeeItem from "./EditTeeItem";
import { addTee } from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import jobStatus from "../../../shared/variables/job-status";
import Loader from "../../../shared/components/loaders/Loader";

class EditTeesList extends Component {
  state = { disabled: true, course: {}, selectedTeeId: "" };

  componentDidMount() {
    this.setState({
      selectedTeeId: this.props.course.tee_id,
    });
  }
  onChangeTee = (value) => {
    this.setState({
      disabled: false,
      course: value,
      selectedTeeId: value.tee_id,
    });
  };

  onRemoveTee = () => {
    const course = {
      course_id: this.props.course.course_id,
      tee_id: null,
    };
    this.setState({
      disabled: false,
      course: course,
      selectedTeeId: null,
    });
  };

  onClickSave = () => {
    const { course } = this.state;
    const { onClickCancel, addTee } = this.props;
    addTee(course.course_id, course.tee_id, this.props.updateCourses);
    onClickCancel();
  };

  sortTeesByCR = (tees) => {
    tees.sort(function (t1, t2) {
      return t2.Ratings[0].CourseRating - t1.Ratings[0].CourseRating;
    });
  };

  render() {
    const { tees, onClickCancel, course, fetchTeesStatus } = this.props;
    const { disabled, selectedTeeId } = this.state;
    this.sortTeesByCR(tees);
    return (
      <Fragment>
        <li>
          <div className="item header border-focus" to="#" tabIndex="-1">
            <span className="star" />
            <span className="details">
              <span className="tee abbr" data-abbr="Name" data-full="Name" />
            </span>
            {!this.props.trialGolfer && (
              <span
                className="index abbr"
                data-abbr="Total"
                data-full="C.R. / Slope / Par"
              />
            )}
          </div>
        </li>
        {fetchTeesStatus === jobStatus.PROCESSING ? (
          <Loader />
        ) : (
          tees.map((tee, key) => (
            <EditTeeItem
              tee={tee}
              key={key}
              trialGolfer={this.props.trialGolfer}
              course={course}
              onChangeTee={this.onChangeTee}
              isChecked={selectedTeeId === tee.TeeSetRatingId}
            />
          ))
        )}
        {this.props.course.tee_id != null && (
          <li>
            <span className="item">
              <input
                type="radio"
                className="radio-tee"
                id={"no-tee"}
                name="no-tee"
                onChange={this.onRemoveTee}
                checked={!selectedTeeId}
              />
              <div className="item details">
                <span className="details">
                  <span className="tee">No tee</span>
                </span>
              </div>
            </span>
          </li>
        )}
        <div className="row">
          <div className="col">
            <button onClick={onClickCancel} className="btn blank zero-pad blue">
              Cancel
            </button>
          </div>
          <div className="col">
            <button
              className="btn fill cardinal"
              disabled={disabled}
              onClick={this.onClickSave}
            >
              Save Changes
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addTee }, dispatch);

export default connect(null, mapDispatchToProps)(EditTeesList);
