import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { showIfExists } from "../../../shared/helpers/ui-helper";

class CoursesListItem extends Component {
  render() {
    const { course, navLink } = this.props;
    const id = course.TeeSetId || course.tee_id;
    const teeSetId = id ? `?teeSetId=${id}` : "";
    return (
      <NavLink to={`${navLink}${teeSetId}`} className="item">
        <span className="item__details">
          <div className="item__name">{this.getLeftName(course)}</div>
          <span className="item__extras">{this.getRightName(course)}</span>
          <p className="item__location">{this.getLocation(course)}</p>
        </span>
      </NavLink>
    );
  }

  getLeftName = (course) =>
    course.facility_name || course.FacilityName
      ? course.facility_name || course.FacilityName
      : course.course_name || course.CourseName;

  getRightName = (course) =>
    (course.facility_name || course.FacilityName) &&
    (course.facility_name || course.FacilityName) !==
      (course.course_name || course.CourseName)
      ? course.course_name || course.CourseName
      : "";

  getLocation = (course) => (
    <Fragment>
      {`${showIfExists(course.City)}
      ${showIfExists(course.State, ", ")}
      ${showIfExists(course.State)}`}
    </Fragment>
  );
}

CoursesListItem.propTypes = {
  course: PropTypes.object.isRequired,
  navLink: PropTypes.string.isRequired,
};

export default CoursesListItem;
