import {
  GOLFER_PROFILE_FETCH,
  fetchGolferProfileSuccess,
  fetchGolferProfileFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(GOLFER_PROFILE_FETCH))
    .pipe(switchMap(({ payload }) => fetchGolferProfile(payload)));

const fetchGolferProfile = () =>
  from(GolferApi.golferProfile())
    .pipe(map((response) => fetchGolferProfileSuccess(response.data.golfers)))
    .pipe(catchError(() => of(fetchGolferProfileFailed())));
