import { checkApiError } from "../../auth/actions";
import { appendLogoToMemberships } from "../actions/golfer-profile-fetch";

export const GOLFER_ASSOCIATIONS_FETCH = "GOLFER_ASSOCIATIONS_FETCH";
export const GOLFER_ASSOCIATIONS_FETCH_FAILED =
  "GOLFER_ASSOCIATIONS_FETCH_FAILED";
export const GOLFER_ASSOCIATIONS_FETCH_SUCCESS =
  "GOLFER_ASSOCIATIONS_FETCH_SUCCESS";
export const GOLFER_ASSOCIATIONS_FETCH_RESET =
  "GOLFER_ASSOCIATIONS_FETCH_RESET";

export const fetchContactAssociations = () => ({
  type: GOLFER_ASSOCIATIONS_FETCH,
});

export function fetchContactAssociationsFailed(error) {
  return (dispatch) => {
    dispatch({ type: GOLFER_ASSOCIATIONS_FETCH_FAILED });
    dispatch(checkApiError(error));
  };
}

export function fetchContactAssociationsSuccess(payload) {
  return (dispatch) => {
    dispatch({ type: GOLFER_ASSOCIATIONS_FETCH_SUCCESS, payload });
    if (payload && payload.length > 0) {
      dispatch(appendLogoToMemberships(payload));
    }
  };
}

export const fetchContactAssociationsReset = (payload) => ({
  type: GOLFER_ASSOCIATIONS_FETCH_RESET,
  payload,
});
