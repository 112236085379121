import MyCourses from "../../screens/my-courses/components/MyCourses";
import SelectCourses from "../../screens/my-courses/components/SelectCourses";
import SelectTees from "../../screens/my-courses/components/SelectTees";
import DefaultLayout from "../components/layouts/DefaultLayout";
import AddCoursesContainer from "../../screens/my-courses/components/AddCoursesContainer";

export default {
  myCourses: {
    path: "/my-courses",
    component: MyCourses,
    exact: false,
    rootPath: true,
    authRequired: true,
    layout: DefaultLayout,
  },
  myCoursesSelectCourse: {
    path: "/my-courses/select-course",
    component: SelectCourses,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  selectTee: {
    path: "/my-courses/:courseId/select-tee",
    component: SelectTees,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  addCourses: {
    path: "/my-courses/add-courses",
    component: AddCoursesContainer,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
};
