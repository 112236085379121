import BaseApi from "./base-api";
import { getUserGhinNo } from "../shared/helpers/user-helper";

class AssociationsApi extends BaseApi {
  apiUrl = `${this.baseUrl}/api/v1`;

  getAssociations = () => {
    return this.httpGet(`${this.apiUrl}/public/golf_associations.json`);
  };

  getGolferAssociations = () => {
    const url = `${this.apiUrl}/golfers/${getUserGhinNo()}/associations.json`;
    return this.httpGet(url);
  };

  getAssociationSettings = (associationId) => {
    const url = `${this.apiUrl}/associations/${associationId}/association_settings.json`;
    return this.httpGet(url);
  };
}

export default new AssociationsApi();
