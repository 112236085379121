import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchChildScores,
  postCombinedScores,
  fetchChildScoresReset,
  postCombinedScoresReset,
} from "../../actions";
import jobStatus from "../../../../shared/variables/job-status";
import Loader from "../../../../shared/components/loaders/Loader";
import DesktopHeader from "../../../post-score/components/EditRoundStats/DesktopHeader";
import MobileHeader from "../../../post-score/components/EditRoundStats/MobileHeader";
import Box from "../../../../shared/components/box/Box";
import BoxBody from "../../../../shared/components/box/BoxBody";
import PostHbhForm from "../../../post-score/components/PostHbhScore/PostHbhForm";
import PostInvalidScoreModal from "../../../post-score/components/Shared/Modals/PostInvalidScoreModal";
import ClearAllStatsModal from "../../../post-score/components/Shared/Modals/ClearAllStatsModal";
import ratingType from "../../../../shared/variables/rating-type";
import teeSetSide from "../../../../shared/variables/tee-set-side";
import { convertScoreToRoundSetup } from "../../../post-score/helpers/edit-round-stats-helper";
import { postScoreMode } from "../../../post-score/variables/post-score-variables";
import EditScoreSubmit from "./EditScoreSubmit";
import Navigate from "../../../../shared/navigation/navigate";
import { validateAdvStats } from "../../../post-score/validations/post-hbh-score-validation";
import { getVisibleHoles } from "../../../post-score/helpers/post-hbh-score-helper";
import IncompleteUpdatedStatsPostModal from "../../../post-score/components/Shared/Modals/IncompleteUpdatedStatsPostModal";
import { initialTotalCombinedScores } from "../../../../shared/helpers/combined-scores-helper";

class EditCombinedScores extends Component {
  formRef;

  constructor(props) {
    super(props);
    this.state = {
      withAdvancedStats: false,
      isClearAllStatsModalOpen: false,
      invalidScoreModalOpen: false,
      invalidScoreModalMessage: "",
      incompleteUpdatedStatsPostModalOpen: false,
      submitButtonEnabled: false,
    };
  }

  componentDidMount() {
    const { score } = this.props;
    if (score) {
      this.props.fetchChildScores(score.id);
    } else {
      this.onAbort();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { postStatus } = nextProps;
    if (
      this.props.postStatus !== postStatus &&
      postStatus === jobStatus.SUCCESS
    ) {
      this.props.fetchChildScoresReset();
      this.props.postCombinedScoresReset();
      this.onAbort();
    }
  }

  getScoreSentOnRoute = () => {
    try {
      return this.props.history.location.state.score;
    } catch {
      return undefined;
    }
  };
  fetchMoreScores = () => {
    const { filter, extraParams } = this.state;
    const { offset } = this.props;
    this.props.fetchStatsScoresNext(filter, offset, true, extraParams);
  };

  getMobileHeaderSide = (score) =>
    score.tee_set_side !== teeSetSide.Back ? ratingType.FRONT : ratingType.BACK;

  reCalculateStats = () => {
    this.setState({ submitButtonEnabled: true });
    const { scores } = this.props;
    const combinedTotal = {
      putts: 0,
      gir: 0,
      acc: 0,
    };
    scores.forEach((score, index) => {
      const values = this[`formRef${index}`].getFormValues();
      combinedTotal.putts += values.totalPutts;
      combinedTotal.gir += values.totalGir;
      combinedTotal.acc += values.totalDrivingAcc;
    });

    this[`formRef${scores.length - 1}`].updateTotalCombinedScores(
      combinedTotal
    );
  };

  checkValidScores = () => {
    const { scores } = this.props;
    const valid = [];
    scores.forEach((score, index) => {
      const values = this[`formRef${index}`].getFormValues();
      const roundSetup = convertScoreToRoundSetup(score, score.courseDetails);
      const visibleHoles = getVisibleHoles(values, roundSetup.tees.RatingType);
      if (!visibleHoles.some((hole) => hole.adjScore <= hole.putts)) {
        valid.push(score.id);
      }
    });

    if (valid.length !== scores.length) {
      this.setState({
        invalidScoreModalOpen: true,
        invalidScoreModalMessage:
          "Putts cannot be equal to or greater than score.",
      });
      return false;
    }
    return true;
  };

  checkValidStats = () => {
    const { scores } = this.props;
    const valid = [];
    scores.forEach((score, index) => {
      const values = this[`formRef${index}`].getFormValues();
      const roundSetup = convertScoreToRoundSetup(score, score.courseDetails);
      if (validateAdvStats(roundSetup, values)) {
        valid.push(score.id);
      }
    });
    if (valid.length !== scores.length) {
      this.setState({ incompleteUpdatedStatsPostModalOpen: true });
      return false;
    }
    return true;
  };

  onCleanScores = () => {
    const { scores } = this.props;
    this.setState({ isClearAllStatsModalOpen: false });
    scores.forEach((score, index) => {
      this[`formRef${index}`].clearAllStats();
    });
    this.reCalculateStats();
  };

  onAbort = () => Navigate.goBack();

  onSubmit = () => {
    const validScores = this.checkValidScores();
    if (validScores) {
      const validStats = this.checkValidStats();
      if (validStats) {
        this.onSubmitScores();
      }
    }
  };

  onSubmitScores = () => {
    const { scores } = this.props;
    scores.forEach((score, index) => {
      const values = this[`formRef${index}`].getFormValues();
      this.props.postCombinedScores(score, values, scores.length);
    });
  };

  renderScoresForm(score, index) {
    const { onlyView, scores } = this.props;
    const lastScore = scores.length === index + 1;
    const totalCombinedScores = initialTotalCombinedScores(scores);
    return (
      <div key={score.id}>
        <MobileHeader
          onClearAllStats={() =>
            this.setState({ isClearAllStatsModalOpen: true })
          }
          side={this.getMobileHeaderSide(score)}
          score={score}
          lastScore={lastScore}
        />
        <PostHbhForm
          withAdvancedStats
          combinedScoresForm
          showSideTotalsColumn
          showCombinedScoresTotals={lastScore}
          ref={(ref) => (this[`formRef${index}`] = ref)}
          maxHbhScore={score.maximumScores}
          roundSetup={convertScoreToRoundSetup(score, score.courseDetails)}
          courseDetails={score.courseDetails}
          isScoreReadOnly={true}
          initialHoleValues={score.hole_details}
          postScoreMode={postScoreMode.EDIT}
          viewScoreCard={onlyView}
          customSubmit={() => {}}
          onSubmit={() => {}}
          alertInvalidScore={() => {}}
          postHbhScoreStatus="postHbhCombinedScores"
          totalCombinedScores={lastScore ? totalCombinedScores : null}
          reCalculateStats={this.reCalculateStats}
        />
      </div>
    );
  }

  renderContent() {
    const {
      isClearAllStatsModalOpen,
      invalidScoreModalOpen,
      invalidScoreModalMessage,
      incompleteUpdatedStatsPostModalOpen,
      submitButtonEnabled,
    } = this.state;

    const { onlyView, scores, postStatus, golferActive } = this.props;
    const title = onlyView ? "View Scorecard" : "Edit Round Stats";
    return (
      <Fragment>
        <section className="post-score">
          <Box>
            {golferActive && (
              <DesktopHeader
                title={title}
                onClearAllStats={() =>
                  this.setState({ isClearAllStatsModalOpen: true })
                }
              />
            )}
            <div style={{ backgroundColor: "white" }}>
              <BoxBody isTabletMod={true}>
                {scores.map((score, index) =>
                  this.renderScoresForm(score, index)
                )}
                {golferActive && (
                  <EditScoreSubmit
                    submitButtonEnabled={submitButtonEnabled}
                    postStatus={postStatus}
                    onAbort={this.onAbort}
                    onSubmit={this.onSubmit}
                  />
                )}
              </BoxBody>
            </div>
          </Box>
        </section>
        <PostInvalidScoreModal
          message={invalidScoreModalMessage}
          isOpen={invalidScoreModalOpen}
          onClose={() => this.setState({ invalidScoreModalOpen: false })}
        />
        <ClearAllStatsModal
          isOpen={isClearAllStatsModalOpen}
          onAbort={() => this.setState({ isClearAllStatsModalOpen: false })}
          onConfirm={this.onCleanScores}
        />
        <IncompleteUpdatedStatsPostModal
          isOpen={incompleteUpdatedStatsPostModalOpen}
          onAbort={() =>
            this.setState({ incompleteUpdatedStatsPostModalOpen: false })
          }
          onConfirm={() => {
            this.setState({ incompleteUpdatedStatsPostModalOpen: false });
            this.onSubmitScores();
          }}
        />
      </Fragment>
    );
  }

  render() {
    const { fetchStatus, scores } = this.props;
    return (
      <Fragment>
        {fetchStatus === jobStatus.SUCCESS && scores.length > 0 ? (
          this.renderContent()
        ) : (
          <Loader />
        )}
      </Fragment>
    );
  }
}

EditCombinedScores.propTypes = {
  score: PropTypes.shape({}),
  onlyView: PropTypes.bool,
};

EditCombinedScores.defaultProps = {
  score: undefined,
  onlyView: false,
};

const mapStateToProps = (state, props) => ({
  scores: state.statsReducer.combinedScoresReducer.scores,
  fetchStatus: state.statsReducer.combinedScoresReducer.jobStatus,
  postStatus: state.statsReducer.postCombinedScoresReducer.jobStatus,
  score: props.history?.location?.state?.score,
  golferActive: state.profileReducer.golferProfileFetchReducer.golferActive,
  onlyView: props.history?.location?.state?.onlyView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchChildScores,
      postCombinedScores,
      fetchChildScoresReset,
      postCombinedScoresReset,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditCombinedScores);
