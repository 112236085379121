import React, { Component } from "react";
import PropTypes from "prop-types";

class ViewHistory extends Component {
  render = () => (
    <div className="columns">
      <div className="row">
        <div className="col auto h-centered">
          <button
            className="btn outline blue"
            onClick={this.props.onViewScoreHistory}
          >
            View Score History
          </button>
        </div>
      </div>
    </div>
  );
}

ViewHistory.propTypes = {
  onViewScoreHistory: PropTypes.func.isRequired,
};

export default ViewHistory;
