import { combineReducers } from "redux";
import scoresReducer from "./stats-scores-fetch";
import combinedScoresReducer from "./fetch-combined-scores";
import postCombinedScoresReducer from "./post-combined-scores";
import averageScoresReducer from "./stats-average-scores-fetch";

const reducers = combineReducers({
  scoresReducer,
  combinedScoresReducer,
  postCombinedScoresReducer,
  averageScoresReducer,
});

export default reducers;
