import { FETCH_TEES, fetchTeesSuccess, fetchTeesFailed } from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import CoursesApi from "../../../services/courses-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_TEES))
    .pipe(switchMap(({ payload }) => fetchTees(payload)));

const fetchTees = (payload) =>
  from(CoursesApi.getCourseDetails(payload.courseId))
    .pipe(map((r) => fetchTeesSuccess(r.data.TeeSets, payload.callback)))
    .pipe(catchError((error) => of(fetchTeesFailed(error))));
