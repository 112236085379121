import React, { Component, Fragment } from "react";
import SelectTee from "./SelectTee";

export default class SelectCourses extends Component {
  render() {
    return (
      <Fragment>
        <div className="container">
          <section className="post-score">
            <SelectTee {...this.props} />
          </section>
        </div>
      </Fragment>
    );
  }
}
