import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_2020_SCORES,
  FETCH_2020_SCORES_SUCCESS,
  FETCH_2020_SCORES_FAILED,
} from "../actions";

const initialState = {
  scores: [],
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_2020_SCORES:
      return {
        ...state,
        scores: [],
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_2020_SCORES_SUCCESS:
      return {
        ...state,
        scores: [...state.scores, ...payload.scores],
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_2020_SCORES_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    default:
      return state;
  }
};
