import React, { Component } from "react";
import PropTypes from "prop-types";

class BoxBody extends Component {
  render = () => <div className={this.getClass()}>{this.props.children}</div>;

  getClass = () =>
    `box-body ${this.props.isTabletMod ? "tablet-mod" : ""} ${
      this.props.additionalCssClass
    }`;
}

BoxBody.defaultProps = {
  children: {},
  isTabletMod: false,
  additionalCssClass: "",
};

BoxBody.propTypes = {
  children: PropTypes.any.isRequired,
  isTabletMod: PropTypes.bool.isRequired,
  additionalCssClass: PropTypes.string.isRequired,
};

export default BoxBody;
