import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_COURSE_DETAILS,
  FETCH_COURSE_DETAILS_SUCCESS,
  FETCH_COURSE_DETAILS_FAILED,
  FETCH_COURSE_DETAILS_RESET,
} from "../actions";
import { removeEmptyTeesFromCourse } from "../../../shared/helpers/transformer-helper";

const initialState = {
  course: {
    TeeSets: [],
  },
  fetchCourseDetailsStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COURSE_DETAILS:
      return {
        ...state,
        course: initialState.course,
        fetchCourseDetailsStatus: jobStatus.PROCESSING,
      };
    case FETCH_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        course: removeEmptyTeesFromCourse(payload),
        fetchCourseDetailsStatus: jobStatus.SUCCESS,
      };
    case FETCH_COURSE_DETAILS_FAILED:
      return {
        ...state,
        fetchCourseDetailsStatus: jobStatus.FAILED,
      };
    case FETCH_COURSE_DETAILS_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
