import {
  CHANGE_COURSES_ORDER,
  changeCoursesOrderSuccess,
  changeCoursesOrderFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import CoursesApi from "../../../services/courses-api";

export default (action$) =>
  action$
    .pipe(ofType(CHANGE_COURSES_ORDER))
    .pipe(switchMap(({ payload }) => changeCoursesOrder(payload)));

const changeCoursesOrder = (payload) =>
  from(CoursesApi.changeCourseOrder(payload))
    .pipe(
      map((response) =>
        changeCoursesOrderSuccess(response.data.golfer_course_preference)
      )
    )
    .pipe(catchError((error) => of(changeCoursesOrderFailed(error))));
