import React, { Component } from "react";
import PropTypes from "prop-types";
import ratingType from "../../../../../shared/variables/rating-type";

class MobileHeaderMiniScroll extends Component {
  render() {
    const { side } = this.props;
    return (
      <div className="table-location">
        <strong>{side !== ratingType.BACK ? "Front 9" : "Back 9"}</strong>
        <div
          className="table-location__bar"
          id={`post-hbh-mini-scroll-${side}`}
          style={{ width: 60 }}
        >
          <div
            id={`post-hbh-mini-scroll-slider-${side}`}
            className="table-location__indicator"
            style={{ width: 20, marginLeft: 0 }}
          />
        </div>
      </div>
    );
  }
}

MobileHeaderMiniScroll.propTypes = {
  side: PropTypes.string.isRequired,
};

export default MobileHeaderMiniScroll;
