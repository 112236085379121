import {
  FETCH_MAX_HBH_SCORE,
  fetchMaxHbhScoreFailed,
  fetchMaxHbhScoreSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import SupportingCalculationApi from "../../../services/supporting-calculation-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_MAX_HBH_SCORE))
    .pipe(switchMap(({ payload }) => fetchMaxHbhScore(payload)));

const fetchMaxHbhScore = (payload) =>
  from(
    SupportingCalculationApi.getMaxHbhScore(
      payload.golferId,
      payload.teeSetId,
      payload.teeSetSide,
      payload.playedAt
    )
  )
    .pipe(
      map((response) => fetchMaxHbhScoreSuccess(response.data.maximum_scores))
    )
    .pipe(catchError((error) => of(fetchMaxHbhScoreFailed(error))));
