import {
  sharedValidationSchema,
  sharedInitialValues,
  validateSharedFields,
} from "./shared-round-setup-form-validation";
import {
  isTeeHolesInfoAvailable,
  isTeeHolesAllocationAvailable,
} from "../helpers/post-hbh-score-helper";
import { isEmpty } from "../../../shared/helpers/ui-helper";

export const validationSchema = sharedValidationSchema;

export const initialValues = sharedInitialValues;

export const validateFields = (values, isManualCourse) => {
  let errors = {};

  validateSharedFields(values, errors, isManualCourse);
  validateHbhTeeSet(values, errors, isManualCourse);

  return errors;
};

const validateHbhTeeSet = (values, errors, isManualCourse) => {
  if (
    !isEmpty(values.tees.value) &&
    !isManualCourse &&
    !values.isManualRating &&
    !isTeeHolesInfoAvailable(values.tees.value) &&
    !isTeeHolesAllocationAvailable(values.tees.value)
  ) {
    errors.tees = "Post hbh not available for this tee set";
  }
};
