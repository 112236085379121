import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Cell } from "recharts";
import {
  normalizeFloat,
  normalizeFloatRounded,
  renderSuperChartPercentage,
  stringToFloat,
} from "../../helpers/ui-helper";

const COLORS = ["#0088ce", "#00466e", "#677c8d", "#ca243e", "#6d0020"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.75;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent * 100 > 5) {
    return (
      <Fragment>
        <text
          className="donut__label"
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "middle" : "middle"}
          dominantBaseline="central"
        >
          <tspan className="label-value">
            {`${normalizeFloatRounded(normalizeFloat(percent, 100))}`}
          </tspan>
          <tspan baselineShift="super" className="label-percent">
            {"%"}
          </tspan>
        </text>
      </Fragment>
    );
  }
};

class DonutChart extends Component {
  getPieData() {
    const data = [];
    const { statistics } = this.props;
    data.push({
      name: "Group A",
      value: normalizeFloat(statistics.birdies_or_better_percent),
    });
    data.push({
      name: "Group B",
      value: normalizeFloat(statistics.pars_percent),
    });
    data.push({
      name: "Group C",
      value: normalizeFloat(statistics.bogeys_percent),
    });
    data.push({
      name: "Group D",
      value: normalizeFloat(statistics.double_bogeys_percent),
    });
    data.push({
      name: "Group E",
      value: normalizeFloat(statistics.triple_bogeys_or_worse_percent),
    });
    return data;
  }

  handleNaScore = (score) =>
    score < 0.0000001 || isNaN(score) ? "N/A" : score;

  renderParSLabel = (parField) =>
    this.handleNaScore(stringToFloat(this.props.statistics[parField]));

  render() {
    const { statistics, showEmptyFilter, renderDonutChartButton } = this.props;
    const parOrBeter =
      statistics.pars_or_better ||
      normalizeFloatRounded(
        normalizeFloat(statistics.pars_percent, 100) +
          normalizeFloat(statistics.birdies_or_better_percent, 100)
      );
    const pieData = this.getPieData();
    const emptyClass = showEmptyFilter ? "blackWhite blurred" : "";
    return (
      <Fragment>
        <div className={`donut ${emptyClass}`}>
          <div className="donut__container">
            <PieChart width={350} height={350}>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius="100%"
                startAngle={90}
                endAngle={-360}
                fill="#8884d8"
                dataKey="value"
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>

            <div className="donut-mid">
              <div className="donut-mid__percentage">
                {renderSuperChartPercentage(normalizeFloat(parOrBeter))}
              </div>
              <div className="donut-mid__label">
                Par Or <br /> Better
              </div>
            </div>
          </div>

          <ul className="donut-legend">
            <li>
              <span className="donut-legend__color light-blue" />
              Birdies or Better
            </li>
            <li>
              <span className="donut-legend__color dark-blue" />
              Pars
            </li>
            <li>
              <span className="donut-legend__color gray" />
              Bogeys
            </li>
            <li>
              <span className="donut-legend__color light-red" />
              Double Bogeys
            </li>
            <li>
              <span className="donut-legend__color dark-red" />
              Triple Bogeys or Higher
            </li>
          </ul>

          <div className="donut-stats">
            <div className="donut-stat__item">
              <span className="donut-stat__label">Par 3s</span>
              <span className="donut-stat">
                {this.renderParSLabel("par3s_average")}
              </span>
            </div>
            <div className="donut-stat__item">
              <span className="donut-stat__label">Par 4s</span>
              <span className="donut-stat">
                {this.renderParSLabel("par4s_average")}
              </span>
            </div>
            <div className="donut-stat__item">
              <span className="donut-stat__label">Par 5s</span>
              <span className="donut-stat">
                {this.renderParSLabel("par5s_average")}
              </span>
            </div>
          </div>
          <div className="row-action">
            {renderDonutChartButton && renderDonutChartButton()}
          </div>
        </div>
      </Fragment>
    );
  }
}

DonutChart.propTypes = {
  statistics: PropTypes.shape({}),
  showEmptyFilter: PropTypes.bool,
  renderDonutChartButton: PropTypes.func,
};

DonutChart.defaultProps = {
  statistics: {
    pars_percent: 0.2,
    par3s_average: 3.24,
    par4s_average: 4.101,
    par5s_average: 5.501,
    bogeys_percent: 0.2,
    double_bogeys_percent: 0.06,
    birdies_or_better_percent: 0.44,
    triple_bogeys_or_worse_percent: 0.1,
  },
  showEmptyFilter: false,
  renderDonutChartButton: undefined,
};

export default DonutChart;
