import React, { Component } from "react";
import { getUser } from "../../../shared/helpers/user-helper";
import { setEvent } from "../../../firebaseConfig";
import allyLogo from "../../../assets/img/logos/ally-logo-plum-200x120.png";

class Partners extends Component {
  handleClick = () => {
    const user = getUser();
    setEvent(
      user,
      "Sentry_Logo_Click",
      "Logo_Click",
      "Footer_USGA_Partners_03_02_2021"
    );
  };

  render() {
    return (
      <div className="partners">
        <h3 className="partners__title">Usga Partners</h3>
        <div className="partners__list">
          <a
            className="partner__item w-33 ally border-focus"
            href="https://www.ally.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="logo_ally" className="partner__logo" src={allyLogo} />
          </a>
          <a
            className="partner__item w-33 american-express border-focus"
            href="https://global.americanexpress.com/entertainment/home/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="logo_american_express"
              className="partner__logo"
              src="/img/logos/logo_american_express.png"
            />
          </a>
          <a
            className="partner__item w-33 cisco border-focus"
            href="https://www.cisco.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="logo_cisco"
              className="partner__logo fit-size"
              src="/img/logos/logo_cisco.svg"
            />
          </a>
          <a
            className="partner__item w-25 delloite border-focus"
            href="https://www2.deloitte.com/us/en/pages/consulting/articles/united-states-golf-association.html?id=us:2pm:3pt:lookagain:awa:cons:060116:usga"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="logo_delloite"
              className="partner__logo fit-size"
              src="/img/logos/logo_delloite.svg"
            />
          </a>
          <a
            className="partner__item w-25 sentry border-focus"
            href="https://www.sentry.com/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.handleClick}
          >
            <img
              alt="logo_sentry"
              className="partner__logo sentry"
              src="/img/logos/sentry_03.png"
            />
          </a>
          <a
            className="partner__item w-25 lexus border-focus"
            href="https://www.lexus.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="logo_lexus"
              className="partner__logo lexus"
              src="/img/logos/logo_lexus.png"
            />
          </a>
          <a
            className="partner__item w-25 rolex border-focus"
            href="https://www.rolex.com/world-of-rolex/golf.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="logo_rolex"
              className="partner__logo"
              src="/img/logos/logo_rolex.svg"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default Partners;
