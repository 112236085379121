import jobStatus from "../../../shared/variables/job-status";
import { FETCH_TEES, FETCH_TEES_SUCCESS, FETCH_TEES_FAILED } from "../actions";

const initialState = {
  tees: [],
  fetchTeesStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TEES:
      return {
        ...state,
        tees: [],
        fetchTeesStatus: jobStatus.PROCESSING,
      };
    case FETCH_TEES_SUCCESS:
      return {
        ...state,
        tees: payload,
        fetchTeesStatus: jobStatus.SUCCESS,
      };
    case FETCH_TEES_FAILED:
      return {
        ...state,
        fetchTeesStatus: jobStatus.FAILED,
      };
    default:
      return state;
  }
};
