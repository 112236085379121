import { store } from "../store/index";

const getState = () => store.getState().golfersReducer.followedGolfersReducer;

const getGolfers = () => getState().golfers;

export const userExistInFollowed = (id, club) => {
  const golferExist = getGolfers().filter(
    (g) =>
      g.id.toString() === id.toString() &&
      g.club_id.toString() === club.toString()
  );
  if (golferExist.length > 0) {
    return true;
  }
  return false;
};
