export const ACCEPT_TERMS = "ACCEPT_TERMS";
export const ACCEPT_TERMS_FAILED = "ACCEPT_TERMS_FAILED";
export const ACCEPT_TERMS_SUCCESS = "ACCEPT_TERMS_SUCCESS";

export const acceptTerms = () => ({ type: ACCEPT_TERMS });

export const acceptTermsSuccess = () => ({ type: ACCEPT_TERMS_SUCCESS });

export const acceptTermsFailed = (payload) => ({
  type: ACCEPT_TERMS_FAILED,
  payload,
});
