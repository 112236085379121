import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "./YesNoModal";

class HandicapInfoModal extends Component {
  render() {
    const { isOpen, onAbort, isOkVisible } = this.props;
    return (
      <YesNoModal
        onAbort={onAbort}
        onConfirm={onAbort}
        isOpen={isOpen}
        isYesVisible={isOkVisible}
        isNoVisible={false}
        yesTitle="Ok"
      >
        <h3>Handicap Allowance</h3>
        <p>
          The percentage of a Course Handicap recommended to create equity for
          all players participating in a specific format of play.
        </p>
      </YesNoModal>
    );
  }
}

HandicapInfoModal.defaultProps = {
  isOkVisible: false,
};

HandicapInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAbort: PropTypes.func.isRequired,
  isOkVisible: PropTypes.bool,
};

export default HandicapInfoModal;
