import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../../../../shared/routes/index";

class NavigationLoggedLinks extends Component {
  render() {
    return (
      <ul className="nav__links">
        <li>
          <NavLink
            activeClassName="is-active"
            className="nav__link bottom-border-focus"
            to={routes.postScore.path}
          >
            POST SCORE
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="is-active"
            className="nav__link bottom-border-focus"
            to={routes.golferLookup.path}
          >
            GOLFER LOOKUP
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="is-active"
            className="nav__link bottom-border-focus"
            to={routes.stats.path}
          >
            STATS
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="is-active"
            className="nav__link bottom-border-focus"
            to={routes.handicapCourseCalculator.path}
          >
            HANDICAP CALCULATOR
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="is-active"
            className="nav__link bottom-border-focus"
            to={routes.profileHistory.path}
          >
            HANDICAP HISTORY
          </NavLink>
        </li>
      </ul>
    );
  }
}

export default NavigationLoggedLinks;
