import {
  FETCH_END_OF_YEAR_CAMPAIGN,
  fetchEndOfYearCampaignFailed,
  fetchEndOfYearCampaignSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import EndOfYearCampaignApi from "../../../services/end-of-year-campaign-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_END_OF_YEAR_CAMPAIGN))
    .pipe(switchMap((payload) => fetchEndOfYear(payload)));

const fetchEndOfYear = (payload) =>
  from(EndOfYearCampaignApi.getGolferYearEndSummary(payload.payload.golfer_id))
    .pipe(
      map((response) =>
        fetchEndOfYearCampaignSuccess(response.data.year_end_summary)
      )
    )
    .pipe(catchError((error) => of(fetchEndOfYearCampaignFailed(error))));
