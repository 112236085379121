import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_COUNTRIES_SELECT_COURSE,
  FETCH_COUNTRIES_SELECT_COURSE_SUCCESS,
  FETCH_COUNTRIES_SELECT_COURSE_FAILED,
} from "../actions";

const initialState = {
  countries: [],
  fetchCountriesStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COUNTRIES_SELECT_COURSE:
      return {
        ...state,
        fetchCountriesStatus: jobStatus.PROCESSING,
      };
    case FETCH_COUNTRIES_SELECT_COURSE_SUCCESS:
      return {
        ...state,
        countries: payload,
        fetchCountriesStatus: jobStatus.SUCCESS,
      };
    case FETCH_COUNTRIES_SELECT_COURSE_FAILED:
      return {
        ...state,
        fetchCountriesStatus: jobStatus.FAILED,
      };
    default:
      return state;
  }
};
