import golferLookupRoutes from "./golfer-lookup";
import golferRoutes from "./golfer";
import miscRoutes from "./misc";
import postScoreRoutes from "./post-score";
import profileRoutes from "./profile";
import handicapCalculatorRoutes from "./handicap-calculator";
import stats from "./stats";
import pagesRoutes from "./pages";
import mycardRoutes from "./mycard";
import authRoutes from "./auth";
import myCourses from "./my-courses";

export const routes = {
  ...golferLookupRoutes,
  ...golferRoutes,
  ...miscRoutes,
  ...postScoreRoutes,
  ...profileRoutes,
  ...handicapCalculatorRoutes,
  ...stats,
  ...pagesRoutes,
  ...mycardRoutes,
  ...authRoutes,
  ...myCourses,
};

export const routesArray = Object.values(routes);
export const oldWebsiteRoutes = ["/lookup.aspx", "/scoreposting.aspx"];
