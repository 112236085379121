import React, { Component } from "react";
import Partners from "./Partners";
import MobileApp from "./MobileApp";

class Information extends Component {
  render() {
    return (
      <div className="information__container">
        <Partners />
        <MobileApp />
      </div>
    );
  }
}

export default Information;
