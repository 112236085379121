import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";

class IncompleteUpdatedStatsPostModal extends Component {
  render() {
    const { isOpen, onAbort, onConfirm } = this.props;
    return (
      <YesNoModal
        onConfirm={onConfirm}
        onAbort={onAbort}
        isOpen={isOpen}
        yesTitle="Update Round Stats"
        noTitle="Cancel"
      >
        <p>
          You are about to update the round stats without fully completing all
          stats for this round. Incomplete information will impact your overall
          stats.
        </p>
      </YesNoModal>
    );
  }
}

IncompleteUpdatedStatsPostModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAbort: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.object,
};

export default IncompleteUpdatedStatsPostModal;
