import { checkApiError } from "../../auth/actions";

export const FETCH_CLUBS = "FETCH_CLUBS";
export const FETCH_CLUBS_FAILED = "FETCH_CLUBS_FAILED";
export const FETCH_CLUBS_SUCCESS = "FETCH_CLUBS_SUCCESS";
export const SELECT_CLUB = "SELECT_CLUB";

export const fetchClubs = () => ({
  type: FETCH_CLUBS,
});

export function fetchClubsFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_CLUBS_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchClubsSuccess = (data) => {
  const payload = data
    .filter((a) => a.active === "true")
    .sort((a, b) => a.club_name.localeCompare(b.club_name));
  return {
    type: FETCH_CLUBS_SUCCESS,
    payload,
  };
};

export const selectClub = (payload) => ({
  type: SELECT_CLUB,
  payload,
});
