import {
  FETCH_PRODUCT_ALERT,
  fetchProductAlertFailed,
  fetchProductAlertSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import ProductAlertApi from "../../../services/product-alert-api";

export default (action$) =>
  action$.pipe(ofType(FETCH_PRODUCT_ALERT)).pipe(switchMap(getAssociations));

const getAssociations = () =>
  from(ProductAlertApi.getProductAlert())
    .pipe(
      map((response) => fetchProductAlertSuccess(response.data.product_alerts))
    )
    .pipe(catchError((error) => of(fetchProductAlertFailed(error))));
