import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "./YesNoModal";
import Navigate from "../../navigation/navigate";

class NoSlopeRatingModal extends Component {
  onClose = () => {
    const { onClose } = this.props;
    onClose();
    Navigate.goBack();
  };

  render() {
    const { isOpen, message } = this.props;
    return (
      <YesNoModal
        onConfirm={this.onClose}
        onAbort={this.onClose}
        isNoVisible={false}
        isOpen={isOpen}
        yesTitle="Ok"
      >
        <p>{message}</p>
      </YesNoModal>
    );
  }
}

NoSlopeRatingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
};

NoSlopeRatingModal.defaultProps = {
  message:
    "Unable to post a score for this Short Course. Tees do not include a Slope Rating.",
};

export default NoSlopeRatingModal;
