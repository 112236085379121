export const EMAIL_CHANGE_INFORMATION = "EMAIL_CHANGE_INFORMATION";
export const EMAIL_CHANGE_INFORMATION_FAILED =
  "EMAIL_CHANGE_INFORMATION_FAILED";
export const EMAIL_CHANGE_INFORMATION_SUCCESS =
  "EMAIL_CHANGE_INFORMATION_SUCCESS";

export const emailChangeInformation = (payload) => ({
  type: EMAIL_CHANGE_INFORMATION,
  payload,
});

export const emailChangeInformationFailed = (payload) => ({
  type: EMAIL_CHANGE_INFORMATION_FAILED,
  payload,
});

export const emailChangeInformationSuccess = (payload) => ({
  type: EMAIL_CHANGE_INFORMATION_SUCCESS,
  payload,
});
