import { LOGIN, loginSuccess, loginFailed } from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";
import {
  recaptcha_exe,
  recaptcha_ready,
} from "../../../shared/helpers/recaptcha-v3-helper";

export default (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap(({ payload }) => recaptcha_ready(payload)),
    switchMap((payload) => recaptcha_exe(payload)),
    switchMap((payload) => login(payload))
  );

const login = (payload) =>
  from(
    GolferApi.login(
      payload.user_token,
      payload.password,
      payload.emailOrGhin,
      payload.remember_me
    )
  )
    .pipe(map((response) => loginSuccess(response.data, payload)))
    .pipe(catchError((error) => of(loginFailed(error.response?.data))));
