import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";
import { routes } from "../../../../../shared/routes/index";
import navigate from "../../../../../shared/navigation/navigate";

class HbhNotAvailableModal extends Component {
  onClickPostTotalScore = () => {
    const { course, teeSetID } = this.props;
    if (course) {
      const route = routes.postScoreTotalPost.path.replace(
        ":courseId",
        `${course.CourseId}?teeSetId=${teeSetID}`
      );
      navigate.resetToPath(route);
    } else {
      navigate.toPath(routes.postScoreTotalSelectCourse.path);
    }
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <YesNoModal
        onConfirm={onClose}
        onAbort={this.onClickPostTotalScore}
        isOpen={isOpen}
        isYesVisible={true}
        isNoVisible={true}
        reversedHighlight
        reversedOrder
        yesTitle="Cancel"
        noTitle="Post Total Score"
      >
        <p>
          Hole-by-hole score posting is not available for this course/tee as Par
          or Stroke Index data is not available.
        </p>
      </YesNoModal>
    );
  }
}

HbhNotAvailableModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HbhNotAvailableModal;
