import ratingType from "../../../shared/variables/rating-type";
import * as Yup from "yup";
import { isEmpty } from "../../../shared/helpers/ui-helper";
import numberOfHoles from "../../../shared/variables/number-of-holes";
import {
  defaultDrivingOption,
  defaultGirOption,
  girOptions,
  drivingOptions,
  drivingFairwayHit,
} from "../variables/post-score-variables";
import { getVisibleHoles } from "../helpers/post-hbh-score-helper";
import { sortBy } from "lodash";

export const validationSchema = Yup.object({
  totalYards: Yup.number(),
  frontScore: Yup.number(),
  frontAdjScore: Yup.number(),
  backScore: Yup.number(),
  backAdjScore: Yup.number(),
  totalScore: Yup.number(),
  totalAdjScore: Yup.number(),
  frontYardage: Yup.number(),
  backYardage: Yup.number(),
  frontPar: Yup.number(),
  backPar: Yup.number(),
  totalPar: Yup.number(),
  holes: Yup.array(),
  tees: Yup.object(),
  courseId: Yup.number(),
  datePlayed: Yup.date(),
  scoreType: Yup.string(),
  nrOfHoles: Yup.string(),
  withAdvancedStats: Yup.boolean(),
  frontPutts: Yup.number(),
  backPutts: Yup.number(),
  totalPutts: Yup.number(),
  frontGir: Yup.number(),
  backGir: Yup.number(),
  totalGir: Yup.number(),
  frontDrivingAcc: Yup.number(),
  backDrivingAcc: Yup.number(),
  totalDrivingAcc: Yup.number(),
});

export const initialValues = (
  courseDetails,
  roundSetup,
  maxHbhScore,
  initialHoleValues = [],
  withAdvancedStats = false,
  totalCombinedScores = undefined,
  combinedScoresForm = false
) => {
  const teeSetInfo =
    courseDetails.TeeSets.find(
      (x) => x.TeeSetRatingId === roundSetup.tees.TeeSetRatingId
    ) || [];
  const initialValues = combinedScoresForm
    ? getInitCombinedHolesValues(teeSetInfo, maxHbhScore, initialHoleValues)
    : getInitHolesValues(teeSetInfo, maxHbhScore, initialHoleValues);
  return {
    ...initialValues,
    ...getInitRoundSetup(roundSetup, withAdvancedStats),
    ...getInitCourseDetailsTotals(teeSetInfo),
    ...getInitScoreTotals(initialHoleValues),
    totalCombinedScores,
  };
};

export const validateFields = (values, roundSetup, useScaling, hasHandicap) => {
  let errors = {};

  if (useScaling && roundSetup.nrOfHoles != numberOfHoles.NINE) {
    validateFilledScoresSide(values.holes, hasHandicap, errors);
  } else {
    const visibleHoles = getVisibleHoles(values, roundSetup.tees.RatingType);

    visibleHoles.map((x) => {
      validateScore(x, errors);
      return x;
    });
  }

  return errors;
};

const validateFilledScoresSide = (holes, hasHandicap, errors) => {
  const frontFilled = holes.filter(
    (hole) => hole.side === ratingType.FRONT && !isEmpty(hole.score)
  ).length;
  const backFilled = holes.filter(
    (hole) => hole.side === ratingType.BACK && !isEmpty(hole.score)
  ).length;

  const atLeastOneSide = frontFilled === 9 || backFilled == 9;
  const justOneSide = atLeastOneSide && (frontFilled === 0 || backFilled == 0);
  const bothSides = frontFilled === 9 && backFilled == 9;

  if (hasHandicap && !atLeastOneSide) {
    errors.holes =
      "You must enter a score for at least 9 holes of the same side (e.g. Front 9, Back 9) in order to post your score.";
  } else if (!hasHandicap && !justOneSide && !bothSides) {
    errors.holes =
      "You must enter a score for 9 holes of the same side (e.g. Front 9, Back 9) or all 18 holes in order to post your score.";
  }
};

const validateScore = (hole, errors) => {
  if (isEmpty(hole.score)) {
    errors.holes =
      "You must enter a score for every hole in order to post your score";
  }
};

export const validateAdvStats = (roundSetup, values) => {
  let isValid = true;
  if (values.withAdvancedStats === true) {
    const visibleHoles = getVisibleHoles(values, roundSetup.tees.RatingType);

    visibleHoles
      .filter((hole) => hole.score !== "")
      .forEach((x) => {
        if (
          isEmpty(x.putts) ||
          isEmpty(x.gir.value) ||
          (isEmpty(x.drivingAcc.value) && x.par !== 3)
        )
          isValid = false;
      });
  }

  return isValid;
};

const getInitHolesValues = (teeSetInfo, maxHbhScore, initialHoleValues) => {
  let result = { holes: [] };
  for (let index = 0; index < teeSetInfo.Holes.length; index++) {
    const holeDetails = teeSetInfo.Holes[index];
    const initialHoleValue = initialHoleValues.find(
      (x) => x.hole_number === holeDetails.Number
    );

    result.holes.push({
      index: index,
      id: holeDetails.HoleId,
      nr: holeDetails.Number,
      side: index < 9 ? ratingType.FRONT : ratingType.BACK,
      length: holeDetails.Length,
      par: holeDetails.Par,
      strokeIndex: holeDetails.Allocation,
      score: getInitHoleScore(initialHoleValue),
      maxScore: getHoleMaxScore(holeDetails.Number, maxHbhScore),
      adjScore: getInitHoleAdjScore(initialHoleValue),
      putts: getInitHolePutts(initialHoleValue),
      gir: getInitHoleGir(initialHoleValue),
      drivingAcc: getInitHoleDrivingAcc(initialHoleValue),
    });
  }

  return result;
};

const getInitCombinedHolesValues = (
  teeSetInfo,
  maxHbhScore,
  initialHoleValues
) => {
  let result = { holes: [] };
  const sortedHoles = sortBy(initialHoleValues, "hole_number");
  for (let index = 0; index < sortedHoles.length; index++) {
    const holeDetails = sortedHoles[index];
    const teeSetHoleValue = teeSetInfo.Holes.find(
      (x) => x.Number === holeDetails.hole_number
    );

    result.holes.push({
      index: index,
      id: holeDetails.id,
      nr: holeDetails.hole_number,
      side: holeDetails.hole_number <= 9 ? ratingType.FRONT : ratingType.BACK,
      length: teeSetHoleValue.Length,
      par: holeDetails.par,
      strokeIndex: teeSetHoleValue.Allocation,
      score: getInitHoleScore(holeDetails),
      maxScore: getHoleMaxScore(holeDetails.hole_number, maxHbhScore),
      adjScore: getInitHoleAdjScore(holeDetails),
      putts: getInitHolePutts(holeDetails),
      gir: getInitHoleGir(holeDetails),
      drivingAcc: getInitHoleDrivingAcc(holeDetails),
    });
  }

  return result;
};

const getHoleMaxScore = (holeNr, maxHbhScore) => {
  const maxScore = maxHbhScore.find((x) => x.hole_number === holeNr);
  return maxScore ? maxScore.maximum_score : 0;
};

const getInitHoleScore = (initialHoleValue) =>
  initialHoleValue?.raw_score ? initialHoleValue.raw_score : "";

const getInitHoleAdjScore = (initialHoleValue) =>
  initialHoleValue?.adjusted_gross_score
    ? initialHoleValue.adjusted_gross_score
    : "";

const getInitHolePutts = (initialHoleValue) =>
  initialHoleValue &&
  initialHoleValue.putts !== undefined &&
  initialHoleValue.putts !== null
    ? initialHoleValue.putts
    : "";

const getInitHoleGir = (initialHoleValue) => {
  if (
    initialHoleValue &&
    initialHoleValue.gir_flag !== undefined &&
    initialHoleValue.gir_flag !== null
  ) {
    if (initialHoleValue.gir_flag) {
      return girOptions.find((x) => x.value === 0);
    } else if (initialHoleValue.approach_shot_accuracy !== null) {
      return girOptions.find(
        (x) => x.value === initialHoleValue.approach_shot_accuracy + 1
      );
    }
  }
  return defaultGirOption;
};

const getInitHoleDrivingAcc = (initialHoleValue) => {
  if (!initialHoleValue) return defaultDrivingOption;

  if (
    initialHoleValue.drive_accuracy !== undefined &&
    initialHoleValue.drive_accuracy !== null
  ) {
    return drivingOptions.find(
      (x) => x.value === initialHoleValue.drive_accuracy
    );
  } else if (
    (initialHoleValue.drive_accuracy === undefined ||
      initialHoleValue.drive_accuracy === null) &&
    initialHoleValue.fairway_hit === true
  ) {
    return drivingOptions.find((x) => x.value === drivingFairwayHit);
  } else {
    return defaultDrivingOption;
  }
};

const getInitRoundSetup = (roundSetup, withAdvancedStats = false) => ({
  tees: roundSetup.tees,
  courseId: roundSetup.courseId,
  datePlayed: roundSetup.datePlayed,
  scoreType: roundSetup.scoreType,
  nrOfHoles:
    roundSetup.nrOfHoles == numberOfHoles.NINE
      ? numberOfHoles.NINE
      : numberOfHoles.EIGHTEEN,
  withAdvancedStats: withAdvancedStats,
});

const getInitScoreTotals = (initHoleValues) => {
  let result = {
    frontScore: 0,
    frontAdjScore: 0,
    backScore: 0,
    backAdjScore: 0,
    totalScore: 0,
    totalAdjScore: 0,
    frontPutts: 0,
    backPutts: 0,
    totalPutts: 0,
    frontGir: 0,
    backGir: 0,
    totalGir: 0,
    frontDrivingAcc: 0,
    backDrivingAcc: 0,
    totalDrivingAcc: 0,
  };

  initHoleValues.forEach((hole) => {
    if (hole.hole_number < 10) {
      result.frontScore += hole.raw_score;
      result.frontAdjScore += hole.adjusted_gross_score;
      result.frontPutts += hole.putts;
      result.frontGir += hole.gir_flag ? 1 : 0;
      result.frontDrivingAcc += hole.fairway_hit ? 1 : 0;
    } else {
      result.backScore += hole.raw_score;
      result.backAdjScore += hole.adjusted_gross_score;
      result.backPutts += hole.putts;
      result.backGir += hole.gir_flag ? 1 : 0;
      result.backDrivingAcc += hole.fairway_hit ? 1 : 0;
    }

    result.totalScore += hole.raw_score;
    result.totalAdjScore += hole.adjusted_gross_score;
    result.totalPutts += hole.putts;
    result.totalGir += hole.gir_flag ? 1 : 0;
    result.totalDrivingAcc = result.frontDrivingAcc + result.backDrivingAcc;
  });

  return result;
};

const getInitCourseDetailsTotals = (teeSetInfo) => {
  let result = {
    totalYards: teeSetInfo.TotalYardage,
    frontYardage: 0,
    backYardage: 0,
    frontPar: 0,
    backPar: 0,
    totalPar: 0,
  };

  teeSetInfo.Holes.forEach((hole) => {
    if (hole.Number < 10) {
      result.frontYardage += hole.Length;
      result.frontPar += hole.Par;
    } else {
      result.backYardage += hole.Length;
      result.backPar += hole.Par;
    }

    result.totalPar += hole.Par;
  });

  return result;
};
