import { checkApiError } from "../../auth/actions";

export const FETCH_END_OF_YEAR_CAMPAIGN = "FETCH_END_OF_YEAR_CAMPAIGN";
export const FETCH_END_OF_YEAR_CAMPAIGN_FAILED =
  "FETCH_END_OF_YEAR_CAMPAIGN_FAILED";
export const FETCH_END_OF_YEAR_CAMPAIGN_SUCCESS =
  "FETCH_END_OF_YEAR_CAMPAIGN_SUCCESS";

export const fetchEndOfYearCampaign = (load_image, golfer_id) => {
  return {
    type: FETCH_END_OF_YEAR_CAMPAIGN,
    payload: { load_image, golfer_id },
  };
};

export function fetchEndOfYearCampaignFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_END_OF_YEAR_CAMPAIGN_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchEndOfYearCampaignSuccess = (payload) => ({
  type: FETCH_END_OF_YEAR_CAMPAIGN_SUCCESS,
  payload,
});
