export const LOGIN_WITH_GHIN = "LOGIN_WITH_GHIN";
export const LOGIN_WITH_GHIN_FAILED = "LOGIN_WITH_GHIN_FAILED";
export const LOGIN_WITH_GHIN_SUCCESS = "LOGIN_WITH_GHIN_SUCCESS";

export const login = (password, emailOrGhin) => ({
  type: LOGIN_WITH_GHIN,
  payload: { password, emailOrGhin },
});

export const loginFailed = (payload) => ({
  type: LOGIN_WITH_GHIN_FAILED,
  payload,
});

export const loginSuccess = (payload) => ({
  type: LOGIN_WITH_GHIN_SUCCESS,
  payload,
});
