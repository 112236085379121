import jobStatus from "../../../shared/variables/job-status";

import {
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILED,
} from "../actions";

const initialState = {
  status: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type }) => {
  switch (type) {
    case SET_PASSWORD:
      return {
        ...state,
        status: jobStatus.PROCESSING,
      };
    case SET_PASSWORD_SUCCESS: {
      return {
        ...state,
        status: jobStatus.SUCCESS,
      };
    }
    case SET_PASSWORD_FAILED: {
      return {
        ...state,
        status: jobStatus.FAILED,
      };
    }
    default:
      return state;
  }
};
