import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "./YesNoModal";

class RegisterErrorModal extends Component {
  render() {
    const { isOpen, onAbort, onConfirm } = this.props;
    return (
      <YesNoModal
        onConfirm={onConfirm}
        onAbort={onAbort}
        isOpen={isOpen}
        isNoVisible={false}
        yesTitle="OK"
        noTitle="Cancel"
      >
        <p>{this.props.validationMessage}</p>
      </YesNoModal>
    );
  }
}

RegisterErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  validationMessage: PropTypes.string,
  onAbort: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default RegisterErrorModal;
