import moment from "moment";

export default {
  RECENT_AND_REVISION_SCORES: "recent_and_revision_scores",
  YEAR: `${moment().format("YYYY")}_scores`,
  PAST_YEAR: `${moment().subtract(1, "year").format("YYYY")}_scores`,
  PAST_12MONTH_SCORES: "past_12month_scores",
  SCORES_BY_COURSE_OR_TEE: "scores_by_course_or_tee",
  COMPETITION_SCORES: "competition_scores",
  TWO_YEARS_AGO: `${moment().subtract(2, "year").format("YYYY")}_scores`,
};
