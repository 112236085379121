import { isEmpty } from "../../../shared/helpers/ui-helper";
import {
  sharedValidationSchema,
  sharedInitialValues,
  validateSharedFields,
} from "./shared-round-setup-form-validation";

export const validationSchema = sharedValidationSchema;

export const initialValues = sharedInitialValues;

export const validateFields = (values, isManualCourse) => {
  let errors = {};

  validateSharedFields(values, errors, isManualCourse);
  validateInputScores(values, errors);

  return errors;
};

const validateInputScores = (values, errors) => {
  if (isEmpty(values.totalScore)) {
    errors.totalScore = "Required";
  }

  if (!isEmpty(values.frontScore) && values.frontScore > 99) {
    errors.frontScore = "Front score must be under 100";
  }

  if (!isEmpty(values.backScore) && values.backScore > 99) {
    errors.backScore = "Back score must be under 100";
  }

  if (!isEmpty(values.frontScore) && isEmpty(values.backScore)) {
    errors.backScore = "Back score must be filled if front score is populated";
  }

  if (isEmpty(values.frontScore) && !isEmpty(values.backScore)) {
    errors.frontScore = "Front score must be filled if back score is populated";
  }
};
