import {
  REMOVE_COURSE,
  removeCourseSuccess,
  removeCourseFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import CoursesApi from "../../../services/courses-api";

export default (action$) =>
  action$
    .pipe(ofType(REMOVE_COURSE))
    .pipe(switchMap(({ payload }) => removeMyCourse(payload)));

const removeMyCourse = (payload) =>
  from(CoursesApi.deleteMyCourse(payload))
    .pipe(
      map((response) => removeCourseSuccess(response.data.delete_my_course))
    )
    .pipe(catchError((error) => of(removeCourseFailed(error))));
