import React, { Component } from "react";

class MobileApp extends Component {
  render() {
    return (
      <div className="mobile__app">
        <h3 className="app__title">Ghin App</h3>
        <div className="div-with-border">
          <div className="sentry-logo-container">
            <img
              alt="sentry"
              className="sentry-logo"
              src="/img/logos/sentry_02.png"
            />
          </div>
          <div className="app__details">
            <span className="app__logo" />
            <ul className="app__stores">
              <li>
                <a
                  className="store app-store"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://itunes.apple.com/us/app/ghin-mobile/id491796218?mt=8"
                >
                  Download on the App Store
                </a>
              </li>
              <li>
                <a
                  className="store google-play"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.advancedmobile.android.ghin&amp;hl=en_US"
                >
                  Get it on Google Play
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileApp;
