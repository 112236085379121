import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";

class ClearAllScoresModal extends Component {
  render() {
    const { isOpen, onAbort, onConfirm } = this.props;
    return (
      <YesNoModal
        onConfirm={onConfirm}
        onAbort={onAbort}
        isOpen={isOpen}
        reversedOrder={true}
        reversedHighlight={true}
        contentLabel="Are you sure you want to clear all scores?"
      >
        <p>Are you sure you want to clear all scores?</p>
      </YesNoModal>
    );
  }
}

ClearAllScoresModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAbort: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.object,
};

export default ClearAllScoresModal;
