import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_GOLFERS,
  FETCH_GOLFERS_SUCCESS,
  FETCH_GOLFERS_FAILED,
  RESET_GOLFERS,
} from "../actions";
import {
  searchType,
  defaultPagination,
} from "../../../shared/variables/pagination";

const initialState = {
  golfers: [],
  hasNextPage: false,
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_GOLFERS:
      return {
        ...state,
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_GOLFERS_SUCCESS:
      return {
        ...state,
        golfers:
          payload.type === searchType.NEXT_PAGE
            ? state.golfers.concat(payload.golfers)
            : payload.golfers,
        hasNextPage:
          state.golfers.concat(payload.golfers).length >=
          payload.page * defaultPagination.PER_PAGE,
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_GOLFERS_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    case RESET_GOLFERS:
      return {
        ...state,
        golfers: [],
      };
    default:
      return state;
  }
};
