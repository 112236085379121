import React, { Component } from "react";
import PropTypes from "prop-types";

class BoxHead extends Component {
  render() {
    return (
      <div className="box-head">
        <h3 className="box-title">{this.props.title}</h3>
      </div>
    );
  }
}

BoxHead.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BoxHead;
