import {
  POST_COMBINED_SCORES,
  postCombinedScoresSuccess,
  postCombinedScoresFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, flatMap } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";
import { isEmpty } from "../../../shared/helpers/ui-helper";
import { drivingFairwayHit } from "../../post-score/variables/post-score-variables";
import { appendGirToParams } from "../../post-score/helpers/post-hbh-score-helper";

export default (action$) =>
  action$
    .pipe(ofType(POST_COMBINED_SCORES))
    .pipe(flatMap(({ payload }) => postEditRoundStats(payload)));

const postEditRoundStats = (payload) =>
  from(ScoresApi.postEditRoundStats(payload.score.id, getParams(payload)))
    .pipe(
      map((response) =>
        postCombinedScoresSuccess(response.data, payload.scoresLength)
      )
    )
    .pipe(catchError((error) => of(postCombinedScoresFailed(error))));

const getParams = (payload) => ({
  statistics: getHoleParams(payload),
});

const getHoleParams = (payload) =>
  payload.score.hole_details.map((scoreHole) => {
    let params = { hole_number: scoreHole.hole_number };

    const statsScore = payload.roundStats.holes.find(
      (x) => x.nr === scoreHole.hole_number
    );

    if (!isEmpty(statsScore.drivingAcc.value)) {
      params = Object.assign(params, {
        drive_accuracy:
          statsScore.drivingAcc.value === drivingFairwayHit
            ? ""
            : statsScore.drivingAcc.value,
        fairway_hit: statsScore.drivingAcc.value === drivingFairwayHit,
      });
    }

    if (!isEmpty(statsScore.putts)) {
      params = Object.assign(params, { putts: statsScore.putts });
    }

    params = appendGirToParams(params, statsScore.gir.value);

    return params;
  });
