import React, { Component } from "react";
import PropTypes from "prop-types";
import DefaultCustomSelect from "../../../../shared/components/selects/DefaultCustomSelect";
import ScoreType from "../../../../shared/variables/stats-score-type";
import Navigate from "../../../../shared/navigation/navigate";
import { bindActionCreators } from "redux";
import { fetchEndOfYearCampaign } from "../../../end-of-year-campaign/actions";
import { connect } from "react-redux";
import InactiveGolferModal from "../../../end-of-year-campaign/components/InactiveGolferModal";
import {
  GHIN_REWIND_YEAR,
  HIDE_GHIN_REWIND_BUTTONS,
} from "../../../end-of-year-campaign/year-end-constants";
import moment from "moment";

class HeaderFilterView extends Component {
  filterSelect = () => {
    let filters = [];

    if (this.props.golferActive)
      filters.push({
        value: ScoreType.RECENT_AND_REVISION_SCORES,
        label: ScoreType.RECENT_AND_REVISION_SCORES,
      });

    filters = [
      ...filters,
      { value: ScoreType.YEAR, label: ScoreType.YEAR },
      { value: ScoreType.PAST_YEAR, label: ScoreType.PAST_YEAR },
      { value: ScoreType.BY_COURSE, label: ScoreType.BY_COURSE },
      { value: ScoreType.MONTHS_12, label: ScoreType.MONTHS_12 },
      { value: ScoreType.TOURNAMENT, label: ScoreType.TOURNAMENT },
      { value: ScoreType.TWO_YEARS_AGO, label: ScoreType.TWO_YEARS_AGO },
    ];

    const threeYearsAgo = parseInt(ScoreType.THREE_YEARS_AGO.split(" ")[0]);
    if (threeYearsAgo >= 2020) {
      filters.push({
        value: ScoreType.THREE_YEARS_AGO,
        label: ScoreType.THREE_YEARS_AGO,
      });
    }
    console.log(filters);
    return filters;
  };

  state = {
    filter: this.filterSelect()[0],
    clubs_select: [],
    selectedClub: undefined,
    isInactiveModalOn: false,
  };

  closeModal = () => this.setState({ isInactiveModalOn: false });

  openModal = (end_of_year_path) => {
    if (this.props.golferActive) {
      Navigate.toPath(end_of_year_path);
    } else {
      this.setState({ isInactiveModalOn: true });
    }
  };

  handleChangeFilter = (filter) => {
    this.setState({ filter });
    if (filter.value !== ScoreType.BY_COURSE) {
      this.setState({ clubs_select: [], selectedClub: undefined });
    }
    this.props.handleChangeFilter(filter.value);
  };

  handleChangeClub = (selectedClub) => {
    this.setState({ selectedClub });
    const { course_id, tee_set_id } = selectedClub;
    if (course_id && tee_set_id) {
      this.props.handleChangeFilter(ScoreType.BY_COURSE_ID_AND_TEE_SET_ID, {
        course_id,
        tee_set_id,
      });
    } else {
      this.props.handleChangeFilter(ScoreType.BY_COURSE);
    }
    this.props.handleChangeClub(selectedClub.value);
  };

  viewAvgScorePerHole = (selectedClub) => {
    this.props.viewAvgScorePerHole(selectedClub);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { scores } = nextProps;
    if (this.props.scores !== scores && this.state.selectedClub === undefined) {
      this.buildClubsSelect(this.state.filter, scores);
    }
  }

  buildClubsSelect = (filter, scores) => {
    let clubs_select = [];
    let selectedClub = undefined;
    if (filter.value === ScoreType.BY_COURSE) {
      clubs_select = this.getUniqClubsForScores(scores);
      selectedClub = clubs_select[0];
    }
    this.setState({ clubs_select, selectedClub });
  };

  getUniqClubsForScores(scores) {
    const renderLabel = (item) => {
      const teeLabel = item.tee_name ? `\n${item.tee_name}` : "";
      if (item.facility_name && item.facility_name !== item.course_name) {
        return `${item.facility_name}\n${item.course_name}${teeLabel}`;
      }
      return `${item.facility_name || item.course_name}${teeLabel}`;
    };

    const result = [];
    result.push({ value: "", label: <div>All Courses</div> });
    const map = new Map();
    for (const item of scores) {
      const uniqValue =
        item.course_id && item.tee_set_id
          ? `${item.course_id}-${item.tee_set_id}`
          : `${item.course_name}_${item.tee_name}`;
      if (!map.has(uniqValue)) {
        map.set(uniqValue, true);
        result.push({
          value: uniqValue,
          label: renderLabel(item),
          course_id: item.course_id,
          tee_set_id: item.tee_set_id,
        });
      }
    }
    return result.sort((a, b) => a.value.localeCompare(b.value));
  }

  renderSelectClub() {
    const { selectedClub, clubs_select } = this.state;
    if (!selectedClub) return null;
    return (
      <DefaultCustomSelect
        className="default_select full_on_phone"
        classNamePrefix="ds"
        title="Select Course"
        value={selectedClub}
        defaultValue={selectedClub}
        options={clubs_select}
        onChange={this.handleChangeClub}
      />
    );
  }

  renderViewAvgScore() {
    const { selectedClub } = this.state;
    const { scores } = this.props;
    let filteredScores = scores.filter((s) => s.statistics !== null);
    if (
      !selectedClub ||
      selectedClub.value === "" ||
      !selectedClub.course_id ||
      filteredScores.length == 0
    )
      return null;
    return (
      <div className="item-list__action">
        <button
          onClick={() => this.viewAvgScorePerHole(selectedClub)}
          className="btn lnk blue no-pad small type-z-button filter"
        >
          View Average Score Per Hole
        </button>
      </div>
    );
  }

  isGhinRewindButtonVisible(score_filter_value) {
    if (HIDE_GHIN_REWIND_BUTTONS) return false;

    const ghin_rewind_year = parseInt(GHIN_REWIND_YEAR);
    const selected_filter_value = GHIN_REWIND_YEAR + " Scores";

    const isVisible = this.props.memberships.find((m) => {
      const year_added_to_club = moment(m.created_at).year();
      return (
        m.technology_provider == "GHIN" &&
        year_added_to_club <= ghin_rewind_year
      );
    });

    return (
      score_filter_value === selected_filter_value &&
      isVisible !== undefined &&
      isVisible !== null
    );
  }

  render() {
    const { filter } = this.state;

    const isButtonVisible = this.isGhinRewindButtonVisible(filter.value);
    const end_of_year_title = GHIN_REWIND_YEAR + " GHIN REWIND";
    const end_of_year_path = "/" + GHIN_REWIND_YEAR + "-overview";

    return (
      <div className="item-list courses">
        <div className="filter__list">
          <label className="black">Filter by</label>
          <DefaultCustomSelect
            className="default_select full_on_phone svg-color"
            classNamePrefix="ds"
            title="Filter by"
            value={filter}
            defaultValue={filter}
            options={this.filterSelect()}
            onChange={this.handleChangeFilter}
            aria-label="Filter By"
          />
          {isButtonVisible && (
            <button
              className="btn lnk blue small"
              onClick={() => this.openModal(end_of_year_path)}
              role="link"
            >
              {end_of_year_title}
            </button>
          )}
          {this.renderSelectClub()}
          {this.renderViewAvgScore()}
        </div>
        <InactiveGolferModal
          isOpen={this.state.isInactiveModalOn}
          onClose={this.closeModal}
        ></InactiveGolferModal>
      </div>
    );
  }
}

HeaderFilterView.propTypes = {
  handleChangeFilter: PropTypes.func.isRequired,
  handleChangeClub: PropTypes.func.isRequired,
  viewAvgScorePerHole: PropTypes.func.isRequired,
  scores: PropTypes.array.isRequired,
};

const mapStateToProps = ({ profileReducer }) => {
  return {
    golferActive: profileReducer.golferProfileFetchReducer.golferActive,
    memberships: profileReducer.golferProfileFetchReducer.golferMemberships,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchEndOfYearCampaign,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderFilterView);
