import React, { Component } from "react";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import PropTypes from "prop-types";

class RemoveAllGolfersModal extends Component {
  render() {
    const { isOpen, onAbort, onConfirm } = this.props;
    return (
      <YesNoModal
        reversedOrder
        onConfirm={onConfirm}
        onAbort={onAbort}
        isOpen={isOpen}
      >
        <p>Are you sure you want to remove all golfers?</p>
      </YesNoModal>
    );
  }
}

RemoveAllGolfersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAbort: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default RemoveAllGolfersModal;
