import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { getFormattedDate } from "../../../shared/helpers/date-helper";

const MAX_SCORES = 20;

class MyCardScores extends Component {
  renderScores() {
    const { scores } = this.props;
    return scores.map((score, index) => (
      <div key={index} className="my-card-body__cell">
        <span className="date">
          {getFormattedDate(score.played_at, "YYYY-MM-DD", "MM/YYYY")}
        </span>
        <strong className="score">
          {score.adjusted_gross_score}
          {score.used && "* "}
        </strong>
        <span className="letter">{score.score_type_display_short}</span>
      </div>
    ));
  }

  renderEmptyScores() {
    const { scores } = this.props;
    if (scores.length > MAX_SCORES) return;
    const emptyRows = [...Array(MAX_SCORES - scores.length).keys()];
    return emptyRows.map((item) => (
      <div key={item} className="my-card-body__cell" />
    ));
  }

  render() {
    return (
      <Fragment>
        <div className="my-card__body">
          <div className="my-card-body__row four-cols">
            {this.renderScores()}
            {this.renderEmptyScores()}
          </div>
        </div>
      </Fragment>
    );
  }
}

MyCardScores.propTypes = {
  scores: PropTypes.arrayOf(PropTypes.shape({})),
};

MyCardScores.defaultProps = {
  scores: [],
};

export default MyCardScores;
