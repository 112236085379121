import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { urlWithHttp } from "../../../shared/helpers/ui-helper";

class AssociationItem extends Component {
  renderUrl() {
    const {
      association: { web_address },
      position,
      length,
      setFocus,
    } = this.props;
    if (!web_address || web_address === "null") return null;
    return (
      <p className="rounded-border-focus">
        Website:{" "}
        <a
          href={urlWithHttp(web_address)}
          rel="noopener noreferrer"
          target="_blank"
          onKeyDown={
            web_address && web_address !== "null" && position + 1 === length
              ? setFocus
              : null
          }
        >
          {web_address}
        </a>
      </p>
    );
  }

  renderEmail() {
    const {
      association: { email, web_address },
      position,
      length,
      setFocus,
    } = this.props;
    if (!email || email === "null") return null;
    return (
      <p className="rounded-border-focus">
        Email:{" "}
        <a
          href={`mailto:${email}`}
          rel="noopener noreferrer"
          target="_blank"
          ref={position === 0 ? this.props.reference : ""}
          onKeyDown={
            (!web_address || web_address === "null") && position + 1 === length
              ? setFocus
              : null
          }
        >
          {email}
        </a>
      </p>
    );
  }

  render() {
    const { association } = this.props;
    return (
      <Fragment>
        <li key={association.id} role="presentation">
          <h2>{association.name}</h2>
          {this.renderEmail()}
          {this.renderUrl()}
        </li>
      </Fragment>
    );
  }
}

AssociationItem.propTypes = {
  association: PropTypes.shape({}).isRequired,
};

export default AssociationItem;
