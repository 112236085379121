import React from "react";
import PropTypes from "prop-types";

class DatePickerInput extends React.Component {
  render() {
    return (
      <input
        type="text"
        onChange={this.props.onDeleteInput}
        onClick={this.props.onClick}
        value={this.props.value}
        className="large2"
      />
    );
  }
}

DatePickerInput.defaultProps = {
  onClick: () => {},
};

DatePickerInput.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default DatePickerInput;
