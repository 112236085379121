import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { canChangeProfile } from "../../../../shared/helpers/user-helper";

export default class UserMenuLinks extends Component {
  trapFocus(element) {
    var focusableLinks = element.querySelectorAll("li a");

    var firstFocusableLink = focusableLinks[0];
    var lastFocusableLink = focusableLinks[focusableLinks.length - 1];
    var KEYCODE_TAB = 9;
    var KEYCODE_ESC = 27;

    element.addEventListener("keydown", function (e) {
      var isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;
      var isEscPressed = e.key === "Escape" || e.keyCode === KEYCODE_ESC;

      if (!isTabPressed) {
        if (isEscPressed) {
          document.querySelector("button.user-menu__trigger").focus();
          e.preventDefault();
        }
        return;
      }

      /* shift + tab */
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableLink) {
          lastFocusableLink.focus();
          e.preventDefault();
        }
      } /* tab */ else {
        if (document.activeElement === lastFocusableLink) {
          firstFocusableLink.focus();
          e.preventDefault();
        }
      }
    });
  }

  componentDidMount() {
    this.trapFocus(document.querySelector("#menu__links"));
  }

  render() {
    const { logout, onClick } = this.props;
    return (
      <Fragment>
        <ul className="menu__links compact" id="menu__links">
          <li>
            <NavLink
              className="menu__link border-focus"
              to="/my-profile"
              activeClassName="is-active"
              onClick={() => {
                onClick();
              }}
            >
              <span>MY PROFILE</span>
            </NavLink>
          </li>

          {canChangeProfile() && (
            <li>
              <NavLink
                className="menu__link border-focus"
                activeClassName="is-active"
                to="/change-golfer"
                onClick={() => {
                  onClick();
                }}
              >
                <span>CHANGE GOLFER</span>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              className="menu__link border-focus"
              to="/?logout"
              onClick={() => {
                onClick();
                logout();
              }}
            >
              <span>Log Out</span>
            </NavLink>
          </li>
        </ul>
      </Fragment>
    );
  }
}

UserMenuLinks.propTypes = {
  onClick: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};
