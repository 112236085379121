import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactToPrint from "react-to-print";

import jobStatus from "../../../shared/variables/job-status";
import Loader from "../../../shared/components/loaders/Loader";
import MyCardUserInfo from "./MyCardUserInfo";
import MyCardScores from "./MyCardScores";
import ScoreType from "../../../shared/variables/stats-score-type";
import { fetchStatsScores } from "../../stats/actions";
import { fetch2020Scores } from "../actions";
import { Helmet } from "react-helmet";
import {
  MY_CARD_TITLE,
  MY_CARD_DESC,
} from "../../../shared/variables/meta-seo";

class MyCard extends Component {
  state = {
    showPrintButton: true,
  };

  componentDidMount() {
    this.props.fetch2020Scores();
    this.props.fetchStatsScores(ScoreType.REVISION, false);
  }

  onStartPrint = () => this.setState({ showPrintButton: false });

  onEndPrint = () => this.setState({ showPrintButton: true });

  renderContent() {
    const { scores, scores2020 } = this.props;
    const { showPrintButton } = this.state;
    return (
      <Fragment>
        <div
          className="my-card-scrollable"
          ref={(el) => (this.componentPrintRef = el)}
        >
          <div className="my-card" style={{ marginRight: "45px" }}>
            <MyCardUserInfo scores={scores} scores2020={scores2020} />
            <div className="my-card__mid">
              Score History - Most Recent First * If Used
            </div>
            <MyCardScores scores={scores} />
          </div>
        </div>
        <div className="row">
          <div className="col auto h-centered">
            <ReactToPrint
              onBeforePrint={this.onStartPrint}
              onAfterPrint={this.onEndPrint}
              onPrintError={this.onEndPrint}
              trigger={() => (
                <button
                  className="btn fill cardinal"
                  style={{ display: showPrintButton ? "" : "none" }}
                >
                  Print
                </button>
              )}
              content={() => this.componentPrintRef}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const { fetchStatus } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>{MY_CARD_TITLE}</title>
          <meta name="description" content={MY_CARD_DESC} />
        </Helmet>
        <section className="my-card-section">
          {fetchStatus === jobStatus.SUCCESS ? (
            this.renderContent()
          ) : (
            <Loader />
          )}
        </section>
      </Fragment>
    );
  }
}

MyCard.propTypes = {
  fetchStatsScores: PropTypes.func.isRequired,
  fetchStatus: PropTypes.string,
  scores: PropTypes.arrayOf(PropTypes.shape({})),
  scores2020: PropTypes.arrayOf(PropTypes.shape({})),
};

MyCard.defaultProps = {
  scores: [],
  scores2020: [],
};

const mapStateToProps = ({ statsReducer, scores2020Reducer }) => ({
  scores: statsReducer.scoresReducer.scores,
  scores2020: scores2020Reducer.scores2020Reducer.scores,
  fetchStatus: statsReducer.scoresReducer.jobStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchStatsScores, fetch2020Scores }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyCard);
