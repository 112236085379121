import {
  CONFIRM_EMAIL_CHANGE,
  confirmEmailChangeSuccess,
  confirmEmailChangeFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(CONFIRM_EMAIL_CHANGE))
    .pipe(switchMap(({ payload }) => action(payload)));

const action = (payload) => {
  const golfer_id = payload.golfer_id;
  payload = {
    token: payload.token,
    user_password: payload.user_password,
    new_email: payload.new_email,
  };

  return from(GolferApi.confirmEmailChange(golfer_id, payload))
    .pipe(map((response) => confirmEmailChangeSuccess(response.data)))
    .pipe(
      catchError((error) => of(confirmEmailChangeFailed(error.response?.data)))
    );
};
