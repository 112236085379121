import BaseApi from "./base-api";
import { objToQuerystring } from "../shared/helpers/querystring-helper";
import { golferId, getGolferId } from "../shared/helpers/api-helper";

class FollowApi extends BaseApi {
  apiUrl = `${this.baseUrl}/api/v1`;

  getFollowedGolfers = () => {
    const url = `${this.apiUrl}/followed_golfers/${getGolferId()}.json`;
    return this.httpGet(url);
  };

  followGolfer = (followed_golfer_id, club_id) => {
    const params = {
      ...golferId(),
      followed_golfer_id,
      club_id,
    };
    return this.httpPost(
      `${this.apiUrl}/follow_golfer.json?${objToQuerystring(params)}`
    );
  };

  unFollowGolfer = (unfollowed_golfer_id, club_id) => {
    const params = {
      ...golferId(),
      unfollowed_golfer_id,
      club_id,
    };
    return this.httpPost(
      `${this.apiUrl}/unfollow_golfer.json?${objToQuerystring(params)}`
    );
  };
}

export default new FollowApi();
