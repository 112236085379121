import React, { Component, Fragment } from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { routes } from "../../../../shared/routes";
import PromoBanner from "../../../../shared/components/banners/PromoBanner";

export default class PostHbhScoreContainer extends Component {
  render() {
    return (
      <Fragment>
        <PromoBanner />
        <section className="post-score">
          <ul className="tabs light">
            <li>
              <NavLink
                to={routes.postScoreTotal.path}
                activeClassName="is-active"
              >
                Total Score
              </NavLink>
            </li>
            <li>
              <NavLink
                to={routes.postScoreHbh.path}
                activeClassName="is-active"
              >
                Hole-By-Hole Score
              </NavLink>
            </li>
          </ul>
          <Switch>
            <Redirect
              exact
              from={routes.postScoreHbh.path}
              to={routes.postScoreHbhSelectCourse.path}
            />
            <Route {...routes.postScoreHbhSelectCourse} />
            <Route {...routes.postScoreHbhMyCourses} />
            <Route {...routes.postScoreHbhRoundSetup} />
            <Route {...routes.postScoreHbhPost} />
            <Route {...routes.postScoreHbhSummary} />
          </Switch>
        </section>
      </Fragment>
    );
  }
}
