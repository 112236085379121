import React, { Component, Fragment } from "react";
import Header from "../../../screens/header/components/Header/Header";
import Navigation from "../../../screens/header/components/Navigation/Navigation";
import Footer from "../../../screens/footer/components/Footer";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProductAlert from "../../../screens/product-alert/components/ProductAlert";
import navigationHistory from "../../navigation/navigation-history";
import { setEvent } from "../../../firebaseConfig";
import { getUser, isUserLoggedIn } from "../../../shared/helpers/user-helper";

class DefaultLayout extends Component {
  constructor() {
    super();
    this.state = {
      top: 0,
      displayHeader: true,
      displayFooter: true,
    };
  }

  componentWillUnmount() {
    this.listenChangeRoute();
    document.removeEventListener("scroll", this.trackScrolling);
  }

  componentDidMount() {
    this.checkRoutePath(navigationHistory?.location);
    this.listenChangeRoute = navigationHistory?.listen((location) => {
      this.checkRoutePath(location);
      const user = getUser();
      setEvent(
        user,
        "Sentry_Logo_Display",
        "Logo_Display",
        "Header_03_02_2021"
      );
      document.addEventListener("scroll", this.trackScrolling);
    });
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("header");
    if (this.isBottom(wrappedElement)) {
      document.removeEventListener("scroll", this.trackScrolling);
      const user = getUser();
      setEvent(
        user,
        "Sentry_Logo_Display",
        "Logo_Display",
        "Footer_USGA_Partners_03_02_2021"
      );
      setEvent(
        user,
        "Sentry_Logo_Display",
        "Logo_Display",
        "Footer_GHIN_Mobile_03_02_2021"
      );
    }
  };

  checkRoutePath = (location) => {
    const display = location?.pathname === "/new-login"; // TODO move in constants
    this.setState({ displayHeader: !display, displayFooter: !display });
  };

  renderHeader() {
    var is_trial = false;
    if (this.props.profile) is_trial = this.props.profile.is_trial;
    return (
      <Fragment>
        <ProductAlert
          setTop={(top) => {
            this.setState({ top: top }, () => {
              window.scrollTo(0, 0);
            });
          }}
        />
        <div
          className="s-navigation"
          id={"header"}
          style={{ top: this.state.top }}
        >
          <Header />
          <Navigation />
        </div>
        <div
          className={`s-navigation-placeholder ${
            isUserLoggedIn() && (!this.props.golferActive || is_trial)
              ? "smaller-height"
              : ""
          }`}
          style={{ marginTop: this.state.top }}
        />
      </Fragment>
    );
  }

  render() {
    const { displayFooter, displayHeader } = this.state;
    return (
      <Fragment>
        {displayHeader && this.renderHeader()}
        <Switch>
          <div id="main-content">
            {this.props.routes.map((route, key) => (
              <Route key={key} {...route} />
            ))}
          </div>
        </Switch>
        {displayFooter && <Footer />}
      </Fragment>
    );
  }
}

DefaultLayout.propTypes = {
  routes: PropTypes.array.isRequired,
  alerts: PropTypes.array,
  hiddenAt: PropTypes.number,
  user: PropTypes.shape({}),
  lastFetch: PropTypes.number,
};

DefaultLayout.defaultProps = {
  routes: [],
  alerts: [],
  hiddenAt: undefined,
  user: undefined,
  lastFetch: undefined,
};

const mapStateToProps = ({
  productAlertReducer,
  authReducer,
  profileReducer,
}) => ({
  alerts: productAlertReducer.productAlertReducer.alerts,
  lastFetch: productAlertReducer.productAlertReducer.lastFetch,
  hiddenAt: productAlertReducer.productAlertReducer.hiddenAt,
  user: authReducer.loginReducer.user,
  golferActive: profileReducer.golferProfileFetchReducer.golferActive,
  profile: profileReducer.golferProfileFetchReducer.golferProfile,
});

export default connect(mapStateToProps, null)(DefaultLayout);
