import jobStatus from "../../../shared/variables/job-status";

import {
  FETCH_MINORS,
  FETCH_MINORS_SUCCESS,
  FETCH_MINORS_FAILED,
} from "../actions";

const initialState = {
  status: jobStatus.NOT_STARTED,
  minors: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MINORS:
      return {
        ...state,
        status: jobStatus.PROCESSING,
        minors: [],
      };
    case FETCH_MINORS_SUCCESS: {
      return {
        ...state,
        minors: payload.golfers,
        status: jobStatus.SUCCESS,
      };
    }
    case FETCH_MINORS_FAILED: {
      return {
        ...state,
        status: jobStatus.FAILED,
      };
    }
    default:
      return state;
  }
};
