import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_PLAYING_HANDICAPS,
  FETCH_PLAYING_HANDICAPS_FAILED,
  FETCH_PLAYING_HANDICAPS_SUCCESS,
} from "../actions";

const initialState = {
  handicaps: undefined,
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PLAYING_HANDICAPS:
      return {
        ...state,
        handicaps: initialState.handicaps,
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_PLAYING_HANDICAPS_SUCCESS:
      return {
        ...state,
        handicaps: payload,
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_PLAYING_HANDICAPS_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    default:
      return state;
  }
};
