import { checkApiError } from "../../auth/actions";

export const FETCH_MANUAL_COURSE_HANDICAP = "FETCH_MANUAL_COURSE_HANDICAP";
export const FETCH_MANUAL_COURSE_HANDICAP_FAILED =
  "FETCH_MANUAL_COURSE_HANDICAPL_FAILED";
export const FETCH_MANUAL_COURSE_HANDICAP_SUCCESS =
  "FETCH_MANUAL_COURSE_HANDICAP_SUCCESS";
export const FETCH_MANUAL_COURSE_HANDICAP_RESET =
  "FETCH_MANUAL_COURSE_HANDICAP_RESET";

export const fetchManualCourseHandicap = (
  golferId,
  courseRating,
  slopeRating,
  nrOfHoles
) => ({
  type: FETCH_MANUAL_COURSE_HANDICAP,
  payload: {
    golferId: golferId,
    courseRating: courseRating,
    slopeRating: slopeRating,
    nrOfHoles: nrOfHoles,
  },
});

export function fetchManualCourseHandicapFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_MANUAL_COURSE_HANDICAP_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchManualCourseHandicapSuccess = (payload) => ({
  type: FETCH_MANUAL_COURSE_HANDICAP_SUCCESS,
  payload,
});

export const resetManualCourseHandicap = () => ({
  type: FETCH_MANUAL_COURSE_HANDICAP_RESET,
});
