import { store } from "../../../shared/store";
import { fetchContactAssociations } from "../../profile/actions";

export const GOLFER_PROFILE_FETCH = "GOLFER_PROFILE_FETCH";
export const GOLFER_PROFILE_FETCH_FAILED = "GOLFER_PROFILE_FETCH_FAILED";
export const GOLFER_PROFILE_FETCH_SUCCESS = "GOLFER_PROFILE_FETCH_SUCCESS";
export const GOLFER_PROFILE_LOGOUT = "GOLFER_PROFILE_LOGOUT";
export const APPEND_LOGO_TO_MEMBERSHIPS = "APPEND_LOGO_TO_MEMBERSHIPS";

export const fetchGolferProfile = () => ({
  type: GOLFER_PROFILE_FETCH,
});

export const fetchGolferProfileFailed = () => ({
  type: GOLFER_PROFILE_FETCH_FAILED,
});

export function fetchGolferProfileSuccess(payload) {
  return (dispatch) => {
    dispatch({ type: GOLFER_PROFILE_FETCH_SUCCESS, payload });
    dispatch(fetchContactAssociations());
  };
}

export const logoutGolferProfile = () => ({
  type: GOLFER_PROFILE_LOGOUT,
});

export function appendLogoToMemberships(associations) {
  return (dispatch) => {
    const { golferMemberships } =
      store.getState().profileReducer.golferProfileFetchReducer;
    const memberships = golferMemberships.map((membership) => {
      const foundAssociation = associations.find(
        (x) => Number(x.id) === Number(membership.association_id)
      );
      if (foundAssociation && foundAssociation.logo) {
        membership.logo = foundAssociation.logo;
      }
      return membership;
    });
    dispatch({ type: APPEND_LOGO_TO_MEMBERSHIPS, payload: memberships });
  };
}
