import { fetchStatsCombinedScores } from "./stats-combined-scores-fetch";
import ScoreType from "../../../shared/variables/stats-score-type";
import { checkApiError } from "../../auth/actions";
import { getAdditionalData } from "../../../shared/helpers/scores-helper";

export const FETCH_STATS_SCORES = "FETCH_STATS_SCORES";
export const FETCH_STATS_SCORES_NEXT = "FETCH_STATS_SCORES_NEXT";
export const FETCH_STATS_SCORES_FAILED = "FETCH_STATS_SCORES_FAILED";
export const FETCH_STATS_SCORES_SUCCESS = "FETCH_STATS_SCORES_SUCCESS";
export const FETCH_STATS_SCORES_UPDATE = "FETCH_STATS_SCORES_UPDATE";

export const fetchStatsScoresNext = (
  filter,
  offset,
  onlyStats = false,
  extraParams = undefined
) => ({
  type: FETCH_STATS_SCORES_NEXT,
  payload: { filter, offset, onlyStats, extraParams },
});

export const fetchStatsScores = (
  filter,
  onlyStats = false,
  extraParams = undefined
) => ({
  type: FETCH_STATS_SCORES,
  payload: { filter, onlyStats, extraParams },
});

export function getStatsScores(
  filter,
  onlyStats = false,
  extraParams = undefined
) {
  return (dispatch) => {
    if (filter === ScoreType.RECENT_AND_REVISION_SCORES) {
      dispatch(fetchStatsCombinedScores(onlyStats, extraParams));
    } else {
      dispatch(fetchStatsScores(filter, onlyStats, extraParams));
    }
  };
}

export function fetchStatsScoresFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_STATS_SCORES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchStatsScoresSuccess = (data, onlyStats, filter) => {
  const additionalData = getAdditionalData(data, filter);
  const scores = data && data.scores ? data.scores : [];
  return {
    type: FETCH_STATS_SCORES_SUCCESS,
    payload: { scores, onlyStats, additionalData },
  };
};

export const updateStatsScores = (updatedScore) => ({
  type: FETCH_STATS_SCORES_UPDATE,
  payload: { updatedScore: updatedScore },
});
