import {
  POST_EDIT_ROUND_STATS,
  postEditRoundStatsFailed,
  postEditRoundStatsSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";
import { isEmpty } from "../../../shared/helpers/ui-helper";
import { drivingFairwayHit } from "../variables/post-score-variables";
import { appendGirToParams } from "../helpers/post-hbh-score-helper";

export default (action$) =>
  action$
    .pipe(ofType(POST_EDIT_ROUND_STATS))
    .pipe(switchMap(({ payload }) => postEditRoundStats(payload)));

const postEditRoundStats = (payload) =>
  from(ScoresApi.postEditRoundStats(payload.score.id, getParams(payload)))
    .pipe(map((response) => postEditRoundStatsSuccess(response.data.scores[0])))
    .pipe(catchError((error) => of(postEditRoundStatsFailed(error))));

const getParams = (payload) => ({
  statistics: getHoleParams(payload),
});

const getHoleParams = (payload) =>
  payload.score.hole_details.map((scoreHole) => {
    let params = { hole_number: scoreHole.hole_number };

    const statsScore = payload.roundStats.holes.find(
      (x) => x.nr === scoreHole.hole_number
    );

    if (!isEmpty(statsScore.drivingAcc.value)) {
      params = Object.assign(params, {
        drive_accuracy:
          statsScore.drivingAcc.value === drivingFairwayHit
            ? ""
            : statsScore.drivingAcc.value,
        fairway_hit: statsScore.drivingAcc.value === drivingFairwayHit,
      });
    }

    if (!isEmpty(statsScore.putts)) {
      params = Object.assign(params, { putts: statsScore.putts });
    }

    params = appendGirToParams(params, statsScore.gir.value);

    return params;
  });
