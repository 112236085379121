import jobStatus from "../../../shared/variables/job-status";
import {
  POST_TOTAL_SCORE,
  POST_TOTAL_SCORE_SUCCESS,
  POST_TOTAL_SCORE_FAILED,
  POST_TOTAL_SCORE_RESET,
} from "../actions";

const initialState = {
  postedScore: {},
  postTotalScoreErrors: undefined,
  postTotalScoreStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case POST_TOTAL_SCORE:
      return {
        ...state,
        postTotalScoreStatus: jobStatus.PROCESSING,
      };
    case POST_TOTAL_SCORE_SUCCESS:
      return {
        ...state,
        postedScore: payload,
        postTotalScoreStatus: jobStatus.SUCCESS,
      };
    case POST_TOTAL_SCORE_FAILED:
      return {
        ...state,
        postTotalScoreErrors:
          payload && payload.response && payload.response.data
            ? payload.response.data.errors
            : undefined,
        postTotalScoreStatus: jobStatus.FAILED,
      };
    case POST_TOTAL_SCORE_RESET: {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return state;
  }
};
