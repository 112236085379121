import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAssociations } from "../actions";
import jobStatus from "../../../shared/variables/job-status";
import Loader from "../../../shared/components/loaders/Loader";
import AssociationItem from "./AssociationItem";
import animateScrollTo from "animated-scroll-to";
import { Helmet } from "react-helmet";
import {
  GHIN_ASSOCIATIONS_DESC,
  GHIN_ASSOCIATIONS_TITLE,
} from "../../../shared/variables/meta-seo";

const utilizeFocus = () => {
  const ref = React.createRef();
  const setFocus = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      event.stopPropagation();
      ref.current && ref.current.focus();
    }
  };

  return { setFocus, ref };
};

class GhinAssociations extends Component {
  constructor(props) {
    super(props);
    const references = this.referencesByLettersFunction();
    this.referencesFromLetterToEmailByLetters = references.letterToEmailRefs;
    this.referencesFromEmailToLetterByLetters = references.emailToLetterRefs;
  }
  headerRef = React.createRef();

  state = {
    letters: [],
  };

  componentDidMount() {
    this.props.fetchAssociations();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { associations } = nextProps;
    let nonTrialAssociations = [];

    if (associations)
      nonTrialAssociations = associations.filter(
        (assoc) => assoc.name !== "Trial Association"
      );

    if (nonTrialAssociations && nonTrialAssociations.length > 0) {
      const letters = [];
      const map = new Map();
      for (const association of nonTrialAssociations) {
        const letter = association.name.charAt(0).toUpperCase();
        if (!map.has(letter)) {
          map.set(letter, true);
          letters.push(letter);
        }
      }
      this.setState({ letters });
    }
  }

  onClickLetter(l) {
    animateScrollTo(document.querySelector(`#${l}`), {
      speed: 100,
      verticalOffset:
        l === this.state.letters[0]
          ? -this.headerRef.current.offsetHeight
          : -50,
    });
  }

  renderLetters() {
    const { letters } = this.state;
    return letters.map((letter) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        onClick={() => this.onClickLetter(letter)}
        key={letter}
        title={letter}
        href="#"
        onKeyDown={this.referencesFromLetterToEmailByLetters[letter].setFocus}
        ref={this.referencesFromEmailToLetterByLetters[letter].ref}
      >
        {letter}
      </a>
    ));
  }

  render() {
    const { associationsJobStatus } = this.props;
    if (associationsJobStatus === jobStatus.PROCESSING) return <Loader />;
    const groupAssociationsByLetter = this.groupAssociationsByLetter();
    return (
      <Fragment>
        <Helmet>
          <title>{GHIN_ASSOCIATIONS_TITLE}</title>
          <meta name="description" content={GHIN_ASSOCIATIONS_DESC} />
        </Helmet>
        <div className="page_container ghin_associations">
          <section>
            <div className="box-content fix-right-links" ref={this.headerRef}>
              <h1>GHIN Associations</h1>
              <p>
                USGA Golf Handicap Information Network (GHIN) services are
                offered to clubs and golfers exclusively through golf
                associations. For more information about how to become a member
                of a club using the GHIN service, or about how your club can
                join a golf association using the GHIN service, please find the
                contact information for the appropriate golf association below.
              </p>
              <p>
                <a
                  href="https://www.usga.org/content/usga/home-page/get-handicap.html?utm_source=ghin&utm_medium=web&utm_campaign=gah/"
                  target="_blank"
                  className="out_link border-focus"
                  rel="noopener noreferrer"
                >
                  Get a Handicap Index®
                </a>
              </p>
            </div>

            <div className="box-panel">
              <div className="alinks_wrapper">
                <div className="alinks">{this.renderLetters()}</div>
              </div>

              <div id="content">
                {Object.keys(groupAssociationsByLetter).map((letter) => (
                  <Fragment key={letter}>
                    <div className="hr_anchor" id={letter}>
                      <span>{letter}</span>
                    </div>
                    <ul
                      className="assoc rounded-border-focus"
                      role="presentation"
                    >
                      {groupAssociationsByLetter[letter].map(
                        (association, key) => {
                          const nextLetter = this.nextExistentLetter(
                            groupAssociationsByLetter,
                            letter
                          );
                          return (
                            <AssociationItem
                              association={association}
                              key={key}
                              reference={
                                this.referencesFromLetterToEmailByLetters[
                                  letter
                                ].ref
                              }
                              setFocus={
                                nextLetter
                                  ? this.referencesFromEmailToLetterByLetters[
                                      nextLetter
                                    ].setFocus
                                  : null
                              }
                              position={key}
                              length={groupAssociationsByLetter[letter].length}
                            />
                          );
                        }
                      )}
                    </ul>
                  </Fragment>
                ))}
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    );
  }

  groupAssociationsByLetter = () => {
    const { associations } = this.props;
    let nonTrialAssociations = [];

    if (associations)
      nonTrialAssociations = associations.filter(
        (assoc) => assoc.name !== "Trial Association"
      );

    return nonTrialAssociations.reduce((acc, association) => {
      const letter = association.name[0].toUpperCase();
      return {
        ...acc,
        [letter]: [...(acc[letter] ? acc[letter] : []), association],
      };
    }, {});
  };

  referencesByLettersFunction = () => {
    let letters = [];
    for (let i = 65; i < 91; i++) {
      letters.push(String.fromCharCode(i));
    }
    let letterToEmailRefs = {};
    let emailToLetterRefs = {};
    letters.forEach((letter) => {
      letterToEmailRefs[letter] = utilizeFocus();
      emailToLetterRefs[letter] = utilizeFocus();
    });

    return { letterToEmailRefs, emailToLetterRefs };
  };

  nextExistentLetter = (groupAssociationsByLetter, letter) => {
    for (let i = letter.charCodeAt(0) + 1; i <= "Z".charCodeAt(0); i++) {
      if (groupAssociationsByLetter[String.fromCharCode(i)] !== undefined) {
        return String.fromCharCode(i);
      }
    }
  };
}

GhinAssociations.propTypes = {
  fetchAssociations: PropTypes.func.isRequired,
  associationsJobStatus: PropTypes.string.isRequired,
  associations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchAssociations }, dispatch);

const mapStateToProps = ({ ghinAssociationsReducer }) => ({
  associations: ghinAssociationsReducer.associationsReducer.associations,
  associationsJobStatus: ghinAssociationsReducer.associationsReducer.jobStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(GhinAssociations);
