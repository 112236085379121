import React, { Component, Fragment } from "react";
import ViewAvgHbhScoreTable from "./ViewAvgHbhScoreTable";
import { scrollHbhSide } from "../../../post-score/helpers/post-hbh-score-helper";
import ScoresApi from "../../../../services/scores-api";
import MobileHeaderMiniScroll from "../../../post-score/components/Shared/Misc/MobileHeaderMiniScroll";
import Loader from "../../../../shared/components/loaders/Loader";
import ratingType from "../../../../shared/variables/rating-type";
import { routes } from "../../../../shared/routes";

class ViewAvgHbhScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avgScorePerHole: null,
      tee_set: null,
    };
  }
  componentDidMount() {
    const locationState = this.props.location.state;
    ScoresApi.getAverageScorePerHole(
      locationState.golferId,
      locationState.selectedClub.tee_set_id
    ).then((response) => {
      this.setState({ avgScorePerHole: response.data.average_scores });
    });
    ScoresApi.getScoreCourseDetails(locationState.selectedClub.course_id).then(
      (response) => {
        const teeSetInfo =
          response.data.TeeSets.find(
            (x) =>
              x.TeeSetRatingId ==
              parseInt(locationState.selectedClub.tee_set_id)
          ) || [];
        this.setState({ tee_set: teeSetInfo });
      }
    );
  }

  renderSide(teeSet, avgScores, side) {
    return (
      <>
        <div className="pre-table">
          <MobileHeaderMiniScroll side={side} />
        </div>
        <div onScroll={(event) => scrollHbhSide(event, side)}>
          <ViewAvgHbhScoreTable
            teeSet={teeSet}
            side={side}
            avgScores={avgScores}
            showSideTotalsColumn={true}
            sideTotalsColumnTitle={side === ratingType.FRONT ? "OUT" : "IN"}
            showTotalsColumn={side === ratingType.BACK}
          />
        </div>
        <br />
      </>
    );
  }

  render() {
    const options = this.state;
    return options.tee_set && options.avgScorePerHole ? (
      <Fragment>
        <div className="box">
          <div className="box-head">
            <h3 className="box-title">Average Score Per Hole</h3>
          </div>
          <div className="box-body tablet-mod">
            {this.renderSide(
              options.tee_set,
              options.avgScorePerHole,
              ratingType.FRONT
            )}
            {this.renderSide(
              options.tee_set,
              options.avgScorePerHole,
              ratingType.BACK
            )}
            <div className="row" style={{ justifyContent: "center" }}>
              <button
                onClick={() => routes.stats.navigate()}
                className="btn blank blue"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <Loader />
      </Fragment>
    );
  }
}

export default ViewAvgHbhScore;
