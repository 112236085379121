import jobStatus from "../../../shared/variables/job-status";
import {
  CONFIRM_POST_SCORE,
  CONFIRM_POST_SCORE_SUCCESS,
  CONFIRM_POST_SCORE_FAILED,
  CONFIRM_POST_SCORE_RESET,
} from "../actions";

const initialState = {
  confirmedScore: {},
  confirmPostScoreStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CONFIRM_POST_SCORE:
      return {
        ...state,
        confirmPostScoreStatus: jobStatus.PROCESSING,
      };
    case CONFIRM_POST_SCORE_SUCCESS:
      return {
        ...state,
        confirmedScore: payload,
        confirmPostScoreStatus: jobStatus.SUCCESS,
      };
    case CONFIRM_POST_SCORE_FAILED:
      return {
        ...state,
        confirmPostScoreStatus: jobStatus.FAILED,
      };
    case CONFIRM_POST_SCORE_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
