import { getUser, getUserGhinNo } from "../../../shared/helpers/user-helper";
import { userExistInGolfers } from "../../../shared/helpers/handicap-calculator-helper";
export const HANDICAP_CALCULATOR_GOLFERS_INIT =
  "HANDICAP_CALCULATOR_GOLFERS_INIT";
export const HANDICAP_CALCULATOR_GOLFERS_ADD =
  "HANDICAP_CALCULATOR_GOLFERS_ADD";
export const HANDICAP_CALCULATOR_GOLFERS_REMOVE =
  "HANDICAP_CALCULATOR_GOLFERS_REMOVE";
export const HANDICAP_CALCULATOR_GOLFERS_HIDE_ALERT =
  "HANDICAP_CALCULATOR_GOLFERS_HIDE_ALERT";
export const RESET_HANDICAP_CALCULATOR = "RESET_HANDICAP_CALCULATOR";
export const HANDICAP_CALCULATOR_SET_GOLFER_TEE =
  "HANDICAP_CALCULATOR_SET_GOLFER_TEE";
export const HANDICAP_CALCULATOR_SET_NUMBER_OF_HOLES =
  "HANDICAP_CALCULATOR_SET_NUMBER_OF_HOLES";
export const RESET_ADDED_GOLFERS_COUNT = "RESET_ADDED_GOLFERS_COUNT";
export const REMOVE_ALL_GOLFERS = "REMOVE_ALL_GOLFERS";
export const SET_HANDICAP_ALLOWANCE = "SET_HANDICAP_ALLOWANCE";
export const SET_INITIAL_TEE_SET_ID = "SET_INITIAL_TEE_SET_ID";
export const REMOVE_INITIAL_TEE_SET_ID = "REMOVE_INITIAL_TEE_SET_ID";
export const ADD_GOLFERS_MANUAL_ID_REFERENCE =
  "ADD_GOLFERS_MANUAL_ID_REFERENCE";

export const handicapCalculatorInit = (courseId) => {
  const golfer = { ...getUser(), localUser: true };
  let payload = { courseId };
  if (!userExistInGolfers(golfer.ghin_number)) {
    payload = { courseId, golfer };
  }
  return {
    type: HANDICAP_CALCULATOR_GOLFERS_INIT,
    payload,
  };
};

export const handicapCalculatorAddGolfer = (golfer) => ({
  type: HANDICAP_CALCULATOR_GOLFERS_ADD,
  payload: { golfer },
});

export const handicapCalculatorRemoveGolfer = (golferId) => ({
  type: HANDICAP_CALCULATOR_GOLFERS_REMOVE,
  payload: { golferId: parseInt(golferId, 10) },
});

export const handicapCalculatorHideAlert = () => ({
  type: HANDICAP_CALCULATOR_GOLFERS_HIDE_ALERT,
});

export const resetHandicapCalculator = () => ({
  type: RESET_HANDICAP_CALCULATOR,
});

export const setGolferSelectedTee = (golfer, teeId) => {
  const userSelectedTee =
    parseInt(getUserGhinNo(), 10) === golfer ? teeId : null;
  return {
    type: HANDICAP_CALCULATOR_SET_GOLFER_TEE,
    payload: { golfer, teeId, userSelectedTee },
  };
};

export const setNumberOfHoles = (numberOfHoles) => ({
  type: HANDICAP_CALCULATOR_SET_NUMBER_OF_HOLES,
  payload: { numberOfHoles },
});

export const resetAddedGolfersCount = () => ({
  type: RESET_ADDED_GOLFERS_COUNT,
});

export const removeAllGolfers = () => ({
  type: REMOVE_ALL_GOLFERS,
  payload: { golferId: parseInt(getUserGhinNo(), 10) },
});

export const setHandicapAllowance = (allowance) => ({
  type: SET_HANDICAP_ALLOWANCE,
  payload: { allowance },
});

export const setInitialTeeSetId = (initialTeeSetId) => ({
  type: SET_INITIAL_TEE_SET_ID,
  payload: { initialTeeSetId },
});

export const removeInitialTeeSetId = () => ({
  type: REMOVE_INITIAL_TEE_SET_ID,
});

export const addGolfersManualIdReference = () => ({
  type: ADD_GOLFERS_MANUAL_ID_REFERENCE,
});
