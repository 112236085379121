/* eslint-disable react/no-unescaped-entities */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Navigate from "../../../../shared/navigation/navigate";
import LoaderButton from "../../../../shared/components/buttons/LoaderButton";
import jobStatus from "../../../../shared/variables/job-status";
import AuthErrorModal from "../common/AuthErrorModal";
import { Formik } from "formik";
import * as Yup from "yup";
import { forgotLogin } from "../../actions";
import SuccessBody from "./Components/SuccessBody";
import { Helmet } from "react-helmet";
import {
  FORGOT_LOGIN_DESC,
  FORGOT_LOGIN_TITLE,
} from "../../../../shared/variables/meta-seo";

class CreateProfile extends Component {
  state = {
    failedModalOpen: false,
    displaySuccessBody: false,
    emailOrGhin: undefined,
  };

  validationSchema = Yup.object({
    emailOrGhin: Yup.string().required("Email Address or GHIN Number required"),
  });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { postStatus } = nextProps;
    if (
      postStatus !== this.props.postStatus &&
      postStatus === jobStatus.FAILED
    ) {
      this.setState({ failedModalOpen: true });
    }

    if (
      postStatus !== this.props.postStatus &&
      postStatus === jobStatus.SUCCESS
    ) {
      this.setState({ displaySuccessBody: true });
    }
  }

  onCancel = () => Navigate.toPath("/");

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    this.setState({ emailOrGhin: values.emailOrGhin }, this.forgotLoginAction);
  };

  forgotLoginAction = () => {
    const { emailOrGhin } = this.state;
    this.props.forgotLogin({ email_or_ghin: emailOrGhin });
  };

  renderSuccessMessage() {
    const { emailOrGhin } = this.state;
    const { successMessage, postStatus } = this.props;
    return (
      <SuccessBody
        onBackToLogin={this.onCancel}
        message={successMessage}
        onResendVerification={this.forgotLoginAction}
        postStatus={postStatus}
        emailOrGhin={emailOrGhin}
      />
    );
  }

  renderForm(options) {
    const link = <a href="/contact-us"> Golf Association.</a>;

    return (
      <Fragment>
        <div className="top-label">
          Please provide one of the options below to reset your password:
        </div>
        <div className="forgot-form">
          <div className="row">
            <div className="col is-full">
              <label htmlFor="password">Email Address or GHIN Number</label>
              <input
                type="text"
                id="emailOrGhin"
                value={options.values.emailOrGhin}
                name="emailOrGhin"
                onBlur={options.handleBlur}
                onChange={options.handleChange}
              />
              <span className="error">
                {options.touched.emailOrGhin ? options.errors.emailOrGhin : ""}
              </span>
            </div>
          </div>
          <span className="field_place_holder" />
          <span className="field_place_holder" />
          <div className="bottom-actions">
            <button className="btn blank blue" onClick={this.onCancel}>
              Cancel
            </button>
            <LoaderButton
              type="submit"
              className="btn fill cardinal"
              onClick={options.handleSubmit}
              disabled={!options.isValid}
              loading={String(this.props.postStatus === jobStatus.PROCESSING)}
            >
              &nbsp;&nbsp;&nbsp;&nbsp; NEXT &nbsp;&nbsp;&nbsp;&nbsp;
            </LoaderButton>
          </div>
          <div className="bottom-link">
            Don't know your GHIN Number or email address? Please contact your
            Club or {link}
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const { failedModalOpen, displaySuccessBody } = this.state;
    const { error } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>{FORGOT_LOGIN_TITLE}</title>
          <meta name="description" content={FORGOT_LOGIN_DESC} />
        </Helmet>
        <div className="page_container create-profile-page">
          <div className="box-content">
            <h1>Forgot Login Information</h1>
          </div>
          <div className="box-panel center">
            <div className="auth__container">
              {displaySuccessBody ? (
                this.renderSuccessMessage()
              ) : (
                <div className="forgot-login">
                  <Formik
                    onSubmit={this.onSubmit}
                    validationSchema={this.validationSchema}
                  >
                    {(options) => this.renderForm(options)}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>
        <AuthErrorModal
          errorBody={error}
          visible={failedModalOpen}
          onClose={() => this.setState({ failedModalOpen: false })}
          onOk={() => this.setState({ failedModalOpen: false })}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authReducer }) => ({
  postStatus: authReducer.forgotLoginReducer.status,
  successMessage: authReducer.forgotLoginReducer.successMessage,
  error: authReducer.forgotLoginReducer.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ forgotLogin }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfile);
