import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_END_OF_YEAR_CAMPAIGN,
  FETCH_END_OF_YEAR_CAMPAIGN_SUCCESS,
  FETCH_END_OF_YEAR_CAMPAIGN_FAILED,
} from "../actions";

const initialState = {
  year_end_summary: undefined,
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_END_OF_YEAR_CAMPAIGN:
      return {
        ...state,
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_END_OF_YEAR_CAMPAIGN_SUCCESS:
      return {
        ...state,
        year_end_summary: payload,
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_END_OF_YEAR_CAMPAIGN_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    default:
      return state;
  }
};
