import React, { Component } from "react";
import PropTypes from "prop-types";
import TeeListItem from "./TeeListItem";

class TeesList extends Component {
  sortTeesByCR = (tees) => {
    tees.sort(function (t1, t2) {
      return t2.Ratings[0].CourseRating - t1.Ratings[0].CourseRating;
    });
  };

  render() {
    const { tees } = this.props;
    this.sortTeesByCR(tees);
    return (
      <ul className="lookup__list">
        <li>
          <div
            className="item header border-focus my-courses"
            to="#"
            tabIndex="-1"
          >
            <span className="details">
              <span className="tee abbr" data-abbr="Tees" data-full="Tees" />
            </span>
            {!this.props.trialGolfer && (
              <span
                className="index abbr"
                data-abbr="Total"
                data-full="C.R. / Slope / Par"
              />
            )}
          </div>
        </li>
        {tees.map((tee, key) => (
          <TeeListItem
            key={key}
            tee={tee}
            trialGolfer={this.props.trialGolfer}
            courseId={this.props.courseId}
            courses={this.props.courses}
            extraParam={this.props.extraParam}
          />
        ))}
      </ul>
    );
  }
}

TeesList.propTypes = {
  tees: PropTypes.array.isRequired,
};

export default TeesList;
