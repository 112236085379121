import { checkApiError } from "../../auth/actions";

export const FETCH_MANUALLY_CALCULATOR = "FETCH_MANUALLY_CALCULATOR";
export const FETCH_MANUALLY_CALCULATOR_FAILED =
  "FETCH_MANUALLY_CALCULATOR_FAILED";
export const FETCH_MANUALLY_CALCULATOR_SUCCESS =
  "FETCH_MANUALLY_CALCULATOR_SUCCESS";
export const FETCH_MANUALLY_CALCULATOR_RESET =
  "FETCH_MANUALLY_CALCULATOR_RESET";

export const fetchManuallyCalculator = (
  handicapIndex,
  courseRating,
  slopeRating,
  par,
  handicapAllowance
) => ({
  type: FETCH_MANUALLY_CALCULATOR,
  payload: { handicapIndex, courseRating, slopeRating, par, handicapAllowance },
});

export const fetchManuallyCalculatorSuccess = (payload) => ({
  type: FETCH_MANUALLY_CALCULATOR_SUCCESS,
  payload,
});

export function fetchManuallyCalculatorFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_MANUALLY_CALCULATOR_FAILED });
    dispatch(checkApiError(error));
  };
}

export const manuallyCalculatorReset = () => ({
  type: FETCH_MANUALLY_CALCULATOR_RESET,
});
