import { combineEpics } from "redux-observable";
import {
  FETCH_CLUB_GOLFERS,
  FETCH_CLUB_GOLFERS_NEXT,
  fetchClubGolfersSuccess,
  fetchClubGolfersFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export const fetchClubGolfers = (action$) =>
  action$
    .pipe(ofType(FETCH_CLUB_GOLFERS))
    .pipe(switchMap(({ payload }) => getClubGolfers(payload)));

export const fetchNextPageClubGolfers = (action$) =>
  action$
    .pipe(ofType(FETCH_CLUB_GOLFERS_NEXT))
    .pipe(switchMap(({ payload }) => getClubGolfers(payload)));

const getClubGolfers = (payload) =>
  from(
    GolferApi.getClubGolfers(
      payload.clubId,
      payload.filter,
      payload.sortCriteria,
      payload.page
    )
  )
    .pipe(map((response) => fetchClubGolfersSuccess(response.data)))
    .pipe(catchError((error) => of(fetchClubGolfersFailed(error))));

export default combineEpics(fetchClubGolfers, fetchNextPageClubGolfers);
