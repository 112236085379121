import React, { Component } from "react";
import PropTypes from "prop-types";

class Submit extends Component {
  render() {
    const { options, title } = this.props;
    const too_large_on_phone = title.length > 22 ? "too_large_on_phone" : "";
    return (
      <div className="row">
        <div className="col auto h-centered">
          <button
            type="submit"
            className={`btn fill cardinal ${too_large_on_phone}`}
            onClick={options.handleSubmit}
            disabled={!options.isValid}
          >
            {title}
          </button>
        </div>
      </div>
    );
  }
}

Submit.propTypes = {
  options: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default Submit;
