import loginEpic from "./login";
import trialEpic from "./trial";
import homeCoursesEpic from "./home-courses";
import createProfile from "./create-profile";
import resendVerification from "./resend-verification";
import golferInformation from "./golfer-information";
import emailChangeInformation from "./email-change-information";
import confirmEmailChange from "./confirm-email-change";
import setPassword from "./set-password";
import forgotLogin from "./forgot-login";
import acceptTerms from "./accept-terms";
import searchGolfer from "./search-golfer";
import fetchMinors from "./fetch-minors";

import { combineEpics } from "redux-observable";

export default combineEpics(
  loginEpic,
  homeCoursesEpic,
  createProfile,
  resendVerification,
  golferInformation,
  emailChangeInformation,
  confirmEmailChange,
  setPassword,
  forgotLogin,
  acceptTerms,
  searchGolfer,
  fetchMinors,
  trialEpic
);
