import React, { Component } from "react";

class Title extends Component {
  render() {
    return (
      <div className="box-head">
        <h3 className="box-title">Post Hole-By-Hole Score</h3>
      </div>
    );
  }
}

export default Title;
