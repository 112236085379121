import {
  FETCH_COUNTRIES_SELECT_COURSE,
  fetchCountriesSelectCourseSuccess,
  fetchCountriesSelectCourseFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import CountriesStatesApi from "../../../services/countries-states-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_COUNTRIES_SELECT_COURSE))
    .pipe(switchMap(getCountries));

const getCountries = () =>
  from(CountriesStatesApi.get())
    .pipe(
      map((response) =>
        fetchCountriesSelectCourseSuccess(response.data.countries)
      )
    )
    .pipe(catchError((error) => of(fetchCountriesSelectCourseFailed(error))));
