import {
  POST_TOTAL_SCORE,
  postTotalScoreSuccess,
  postTotalScoreFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";
import { isEmpty } from "../../../shared/helpers/ui-helper";
import { golferId, golferGender } from "../../../shared/helpers/api-helper";
import teeSetSide from "../../../shared/variables/tee-set-side";
import { convertDateToString } from "../../../shared/helpers/date-helper";
import numberOfHoles from "../../../shared/variables/number-of-holes";

export default (action$) =>
  action$
    .pipe(ofType(POST_TOTAL_SCORE))
    .pipe(switchMap(({ payload }) => postTotalScore(payload)));

const postTotalScore = (payload) =>
  from(
    hasToUseAdjustedScore(payload)
      ? ScoresApi.postAdjustedScore(getAdjustedScoreParams(payload))
      : ScoresApi.post18h9and9Score(get18h9and9ScoreParams(payload))
  )
    .pipe(map((r) => postTotalScoreSuccess(r.data.score)))
    .pipe(catchError((error) => of(postTotalScoreFailed(error))));

const hasToUseAdjustedScore = (payload) =>
  payload.isManualCourse === true ||
  payload.isManualRating === true ||
  (isEmpty(payload.frontScore) &&
    isEmpty(payload.backScore) &&
    !isEmpty(payload.totalScore));

const getAdjustedScoreParams = (payload) =>
  payload.isManualCourse === true || payload.isManualRating === true
    ? {
        ...golferId(),
        ...golferGender(),
        course_name: payload.manualCourseName,
        tee_name: "N/A",
        tee_set_side:
          payload.nrOfHoles === numberOfHoles.EIGHTEEN
            ? teeSetSide.Total
            : teeSetSide.Front,
        course_rating: payload.manualCourseRating,
        slope_rating: payload.manualSlopeRating,
        number_of_holes: payload.nrOfHoles,
        score_type: payload.scoreType,
        played_at: convertDateToString(payload.datePlayed),
        override_confirmation: false,
        is_manual: true,
        adjusted_gross_score: payload.totalScore,
      }
    : {
        ...golferId(),
        ...golferGender(),
        course_id: payload.courseId,
        tee_set_id: payload.tees.value.TeeSetRatingId,
        tee_set_side: teeSetSide[payload.tees.value.RatingType],
        number_of_holes: payload.nrOfHoles,
        score_type: payload.scoreType,
        played_at: convertDateToString(payload.datePlayed),
        override_confirmation: false,
        is_manual: false,
        adjusted_gross_score: payload.totalScore,
      };

const get18h9and9ScoreParams = (payload) =>
  isEmpty(payload.courseId)
    ? {
        ...golferId(),
        ...golferGender(),
        tee_set_side: teeSetSide.Total,
        number_of_holes: payload.nrOfHoles,
        score_type: payload.scoreType,
        played_at: convertDateToString(payload.datePlayed),
        override_confirmation: false,
        is_manual: true,
        front9_course_name: payload.manualCourseName,
        front9_tee_name: "N/A",
        front9_course_rating: payload.manualCourseRating,
        front9_slope_rating: payload.manualSlopeRating,
        front9_adjusted: payload.frontScore,
        back9_course_name: payload.manualCourseName,
        back9_tee_name: "N/A",
        back9_course_rating: payload.manualCourseRating,
        back9_slope_rating: payload.manualSlopeRating,
        back9_adjusted: payload.backScore,
      }
    : {
        ...golferId(),
        ...golferGender(),
        course_id: payload.courseId,
        tee_set_id: payload.tees.value.TeeSetRatingId,
        tee_set_side: teeSetSide[payload.tees.value.RatingType],
        number_of_holes: payload.nrOfHoles,
        score_type: payload.scoreType,
        front9_adjusted: payload.frontScore,
        back9_adjusted: payload.backScore,
        played_at: convertDateToString(payload.datePlayed),
        override_confirmation: false,
        is_manual: false,
      };
