import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";

class PostInvalidScoreModal extends Component {
  render() {
    const { isOpen, onClose, message } = this.props;
    return (
      <YesNoModal
        onConfirm={onClose}
        onAbort={onClose}
        isNoVisible={false}
        isOpen={isOpen}
        yesTitle="Ok"
        contentLabel={message}
      >
        <p>{message}</p>
      </YesNoModal>
    );
  }
}

PostInvalidScoreModal.propTypes = {
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PostInvalidScoreModal;
