import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_MANUALLY_CALCULATOR,
  FETCH_MANUALLY_CALCULATOR_SUCCESS,
  FETCH_MANUALLY_CALCULATOR_FAILED,
  FETCH_MANUALLY_CALCULATOR_RESET,
} from "../actions";

const initialState = {
  manualCourseHandicap: undefined,
  manualPlayingHandicap: undefined,
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MANUALLY_CALCULATOR:
      return {
        ...state,
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_MANUALLY_CALCULATOR_SUCCESS:
      return {
        ...state,
        manualCourseHandicap: payload.manual_course_handicap,
        manualPlayingHandicap: payload.manual_playing_handicap,
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_MANUALLY_CALCULATOR_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    case FETCH_MANUALLY_CALCULATOR_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
