import React, { Component, Fragment } from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { routes } from "../../../../shared/routes/index";
import PromoBanner from "../../../../shared/components/banners/PromoBanner";

export default class PostHbhScoreContainer extends Component {
  render() {
    return (
      <Fragment>
        <PromoBanner />
        <section className="post-score">
          <ul className="tabs light">
            <li>
              <NavLink
                to={routes.postScoreTotal.path}
                activeClassName="is-active"
              >
                Total Score
              </NavLink>
            </li>
            <li>
              <NavLink
                to={routes.postScoreHbh.path}
                activeClassName="is-active"
              >
                Hole-By-Hole Score
              </NavLink>
            </li>
          </ul>
          <Switch>
            <Redirect
              exact
              from={routes.postScoreTotal.path}
              to={routes.postScoreTotalSelectCourse.path}
            />
            <Route {...routes.postScoreTotalSelectCourse} />
            <Route {...routes.postScoreTotalMyCourses} />
            <Route {...routes.postScoreTotalPost} />
            <Route {...routes.postScoreTotalSummary} />
          </Switch>
        </section>
      </Fragment>
    );
  }
}

PostHbhScoreContainer.propTypes = {
  match: PropTypes.object.isRequired,
};
