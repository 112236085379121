import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { getFormattedDate } from "../../../shared/helpers/date-helper";
import {
  getScoresPerPage,
  hidden,
  normalizeScoreBarHeight,
  stringToFloat,
} from "../../../shared/helpers/ui-helper";
import { connect } from "react-redux";
import { fetchRecentScores } from "../actions";
import { bindActionCreators } from "redux";
import { isUserLoggedIn } from "../../../shared/helpers/user-helper";
import { getDifferential } from "../../../shared/helpers/scores-helper";

class RecentScores extends Component {
  constructor(props) {
    super(props);
    this.state = { offset: 0, limit: 0, maxRecentScores: 20 };
  }

  recentScoresSliderRef;

  componentDidMount = () => {
    if (isUserLoggedIn()) {
      this.props.fetchRecentScores(0, this.state.maxRecentScores);
    }
  };

  hasPrev = () =>
    this.state.offset + this.state.limit < this.state.maxRecentScores;
  hasNext = () => this.state.offset !== 0;

  fetchNext = (forward) => {
    const { offset } = this.state;
    const limit = getScoresPerPage(
      this.recentScoresSliderRef,
      this.props.defaultScoresPerPage
    );
    let newOffset = forward ? offset - limit : offset + limit;
    newOffset = newOffset < 0 ? 0 : newOffset;
    this.setState({ offset: newOffset, limit: limit });
  };

  render = () => {
    return (
      <div
        className="section__body panel recent-scores__slider"
        ref={(ref) => (this.recentScoresSliderRef = ref)}
      >
        <div className="custom-chart">
          <div className="cusutom-chart__container">
            <div className="custom-chart__timeline">
              {this.getScoresPage().map((score, key) =>
                this.renderScore(score, key)
              )}
              <div className="custom-chart_h" />
            </div>
          </div>
          {this.renderControls()}
        </div>
      </div>
    );
  };

  renderControls = () => (
    <Fragment>
      <button
        className={`custom-chart__control prev ${hidden(!this.hasPrev())}`}
        onClick={() => this.fetchNext(false)}
      >
        <i className="material-icons-outlined">chevron_left</i>
      </button>
      <button
        className={`custom-chart__control next ${hidden(!this.hasNext())}`}
        onClick={() => this.fetchNext(true)}
      >
        <i className="material-icons-outlined">chevron_right</i>
      </button>
    </Fragment>
  );

  renderScore = (score, key) => (
    <div
      key={key}
      className={
        "custom-chart__bar" + (this.props.golferActive ? "" : " no-hover")
      }
      style={{
        height: `${normalizeScoreBarHeight(score.adjusted_gross_score)}%`,
      }}
    >
      <span className="custom-chart__bar__value">
        {score.adjusted_gross_score}
      </span>
      <span className="custom-chart__bar__date">
        {getFormattedDate(score.played_at, "YYYY-MM-DD", "MM/DD/YY")}
      </span>
      {this.props.golferActive && (
        <div
          className={"hover_tooltip" + (this.props.trialGolfer ? " trial" : "")}
        >
          {this.render_tooltip(score)}
        </div>
      )}
    </div>
  );

  renderPlayedHoles(score) {
    if (score.number_of_played_holes > 9 && score.number_of_played_holes < 18) {
      return (
        <span className="subscript">{`(${score.number_of_played_holes})`}</span>
      );
    }
  }

  getScoreType = (score) => score.score_type_display_short === "N" && "N";

  getScoresPage = () => {
    const scoresPerPage = getScoresPerPage(
      this.recentScoresSliderRef,
      this.props.defaultScoresPerPage
    );
    const scores = this.props.scores.slice(
      this.state.offset,
      this.state.offset + scoresPerPage
    );
    return this.reverseScores(scores);
  };

  reverseScores = (scores) => scores.reverse();

  render_tooltip = (score) => (
    <Fragment>
      <p>{score.facility_name}</p>
      {score.facility_name !== score.course_name && <p>{score.course_name}</p>}
      <div className={"score_info" + (this.props.trialGolfer ? " trial" : "")}>
        <div>
          SCORE
          <br />
          <span>
            {score.adjusted_gross_score}
            {this.props.useScaling && this.renderPlayedHoles(score)}
            {this.props.useScaling && this.getScoreType(score)}
          </span>
        </div>
        {!this.props.trialGolfer && (
          <div>
            C.R./SLOPE
            <br />
            <span>
              {stringToFloat(score.course_rating, 1)}/{score.slope_rating}
            </span>
          </div>
        )}
        {!this.props.trialGolfer && (
          <div>
            DIFF
            <br />
            <span>{getDifferential(score, this.props.useScaling)}</span>
          </div>
        )}
      </div>
    </Fragment>
  );
}

RecentScores.propTypes = {
  defaultScoresPerPage: PropTypes.number.isRequired,
  scores: PropTypes.array.isRequired,
  fetchRecentScores: PropTypes.func.isRequired,
  useScaling: PropTypes.bool,
};

const mapStateToProps = ({ recentScoresReducer, profileReducer }) => ({
  scores: recentScoresReducer.recentScoresFetchReducer.recentScores,
  golferActive: profileReducer.golferProfileFetchReducer.golferActive,
  trialGolfer: profileReducer.golferProfileFetchReducer.trialGolfer,
  useScaling:
    profileReducer.golferProfileFetchReducer.golferProfile?.use_scaling,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchRecentScores }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RecentScores);
