import jobStatus from "../../../shared/variables/job-status";
import {
  POST_HBH_SCORE,
  POST_HBH_SCORE_SUCCESS,
  POST_HBH_SCORE_FAILED,
  POST_HBH_SCORE_RESET,
} from "../actions";

const initialState = {
  postedScore: {},
  postHbhScoreErrors: undefined,
  postHbhScoreStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case POST_HBH_SCORE:
      return {
        ...state,
        postHbhScoreStatus: jobStatus.PROCESSING,
      };
    case POST_HBH_SCORE_SUCCESS:
      return {
        ...state,
        postedScore: payload,
        postHbhScoreStatus: jobStatus.SUCCESS,
      };
    case POST_HBH_SCORE_FAILED:
      return {
        ...state,
        postHbhScoreErrors:
          payload && payload.response && payload.response.data
            ? payload.response.data.errors
            : {},
        postHbhScoreStatus: jobStatus.FAILED,
      };
    case POST_HBH_SCORE_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
