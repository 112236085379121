import {
  FETCH_MY_COURSES,
  fetchMyCoursesSuccess,
  fetchMyCoursesFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import CoursesApi from "../../../services/courses-api";

export default (action$) =>
  action$.pipe(ofType(FETCH_MY_COURSES)).pipe(switchMap(fetchMyCourses));

const fetchMyCourses = () =>
  from(CoursesApi.getMyCourses())
    .pipe(map((r) => fetchMyCoursesSuccess(r.data.golfer_course_preference)))
    .pipe(catchError((error) => of(fetchMyCoursesFailed(error))));
