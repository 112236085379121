// This is the public score_site_key from our recaptcha google cloud project.
// Before an action which we want to verify, we first obtain a token from google cloud.
// Then we attach the token to the request which goes to our backend.
import appConfig from "../config/index";

// Gets the token from google, the action we are verifying is called "LOGIN"
export const recaptcha_exe = (payload) => {
  return window.grecaptcha
    .execute(appConfig.site_key, { action: "LOGIN" })
    .then(function (token) {
      payload["user_token"] = token;
      return payload;
    })
    .catch(function () {
      payload["user_token"] = "error";
      return payload;
    });
};
// Makes sure that the recaptcha API has been loaded first.
export const recaptcha_ready = (payload) => {
  return new Promise(function (resolve) {
    window.grecaptcha.ready(() => {
      resolve(payload);
    });
  });
};
