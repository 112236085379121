import NotFound from "../../screens/not-found/components/NotFound";
import Navigate from "../navigation/navigate";
import DefaultLayout from "../components/layouts/DefaultLayout";

export default {
  notFound: {
    path: "/not-found",
    component: NotFound,
    exact: true,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
    navigate: (requestedUrl) =>
      Navigate.toPath(`/not-found?requestedUrl=${requestedUrl}`),
  },
  root: {
    path: "/",
    component: undefined,
    exact: true,
    rootPath: true,
    layout: DefaultLayout,
    authRequired: false,
    navigate: () => Navigate.toPath("/"),
  },
};
