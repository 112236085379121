import {
  FETCH_CALCULATOR_COURSE,
  fetchCalculatorCourseFailed,
  fetchCalculatorCourseSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import CoursesApi from "../../../services/courses-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_CALCULATOR_COURSE))
    .pipe(switchMap(({ payload }) => fetchCalculatorCourse(payload)));

const fetchCalculatorCourse = (payload) =>
  from(CoursesApi.getCourseDetails(payload.courseId))
    .pipe(map((response) => fetchCalculatorCourseSuccess(response.data)))
    .pipe(catchError((error) => of(fetchCalculatorCourseFailed(error))));
