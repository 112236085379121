import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../../../shared/routes/index";
import RecentScores from "../../recent-scores/components/RecentScores";

class RecentScoresContainer extends Component {
  render = () => (
    <Fragment>
      <section className="tournaments-and-events">
        <div className="section__head">
          <h2 className="section__title">Recent Scores</h2>
          <ul className="section__controls">
            <li>
              <NavLink
                className="section__link default-section-link"
                to={routes.stats.path}
              >
                View All Stats
                <i className="material-icons-outlined">chevron_right</i>
              </NavLink>
            </li>
          </ul>
        </div>
        <RecentScores defaultScoresPerPage={9} />
      </section>
    </Fragment>
  );
}

export default RecentScoresContainer;
