import React, { Component } from "react";
import PropTypes from "prop-types";
import jobStatus from "../../../../../shared/variables/job-status";
import LoaderButton from "../../../../../shared/components/buttons/LoaderButton";

class SuccessBody extends Component {
  render() {
    const { message, onBackToLogin, postStatus, onResendVerification } =
      this.props;
    const link = <a href="/contact-us">Golf Association</a>;
    return (
      <div className="center">
        <br />
        <br />
        <div className="success-icon" />
        <div className="success-title">{message}</div>
        <button className="btn fill cardinal center" onClick={onBackToLogin}>
          BACK TO LOG IN
        </button>
        <br />
        <br />
        <LoaderButton
          suffix="-reverse"
          className="btn outline blue center"
          onClick={onResendVerification}
          disabled={postStatus === jobStatus.PROCESSING}
          loading={String(postStatus === jobStatus.PROCESSING)}
        >
          RESEND LINK TO EMAIL
        </LoaderButton>

        <div className="field_place_holder" />
        <div className="bottom-link">
          If you no longer have access to your email address or did not receive
          the link, please contact your Club or {link}.
        </div>
        <br />
        <br />
      </div>
    );
  }
}

SuccessBody.propTypes = {
  message: PropTypes.string.isRequired,
  onBackToLogin: PropTypes.func.isRequired,
  onResendVerification: PropTypes.func.isRequired,
  postStatus: PropTypes.string.isRequired,
};

export default SuccessBody;
