import SelectCourse from "../../screens/handicap-calculator/components/SelectCourse";
import HandicapCalculator from "../../screens/handicap-calculator/components/HandicapCalculator";
import Calculator from "../../screens/handicap-calculator/components/calculator/Calculator";
import AddGolfer from "../../screens/handicap-calculator/components/add-golfer/AddGolfer";
import Following from "../../screens/handicap-calculator/components/add-golfer/Following";
import MyClubs from "../../screens/handicap-calculator/components/add-golfer/MyClubs";
import AllGolfers from "../../screens/handicap-calculator/components/add-golfer/AllGolfers";
import Manually from "../../screens/handicap-calculator/components/add-golfer/Manually";
import ManuallyCalculator from "../../screens/handicap-calculator/components/manually-calculator/ManuallyCalculator";
import Navigate from "../navigation/navigate";
import NoFooterLayout from "../components/layouts/NoFooterLayout";
import MyCoursesList from "../../screens/my-courses/components/MyCoursesList";

export default {
  handicapCourseCalculator: {
    path: "/handicap-calculator",
    component: HandicapCalculator,
    exact: false,
    rootPath: true,
    authRequired: true,
    layout: NoFooterLayout,
  },
  manuallyHandicapCalculator: {
    path: "/handicap-calculator/manually-input",
    component: ManuallyCalculator,
    exact: false,
    rootPath: false,
    authRequired: true,
    navigate: () => Navigate.toPath("/handicap-calculator/manually-input"),
  },
  handicapCalculatorSelectCourse: {
    path: "/handicap-calculator/select-course",
    component: SelectCourse,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  handicapCalculator: {
    path: "/handicap-calculator/:courseId",
    component: HandicapCalculator,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  handicapCalculatorCalculator: {
    path: "/handicap-calculator/:courseId/calculator",
    component: Calculator,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  handicapCalculatorAddGolfer: {
    path: "/handicap-calculator/:courseId/add-golfer",
    component: AddGolfer,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  handicapCalculatorAddGolferFollwing: {
    path: "/handicap-calculator/:courseId/add-golfer/following",
    component: Following,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  handicapCalculatorAddGolferMyClubs: {
    path: "/handicap-calculator/:courseId/add-golfer/my-clubs",
    component: MyClubs,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  handicapCalculatorAddGolferAllGolfers: {
    path: "/handicap-calculator/:courseId/add-golfer/all-golfers",
    component: AllGolfers,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  handicapCalculatorAddGolferManually: {
    path: "/handicap-calculator/:courseId/add-golfer/manually",
    component: Manually,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  handicapCalculatorMyCourses: {
    path: "/handicap-calculator/my-courses",
    component: MyCoursesList,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
};
