import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class Loader extends Component {
  render() {
    const padding = this.props.noPadding ? 0 : 15;
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: padding * 2,
            paddingBottom: padding,
          }}
        >
          <div className="loader" />
        </div>
      </Fragment>
    );
  }
}

Loader.propTypes = {
  noPadding: PropTypes.bool,
};

Loader.defaultProps = {
  noPadding: false,
};

export default Loader;
