import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_CONTACT_ASSOCIATIONS,
  FETCH_CONTACT_ASSOCIATIONS_SUCCESS,
  FETCH_CONTACT_ASSOCIATIONS_FAILED,
} from "../actions";

const initialState = {
  associations: [],
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CONTACT_ASSOCIATIONS:
      return {
        ...state,
        associations: [],
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_CONTACT_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        associations: payload.filter((a) => a.contact_association_email),
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_CONTACT_ASSOCIATIONS_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    default:
      return state;
  }
};
