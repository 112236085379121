import {
  FETCH_CALCULATOR_GOLFER_HANDICAP,
  fetchCalculatorGolferHandicapSuccess,
  fetchCalculatorGolferHandicapFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, flatMap } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_CALCULATOR_GOLFER_HANDICAP))
    .pipe(flatMap(({ payload }) => fetchCalculatorGolferHandicap(payload)));

const fetchCalculatorGolferHandicap = (payload) =>
  from(GolferApi.searchGolfer(payload.golferId))
    .pipe(
      map((response) =>
        fetchCalculatorGolferHandicapSuccess(
          response.data.golfer.handicap_index,
          payload.golferId
        )
      )
    )
    .pipe(
      catchError((error) =>
        of(fetchCalculatorGolferHandicapFailed(payload.golferId, error))
      )
    );
