export const MY_PROFILE_FETCH = "MY_PROFILE_FETCH";
export const MY_PROFILE_FETCH_FAILED = "MY_PROFILE_FETCH_FAILED";
export const MY_PROFILE_FETCH_SUCCESS = "MY_PROFILE_FETCH_SUCCESS";
export const TOGGLE_SECONDARY_ADDRESS = "TOGGLE_SECONDARY_ADDRESS";

export const fetchMyProfile = (payload) => {
  return {
    type: MY_PROFILE_FETCH,
    payload,
  };
};

export const fetchMyProfileFailed = (payload) => ({
  type: MY_PROFILE_FETCH_FAILED,
  payload,
});

export const fetchMyProfileSuccess = (payload) => ({
  type: MY_PROFILE_FETCH_SUCCESS,
  payload,
});

export const toggleSecondaryAddress = (flag) => ({
  type: TOGGLE_SECONDARY_ADDRESS,
  payload: { flag },
});
