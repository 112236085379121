import jobStatus from "../../../shared/variables/job-status";
import { parseAuthError } from "../../../shared/helpers/api-helper";

import {
  LOGIN_WITH_GHIN,
  LOGIN_WITH_GHIN_SUCCESS,
  LOGIN_WITH_GHIN_FAILED,
} from "../actions";

const initialState = {
  error: undefined,
  postStatus: jobStatus.NOT_STARTED,
  loginWithGhinToken: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_WITH_GHIN:
      return {
        ...state,
        postStatus: jobStatus.PROCESSING,
      };
    case LOGIN_WITH_GHIN_SUCCESS: {
      return {
        ...state,
        loginWithGhinToken: payload.golfer_user_token,
        postStatus: jobStatus.SUCCESS,
      };
    }
    case LOGIN_WITH_GHIN_FAILED:
      return {
        ...state,
        postStatus: jobStatus.FAILED,
        error: parseAuthError(payload?.errors),
      };
    default:
      return state;
  }
};
