import {
  ACCEPT_TERMS,
  acceptTermsSuccess,
  acceptTermsFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$.pipe(ofType(ACCEPT_TERMS)).pipe(switchMap(() => action()));

const action = () =>
  from(GolferApi.acceptTerms())
    .pipe(map(() => acceptTermsSuccess()))
    .pipe(catchError((error) => of(acceptTermsFailed(error.response?.data))));
