import Golfer from "../../screens/golfer/components/Golfer";

export default {
  golfer: {
    path: `/golfer-lookup/golfer/:id/club/:club_id`,
    component: Golfer,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
};
