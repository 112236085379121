import React, { Component, Fragment } from "react";
import { Redirect, Route, Switch, matchPath } from "react-router-dom";
import { routes } from "../../../shared/routes/index";
import PromoBanner from "../../../shared/components/banners/PromoBanner";
// import Footer from "../../footer/components/Footer";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import {
  SELECT_COURSE_H_CALC_TITLE,
  SELECT_COURSE_H_CALC_DESC,
} from "../../../shared/variables/meta-seo";

class HandicapCalculator extends Component {
  isFooterVisible = () =>
    !matchPath(
      this.props.location.pathname,
      routes.handicapCalculatorAddGolfer.path
    );

  render = () => (
    <Fragment>
      <Helmet>
        <title>{SELECT_COURSE_H_CALC_TITLE}</title>
        <meta name="description" content={SELECT_COURSE_H_CALC_DESC} />
      </Helmet>
      <div className="container has-adds">
        <PromoBanner />
        <section id="handicap_calculator" className="golfer__list">
          <Switch>
            <Redirect
              exact
              from={routes.handicapCourseCalculator.path}
              to={routes.handicapCalculatorSelectCourse.path}
            />
            <Route {...routes.handicapCalculatorCalculator} />
            <Route {...routes.handicapCalculatorMyCourses} />
            <Route {...routes.handicapCalculatorAddGolfer} />
            <Route {...routes.handicapCalculatorSelectCourse} />
            <Route {...routes.manuallyHandicapCalculator} />
          </Switch>
        </section>
      </div>
      {/* {this.isFooterVisible() && <Footer />} */}
    </Fragment>
  );
}

HandicapCalculator.propTypes = {
  location: PropTypes.object.isRequired,
};

export default HandicapCalculator;
