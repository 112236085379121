import React, { Component } from "react";
import PropTypes from "prop-types";

class DesktopHeader extends Component {
  render() {
    const {
      onToggleAdvancedStats,
      onClearAllScores,
      onClearAllStats,
      isClearAllStatsVisible,
    } = this.props;
    return (
      <div className="box-pre-body">
        <div className="switch-container">
          <input type="checkbox" name="advanced-stats" id="advanced-stats" />
          <label htmlFor="advanced-stats">
            <span>Advanced Stats</span>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span className="switch" onClick={onToggleAdvancedStats} />
            <span className="switch-status" />
          </label>
        </div>
        <ul className="table-controls tablet-and-above">
          <li>
            <button
              className="btn blank medium no-pad"
              onClick={onClearAllScores}
            >
              Clear All Scores
            </button>
          </li>
          {isClearAllStatsVisible && (
            <li>
              <button
                className="btn blank medium no-pad"
                onClick={onClearAllStats}
              >
                Clear All Stats
              </button>
            </li>
          )}
        </ul>
      </div>
    );
  }
}
DesktopHeader.propTypes = {
  onToggleAdvancedStats: PropTypes.func.isRequired,
  onClearAllScores: PropTypes.func.isRequired,
  onClearAllStats: PropTypes.func.isRequired,
  isClearAllStatsVisible: PropTypes.bool.isRequired,
};

export default DesktopHeader;
