import courseDetailsFetchReducer from "./course-details-fetch";
import courseHandicapFetchReducer from "./course-handicap-fetch";
import courseHandicapManualFetchReducer from "./course-handicap-manual-fetch";
import maxHbhScoreFetchReducer from "./max-hbh-score-fetch";
import postTotalScoreReducer from "./post-total-score";
import postScoreConfirmationReducer from "./post-score-confirmation";
import postHbhScoreReducer from "./post-hbh-score";
import postEditRoundStatsReducer from "./post-edit-round-stats";
import { combineReducers } from "redux";

const reducers = combineReducers({
  courseDetailsFetchReducer,
  courseHandicapFetchReducer,
  maxHbhScoreFetchReducer,
  courseHandicapManualFetchReducer,
  postTotalScoreReducer,
  postScoreConfirmationReducer,
  postHbhScoreReducer,
  postEditRoundStatsReducer,
});

export default reducers;
