import React, { Component, Fragment } from "react";
import NavigationLoggedLinks from "./NavigationLoggedLinks";
import { isUserLoggedIn } from "../../../../shared/helpers/user-helper";
import NavigationNotLoggedLinks from "./NavigationNotLoggedLinks";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Navigation extends Component {
  renderNavigation = (loggedIn) => {
    var navigation = loggedIn ? (
      <NavigationLoggedLinks />
    ) : (
      <NavigationNotLoggedLinks />
    );

    return (
      <Fragment>
        <nav className="navigation">
          <div className="container">{navigation}</div>
        </nav>
      </Fragment>
    );
  };

  render() {
    var is_trial = false;
    if (this.props.profile) is_trial = this.props.profile.is_trial;
    var navigationContainer = null;
    if (this.props.isLoggedIn())
      navigationContainer =
        this.props.golferActive && !is_trial
          ? this.renderNavigation(true)
          : null;
    else navigationContainer = this.renderNavigation(false);

    return navigationContainer;
  }
}

Navigation.propTypes = {
  isLoggedIn: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = ({ authReducer, profileReducer }) => ({
  user: authReducer.loginReducer.user,
  isLoggedIn: isUserLoggedIn,
  golferActive: profileReducer.golferProfileFetchReducer.golferActive,
  profile: profileReducer.golferProfileFetchReducer.golferProfile,
});

export default connect(mapStateToProps, null)(Navigation);
