// This file contains the contants of title and descr. meta tags

// Login Page
export const LOGIN_TITLE =
  "GHIN.COM | USGA's Golf Handicap Information Network";
export const LOGIN_DESC =
  "The official website of the United States Golf Association's (USGA) Golf Handicap Information Network (GHIN).  Registered GHIN Golfers can login to post scores, track statistics, calculate Course & Playing Handicaps, lookup golfers and more!";
// Login/Create Profile
export const CREATE_PROFILE_TITLE = "Create Profile";
export const CREATE_PROFILE_DESC =
  "Registered GHIN golfers can create a profile by verifying their email address, last name and GHIN Number.";
// Forgot Login
export const FORGOT_LOGIN_TITLE = "Forgot Login Information";
export const FORGOT_LOGIN_DESC =
  "GHIN golfers who have created profiles can provide their email address or GHIN Number to reset their password.";
// GHIN Associations
export const GHIN_ASSOCIATIONS_TITLE = "GHIN Associations";
export const GHIN_ASSOCIATIONS_DESC =
  "View a list of GHIN Associations and their contact information.  Contact your GHIN Association to find out more about how to become a member of a club using the GHIN service.";
// Contact Us
export const CONTACT_US_TITLE = "Contact Us";
export const CONTACT_US_DESC =
  "Contact your Golf Association to assist you with any questions or issues. ";
// Homepage
export const HOMEPAGE_TITLE = "GHIN: Golf Handicap Information Network";
export const HOMEPAGE_DESC =
  "The official website of the United States Golf Association's (USGA) Golf Handicap Information Network (GHIN)";
// Select Course: Post Total Score
export const SELECT_COURSE_POST_TOTAL_TITLE = "Select Course";
export const SELECT_COURSE_POST_TOTAL_DESC =
  "Select a course in order to post a total score to GHIN.";
// Manually Post Score
export const MANUALLY_POST_SCORE_TITLE = "Manually Post Total Score";
export const MANUALLY_POST_SCORE_DESC =
  "Manually post a total score to GHIN by providing a course name, Course Rating and Slope Rating.";
// Post Total Score
export const POST_TOTAL_SCORE_TITLE = "Post Total Score";
export const POST_TOTAL_SCORE_DESC =
  "Post a 9-hole or 18-hole total score to GHIN.";
// Round Summary (Total Score)
export const RS_TOTAL_TITLE = "Round Summary";
export const RS_TOTAL_DESC = "Review a summary of your golf round.";
// Select Course: Post HBH
export const SELECT_COURSE_POST_HBH_TITLE = "Select Course";
export const SELECT_COURSE_POST_HBH_DESC =
  "Select a course in order to post a hole-by-hole score to GHIN.";
// HBH Round Setup
export const HBH_ROUND_SETUP_TITLE = "Round Setup";
export const HBH_ROUND_SETUP_DESC =
  "Setup your round information by selecting tees, date played, etc. prior to posting your hole-by-hole score.";
// Post Hole-by-Hole Score
export const POST_HBH_TITLE = "Post Hole-by-Hole Score";
export const POST_HBH_DESC =
  "Post your hole-by-hole and keep track of statistics such as number of Putts, Approach Shot Accuracy and Driving Accuracy.";
// Round Summary (HBH Score)
export const RS_HBH_TITLE = "Round Summary";
export const RS_HBH_DESC =
  "Review a scoring summary of your round as well as visualization of advanced statistics. ";
// Golfer Lookup - Following
export const GL_FOLLOWING_TITLE = "Golfer Lookup - Following";
export const GL_FOLLOWING_DESC =
  "List of followed golfers to quickly view their Handicap Index information and recent scores.";
// Golfer Lookup - My Clubs
export const GL_MY_CLUBS_TITLE = "Golfer Lookup - My Clubs";
export const GL_MY_CLUBS_DESC =
  "Lookup golfers within your Club to view their Handicap Index information and recent scores.";
// Golfer Lookup - All Golfers
export const GL_ALL_GOLFERS_TITLE = "Golfer Lookup - All Golfers";
export const GL_ALL_GOLFERS_DESC =
  "Search golfers in particular states or countries to view their Handicap Index information and recent scores.";
//Golfer Detail
export const GOLFER_DETAIL_TITLE = "Golfer Detail";
export const GOLFER_DETAIL_DESC =
  "View golfers Handicap Index information and recent scores.";
// Stats - Score History
export const STATS_SC_HISTORY_TITLE = "Score History";
export const STATS_SC_HISTORY_DESC =
  "Review your recent and revision scores, scores for the calendar year, scores for a particular course/tee, etc.";
// Stats - Stats
export const STATS_STATS_TITLE = "Stats";
export const STATS_STATS_DESC =
  "Review your scoring statistics as well as aggregated advanced statistics such as number of Putts, Approach Shot Accuracy and Driving Accuracy.";
// Views Scores / Edit & Add Stats
export const VW_SC_EDIT_ADD_STATS_TITLE = "Scorecard";
export const VW_SC_EDIT_ADD_STATS_DESC =
  "View your scorecard and edit and add statistics for your hole-by-hole score.";
// Select Course: Handicap Calculator
export const SELECT_COURSE_H_CALC_TITLE = "Select Course";
export const SELECT_COURSE_H_CALC_DESC =
  "Select a course prior to calculating your Course & Playing Handicaps.";
// Manually Calculate Handicap
export const MANUALLY_CALC_H_TITLE = "Manual Handicap Calculator";
export const MANUALLY_CALC_H_DESC =
  "Manually calculate a Course & Playing Handicap by providing a Handicap Index, Course Rating, Slope Rating and Par. ";
// Handicap Calculator
export const H_CALC_TITLE = "Handicap Calculator";
export const H_CALC_DESC =
  "Calculate Course Handicaps & Playing Handicaps for you and fellow golfers in order to determine Shots Off for a match or game. ";
// Handicap Calculator - Following
export const H_CALC_FOLLOWING_TITLE = "Handicap Calculator - Following";
export const H_CALC_FOLLOWING_DESC =
  "Quickly add followed golfers to your Handicap Calculator.";
// Handicap Calculator - My Clubs
export const H_CALC_MY_CLUBS_TITLE = "Handicap Calculator - My Clubs";
export const H_CALC_MY_CLUBS_DESC =
  "Search and add golfers from your Club to your Handicap Calculator.";
// Handicap Calculator - All Golfers
export const H_CALC_ALL_GOLFERS_TITLE = "Handicap Calculator - All Golfers";
export const H_CALC_ALL_GOLFERS_DESC =
  "Search and add golfers in particular states and countries to your Handicap Calculator.";
// Handicap Calculator - Manually Input
export const H_CALC_MAN_INPUT_TITLE = "Handicap Calculator - Manually Input";
export const H_CALC_MAN_INPUT_DESC =
  "Manually input golfers' Handicap Index information to add to your Handicap Calculator. ";
// Handicap History
export const H_HISTORY_TITLE = "Handicap History";
export const H_HISTORY_DESC =
  "Review your Handicap Index revision history over the past 12 months.";
// My Card
export const MY_CARD_TITLE = "My Card";
export const MY_CARD_DESC =
  "View your card which includes your GHIN Number as well as your current Handicap Index and Low Handicap Index.";
// My Profile
export const MY_PROFILE_TITLE = "My Profile";
export const MY_PROFILE_DESC =
  "View and edit your profile information such as email address, mailing address and phone number.";
