import {
  fetchGolferProfileHandicap,
  fetchGolferProfileHandicapFailed,
} from "../actions";

export const FETCH_GOLFER_PROFILE_HANDICAP_LOWHI =
  "FETCH_GOLFER_PROFILE_HANDICAP_LOWHI";
export const FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_FAILED =
  "FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_FAILED";
export const FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_SUCCESS =
  "FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_SUCCESS";

export function fetchGolferProfileHandicapLowhi() {
  return (dispatch) => {
    dispatch(fetchGolferProfileHandicapFailed());
    dispatch({ type: FETCH_GOLFER_PROFILE_HANDICAP_LOWHI });
  };
}

export function fetchGolferProfileHandicapLowhiFailed() {
  return (dispatch) => {
    dispatch({ type: FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_FAILED });
    dispatch(fetchGolferProfileHandicap());
  };
}

export function fetchGolferProfileHandicapLowhiSuccess(payload) {
  return (dispatch) => {
    dispatch({ type: FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_SUCCESS, payload });
    dispatch(fetchGolferProfileHandicap());
  };
}
