import { checkApiError } from "../../auth/actions";

export const POST_HBH_SCORE = "POST_HBH_SCORE";
export const POST_HBH_SCORE_FAILED = "POST_HBH_SCORE_FAILED";
export const POST_HBH_SCORE_SUCCESS = "POST_HBH_SCORE_SUCCESS";
export const POST_HBH_SCORE_RESET = "POST_HBH_SCORE_RESET";

export const postHbhScore = (payload) => ({
  type: POST_HBH_SCORE,
  payload: { ...payload },
});

export function postHbhScoreFailed(error) {
  return (dispatch) => {
    dispatch({ type: POST_HBH_SCORE_FAILED, payload: error });
    dispatch(checkApiError(error));
  };
}

export const postHbhScoreSuccess = (payload) => ({
  type: POST_HBH_SCORE_SUCCESS,
  payload,
});

export const postHbhScoreReset = (payload) => ({
  type: POST_HBH_SCORE_RESET,
  payload,
});
