import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  focusFirstScoreInput,
  focusNextScoreInput,
  getScoreMarkupClass,
  getSideAdjScore,
  getSideHoles,
  getSidePar,
  getSideScore,
  getSideYardage,
  normalizeScore,
} from "../../helpers/post-hbh-score-helper";
import { setScore } from "../../validations/post-hbh-score-calculation";
import ratingType from "../../../../shared/variables/rating-type";

class PostHbhTable extends Component {
  componentDidMount = () => {
    const { autoFocusScore, scoreInputRefs, options } = this.props;

    if (autoFocusScore === true) {
      focusFirstScoreInput(0, scoreInputRefs, options);
    }
  };

  getTotalYardValue() {
    const { options, side, roundSide } = this.props;
    if (options.values.totalCombinedScores)
      return options.values.totalCombinedScores.yards;
    return roundSide === ratingType.TOTAL
      ? options.values.totalYards
      : getSideYardage(options, side);
  }

  getTotalParValue() {
    const { options, side, roundSide } = this.props;
    if (options.values.totalCombinedScores)
      return options.values.totalCombinedScores.pars;
    return roundSide === ratingType.TOTAL
      ? options.values.totalPar
      : getSidePar(options, side);
  }

  getTotalScoreValue() {
    const { options } = this.props;
    if (options.values.totalCombinedScores)
      return options.values.totalCombinedScores.scores;
    return options.values.totalScore;
  }

  getTotalAdjScoreValue() {
    const { options } = this.props;
    if (options.values.totalCombinedScores)
      return options.values.totalCombinedScores.adjScores;
    return options.values.totalAdjScore;
  }

  render() {
    const {
      options,
      side,
      scoreInputRefs,
      isScoreReadOnly,
      showSideTotalsColumn,
      lastTable,
    } = this.props;
    const holes = getSideHoles(options, side);
    return (
      <div className="data-table__wrapper">
        <div
          className={`data-table__scroll ${
            showSideTotalsColumn ? side : "Front"
          } ${lastTable ? "LastScoreTable" : ""}`}
          id={`post-hbh-score-table-${side}`}
        >
          <table className={"data-table scores " + side}>
            <thead>{this.renderHeader(holes)}</thead>
            <tbody>
              {this.renderRow(
                "YARDS",
                holes.map((hole, key) => <td key={key}>{hole.length}</td>),
                getSideYardage(options, side),
                this.getTotalYardValue()
              )}
              {this.renderRow(
                "PAR",
                holes.map((hole, key) => <td key={key}>{hole.par}</td>),
                getSidePar(options, side),
                this.getTotalParValue()
              )}
              {this.renderRow(
                <span>
                  STROKE <br /> INDEX
                </span>,
                holes.map((hole, key) => <td key={key}>{hole.strokeIndex}</td>)
              )}
              {this.renderRow(
                "SCORE",
                holes.map((hole, key) => (
                  <td key={key}>
                    <input
                      inputMode="numeric"
                      pattern="[0-9]*"
                      readOnly={isScoreReadOnly}
                      ref={(ref) => scoreInputRefs.push(ref)}
                      type="text"
                      name={`holes[${hole.nr - 1}].score`}
                      id={`holes[${hole.nr - 1}].score`}
                      value={isScoreReadOnly && !hole.score ? "-" : hole.score}
                      autoComplete="off"
                      className={getScoreMarkupClass(hole, hole.score)}
                      onChange={(event) => {
                        this.onScoreChanged(hole, event);
                      }}
                      onFocus={this.onScoreFocused}
                    />
                  </td>
                )),
                getSideScore(options, side),
                this.getTotalScoreValue()
              )}
              {this.renderRow(
                "ADJ. SCORE",
                holes.map((hole, key) => (
                  <td key={key}>
                    <input
                      type="text"
                      name={`holes[${hole.nr - 1}].adjScore`}
                      id={`holes[${hole.nr - 1}].adjScore`}
                      className={getScoreMarkupClass(hole, hole.adjScore)}
                      disabled
                      value={
                        isScoreReadOnly && !hole.adjScore ? "-" : hole.adjScore
                      }
                      onChange={hole.onChange}
                    />
                  </td>
                )),
                getSideAdjScore(options, side),
                this.getTotalAdjScoreValue()
              )}
            </tbody>
          </table>
        </div>
        <div className="fixed-shadow" />
      </div>
    );
  }

  renderHeader = (holes) => {
    const { showSideTotalsColumn, sideTotalsColumnTitle, showTotalsColumn } =
      this.props;

    return (
      <tr>
        <th className="fixed start">Hole</th>
        {holes.map((hole, key) => (
          <th key={key}>{hole.nr}</th>
        ))}
        {showSideTotalsColumn && (
          <th className={this.getSideTotalsClass()}>{sideTotalsColumnTitle}</th>
        )}
        {showTotalsColumn && <th className="fixed end">TOTAL</th>}
      </tr>
    );
  };

  renderRow = (title, holes, sideTotals = "", totals = "") => {
    const { showSideTotalsColumn, showTotalsColumn } = this.props;
    return (
      <tr>
        <td className="fixed start">{title}</td>
        {holes}
        {showSideTotalsColumn && (
          <td className={this.getSideTotalsClass()}>{sideTotals}</td>
        )}
        {showTotalsColumn && <td className="fixed end">{totals}</td>}
      </tr>
    );
  };

  getSideTotalsClass = () => {
    if (this.props.options.values.totalCombinedScores)
      return "fixed pre-end-total";
    return this.props.showTotalsColumn ? "fixed pre-end" : "fixed end";
  };

  onScoreChanged = (hole, event) => {
    const { options, scoreInputRefs } = this.props;
    const score = normalizeScore(hole, event);
    setScore(hole, options, score);
    this.props.onChangeScore(hole, options);
    focusNextScoreInput(hole, score, scoreInputRefs, options);
  };

  onScoreFocused = (event) => event.target.select();
}

PostHbhTable.defaultProps = {
  sideTotalsColumnTitle: "",
  autoFocusScore: false,
  lastTable: false,
};

PostHbhTable.propTypes = {
  options: PropTypes.object.isRequired,
  scoreInputRefs: PropTypes.array.isRequired,
  side: PropTypes.string.isRequired,
  roundSide: PropTypes.string.isRequired,
  showTotalsColumn: PropTypes.bool.isRequired,
  showSideTotalsColumn: PropTypes.bool.isRequired,
  sideTotalsColumnTitle: PropTypes.string.isRequired,
  isScoreReadOnly: PropTypes.bool.isRequired,
  autoFocusScore: PropTypes.bool,
  onChangeScore: PropTypes.func.isRequired,
  lastTable: PropTypes.bool,
};

export default PostHbhTable;
