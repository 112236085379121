import { checkApiError } from "../../auth/actions";

export const FETCH_CLUB_GOLFERS = "FETCH_CLUB_GOLFERS";
export const FETCH_CLUB_GOLFERS_NEXT = "FETCH_CLUB_GOLFERS_NEXT";
export const FETCH_CLUB_GOLFERS_FAILED = "FETCH_CLUB_GOLFERS_FAILED";
export const FETCH_CLUB_GOLFERS_SUCCESS = "FETCH_CLUB_GOLFERS_SUCCESS";

export const fetchClubGolfers = (clubId, filter, sortCriteria) => ({
  type: FETCH_CLUB_GOLFERS,
  payload: { clubId, filter, sortCriteria },
});

export function fetchClubGolfersFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_CLUB_GOLFERS_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchClubGolfersSuccess = (payload) => ({
  type: FETCH_CLUB_GOLFERS_SUCCESS,
  payload,
});

export const fetchClubGolfersNextPage = (
  clubId,
  filter,
  sortCriteria,
  page
) => ({
  type: FETCH_CLUB_GOLFERS_NEXT,
  payload: { clubId, filter, sortCriteria, page },
});
