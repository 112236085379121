import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_GOLFER_SCORES,
  FETCH_GOLFER_SCORES_SUCCESS,
  FETCH_GOLFER_SCORES_FAILED,
} from "../actions";

const initialState = {
  recentScores: [],
  revisionScores: [],
  nineHoleScores: [],
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_GOLFER_SCORES:
      return {
        ...state,
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_GOLFER_SCORES_SUCCESS:
      return {
        ...state,
        recentScores: payload.recent_scores.scores,
        revisionScores: payload.revision_scores.scores,
        nineHoleScores: payload["9_hole_score"].scores,
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_GOLFER_SCORES_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    default:
      return state;
  }
};
