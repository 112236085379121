import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoaderButton from "../../../../shared/components/buttons/LoaderButton";
import jobStatus from "../../../../shared/variables/job-status";
import GolferItem from "./Components/GolferItem";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import { routes } from "../../../../shared/routes/index";
import { selectGolfer, completeLogout, loadMinorGolfer } from "../../actions";

class SelectGolfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInactiveWarningOpen: false,
      selectedId: undefined,
      selectedGolfer: undefined,
      changeStatus: 0,
      changeStatusMessage: "",
    };
  }

  componentDidMount() {
    const { golfers, minors } = this.props;
    if (golfers.length === 0 && minors.length === 0) {
      routes.login.navigate();
    }
    if (golfers.length > 0 && minors.length > 0) {
      const guardianGolfer = golfers[0];
      this.setState({
        selectedGolfer: guardianGolfer,
        selectedId: guardianGolfer.ghin_number,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentUser } = nextProps;
    if (currentUser && currentUser !== this.props.currentUser) {
      this.onNavigateToHome();
    }
  }

  componentWillUnmount() {
    if (!this.props.currentUser) {
      this.props.completeLogout();
    }
  }

  onNavigateToHome = () => routes.profile.navigate();

  onChangeGolfer = () => {
    const { selectedGolfer } = this.state;
    if (selectedGolfer.isMinor) {
      if (selectedGolfer.golfer_status == "Active")
        this.props.loadMinorGolfer(selectedGolfer);
      else this.setState({ isInactiveWarningOpen: true });
    } else {
      if (selectedGolfer.status == "Active")
        this.props.selectGolfer(selectedGolfer);
      else this.setState({ isInactiveWarningOpen: true });
    }
  };

  onConfirmWarning = () => {
    const { selectedGolfer } = this.state;
    if (selectedGolfer.isMinor) {
      this.props.loadMinorGolfer(selectedGolfer);
    } else {
      this.props.selectGolfer(selectedGolfer);
    }
  };

  onSelectGolfer = (selectedGolfer) =>
    this.setState({
      selectedId: selectedGolfer.id || selectedGolfer.ghin_number,
      selectedGolfer,
    });

  renderGuardianGolfer() {
    const { golfers } = this.props;
    if (golfers && golfers.length > 0) {
      const status = golfers[0]["status"] == "Inactive" ? " (Inactive)" : "";
      return (
        <GolferItem
          golfer={golfers[0]}
          golferId={golfers[0].ghin_number}
          status={status}
          onSelect={this.onSelectGolfer}
          selectedId={this.state.selectedId}
        />
      );
    }
    return null;
  }

  renderActiveMinorsGolfers() {
    const { minors } = this.props;
    const activeGolfers = minors.filter(
      (golfer) => golfer.golfer_status === "Active"
    );
    return activeGolfers.map((golfer) => (
      <GolferItem
        key={JSON.stringify(golfer.id)}
        golfer={{ ...golfer, isMinor: true }}
        golferId={golfer.id}
        onSelect={this.onSelectGolfer}
        selectedId={this.state.selectedId}
      />
    ));
  }

  renderInactiveMinorsGolfers() {
    const { minors } = this.props;
    const inactiveGolfers = minors.filter(
      (golfer) => golfer.golfer_status === "Inactive"
    );
    return inactiveGolfers.map((golfer) => (
      <GolferItem
        key={JSON.stringify(golfer.id)}
        golfer={{ ...golfer, isMinor: true }}
        status={" (Inactive)"}
        golferId={golfer.id}
        onSelect={this.onSelectGolfer}
        selectedId={this.state.selectedId}
      />
    ));
  }

  render() {
    const { postStatus } = this.props;
    return (
      <Fragment>
        <div className="page_container create-profile-page">
          <div className="box-content">
            <h1>Select Golfer</h1>
          </div>
          <div className="box-panel center no-margins">
            <div className="select-golfer">
              <div className="golfer-item top">
                {this.renderGuardianGolfer()}
              </div>
              <div className="separator-label">MINOR ACCOUNTS</div>
              <div className="golfer-item">
                {this.renderActiveMinorsGolfers()}
              </div>
              <div className="golfer-item">
                {this.renderInactiveMinorsGolfers()}
              </div>

              <div className="bottom_action">
                <LoaderButton
                  type="submit"
                  className="btn fill cardinal"
                  onClick={this.onChangeGolfer}
                  disabled={this.state.selectedId === undefined}
                  loading={String(postStatus === jobStatus.PROCESSING)}
                >
                  Continue
                </LoaderButton>
              </div>
            </div>
          </div>
        </div>
        <YesNoModal
          onConfirm={this.onConfirmWarning}
          onAbort={this.onConfirmWarning}
          isOpen={this.state.isInactiveWarningOpen}
          isYesVisible={true}
          isNoVisible={false}
          yesTitle="Ok"
        >
          <h3>Inactive GHIN Membership</h3>
          <p>
            Your GHIN membership is inactive so you are no longer able to access
            some of the features within GHIN.com. Please contact your Club or
            Golf Association for assistance.
          </p>
        </YesNoModal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authReducer }) => ({
  currentUser: authReducer.loginReducer.user,
  golfers: authReducer.loginReducer.golfers,
  minors: authReducer.loginReducer.minors,
  inactiveMinors: authReducer.loginReducer.inactiveMinors,
  postStatus: authReducer.loginReducer.postStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { selectGolfer, completeLogout, loadMinorGolfer },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SelectGolfer);
