import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  fetchClubs,
  fetchClubGolfers,
  fetchFollowedGolfers,
  fetchClubGolfersNextPage,
} from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GolfersList from "../GolfersList";
import jobStatus from "../../../../shared/variables/job-status";
import Loader from "../../../../shared/components/loaders/Loader";
import LoadMore from "../../../../shared/components/loaders/LoadMore";
import HeaderFilter from "./HeaderFilter";

class MyClubs extends Component {
  state = {
    filter: "",
  };

  componentDidMount() {
    this.props.fetchClubs();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selectedClub !== nextProps.selectedClub) {
      this.setState({ filter: "" });
      this.props.fetchFollowedGolfers();
      if (nextProps.fetchFollowedStatus === jobStatus.SUCCESS) {
        const { filter } = this.state;
        const { selectedClub, sortCriteria } = nextProps;
        this.props.fetchClubGolfers(selectedClub.id, filter, sortCriteria);
      }
    }
  }

  loadMore = () => {
    const { filter } = this.state;
    const { selectedClub, nextPage, fetchClubGolfersNextPage, sortCriteria } =
      this.props;
    fetchClubGolfersNextPage(selectedClub.id, filter, sortCriteria, nextPage);
  };

  onSearch = (filter) => {
    this.setState({ filter });
    const { selectedClub, sortCriteria } = this.props;
    this.props.fetchClubGolfers(selectedClub.id, filter, sortCriteria);
  };

  render() {
    const {
      golfers,
      golfersFetchStatus,
      fetchClubsStatus,
      nextPage,
      fetchNextStatus,
    } = this.props;
    if (fetchClubsStatus === jobStatus.PROCESSING) return <Loader />;
    return (
      <Fragment>
        <div className="panel">
          <HeaderFilter filter={this.state.filter} onSearch={this.onSearch} />
          {golfersFetchStatus === jobStatus.PROCESSING ? (
            <Loader />
          ) : (
            <GolfersList golfers={golfers} renderItem={this.props.renderItem} />
          )}
        </div>
        <LoadMore
          onLoadMore={this.loadMore}
          nextPage={nextPage}
          fetchStatus={fetchNextStatus}
        />
      </Fragment>
    );
  }
}

MyClubs.propTypes = {
  fetchClubs: PropTypes.func.isRequired,
  fetchFollowedGolfers: PropTypes.func.isRequired,
  fetchClubGolfers: PropTypes.func.isRequired,
  fetchClubGolfersNextPage: PropTypes.func.isRequired,
  selectedClub: PropTypes.shape({}),
  golfers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  golfersFetchStatus: PropTypes.string,
  fetchFollowedStatus: PropTypes.string,
  fetchClubsStatus: PropTypes.string,
  renderItem: PropTypes.func.isRequired,
  nextPage: PropTypes.number.isRequired,
  fetchNextStatus: PropTypes.bool.isRequired,
  sortCriteria: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ golfersReducer }) => ({
  fetchClubsStatus: golfersReducer.clubsReducer.jobStatus,
  fetchFollowedStatus: golfersReducer.followedGolfersReducer.jobStatus,
  golfersFetchStatus: golfersReducer.clubGolfersReducer.jobStatus,
  selectedClub: golfersReducer.clubsReducer.selectedClub,
  golfers: golfersReducer.clubGolfersReducer.golfers,
  nextPage: golfersReducer.clubGolfersReducer.nextPage,
  fetchNextStatus: golfersReducer.clubGolfersReducer.fetchNextPage,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchClubs,
      fetchClubGolfers,
      fetchFollowedGolfers,
      fetchClubGolfersNextPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyClubs);
