import {
  FETCH_PLAYING_HANDICAPS,
  fetchPlayingHandicapsFailed,
  fetchPlayingHandicapsSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import CalculatorApi from "../../../services/supporting-calculation-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_PLAYING_HANDICAPS))
    .pipe(switchMap(({ payload }) => fetchPlayingHandicaps(payload)));

const fetchPlayingHandicaps = (payload) =>
  from(CalculatorApi.getPlayingHandicaps(payload.golfers))
    .pipe(map((response) => fetchPlayingHandicapsSuccess(response.data)))
    .pipe(catchError((error) => of(fetchPlayingHandicapsFailed(error))));
