import jobStatus from "../../../shared/variables/job-status";
import {
  CONTACT_PROFILE_FETCH,
  CONTACT_PROFILE_FETCH_FAILED,
  CONTACT_PROFILE_FETCH_SUCCESS,
} from "../actions";

const initialState = {
  golferProfile: undefined,
  fetchContactProfileStatus: jobStatus.NOT_STARTED,
};

const getProfileWithHomeClub = (payload) => {
  const mainProfile = payload.find((p) => p.is_home_club === true);
  if (mainProfile) return mainProfile;
  return payload[0];
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CONTACT_PROFILE_FETCH:
      return {
        ...state,
        fetchContactProfileStatus: jobStatus.PROCESSING,
      };
    case CONTACT_PROFILE_FETCH_FAILED:
      return {
        ...state,
        fetchContactProfileStatus: jobStatus.FAILED,
      };
    case CONTACT_PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        golferProfile: getProfileWithHomeClub(payload),
        fetchContactProfileStatus: jobStatus.SUCCESS,
      };
    default:
      return state;
  }
};
