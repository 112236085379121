import { LOGIN_WITH_GHIN, loginSuccess, loginFailed } from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(LOGIN_WITH_GHIN))
    .pipe(switchMap(({ payload }) => login(payload)));

const login = (payload) =>
  from(GolferApi.loginWithGhin(payload.password, payload.emailOrGhin))
    .pipe(map((response) => loginSuccess(response.data, payload)))
    .pipe(catchError((error) => of(loginFailed(error.response?.data))));
