import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { endOfYearModalStyle } from "../../../shared/helpers/ui-helper";

export default class EndOfYearCampaignModal extends Component {
  componentDidMount = () => Modal.setAppElement(this.props.appElementName);

  render() {
    const { isOpen, onClose, children } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={() => onClose()}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="end-of-year-modal modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={true}
        style={endOfYearModalStyle}
      >
        <Fragment>
          <div className="modal__container small end-of-year end-of-year-padding">
            <button
              className="modal__close"
              onClick={onClose}
              style={{ top: "3px", right: "1px" }}
            >
              <i className="material-icons-outlined">clear</i>
            </button>
            <div className="modal__body end-of-year-body">{children}</div>
          </div>
        </Fragment>
      </Modal>
    );
  }
}

EndOfYearCampaignModal.defaultProps = {
  appElementName: "body",
};

EndOfYearCampaignModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  appElementName: PropTypes.string.isRequired,
  children: PropTypes.object,
};
