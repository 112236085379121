import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { routes } from "../../../../shared/routes/index";
import LoaderButton from "../../../../shared/components/buttons/LoaderButton";
import jobStatus from "../../../../shared/variables/job-status";
import ReactTooltip from "react-tooltip";

class RegistrationForm extends Component {
  state = {
    showPassword: false,
    showGenderError: false,
  };
  formRef = React.createRef();

  validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    zipCode: Yup.string().required("ZIP Code is required"),
    gender: Yup.string().required("Required"),
  });

  handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(this.formRef.current);
    this.props.onSwitchForm(Object.fromEntries(formData));
  };

  handleOnClick = (e, valid) => {
    if (!this.state.genderSet || !valid) {
      this.setState({ showGenderError: true });
      e.preventDefault();
    }
  };

  componentDidMount = () => {
    const oldGender = this.props.oldData?.gender;
    if (oldGender) {
      document
        .getElementById(oldGender.toLowerCase())
        .parentNode.classList.add("gender-checked");
      this.setState({ genderSet: true });
      // console.log(document.getElementById(formData.gender.toLowerCase()));
      const event = new Event("click");
      const el = document.getElementById(oldGender.toLowerCase());
      el.checked = true;
      el.dispatchEvent(event);
      this.formOptions.setFieldValue("gender", oldGender, true);
    }
  };

  onToggleShowPassword = () =>
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));

  renderForm = (options) => (
    <Fragment>
      <div className="registration_form trial_form columns">
        <form ref={this.formRef} onSubmit={this.handleSubmit}>
          {this.renderTextInput("firstName", "FIRST NAME", options)}
          {this.renderTextInput("lastName", "LAST NAME", options)}
          <div className="row input-group">
            {this.renderTextInput("zipCode", "ZIP CODE", options, true)}
            {this.renderGenderInput("gender", options)}
          </div>
          <div
            style={{
              color: "gray",
              margin: "auto",
              top: "30px",
              paddingBottom: "30px",
              fontStyle: "italic",
              textAlign: "center",
            }}
          >
            NOTE: You must be 13 or older to register.
          </div>
          <LoaderButton
            className="btn fill cardinal background-focus"
            loading={String(this.props.postStatus === jobStatus.PROCESSING)}
            type="submit"
            disabled={false}
            onClick={(e) => {
              this.handleOnClick(e, options.isValid);
            }}
            style={{
              width: "50%",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            Continue
          </LoaderButton>
          <br></br>
          <div className="row">
            <div className="col is-full">
              <a
                className="lnk white color-focus"
                href={routes.login.path}
                style={{
                  textAlign: "center",
                  color: "dodgerblue",
                  fontWeight: "600",
                }}
              >
                GO BACK
              </a>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );

  renderTextInput = (name, label, options, halfWidth = false) => (
    <div
      className={`col ${halfWidth ? "is-half" : "is-full"}`}
      style={{ height: "90px" }}
    >
      <label
        htmlFor={name}
        style={{
          fontFamily: "National",
          fontSize: "14px",
          color: "#000000",
        }}
      >
        {label}
      </label>
      <input
        autoComplete="off"
        type="text"
        id={name}
        name={name}
        value={options.values[name]}
        onChange={options.handleChange}
        onBlur={options.handleBlur}
        aria-describedby={`${name}Error`}
        aria-invalid={options.errors[name] ? "true" : "false"}
        aria-required="true"
        style={{ marginBottom: "0px" }}
        className={halfWidth ? "half-width" : ""}
      />
      <span className="error" id={`${name}Error`}>
        {this.state.showGenderError || options.touched[name]
          ? options.errors[name]
          : ""}
      </span>
    </div>
  );

  renderGenderInput = (name, options) => {
    const handleGenderChange = (e) => {
      this.setState({ genderSet: true });
      options.handleChange(e);
      document
        .querySelectorAll(".gender-option-male, .gender-option-female")
        .forEach((label) => {
          label.classList.remove("gender-checked");
        });
      const parentLabel = e.target.parentNode;
      if (parentLabel) {
        parentLabel.classList.add("gender-checked");
      }
    };
    return (
      <div className="gender-inputs">
        <div className="col is-full">
          <label>
            <Fragment>
              <span
                style={{
                  fontFamily: "National",
                  fontSize: "14px",
                  color: "#000000",
                }}
              >
                SEX
              </span>
              <a
                href="#donothing"
                onClick={(e) => {
                  e.preventDefault();
                }}
                data-tip
                data-for="sex"
              >
                <i
                  className="material-icons-outlined"
                  style={{
                    color: "#00365f",
                    fontSize: "14px",
                    position: "absolute",
                    marginLeft: "3px",
                  }}
                >
                  info
                </i>
              </a>
              <ReactTooltip
                id="sex"
                className="table-head__tooltip"
                type="light"
                place="top"
                effect={"float"}
              >
                Sex is used for applying proper Ratings for score posting.
              </ReactTooltip>
            </Fragment>
          </label>
          <div className="gender-options">
            <label className="gender-option-male" htmlFor="male">
              <input
                type="radio"
                id="male"
                name={name}
                value="Male"
                checked={options.values[name] === "Male"}
                onChange={handleGenderChange}
                className="gender-radio"
              />
              <span className="gender-label">Male</span>
            </label>
            <label className="gender-option-female" htmlFor="female">
              <input
                type="radio"
                id="female"
                name={name}
                value="Female"
                checked={options.values[name] === "Female"}
                onChange={handleGenderChange}
                className="gender-radio"
              />
              <span className="gender-label">Female</span>
            </label>
          </div>
          {options.errors[name] && this.state.showGenderError && (
            <div className="error">{options.errors[name]}</div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <Formik
        initialValues={{
          firstName: this.props.oldData.firstName || "",
          lastName: this.props.oldData.lastName || "",
          zipCode: this.props.oldData.zipCode || "",
        }}
        validationSchema={this.validationSchema}
        onSubmit={this.onSubmit}
      >
        {(options) => {
          this.formOptions = options;
          return this.renderForm(options);
        }}
      </Formik>
    );
  }
}

RegistrationForm.propTypes = {
  registration: PropTypes.func.isRequired,
  postStatus: PropTypes.string.isRequired,
};

export default RegistrationForm;
