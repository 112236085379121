import React from "react";

function MainContentLink() {
  return (
    <a
      href="#main-content"
      className="hide-link rounded-border-focus"
      onFocus={() => {
        window.scroll(0, 0);
      }}
    >
      Skip to Main Content
    </a>
  );
}

export default MainContentLink;
