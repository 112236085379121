import {
  FETCH_GOLFER_PROFILE_HANDICAP,
  fetchGolferProfileHandicapSuccess,
  fetchGolferProfileHandicapFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";
import { getUser } from "../../../shared/helpers/user-helper";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_GOLFER_PROFILE_HANDICAP))
    .pipe(switchMap(() => getGolferHandicap()));

const getGolferHandicap = () =>
  from(GolferApi.getHandicapHistory(getUser().ghin_number))
    .pipe(
      map((response) =>
        fetchGolferProfileHandicapSuccess(response.data.handicap_revisions)
      )
    )
    .pipe(catchError(() => of(fetchGolferProfileHandicapFailed())));
