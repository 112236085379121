import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

class SelectedCourse extends Component {
  render() {
    const { course, selectCoursePath } = this.props;
    return (
      <div className="row">
        <div className="col is-full">
          <div className="selected">
            <label className="regular black">Course</label>
            <div className="selected__item">
              <div className="selected__details">
                <span>{this.renderFacilityName(course)}</span>
                <span>{this.renderCourseName(course)}</span>
              </div>
              <div className="selected__controls">
                <NavLink className="selected__control" to={selectCoursePath}>
                  Change
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFacilityName = (course) =>
    !course.Facility || course.Facility.FacilityName === course.CourseName
      ? ""
      : course.Facility.FacilityName;

  renderCourseName = (course) => course.CourseName;
}

SelectedCourse.propTypes = {
  course: PropTypes.object.isRequired,
  selectCoursePath: PropTypes.string.isRequired,
};

export default SelectedCourse;
