import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Banner extends Component {
  render() {
    const { location } = this.props;
    const shouldRenderTitle = location.pathname.includes("post-score");

    return (
      <div className="box-body posted-score">
        {shouldRenderTitle && (
          <h4 className="posted-score__title" role="alert">
            Your score has been posted!
          </h4>
        )}

        <div className="powered-by__container">
          <div className="powered-by no-auto center-on-tablet">
            <span>This round summary is powered by</span>
            <img
              className="powered-by__logo cisco"
              src="/img/logos/logo_cisco.svg"
              alt="powered-by-rolex"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Banner);
