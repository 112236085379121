import React, { Component } from "react";
import AddCourseListItem from "./AddCourseListItem";

export default class AddCoursesList extends Component {
  render = () => (
    <ul className="lookup__list">
      {this.props.courses.map((course, key) => (
        <AddCourseListItem
          key={key}
          course={course}
          searchMode={this.props.searchMode}
          alertMax={this.props.alertMax}
          searchPhrase={this.props.searchPhrase}
          navLink={this.props.navLinkPath.replace(
            ":courseId",
            course.course_id || course.CourseID || course.CourseId
          )}
        />
      ))}
    </ul>
  );
}
