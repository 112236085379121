import { combineEpics } from "redux-observable";
import clubsEpic from "./clubs-fetch";
import clubgolfersEpic from "./club-golfers-fetch";
import countriesEpic from "./countries-states-fetch";
import allGolfersEpic from "./all-golfers-fetch";
import followedGolfersEpic from "./followed-golfers-fetch.js";
import followGolferEpic from "./follow-golfer.js";
import unfollowGolferEpic from "./unfollow-golfer.js";

export default combineEpics(
  clubsEpic,
  clubgolfersEpic,
  countriesEpic,
  allGolfersEpic,
  followedGolfersEpic,
  followGolferEpic,
  unfollowGolferEpic
);
