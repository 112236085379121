/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import Loader from "../../../../shared/components/loaders/Loader";
import GolferApi from "../../../../services/golfer-api";
import { concatUserFullName } from "../../../../shared/helpers/user-helper";

class ChooseAccount extends Component {
  state = {
    nonGolfingGuardian: undefined,
  };

  componentDidMount() {
    const { guardianId, golfer, receiveResponseError } = this.props;
    if (guardianId && !golfer) {
      GolferApi.getGuardianData(guardianId)
        .then((response) => {
          this.setState({ nonGolfingGuardian: response?.data?.guardian });
        })
        .catch((error) => {
          receiveResponseError(error?.response?.data?.error);
        });
    }
  }

  renderGolfer() {
    const { golfer, authorizeUser } = this.props;
    if (golfer) {
      return (
        <div className="choose-option" onClick={authorizeUser}>
          <strong>{golfer.player_name}</strong>
          {golfer.email && <span>{golfer.email}</span>}
          {golfer.ghin_number && <span>GHIN Number: {golfer.ghin_number}</span>}
        </div>
      );
    }
    return null;
  }

  renderNonGolfingGuardian() {
    const { guardianId, authorizeUser, golfer } = this.props;
    const { nonGolfingGuardian } = this.state;
    if (nonGolfingGuardian) {
      return (
        <div className="choose-option" onClick={authorizeUser}>
          <strong>
            {concatUserFullName(
              nonGolfingGuardian.prefix,
              nonGolfingGuardian.first_name,
              nonGolfingGuardian.middle_name,
              nonGolfingGuardian.last_name,
              nonGolfingGuardian.suffix
            )}
          </strong>
          {nonGolfingGuardian.email && <span>{nonGolfingGuardian.email}</span>}
        </div>
      );
    }
    return guardianId && !golfer ? <Loader /> : null;
  }

  render() {
    const { useAnotherAccount } = this.props;
    return (
      <section className="page_container">
        <div className="box">
          <div className="box-head">
            <h3 className="box-title">
              Choose an account{" "}
              <span>
                to continue to <strong>GHIN</strong>
              </span>
            </h3>
          </div>
          <div className="box-body">
            <div className="form columns">
              <div className="choose">
                <div className="choose_body">
                  {this.renderGolfer()}
                  {this.renderNonGolfingGuardian()}
                </div>
                <div className="choose_footer">
                  <button
                    onClick={useAnotherAccount}
                    className="btn outline blue full"
                  >
                    Use another account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ChooseAccount;
