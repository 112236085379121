import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import DesktopHeader from "./DesktopHeader";
import Box from "../../../../shared/components/box/Box";
import BoxBody from "../../../../shared/components/box/BoxBody";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import PostHbhForm from "../../../post-score/components/PostHbhScore/PostHbhForm";
import { connect } from "react-redux";
import {
  fetchCourseDetails,
  fetchCourseDetailsReset,
  fetchMaxHbhScore,
  fetchMaxHbhScoreReset,
  postEditRoundStats,
  postEditRoundStatsReset,
} from "../../actions";
import { bindActionCreators } from "redux";
import { getUserGhinNo } from "../../../../shared/helpers/user-helper";
import jobStats from "../../../../shared/variables/job-status";
import Loader from "../../../../shared/components/loaders/Loader";
import { convertScoreToRoundSetup } from "../../helpers/edit-round-stats-helper";
import ClearAllStatsModal from "../Shared/Modals/ClearAllStatsModal";
import ratingType from "../../../../shared/variables/rating-type";
import MobileHeader from "./MobileHeader";
import teeSetSide from "../../../../shared/variables/tee-set-side";
import { postScoreMode } from "../../variables/post-score-variables";
import PostInvalidScoreModal from "../Shared/Modals/PostInvalidScoreModal";
import { Helmet } from "react-helmet";
import {
  VW_SC_EDIT_ADD_STATS_TITLE,
  VW_SC_EDIT_ADD_STATS_DESC,
} from "../../../../shared/variables/meta-seo";

class EditRoundStats extends Component {
  formRef;

  constructor(props) {
    super(props);
    this.state = {
      withAdvancedStats: false,
      isClearAllStatsModalOpen: false,
      invalidScoreModalOpen: false,
      invalidScoreModalMessage: "",
      errorModalOpen: false,
    };
  }

  componentDidMount() {
    const { fetchMaxHbhScore, fetchCourseDetails, score } = this.props;
    fetchMaxHbhScore(
      getUserGhinNo(),
      score.tee_set_id,
      score.tee_set_side,
      score.played_at
    );
    fetchCourseDetails(score.course_id, true);
  }

  componentDidUpdate(prevProps) {
    const { onConfirm, editedScore } = this.props;
    if (this.state.withAdvancedStats === false && this.isApiDataReceived()) {
      this.setState({ withAdvancedStats: true });
      this.formRef.showAdvancedStats();
    }

    if (this.isPostEditRoundStatsSuccess(prevProps)) {
      onConfirm(editedScore);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fetchMaxHbhScoreStatus } = nextProps;
    if (
      this.props.fetchMaxHbhScoreStatus !== fetchMaxHbhScoreStatus &&
      fetchMaxHbhScoreStatus === jobStats.FAILED
    ) {
      this.setState({ errorModalOpen: true });
    }
  }

  isPostEditRoundStatsSuccess = (prevProps) =>
    this.props.postEditRoundStatsStatus !==
      prevProps.postEditRoundStatsStatus &&
    this.props.postEditRoundStatsStatus === jobStats.SUCCESS;

  onCloseErrorModal = () => {
    this.setState({ errorModalOpen: false });
    this.props.onCancel();
  };

  render = () => {
    const {
      maxHbhScore,
      course,
      postEditRoundStats,
      score,
      onCancel,
      onlyView,
      golferActive,
    } = this.props;

    const {
      isClearAllStatsModalOpen,
      invalidScoreModalOpen,
      invalidScoreModalMessage,
      errorModalOpen,
    } = this.state;

    const title = onlyView ? "View Scorecard" : "Edit Round Stats";
    return this.isApiDataReceived() ? (
      <Fragment>
        <Helmet>
          <title>{VW_SC_EDIT_ADD_STATS_TITLE}</title>
          <meta name="description" content={VW_SC_EDIT_ADD_STATS_DESC} />
        </Helmet>
        <section className="post-score">
          <Box>
            {golferActive && (
              <DesktopHeader
                title={title}
                onClearAllStats={() =>
                  this.setState({ isClearAllStatsModalOpen: true })
                }
              />
            )}
            <BoxBody isTabletMod={true}>
              <MobileHeader
                onClearAllStats={() =>
                  this.setState({ isClearAllStatsModalOpen: true })
                }
                side={this.getMobileHeaderSide()}
              />
              <PostHbhForm
                ref={(ref) => (this.formRef = ref)}
                maxHbhScore={maxHbhScore}
                roundSetup={convertScoreToRoundSetup(score, course)}
                courseDetails={course}
                onSubmit={(values) => postEditRoundStats(score, values)}
                onAbort={onCancel}
                isScoreReadOnly={true}
                initialHoleValues={score.hole_details}
                postScoreMode={postScoreMode.EDIT}
                alertInvalidScore={this.alertInvalidScore}
                viewScoreCard={onlyView}
              />
            </BoxBody>
          </Box>
        </section>
        <PostInvalidScoreModal
          message={invalidScoreModalMessage}
          isOpen={invalidScoreModalOpen}
          onClose={() => this.setState({ invalidScoreModalOpen: false })}
        />
        <ClearAllStatsModal
          isOpen={isClearAllStatsModalOpen}
          onAbort={() => this.setState({ isClearAllStatsModalOpen: false })}
          onConfirm={() => {
            this.setState({ isClearAllStatsModalOpen: false });
            this.formRef.clearAllStats();
          }}
        />
      </Fragment>
    ) : (
      <Fragment>
        <Loader />
        <YesNoModal
          onConfirm={this.onCloseErrorModal}
          onAbort={this.onCloseErrorModal}
          isOpen={errorModalOpen}
          isNoVisible={false}
          yesTitle="Ok"
        >
          <p>Unable to retrieve scorecard. The Tee Set is no longer active.</p>
        </YesNoModal>
      </Fragment>
    );
  };

  isApiDataReceived = () =>
    this.props.fetchMaxHbhScoreStatus === this.props.fetchCourseDetailsStatus &&
    this.props.fetchMaxHbhScoreStatus === jobStats.SUCCESS;

  getMobileHeaderSide = () =>
    this.props.score.tee_set_side !== teeSetSide.Back
      ? ratingType.FRONT
      : ratingType.BACK;

  alertInvalidScore = (message) => {
    this.setState({
      invalidScoreModalOpen: true,
      invalidScoreModalMessage: message,
    });
  };

  componentWillUnmount() {
    this.props.fetchCourseDetailsReset();
    this.props.fetchMaxHbhScoreReset();
    this.props.postEditRoundStatsReset();
  }
}

EditRoundStats.propTypes = {
  score: PropTypes.object.isRequired,
  maxHbhScore: PropTypes.array.isRequired,
  fetchMaxHbhScoreStatus: PropTypes.string.isRequired,
  course: PropTypes.object.isRequired,
  fetchCourseDetailsStatus: PropTypes.string.isRequired,
  fetchMaxHbhScore: PropTypes.func.isRequired,
  fetchMaxHbhScoreReset: PropTypes.func.isRequired,
  editedScore: PropTypes.object,
  postEditRoundStats: PropTypes.func.isRequired,
  postEditRoundStatsReset: PropTypes.func.isRequired,
  postEditRoundStatsStatus: PropTypes.string.isRequired,
  fetchCourseDetails: PropTypes.func.isRequired,
  fetchCourseDetailsReset: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onlyView: PropTypes.bool,
};

EditRoundStats.defaultProps = {
  onlyView: false,
};

const mapStateToProps = ({ postScoreReducer, profileReducer }) => ({
  maxHbhScore: postScoreReducer.maxHbhScoreFetchReducer.maxHbhScore,
  postEditRoundStatsStatus:
    postScoreReducer.postEditRoundStatsReducer.postEditRoundStatsStatus,
  editedScore: postScoreReducer.postEditRoundStatsReducer.editedScore,
  golferActive: profileReducer.golferProfileFetchReducer.golferActive,
  fetchMaxHbhScoreStatus:
    postScoreReducer.maxHbhScoreFetchReducer.fetchMaxHbhScoreStatus,
  course: postScoreReducer.courseDetailsFetchReducer.course,
  fetchCourseDetailsStatus:
    postScoreReducer.courseDetailsFetchReducer.fetchCourseDetailsStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMaxHbhScore,
      fetchMaxHbhScoreReset,
      fetchCourseDetails,
      fetchCourseDetailsReset,
      postEditRoundStats,
      postEditRoundStatsReset,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditRoundStats);
