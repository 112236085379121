import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

class NoFooterAndHeaderLayout extends Component {
  render = () => {
    return (
      <Fragment>
        <Switch>
          {this.props.routes.map((route, key) => (
            <Route key={key} {...route} />
          ))}
        </Switch>
      </Fragment>
    );
  };
}

NoFooterAndHeaderLayout.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default NoFooterAndHeaderLayout;
