import { profileWithSecondaryAddress } from "../../../shared/helpers/my-profile-helper";
import jobStatus from "../../../shared/variables/job-status";

import {
  MY_PROFILE_FETCH,
  MY_PROFILE_FETCH_SUCCESS,
  MY_PROFILE_FETCH_FAILED,
  TOGGLE_SECONDARY_ADDRESS,
} from "../actions";

const initialState = {
  status: jobStatus.NOT_STARTED,
  profile: undefined,
  error: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MY_PROFILE_FETCH:
      return {
        ...state,
        status: jobStatus.PROCESSING,
      };
    case MY_PROFILE_FETCH_SUCCESS: {
      return {
        ...state,
        status: jobStatus.SUCCESS,
        profile: profileWithSecondaryAddress(payload.golfers),
      };
    }
    case MY_PROFILE_FETCH_FAILED: {
      return {
        ...state,
        status: jobStatus.FAILED,
        error: payload,
      };
    }
    case TOGGLE_SECONDARY_ADDRESS: {
      return {
        ...state,
        profile: { ...state.profile, hasSecondaryAddress: payload.flag },
      };
    }
    default:
      return state;
  }
};
