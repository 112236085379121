import React, { Component } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { overlayModalStyle } from "../../../../shared/helpers/ui-helper";
import { routes } from "../../../../shared/routes/index";
import { isMobile } from "react-device-detect";

class CreatedStatusModal extends Component {
  componentDidMount = () => Modal.setAppElement("body");

  onOk = () => {
    const { status, onOk } = this.props;
    if (status === 2) {
      routes.login.navigate();
    } else {
      onOk();
    }
  };

  openMobile = () => {
    window.location.replace("com.ghin://login");
  };

  render() {
    const { status, statusMessage, ctaLabel } = this.props;
    let canOpenMobile = isMobile && !ctaLabel && status === 2;
    return (
      <Modal
        isOpen={status !== 0}
        style={overlayModalStyle}
        className="modal-dialog"
      >
        <div className="modal-content">
          {status === 1 && (
            <button className="modal_close" onClick={this.onOk}>
              ✕
            </button>
          )}

          <div className="modal-body">
            <div className="content" style={{ textAlign: "center" }}>
              {status === 2 && <div className="success-icon" />}
              <br />
              <p>{statusMessage}</p>
            </div>
            <div className="actions centered">
              {canOpenMobile && (
                <button
                  style={{ marginBottom: "10px", flexGrow: "1" }}
                  className="btn fill cardinal"
                  onClick={this.openMobile}
                >
                  Open GHIN App
                </button>
              )}
              <button
                style={canOpenMobile ? { flexGrow: "1" } : {}}
                className="btn fill cardinal"
                onClick={this.onOk}
              >
                {ctaLabel
                  ? ctaLabel
                  : status === 2
                  ? "GHIN.com Login Page"
                  : "OK"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

CreatedStatusModal.propTypes = {
  status: PropTypes.number.isRequired,
  statusMessage: PropTypes.string.isRequired,
  onOk: PropTypes.func.isRequired,
  ctaLabel: PropTypes.string,
};

CreatedStatusModal.defaultProps = {
  ctaLabel: undefined,
};

export default CreatedStatusModal;
