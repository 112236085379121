import React, { Component } from "react";
import PropTypes from "prop-types";

class EditScoreSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      initialOptions: undefined,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.viewScoreCard) {
      this.setState({ canSubmit: true });
      return;
    }
    const { initialOptions } = this.state;
    if (nextProps.options) {
      if (!initialOptions) {
        this.setState({ initialOptions: nextProps.options });
        return;
      } else {
        if (initialOptions !== nextProps.options) {
          this.setState({ canSubmit: true });
        }
      }
    }
  }

  render = () => (
    <div className="row" style={{ justifyContent: "center" }}>
      <button onClick={() => this.props.onAbort()} className="btn blank blue">
        Cancel
      </button>
      <button
        type="submit"
        disabled={!this.state.canSubmit}
        onClick={this.props.options.handleSubmit}
        className="btn fill cardinal"
      >
        UPDATE ROUND STATS
      </button>
    </div>
  );
}

EditScoreSubmit.propTypes = {
  onAbort: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  viewScoreCard: PropTypes.bool.isRequired,
};

export default EditScoreSubmit;
