import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_MAX_HBH_SCORE,
  FETCH_MAX_HBH_SCORE_SUCCESS,
  FETCH_MAX_HBH_SCORE_FAILED,
  FETCH_MAX_HBH_SCORE_RESET,
} from "../actions";

const initialState = {
  maxHbhScore: [],
  error: undefined,
  fetchMaxHbhScoreStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MAX_HBH_SCORE:
      return {
        ...state,
        maxHbhScore: initialState.maxHbhScore,
        fetchMaxHbhScoreStatus: jobStatus.PROCESSING,
      };
    case FETCH_MAX_HBH_SCORE_SUCCESS:
      return {
        ...state,
        maxHbhScore: payload,
        fetchMaxHbhScoreStatus: jobStatus.SUCCESS,
      };
    case FETCH_MAX_HBH_SCORE_FAILED:
      return {
        ...state,
        error: payload.error,
        fetchMaxHbhScoreStatus: jobStatus.FAILED,
      };
    case FETCH_MAX_HBH_SCORE_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
