import React, { Component, Fragment } from "react";
import PromoBanner from "../../../shared/components/banners/PromoBanner";
import { Redirect, Route, Switch } from "react-router-dom";
import { routes } from "../../../shared/routes";
import PropTypes from "prop-types";

class MyCourses extends Component {
  render = () => (
    <Fragment>
      <PromoBanner />
      <section className="post-score">
        <Switch>
          <Redirect
            exact
            from={routes.myCourses.path}
            to={routes.myCoursesSelectCourse.path}
          />
          <Route {...routes.myCoursesSelectCourse} />
          <Route {...routes.selectTee} />
          <Route {...routes.addCourses} />
        </Switch>
      </section>
    </Fragment>
  );
}

MyCourses.propTypes = {
  location: PropTypes.object.isRequired,
};

export default MyCourses;
