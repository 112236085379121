import React, { Component } from "react";
import PropTypes from "prop-types";

class MemberSlides extends Component {
  constructor(props) {
    super(props);
    this.state = { slideIndex: 0 };
  }

  changeSlide = (n) => {
    this.setState({
      slideIndex: this.state.slideIndex + n,
    });
  };

  onPrevPress = () => {
    this.changeSlide(-1);
  };

  onNextPress = () => {
    this.changeSlide(1);
  };

  render() {
    const { memberships } = this.props;
    if (memberships.length === 0) return "";
    const { slideIndex } = this.state;
    const membership = memberships[slideIndex];
    const hasPrev = slideIndex > 0;
    const hasNext = slideIndex < memberships.length - 1;

    return (
      <div className="member_slides_pos">
        <div className="memberships_slideshow">
          <h4>GHIN Memberships</h4>
          <div className="slideshow-container">
            <ul className="slide">
              {membership && (
                <li>
                  {membership.logo && (
                    <div className="club-logo__container">
                      <img
                        className="club-logo"
                        alt={membership.club_name}
                        src={membership.logo}
                      />
                    </div>
                  )}
                  <div
                    className="club__details"
                    // style={{
                    //   marginLeft: `${membership.logo ? "40px" : "0px"}`
                    // }}
                  >
                    <div className="assoc">{membership.association_name}</div>
                    <div className="club">{membership.club_name}</div>
                  </div>
                </li>
              )}
            </ul>
            <button
              className={hasPrev ? "prev" : "hidden"}
              onClick={this.onPrevPress}
            >
              &#10094;
            </button>
            <button
              className={hasNext ? "next" : "hidden"}
              onClick={this.onNextPress}
            >
              &#10095;
            </button>
          </div>
        </div>
      </div>
    );
  }
}

MemberSlides.propTypes = {
  memberships: PropTypes.array.isRequired,
};

export default MemberSlides;
