import {
  UN_FOLLOW_GOLFER,
  unFollowGolferSuccess,
  unFollowGolferFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import FollowApi from "../../../services/follow-api";

export default (action$) =>
  action$
    .pipe(ofType(UN_FOLLOW_GOLFER))
    .pipe(switchMap(({ payload }) => unFollowGolfer(payload)));

const unFollowGolfer = (payload) =>
  from(FollowApi.unFollowGolfer(payload.followed_golfer_id, payload.club_id))
    .pipe(
      map((response) => unFollowGolferSuccess(response.data.unfollowing_golfer))
    )
    .pipe(catchError((error) => of(unFollowGolferFailed(error))));
