import React, { Component } from "react";
import PropTypes from "prop-types";
import { inputSearchDelay } from "../../../shared/helpers/ui-helper";

class CoursesSearch extends Component {
  constructor(props) {
    super(props);
    this.inputTimeout = 0;
  }

  onChange = ({ target: { value } }) => {
    if (this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.props.onChange(value);
    }, inputSearchDelay);
  };

  render = () => (
    <div className="search-container">
      <input
        type="text"
        name="search"
        onChange={this.onChange}
        id="search"
        className="search"
        placeholder="ENTER COURSE NAME"
        {...(this.props.value ? { value: this.props.value } : {})}
      />
    </div>
  );
}

CoursesSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default CoursesSearch;
