export const MY_PROFILE_PATCH = "MY_PROFILE_PATCH";
export const MY_PROFILE_PATCH_FAILED = "MY_PROFILE_PATCH_FAILED";
export const MY_PROFILE_PATCH_SUCCESS = "MY_PROFILE_PATCH_SUCCESS";

export const patchMyProfile = (payload) => {
  return {
    type: MY_PROFILE_PATCH,
    payload,
  };
};

export const patchMyProfileFailed = (payload) => ({
  type: MY_PROFILE_PATCH_FAILED,
  payload,
});

export function patchMyProfileSuccess(payload) {
  return (dispatch) => {
    dispatch({ type: MY_PROFILE_PATCH_SUCCESS, payload });
    dispatch({ type: "MY_PROFILE_FETCH_SUCCESS", payload });
  };
}
