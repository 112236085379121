import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_MANUAL_COURSE_HANDICAP,
  FETCH_MANUAL_COURSE_HANDICAP_RESET,
  FETCH_MANUAL_COURSE_HANDICAP_FAILED,
  FETCH_MANUAL_COURSE_HANDICAP_SUCCESS,
} from "../actions";

const initialState = {
  manualCourseHandicap: {},
  fetchManualCourseHandicapStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MANUAL_COURSE_HANDICAP:
      return {
        ...state,
        fetchManualCourseHandicapStatus: jobStatus.PROCESSING,
      };
    case FETCH_MANUAL_COURSE_HANDICAP_SUCCESS:
      return {
        ...state,
        manualCourseHandicap: payload,
        fetchManualCourseHandicapStatus: jobStatus.SUCCESS,
      };
    case FETCH_MANUAL_COURSE_HANDICAP_FAILED:
      return {
        ...state,
        fetchManualCourseHandicapStatus: jobStatus.FAILED,
      };
    case FETCH_MANUAL_COURSE_HANDICAP_RESET:
      return initialState;
    default:
      return state;
  }
};
