import {
  RESEND_VERIFICATION,
  resendVerificationSuccess,
  resendVerificationFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(RESEND_VERIFICATION))
    .pipe(switchMap(({ payload }) => login(payload)));

const login = (payload) =>
  from(GolferApi.resendVerification(payload))
    .pipe(map((response) => resendVerificationSuccess(response.data)))
    .pipe(
      catchError((error) => of(resendVerificationFailed(error.response?.data)))
    );
