import React, { Component } from "react";
import PropTypes from "prop-types";
import SmallLoader from "../loaders/SmallLoader";

class LoaderButton extends Component {
  stringToBoolean(string) {
    switch (string.toLowerCase().trim()) {
      case "true":
      case "yes":
      case "1":
        return true;
      case "false":
      case "no":
      case "0":
      case null:
        return false;
      default:
        return Boolean(string);
    }
  }

  render() {
    const { loading } = this.props;
    const isLoading = this.stringToBoolean(loading);
    return (
      <button {...this.props} disabled={isLoading ? true : this.props.disabled}>
        {isLoading ? (
          <SmallLoader suffix={this.props.suffix} />
        ) : (
          this.props.children
        )}
      </button>
    );
  }
}

LoaderButton.propTypes = {
  loading: PropTypes.string,
};

LoaderButton.defaultProps = {
  loading: false,
};

export default LoaderButton;
