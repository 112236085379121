import golferProfileFetchEpic from "./golfer-profile-fetch";
import golferAssociationsFetchEpic from "./golfer-associations-fetch";
import golferProfileHandicapFetchEpic from "./golfer-profile-handicap-fetch";
import golferProfileHandicapLowhiFetchEpic from "./golfer-profile-handicap-lowhi-fetch";
import { combineEpics } from "redux-observable";

export default combineEpics(
  golferProfileFetchEpic,
  golferProfileHandicapFetchEpic,
  golferAssociationsFetchEpic,
  golferProfileHandicapLowhiFetchEpic
);
