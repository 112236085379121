import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";

class InputField extends Component {
  state = {
    fieldError: undefined,
    fieldValue: undefined,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { inputValue, inputType } = nextProps;
    if (inputType !== this.props.inputType) {
      this.setState({ fieldValue: inputValue, fieldError: undefined });
    }
  }

  getValidationSchema = () => {
    const { inputType } = this.props;
    if (inputType === "email") {
      return Yup.object({
        email: Yup.string()
          .email("Invalid Email Address")
          .required("Email Address is required"),
      });
    } else if (inputType === "ghin_number") {
      return Yup.object({
        ghin_number: Yup.string()
          .required("GHIN Number is required")
          .max(19, "Invalid GHIN Number")
          .typeError("Invalid GHIN Number"),
      });
    } else {
      return Yup.object({
        last_name: Yup.string().required("Last Name is required"),
      });
    }
  };

  handleChangeValue = (event) => {
    const { value, name } = event.target;
    let fieldValue = name === "ghin_number" ? value.replace(/\D/g, "") : value;
    const { inputType, inputName } = this.props;

    this.setState({ fieldValue: fieldValue, fieldError: undefined });
    const validationSchema = this.getValidationSchema();
    validationSchema
      .isValid({
        [inputType]: fieldValue,
      })
      .then((fieldValid) => {
        this.props.onChangeValue(fieldValue, inputName, fieldValid);
      });
  };

  onBlurField = () => {
    const { inputType } = this.props;
    const { fieldValue } = this.state;
    const validationSchema = this.getValidationSchema();
    validationSchema
      .validate({
        [inputType]: fieldValue,
      })
      .catch((err) => {
        this.setState({ fieldError: err.errors.join("\n") });
      });
  };

  render() {
    const { inputType } = this.props;
    const { fieldError, fieldValue } = this.state;
    if (inputType === "email") {
      return (
        <div className="create_form">
          <div className="row">
            <div className="col is-full">
              <label className="black" htmlFor="email">
                Email Address
              </label>
              <input
                type="text"
                id="email"
                value={fieldValue || ""}
                name="email"
                onChange={this.handleChangeValue}
                onBlur={this.onBlurField}
              />
              <span className="error">{fieldError || ""}</span>
            </div>
          </div>
        </div>
      );
    }
    if (inputType === "ghin_number") {
      return (
        <div className="create_form">
          <div className="row">
            <div className="col is-full">
              <label className="black" htmlFor="ghin_number">
                GHIN Number
              </label>
              <input
                type="text"
                id="ghin_number"
                value={fieldValue || ""}
                name="ghin_number"
                onChange={this.handleChangeValue}
                onBlur={this.onBlurField}
              />
              <span className="error">{fieldError || ""}</span>
            </div>
          </div>
        </div>
      );
    }

    if (inputType === "last_name") {
      return (
        <div className="create_form">
          <div className="row">
            <div className="col is-full">
              <label className="black" htmlFor="last_name">
                Last Name
              </label>
              <input
                type="text"
                id="last_name"
                value={fieldValue || ""}
                name="last_name"
                onChange={this.handleChangeValue}
                onBlur={this.onBlurField}
              />
              <span className="error">{fieldError || ""}</span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

InputField.propTypes = {
  inputType: PropTypes.string,
  inputValue: PropTypes.any,
  inputName: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired,
};

InputField.defaultProps = {
  inputType: undefined,
  inputValue: undefined,
};

export default InputField;
