import {
  HOME_COURSES,
  fetchHomeCoursesSuccess,
  fetchHomeCoursesFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { flatMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import CoursesApi from "../../../services/courses-api";

export default (action$) =>
  action$.pipe(ofType(HOME_COURSES)).pipe(flatMap(() => fetchHomeCourses()));

const fetchHomeCourses = () =>
  from(CoursesApi.getHomeCourses())
    .pipe(map((response) => fetchHomeCoursesSuccess(response.data.facilities)))
    .pipe(catchError((error) => of(fetchHomeCoursesFailed(error))));
