import React, { Component } from "react";
import PropTypes from "prop-types";

class Box extends Component {
  render = () => <div className="box">{this.props.children}</div>;
}

Box.defaultProps = {
  children: {},
};

Box.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Box;
