import Navigate from "../navigation/navigate";
import DefaultLayout from "../components/layouts/DefaultLayout";
import Login from "../../screens/auth/components/Login/Login";
import CreateProfile from "../../screens/auth/components/CreateProfile/CreateProfile";
import Trial from "../../screens/auth/components/Trial/Trial";
import ConfirmEmailChange from "../../screens/auth/components/ConfirmEmailChange/ConfirmEmailChange";
import SetPassword from "../../screens/auth/components/CreateProfile/SetPassword";
import ForgotLogin from "../../screens/auth/components/ForgotLogin/ForgotLogin";
import SetNewPassword from "../../screens/auth/components/ForgotLogin/SetNewPassword";
import SelectGolfer from "../../screens/auth/components/SelectGolfer/SelectGolfer";
import ChangeGolfer from "../../screens/auth/components/SelectGolfer/ChangeGolfer";
import SSOLogin from "../../screens/sso/components/Auth";

export default {
  login: {
    path: "/login",
    component: Login,
    exact: true,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
    navigate: (returnUrl) =>
      Navigate.toPath(returnUrl ? `/login?returnUrl=${returnUrl}` : "/login"),
  },
  trial: {
    path: "/trial",
    component: Trial,
    exact: true,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
    navigate: (returnUrl) =>
      Navigate.toPath(returnUrl ? `/trial?returnUrl=${returnUrl}` : "/trial"),
  },
  createProfile: {
    path: "/create-profile",
    component: CreateProfile,
    exact: true,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
    navigate: () => Navigate.toPath("/create-profile"),
  },
  createProfileSetPassword: {
    path: "/auth/create_profile/:token",
    component: SetPassword,
    exact: true,
    rootPath: false,
    authRequired: false,
    directRoute: true,
    layout: DefaultLayout,
  },
  confirmEmailChange: {
    path: "/auth/confirm_email_change/:token",
    component: ConfirmEmailChange,
    exact: true,
    rootPath: true,
    authRequired: false,
    directRoute: true,
    layout: DefaultLayout,
  },
  forgotLogin: {
    path: "/forgot-login-information",
    component: ForgotLogin,
    exact: true,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
    navigate: () => Navigate.toPath("/forgot-login-information"),
  },
  setNewPassword: {
    path: "/auth/set/:token",
    component: SetNewPassword,
    exact: true,
    rootPath: false,
    authRequired: false,
    directRoute: true,
    layout: DefaultLayout,
  },
  selectGolfer: {
    path: "/select-golfer",
    component: SelectGolfer,
    exact: true,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
    navigate: () => Navigate.toPath("/select-golfer"),
  },
  changeGolfer: {
    path: "/change-golfer",
    component: ChangeGolfer,
    exact: true,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
    navigate: () => Navigate.toPath("/change-golfer"),
  },
  oauthAuthorize: {
    path: "/new-login",
    component: SSOLogin,
    exact: true,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
  },
};
