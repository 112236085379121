export const CREATE_PROFILE = "CREATE_PROFILE";
export const CREATE_PROFILE_FAILED = "CREATE_PROFILE_FAILED";
export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";

export const createProfile = (payload) => ({
  type: CREATE_PROFILE,
  payload,
});

export const createProfileFailed = (payload) => ({
  type: CREATE_PROFILE_FAILED,
  payload,
});

export const createProfileSuccess = (payload) => ({
  type: CREATE_PROFILE_SUCCESS,
  payload,
});
