import React, { Fragment, Component } from "react";

export default class EditTeeItem extends Component {
  onClickAdd = (event) => {
    const { onChangeTee, course } = this.props;
    onChangeTee({
      course_id: course.course_id,
      tee_id: Number(event.target.value),
    });
  };

  render() {
    const { tee, course, isChecked } = this.props;

    return (
      <Fragment>
        <li key={course.course_id + "#" + tee.TeeSetRatingId}>
          <span className="item">
            <input
              type="radio"
              className="radio-tee"
              id={course.course_id + "#" + tee.TeeSetRatingId}
              name="tee"
              value={tee.TeeSetRatingId}
              checked={isChecked}
              onChange={this.onClickAdd}
            />
            <div className="item details">
              <span className="details">
                <span className="tee">{tee.TeeSetRatingName}</span>
              </span>
              {!this.props.trialGolfer && (
                <span className="index">
                  {tee.Ratings[0].CourseRating.toFixed(1)}/
                  {tee.Ratings[0].SlopeRating}/{tee.TotalPar}
                </span>
              )}
            </div>
          </span>
        </li>
      </Fragment>
    );
  }
}
