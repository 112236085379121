import React, { Component } from "react";

class Accessibility extends Component {
  render() {
    return (
      <div className="accessibility__container">
        <a
          href="https://www.essentialaccessibility.com/usga?utm_source=usgahomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=usga"
          target="_blank"
          rel="noopener noreferrer"
          className="rounded-border-focus"
        >
          <img
            alt="Install Free Assistive Technology"
            className="logo fit-size"
            src="/img/accessibility.svg"
          />
        </a>
      </div>
    );
  }
}

export default Accessibility;
