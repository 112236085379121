import {
  FETCH_GOLFERS,
  fetchGolfersSuccess,
  fetchGolfersFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_GOLFERS))
    .pipe(switchMap(({ payload }) => getGolfers(payload)));

const getGolfers = (payload) =>
  from(
    GolferApi.getAllGolfers(
      payload.country,
      payload.state,
      payload.params,
      payload.page
    )
  )
    .pipe(
      map((response) =>
        fetchGolfersSuccess(response.data.golfers, payload.page, payload.type)
      )
    )
    .pipe(catchError((error) => of(fetchGolfersFailed(error))));
