import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_AVERAGE_SCORED_SCORES,
  FETCH_AVERAGE_SCORED_SCORES_SUCCESS,
  FETCH_AVERAGE_SCORED_SCORES_FAILED,
} from "../actions";

const initialState = {
  data: undefined,
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_AVERAGE_SCORED_SCORES:
      return {
        ...state,
        data: undefined,
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_AVERAGE_SCORED_SCORES_SUCCESS:
      return {
        ...state,
        data: payload.data,
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_AVERAGE_SCORED_SCORES_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    default:
      return state;
  }
};
