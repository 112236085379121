import React, { Component } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import DatePickerInput from "./DatePickerInput";
import addYears from "date-fns/addYears";

class DatePickerSelect extends Component {
  onDeleteInput = (e) => {
    const { options, fieldName } = this.props;
    options.setFieldValue(fieldName, e.value, true);
  };

  render() {
    const startCurrentYear = new Date(new Date().getFullYear(), 0, 1);
    const { options, fieldName } = this.props;

    return (
      <DatePicker
        customInput={<DatePickerInput onDeleteInput={this.onDeleteInput} />}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="date_picker_wrapper">
            <button
              className="change_month"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {"<"}
            </button>
            <div className="picker_title">{moment(date).format("MMMM")}</div>
            <button
              className="change_month"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {">"}
            </button>
          </div>
        )}
        selected={
          moment(options.values[fieldName]).isValid
            ? options.values[fieldName]
            : new Date()
        }
        onChange={(e) => options.setFieldValue(fieldName, e, true)}
        dateFormat="MM/dd"
        minDate={addYears(startCurrentYear, -50)}
        maxDate={addYears(new Date(), 50)}
      />
    );
  }
}

DatePickerSelect.propTypes = {
  options: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default DatePickerSelect;
