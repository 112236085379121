export default {
  smartBanner: {
    author: "USGA",
    title: "USGA GHIN App",
    storeText: {
      ios: "on the App Store",
      android: "In Google Play",
    },
    price: {
      ios: "GET",
      android: "GET",
    },
    url: {
      ios: process.env.REACT_APP_SMART_BANNER_URL_IOS,
      android: process.env.REACT_APP_SMART_BANNER_URL_ANDROID,
    },
  },
};
