import courseDetailsFetchEpic from "./course-details-fetch";
import courseHandicapFetchEpic from "./course-handicap-fetch";
import courseHandicapManualFetchEpic from "./course-handicap-manual-fetch";
import maxHbhScoreFetchEpic from "./max-hbh-score-fetch";
import postTotalScoreEpic from "./post-total-score";
import postScoreConfirmationEpic from "./post-score-confirmation";
import postHbhScoreEpic from "./post-hbh-score";
import postEditRoundStatsEpic from "./post-edit-round-stats";
import deleteRoundStatsEpic from "./delete-round-stats";
import { combineEpics } from "redux-observable";

export default combineEpics(
  courseDetailsFetchEpic,
  courseHandicapFetchEpic,
  courseHandicapManualFetchEpic,
  maxHbhScoreFetchEpic,
  postTotalScoreEpic,
  postScoreConfirmationEpic,
  postHbhScoreEpic,
  postEditRoundStatsEpic,
  deleteRoundStatsEpic
);
