import { combineEpics } from "redux-observable";
import {
  FETCH_AVERAGE_SCORED_SCORES,
  fetchAverageScoresSuccess,
  fetchAverageScoresFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";

export const fetchAverageScores = (action$) =>
  action$
    .pipe(ofType(FETCH_AVERAGE_SCORED_SCORES))
    .pipe(switchMap(({ payload }) => getScores(payload)));

const getScores = (payload) =>
  from(
    ScoresApi.getAverageScores(
      payload.filter,
      payload.course_id,
      payload.tee_set_id
    )
  )
    .pipe(map((response) => fetchAverageScoresSuccess(response.data)))
    .pipe(catchError((error) => of(fetchAverageScoresFailed(error))));

export default combineEpics(fetchAverageScores);
