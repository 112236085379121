export const courseHasSlopeRating = (course) =>
  course && course.TeeSets && course.TeeSets.some((t) => hasSlopeRating(t));

export const hasSlopeRating = (
  teeSet,
  ratingField = "Ratings",
  slopeField = "SlopeRating"
) => teeSet[ratingField].some((r) => r[slopeField]);

export const removeEmptyTeesHandicapCalculator = (teeSets) => {
  if (!teeSets || (teeSets && teeSets.length === 0)) return teeSets;
  const tee_sets = teeSets.tee_sets?.filter((teeSet) =>
    hasSlopeRating(teeSet, "ratings", "slope_rating")
  );
  return { tee_sets };
};

export const removeEmptyTeesFromCourse = (course) => {
  if (!courseHasSlopeRating(course)) return course;
  const TeeSets = course.TeeSets.filter((teeSet) => hasSlopeRating(teeSet));
  return { ...course, TeeSets };
};
