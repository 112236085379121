import React, { Component } from "react";
import PropTypes from "prop-types";

class ScoreLegend extends Component {
  render = () => (
    <div className="score-legend">
      Score Type: <b>H</b> - Home, <b>A</b> - Away, <b>C</b> - Competition,{" "}
      <b>N</b> - {this.props.useScaling || "Combined"} 9-Hole Rounds, <b>P</b> -
      Penalty, <b>E</b> - Exceptional
    </div>
  );
}

ScoreLegend.propTypes = {
  useScaling: PropTypes.bool,
};

ScoreLegend.defaultProps = {
  useScaling: false,
};

export default ScoreLegend;
