import BaseApi from "./base-api";
import { objToQuerystring } from "../shared/helpers/querystring-helper";

class ProductAlertApi extends BaseApi {
  apiUrl = `${this.baseUrl}/api/v1`;

  getProductAlert = () => {
    const url = `${this.apiUrl}/federations/1/product_alerts.json`;
    let params = {
      source: "ghin",
      active: true,
    };
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };
}

export default new ProductAlertApi();
