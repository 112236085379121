import React, { Component, Fragment } from "react";
import SelectCourse from "../../select-course/components/SelectCourse";
import { routes } from "../../../shared/routes/index";

export default class SelectCourses extends Component {
  onClickManuallyInput = () => {
    routes.manuallyHandicapCalculator.navigate();
  };

  getTabLinkMyCourses = () => routes.handicapCalculatorMyCourses.path;

  getTabLinkPostScore = () => routes.handicapCalculatorSelectCourse.path;

  render() {
    return (
      <Fragment>
        <div className="container">
          <section className="post-score">
            <SelectCourse
              {...this.props}
              hasManuallyInput={false}
              manuallyHandicapInput={this.onClickManuallyInput}
              navItemLink={routes.handicapCalculatorCalculator.path}
              tabLinkMyCourses={this.getTabLinkMyCourses}
              tabLinkPostCourse={this.getTabLinkPostScore}
            />
          </section>
        </div>
      </Fragment>
    );
  }
}
