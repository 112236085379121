import React, { Component, Fragment } from "react";
import Header from "../../../screens/header/components/Header/Header";
import Navigation from "../../../screens/header/components/Navigation/Navigation";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProductAlert from "../../../screens/product-alert/components/ProductAlert";

class NoFooterLayout extends Component {
  constructor() {
    super();
    this.state = {
      top: 0,
    };
  }

  render = () => {
    return (
      <Fragment>
        <ProductAlert
          setTop={(top) => {
            this.setState({ top: top }, () => {
              window.scrollTo(0, 0);
            });
          }}
        />
        <div className="s-navigation" style={{ top: this.state.top }}>
          <Header />
          <Navigation />
        </div>
        <div
          className="s-navigation-placeholder"
          style={{ marginTop: this.state.top }}
        />
        <Switch>
          {this.props.routes.map((route, key) => (
            <Route key={key} {...route} />
          ))}
        </Switch>
      </Fragment>
    );
  };
}

NoFooterLayout.propTypes = {
  routes: PropTypes.array.isRequired,
  alerts: PropTypes.array,
  hiddenAt: PropTypes.number,
  user: PropTypes.shape({}),
  lastFetch: PropTypes.number,
};

NoFooterLayout.defaultProps = {
  alerts: [],
  hiddenAt: undefined,
  user: undefined,
  lastFetch: undefined,
};

const mapStateToProps = ({ productAlertReducer, authReducer }) => ({
  alerts: productAlertReducer.productAlertReducer.alerts,
  lastFetch: productAlertReducer.productAlertReducer.lastFetch,
  hiddenAt: productAlertReducer.productAlertReducer.hiddenAt,
  user: authReducer.loginReducer.user,
});

export default connect(mapStateToProps, null)(NoFooterLayout);
