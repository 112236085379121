import {
  SEARCH_GOLFER,
  searchGolferSuccess,
  searchGolferFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(SEARCH_GOLFER))
    .pipe(switchMap(({ payload }) => action(payload)));

const action = (payload) =>
  from(GolferApi.searchMinorGolfer(payload))
    .pipe(map((response) => searchGolferSuccess(response.data)))
    .pipe(catchError((error) => of(searchGolferFailed(error.response?.data))));
