import {
  CONFIRM_POST_SCORE,
  confirmPostScoreFailed,
  confirmPostScoreSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";

export default (action$) =>
  action$
    .pipe(ofType(CONFIRM_POST_SCORE))
    .pipe(switchMap(({ payload }) => confirmPostScore(payload)));

const confirmPostScore = (payload) =>
  from(ScoresApi.confirmScore(payload.scoreId, payload.golferId))
    .pipe(map((r) => confirmPostScoreSuccess(r.data.score)))
    .pipe(catchError((error) => of(confirmPostScoreFailed(error))));
