import coursesFetchReducer from "./courses-fetch";
import countriesFetchReducer from "./countries-fetch";
import { combineReducers } from "redux";

const reducers = combineReducers({
  coursesFetchReducer,
  countriesFetchReducer,
});

export default reducers;
