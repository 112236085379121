import {
  GOLFER_ASSOCIATIONS_FETCH,
  fetchContactAssociationsFailed,
  fetchContactAssociationsSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";
import { getUserGhinNo } from "../../../shared/helpers/user-helper";

export default (action$) =>
  action$
    .pipe(ofType(GOLFER_ASSOCIATIONS_FETCH))
    .pipe(switchMap(({ payload }) => fetchContactAssociations(payload)));

const fetchContactAssociations = () =>
  from(GolferApi.getGolferAssociations(getUserGhinNo()))
    .pipe(
      map((response) =>
        fetchContactAssociationsSuccess(response.data.Associations)
      )
    )
    .pipe(catchError((error) => of(fetchContactAssociationsFailed(error))));
