import React, { Component } from "react";
import PropTypes from "prop-types";
import { hidden, isEmpty } from "../../../../shared/helpers/ui-helper";
import numberOfHoles from "../../../../shared/variables/number-of-holes";

class Scores extends Component {
  inputs = {
    FRONT: "frontScore",
    BACK: "backScore",
    TOTAL: "totalScore",
  };

  frontScoreRef;
  backScoreRef;

  render() {
    const { options, onOpenInfoModal } = this.props;
    return (
      <div className="row">
        <div className="col is-full">
          <div className="score-panel">
            <div className="score-panel__head">
              <div className="score-panel__title">
                Adjusted Gross Score
                <sup>
                  <button
                    onClick={onOpenInfoModal}
                    className="score-panel__info"
                  >
                    <i className="material-icons-outlined">info</i>
                  </button>
                </sup>
              </div>
            </div>
            <div className="score-panel__body columns">
              <div className="row">
                <div
                  className={`col is-1-of-3 ${hidden(
                    options.values.nrOfHoles !== numberOfHoles.EIGHTEEN
                  )}`}
                >
                  <label
                    className="regular black centered large"
                    htmlFor="front-9"
                  >
                    Front 9
                  </label>
                  <input
                    ref={(ref) => (this.frontScoreRef = ref)}
                    className="large centered-text"
                    type="text"
                    id={this.inputs.FRONT}
                    name={this.inputs.FRONT}
                    value={options.values[this.inputs.FRONT]}
                    onBlur={(e) => this.handleScoreChange(e, options)}
                    onChange={(e) => this.handleScoreChange(e, options)}
                    disabled={this.isHalfScoreDisabled()}
                    aria-label="Enter Front 9 Score"
                  />
                </div>
                <div
                  className={`col is-1-of-3 ${hidden(
                    options.values.nrOfHoles !== numberOfHoles.EIGHTEEN
                  )}`}
                >
                  <label
                    className="regular black centered large"
                    htmlFor="back-9"
                  >
                    Back 9
                  </label>
                  <input
                    ref={(ref) => (this.backScoreRef = ref)}
                    className="large centered-text"
                    type="text"
                    id={this.inputs.BACK}
                    name={this.inputs.BACK}
                    value={options.values[this.inputs.BACK]}
                    onBlur={(e) => this.handleScoreChange(e, options)}
                    onChange={(e) => this.handleScoreChange(e, options)}
                    disabled={this.isHalfScoreDisabled()}
                    aria-label="Enter Back 9 Score"
                  />
                </div>
                <div className="col is-1-of-3 center">
                  <label
                    className="regular black centered large"
                    htmlFor="total-score"
                  >
                    Total Score <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="large centered-text"
                    type="text"
                    id={this.inputs.TOTAL}
                    name={this.inputs.TOTAL}
                    value={options.values[this.inputs.TOTAL]}
                    onBlur={(e) => this.handleScoreChange(e, options)}
                    onChange={(e) => this.handleScoreChange(e, options)}
                    disabled={this.isTotalScoreDisabled()}
                    aria-label="Enter Total Score"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  isTotalScoreDisabled = () => {
    const { options } = this.props;
    return (
      options.values.nrOfHoles === numberOfHoles.EIGHTEEN &&
      (!isEmpty(options.values[this.inputs.FRONT]) ||
        !isEmpty(options.values[this.inputs.BACK]))
    );
  };

  isHalfScoreDisabled = () => {
    const { options } = this.props;
    return (
      isEmpty(options.values[this.inputs.FRONT]) &&
      isEmpty(options.values[this.inputs.BACK]) &&
      !isEmpty(options.values[this.inputs.TOTAL])
    );
  };

  updateTotalScore = (event, options) => {
    if (event.target.name === this.inputs.TOTAL) return;

    const totalScore =
      event.target.name === this.inputs.FRONT
        ? Number(event.target.value) + Number(options.values.backScore)
        : Number(event.target.value) + Number(options.values.frontScore);

    options.setFieldValue(
      this.inputs.TOTAL,
      totalScore !== 0 ? totalScore : "",
      true
    );
  };

  handleScoreChange = (event, options) => {
    const score = this.normalizeScore(event);
    options.handleChange(score);
    this.updateTotalScore(score, options);
    this.focusNextScoreInput(event, score);
  };

  normalizeScore = (event) => {
    let value = event.target.value.match(/\d+/g);

    if (value) {
      switch (event.target.name) {
        case this.inputs.TOTAL:
          value = value[0].match(/(?!0)\d{1,3}/);
          break;
        case this.inputs.FRONT:
          value = value[0].match(/(?!0)\d{1,2}/);
          break;
        case this.inputs.BACK:
          value = value[0].match(/(?!0)\d{1,2}/);
          break;
        default:
      }
    }

    event.target.value = value;
    return event;
  };

  focusNextScoreInput = (event) => {
    if (event.target.value < 10) return;

    switch (event.target.name) {
      case this.inputs.FRONT:
        this.backScoreRef.focus();
        break;
      case this.inputs.BACK:
        this.backScoreRef.blur();
        break;
      default:
    }
  };
}

Scores.propTypes = {
  options: PropTypes.object.isRequired,
  onOpenInfoModal: PropTypes.func.isRequired,
};

export default Scores;
