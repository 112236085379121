import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import DonutChart from "../../../../shared/components/charts/DonutChart";
import AdvancedStats from "../../../../shared/components/charts/AdvancedStats";
import {
  adaptAverageScoreSummaryToDonut,
  adaptAverageAdvancedStatsToChart,
} from "../../../../shared/helpers/stats-helper";

class ChartsView extends Component {
  renderChart(type) {
    const { averageScores } = this.props;
    const length =
      type === "donut"
        ? averageScores.total_summary_rounds
        : averageScores.total_stats_rounds;
    const statistics =
      type === "donut"
        ? adaptAverageScoreSummaryToDonut(averageScores?.score_summary)
        : adaptAverageAdvancedStatsToChart(averageScores?.advanced_stats);

    if (length === 0) {
      return (
        <Fragment>
          <div className="box-head-base">
            <h3 className="box-title">No Rounds</h3>
          </div>
          <div className="box-body grow center">
            <div className="box-head-sample">Sample Data</div>
            {type === "donut" ? (
              <DonutChart showEmptyFilter />
            ) : (
              <AdvancedStats
                puttsPrecision={1}
                upAndDownsPrecision={1}
                showEmptyFilter
                showEmptyStats
              />
            )}
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className="box-head-base">
          <h3 className="box-title">{`Based on ${length} Round${
            length === 1 ? "" : "s"
          }`}</h3>
        </div>
        <div className="box-body grow center">
          {type === "donut" ? (
            <DonutChart statistics={statistics} />
          ) : (
            <AdvancedStats
              puttsPrecision={1}
              upAndDownsPrecision={1}
              showEmptyStats
              statistics={statistics}
            />
          )}
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="stats__container margin00200">
          <div className="stats__row">
            <div className="stats__column">
              <div className="box height100">
                <div className="box-head">
                  <h2 className="box-title">Scoring Summary</h2>
                </div>
                {this.renderChart("donut")}
              </div>
            </div>
            <div className="stats__column">
              <div className="box height100">
                <div className="box-head box-head--row">
                  <h2 className="box-title">Advanced Stats</h2>
                </div>
                {this.renderChart()}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

ChartsView.propTypes = {
  averageScores: PropTypes.shape({}),
};

ChartsView.defaultProps = {
  averageScores: undefined,
};

export default ChartsView;
