import React, { Component, Fragment } from "react";
import CustomIconSelect from "../../../../shared/components/selects/CustomIconSelect";
import { store } from "../../../../shared/store";
import {
  girOptions,
  drivingOptions,
  defaultGirOption,
  defaultDrivingOption,
} from "../../variables/post-score-variables";
import PropTypes from "prop-types";
import {
  getSideGir,
  getSideHoles,
  getSidePutts,
  getSideDrivingAcc,
  normalizePutts,
  focusNextPuttsInput,
} from "../../helpers/post-hbh-score-helper";
import {
  setDrivingAcc,
  setGir,
  setPutts,
} from "../../validations/post-hbh-score-calculation";

class AdvancedStats extends Component {
  onPuttsChanged = (hole, event) => {
    const { options, puttsInputRefs } = this.props;
    const putts = normalizePutts(hole, event);
    setPutts(hole, putts, options);
    this.props.onChangePuts(hole, options);
    focusNextPuttsInput(hole, putts, puttsInputRefs, options);
  };

  onChangeGir = (hole, value, options, flag) => {
    setGir(hole, value, options, flag);
    this.props.onReCalculateStats();
  };

  onClearGir = (hole, defaultGirOption, options) => {
    setGir(hole, defaultGirOption, options);
    this.props.onReCalculateStats();
  };

  onChangeDrivingAcc = (hole, value, options) => {
    setDrivingAcc(hole, value, options);
    this.props.onReCalculateStats();
  };

  onClearDrivingAcc = (hole, value, options) => {
    setDrivingAcc(hole, defaultDrivingOption, options);
    this.props.onReCalculateStats();
  };

  getTotalPuttsValue() {
    const { options } = this.props;
    if (options.values.totalCombinedScores)
      return options.values.totalCombinedScores.putts;
    return options.values.totalPutts;
  }

  getTotalGirValue() {
    const { options } = this.props;
    if (options.values.totalCombinedScores)
      return options.values.totalCombinedScores.gir;
    return options.values.totalGir;
  }

  getTotalDrivingAccValue() {
    const { options } = this.props;
    if (options.values.totalCombinedScores)
      return options.values.totalCombinedScores.acc;
    return options.values.totalDrivingAcc;
  }

  onPuttsFocused = (event) => event.target.select();

  render() {
    const { options, side, lastTable, puttsInputRefs } = this.props;
    const holes = getSideHoles(options, side);
    const golferActive =
      store.getState().profileReducer.golferProfileFetchReducer.golferActive;

    return (
      <Fragment>
        <div className="pre-table mobile-only">
          <h4 className="table-name">Advanced Stats</h4>
        </div>

        <div className="data-table__wrapper">
          <div
            className={`data-table__scroll ${side} ${
              lastTable ? "LastScoreTable" : ""
            }`}
            id={`post-hbh-adv-stats-${side}`}
          >
            <table className={"data-table scores advanced-stats " + side}>
              <tbody>
                {this.renderRow(
                  "PUTTS",
                  holes.map((hole, key) => (
                    <td key={key}>
                      <input
                        ref={(ref) => puttsInputRefs.push(ref)}
                        type="text"
                        name={`holes[${hole.nr - 1}].putts`}
                        id={`holes[${hole.nr - 1}].putts`}
                        value={hole.score === "" ? "" : hole.putts}
                        autoComplete="off"
                        disabled={hole.score == ""}
                        onChange={(event) => {
                          this.onPuttsChanged(hole, event);
                        }}
                        onFocus={this.onPuttsFocused}
                        readOnly={!golferActive}
                      />
                    </td>
                  )),
                  getSidePutts(options, side),
                  this.getTotalPuttsValue()
                )}
                {this.renderRow(
                  "GIR",
                  holes.map((hole, key) => (
                    <td key={key}>
                      {hole.score != "" && (
                        <CustomIconSelect
                          options={girOptions}
                          golferActive={golferActive}
                          value={hole.gir}
                          disabled={hole.score == ""}
                          onClear={() =>
                            this.onClearGir(hole, defaultGirOption, options)
                          }
                          onChange={(value) =>
                            this.onChangeGir(hole, value, options, true)
                          }
                        />
                      )}
                    </td>
                  )),
                  getSideGir(options, side),
                  this.getTotalGirValue()
                )}
                {this.renderRow(
                  <span>
                    DRIVING <br /> ACC
                  </span>,
                  holes.map((hole, key) => (
                    <td key={key}>
                      {hole.par !== 3 && hole.score != "" && (
                        <CustomIconSelect
                          options={drivingOptions}
                          golferActive={golferActive}
                          value={hole.drivingAcc}
                          disabled={hole.score == ""}
                          onClear={() =>
                            this.onClearDrivingAcc(
                              hole,
                              defaultDrivingOption,
                              options
                            )
                          }
                          onChange={(value) =>
                            this.onChangeDrivingAcc(hole, value, options)
                          }
                        />
                      )}
                    </td>
                  )),
                  getSideDrivingAcc(options, side),
                  this.getTotalDrivingAccValue()
                )}
              </tbody>
            </table>
          </div>
          <div className="fixed-shadow" />
        </div>
      </Fragment>
    );
  }

  renderRow = (title, holes, sideTotals = "", totals = "") => {
    const { showSideTotalsColumn, showTotalsColumn } = this.props;
    return (
      <tr>
        <td className="fixed start">{title}</td>
        {holes}
        {showSideTotalsColumn && (
          <td className={this.getSideTotalsClass()}>{sideTotals}</td>
        )}
        {showTotalsColumn && <td className="fixed end">{totals}</td>}
      </tr>
    );
  };

  getSideTotalsClass = () => {
    if (this.props.options.values.totalCombinedScores)
      return "fixed pre-end-total";
    return this.props.showTotalsColumn ? "fixed pre-end" : "fixed end";
  };
}

AdvancedStats.propTypes = {
  options: PropTypes.object.isRequired,
  side: PropTypes.string.isRequired,
  showTotalsColumn: PropTypes.bool.isRequired,
  showSideTotalsColumn: PropTypes.bool.isRequired,
  onChangePuts: PropTypes.func.isRequired,
  onReCalculateStats: PropTypes.func,
  lastTable: PropTypes.bool,
};

AdvancedStats.defaultProps = {
  onReCalculateStats: () => {},
};

export default AdvancedStats;
