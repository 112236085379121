import {
  MY_PROFILE_PATCH,
  patchMyProfileSuccess,
  patchMyProfileFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(MY_PROFILE_PATCH))
    .pipe(switchMap(({ payload }) => action(payload)));

const action = (payload) =>
  from(GolferApi.patchMyProfile(payload))
    .pipe(map((response) => patchMyProfileSuccess(response.data)))
    .pipe(
      catchError((error) => of(patchMyProfileFailed(error.response?.data)))
    );
