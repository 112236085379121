export const LOGIN = "LOGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const PERSIST_INITIAL_LOGIN = "PERSIST_INITIAL_LOGIN";

export const LOGOUT = "LOGOUT";
export const RESET_FORM = "RESET_FORM";

//Terms
export const WAIT_TO_ACCEPT_TERMS = "WAIT_TO_ACCEPT_TERMS";
export const USER_ACCEPTED_TERMS = "USER_ACCEPTED_TERMS";
export const USER_DENIED_TERMS = "USER_DENIED_TERMS";

//Select golfers
export const WAIT_TO_SELECT_GOLFER = "WAIT_TO_SELECT_GOLFER";
export const SELECT_GOLFER = "SELECT_GOLFER";
export const UPDATE_MINORS = "UPDATE_MINORS";
export const START_FETCH = "START_FETCH";
export const STOP_FETCH = "STOP_FETCH";

export const updateMinors = (payload) => ({
  type: UPDATE_MINORS,
  payload,
});

export const login = (password, emailOrGhin, remember_me) => ({
  type: LOGIN,
  payload: { password, emailOrGhin, remember_me },
});

export const loginFailed = (payload) => ({
  type: LOGIN_FAILED,
  payload,
});

export const resetForm = () => ({ type: RESET_FORM });

export const waitForAcceptTerms = (payload) => ({
  type: WAIT_TO_ACCEPT_TERMS,
  payload,
});

export const userDeniedTerms = () => ({ type: USER_DENIED_TERMS });

export const persistInitialLogin = (payload) => ({
  type: PERSIST_INITIAL_LOGIN,
  payload,
});

export const waitToSelectGolfer = () => ({ type: WAIT_TO_SELECT_GOLFER });
