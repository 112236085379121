import BaseApi from "./base-api";
import { objToQuerystring } from "../shared/helpers/querystring-helper";
import { getUserToken } from "../shared/helpers/api-helper";
import moment from "moment";

import {
  statusActive,
  golferCredentials,
  golferId,
  perPage,
  fromGhin,
  sortByFullName,
  getUserLoginToken,
} from "../shared/helpers/api-helper";
import { getUserGuardianId } from "../shared/helpers/user-helper";

class GolferApi extends BaseApi {
  apiUrl = `${this.baseUrl}/api/v1/golfermethods.asmx`;

  login = (user_token, password, email_or_ghin, remember_me = false) => {
    const params = {
      user: {
        password,
        email_or_ghin,
        remember_me,
      },
      token: getUserLoginToken(),
      user_token: user_token,
    };

    return this.httpPost(`${this.baseUrl}/api/v1/golfer_login.json`, params);
  };

  logout = () => {
    const url = `${this.baseUrl}/api/v1/users/logout.json`;
    const params = {
      user_token: getUserToken(),
    };
    return this.httpDelete(`${url}?${objToQuerystring(params)}`);
  };

  getClubs = () => {
    const url = `${this.baseUrl}/api/v1/search_golfer.json`;
    const params = {
      ...statusActive,
      ...golferId(),
    };

    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getClubGolfers = (club, filter = null, sortCriteria, page = 1) => {
    const url = `${this.baseUrl}/api/v1/clubs/${club}/golfers.json`;
    const params = {
      ...statusActive,
      ...perPage,
      ...sortCriteria,
      page,
    };
    if (filter && filter.length > 0) {
      if (isNaN(filter)) {
        params.full_name = filter;
      } else {
        params.golfer_id = filter;
      }
    }
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getAllGolfers = (country, state, extraParams, page = 1) => {
    const url = `${this.baseUrl}/api/v1/golfers.json`;
    let params;
    if (state === "All") {
      params = {
        ...statusActive,
        ...fromGhin,
        ...perPage,
        ...sortByFullName,
        page,
        country,
      };
    } else {
      params = {
        ...statusActive,
        ...fromGhin,
        ...perPage,
        ...sortByFullName,
        page,
        state,
      };
    }
    if (extraParams) {
      params = { ...params, ...extraParams };
    }
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  golferProfile = () => {
    const url = `${this.baseUrl}/api/v1/golfers/search.json`;
    const params = {
      ...golferCredentials(),
      page: 1,
      per_page: 10,
    };
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  findGolfer = (golfer_id, page = 1) => {
    const url = `${this.baseUrl}/api/v1/golfers.json`;
    const params = {
      ...statusActive,
      ...fromGhin,
      ...perPage,
      page,
      golfer_id,
      includeLowHandicapIndex: true,
    };
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getHandicapHistory = (ghin_number) => {
    const date_begin = moment().subtract(1, "y").format("YYYY-MM-DD");
    const date_end = moment().format("YYYY-MM-DD");
    const url = `${this.baseUrl}/api/v1/golfers/${ghin_number}/handicap_history.json`;
    const params = {
      revCount: 0,
      date_begin,
      date_end,
    };
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getHandicapHistoryLowhi = (golfer) => {
    const url = `${this.baseUrl}/api/v1/clubs/${golfer.club_id}/golfers/${golfer.ghin_number}.json`;
    return this.httpGet(url);
  };

  searchGolfer = (golfer_id) => {
    const url = `${this.baseUrl}/api/v1/search_golfer.json`;
    return this.httpGet(`${url}?${objToQuerystring({ golfer_id })}`);
  };

  getGolferAssociations = (golferId) => {
    const url = `${this.baseUrl}/api/v1/golfers/${golferId}/associations.json`;
    return this.httpGet(url);
  };

  disableAutoRenewal = (payload) => {
    const url = `${this.baseUrl}/api/v1/golfers/${payload.golfer_id}/club_affiliations/${payload.affiliation_id}.json`;
    return this.httpPatch(url, payload.data);
  };

  createProfile = (payload) => {
    return this.httpPost(
      `${this.baseUrl}/api/v1/ghin_account_verification.json?${objToQuerystring(
        payload
      )}`,
      false
    );
  };

  checkTrial = (golferId, screen) => {
    const url = `${this.baseUrl}/api/v1/golfers/${golferId}/check_trial.json?screen=${screen}`;
    return this.httpGet(url);
  };

  createTrial = (payload) => {
    const params = {
      golfer: {
        first_name: payload.firstName,
        last_name: payload.lastName,
        gender: payload.gender[0],
        email: payload.email,
        password: payload.password,
        password_confirmation: payload.confirmPassword,
        terms_accepted: true,
        zip_code: payload.zipCode,
      },
    };
    return this.httpPost(
      `${this.baseUrl}/api/v1/golfers/create_trial_golfer.json`,
      params
    );
  };

  resendVerification = (payload) => {
    return this.httpPost(
      `${
        this.baseUrl
      }/api/v1/resend_email_after_ghin_account_verification.json?${objToQuerystring(
        payload
      )}`,
      false
    );
  };

  golferInformation = (payload) => {
    return this.httpGet(
      `${
        this.baseUrl
      }/api/v1/get_golfer_user_information.json?${objToQuerystring(payload)}`,
      false
    );
  };

  setPassword = (payload) => {
    return this.httpPost(
      `${this.baseUrl}/api/v1/users/set_password.json?${objToQuerystring(
        payload
      )}`,
      false
    );
  };

  emailChangeInformation = (golfer_id, payload) => {
    const querystring = objToQuerystring(payload);
    return this.httpGet(
      `${this.baseUrl}/api/v1/golfers/${golfer_id}/get_email_change_information.json?${querystring}`,
      false
    );
  };

  confirmEmailChange = (golfer_id, payload) => {
    return this.httpPost(
      `${this.baseUrl}/api/v1/golfers/${golfer_id}/confirm_email_change.json`,
      payload
    );
  };

  forgotLogin = (payload) => {
    return this.httpPost(
      `${this.baseUrl}/api/v1/forgot_login_information.json?${objToQuerystring(
        payload
      )}`,
      false
    );
  };

  acceptTerms = () => {
    return this.httpPost(`${this.baseUrl}/api/v1/accept_terms.json`);
  };

  searchMinorGolfer = (payload) => {
    const url = `${this.baseUrl}/api/v1/golfers/search.json`;
    const params = {
      page: 1,
      per_page: 10,
      golfer_id: payload,
    };
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  fetchMyProfile = (payload) => {
    const url = `${this.baseUrl}/api/v1/clubs/${payload.club_id}/golfers/${payload.ghin}.json`;
    return this.httpGet(url);
  };

  patchMyProfile = (payload) => {
    const url = `${this.baseUrl}/api/v1/clubs/${payload.club_id}/golfers/${payload.ghin}.json`;
    return this.httpPatch(url, payload.data);
  };

  fetchMinors = () => {
    const guardianId = getUserGuardianId();
    const url = `${this.baseUrl}/api/v1/guardians/${guardianId}/golfers.json`;
    return this.httpGet(url);
  };

  loginWithGhin = (password, email_or_ghin) => {
    const params = {
      user: {
        password,
        email_or_ghin,
      },
    };
    return this.httpPost(`${this.baseUrl}/api/v1/login_with_ghin.json`, params);
  };

  authorizeLoginWithGhin = (url, token) => {
    return this.httpGet(url, token);
  };

  preAuthorizeLoginWithGhin = (url, token) => {
    return this.httpPost(url, { commit: "Authorize" }, token);
  };

  denyAuthorize = (url, token) => {
    return this.httpDelete(url, { commit: "Deny" }, token);
  };

  getGuardianData = (gId) => {
    const url = `${this.baseUrl}/api/v1/guardians/${gId}.json`;
    return this.httpGet(url);
  };
}
export default new GolferApi();
