import {
  SET_PASSWORD,
  setPasswordSuccess,
  setPasswordFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(SET_PASSWORD))
    .pipe(switchMap(({ payload }) => action(payload)));

const action = (payload) =>
  from(GolferApi.setPassword(payload))
    .pipe(map((response) => setPasswordSuccess(response.data)))
    .pipe(catchError((error) => of(setPasswordFailed(error.response?.data))));
