import React, { Component } from "react";
import PropTypes from "prop-types";
import CoursesListItem from "./CoursesListItem";

class CoursesList extends Component {
  render = () => (
    <div className="item-list__body">
      {this.props.courses.map((course, key) => (
        <CoursesListItem
          key={key}
          course={course}
          navLink={this.props.navLinkPath.replace(
            ":courseId",
            course.CourseID || course.CourseId
          )}
        />
      ))}
    </div>
  );
}

CoursesList.propTypes = {
  courses: PropTypes.array.isRequired,
  navLinkPath: PropTypes.string.isRequired,
};

export default CoursesList;
