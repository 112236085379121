export default {
  nineHoles: {
    min: 27,
    max: 90,
  },
  eighteenHoles: {
    min: 54,
    max: 180,
  },
  tenSeventeenHoles: {
    min: 27,
    max: 180,
  },
  nineHolesShort: {
    min: 14,
    max: 90,
  },
  eighteenHolesShort: {
    min: 27,
    max: 180,
  },
};
