import { checkApiError } from "../../auth/actions";

export const FETCH_CALCULATOR_GOLFER_HANDICAP =
  "FETCH_CALCULATOR_GOLFER_HANDICAP";
export const FETCH_CALCULATOR_GOLFER_HANDICAP_FAILED =
  "FETCH_CALCULATOR_GOLFER_HANDICAP_FAILED";
export const FETCH_CALCULATOR_GOLFER_HANDICAP_SUCCESS =
  "FETCH_CALCULATOR_GOLFER_HANDICAP_SUCCESS";
export const FETCH_CALCULATOR_REMOVE_COURSE = "FETCH_CALCULATOR_REMOVE_COURSE";

export const fetchCalculatorGolferHandicap = (golferId) => ({
  type: FETCH_CALCULATOR_GOLFER_HANDICAP,
  payload: { golferId },
});

export function fetchCalculatorGolferHandicapFailed(golferId, error) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CALCULATOR_GOLFER_HANDICAP_FAILED,
      payload: { golferId },
    });
    dispatch(checkApiError(error));
  };
}

export const fetchCalculatorGolferHandicapSuccess = (handicap, golferId) => ({
  type: FETCH_CALCULATOR_GOLFER_HANDICAP_SUCCESS,
  payload: { handicap, golferId },
});
