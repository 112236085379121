import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_CLUB_GOLFERS,
  FETCH_CLUB_GOLFERS_SUCCESS,
  FETCH_CLUB_GOLFERS_FAILED,
  FETCH_CLUB_GOLFERS_NEXT,
} from "../actions";

const FIRST_PAGE = 1;
const initialState = {
  golfers: [],
  nextPage: FIRST_PAGE,
  fetchNextPage: false,
  jobStatus: jobStatus.NOT_STARTED,
};

const getNextPage = (golfers, meta, nextPage) => {
  if (!meta) return FIRST_PAGE;
  if (meta.active_golfers_count <= golfers.length) return FIRST_PAGE;
  return nextPage + FIRST_PAGE;
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CLUB_GOLFERS:
      return {
        ...state,
        golfers: [],
        nextPage: FIRST_PAGE,
        jobStatus: jobStatus.PROCESSING,
        fetchNextPage: false,
      };
    case FETCH_CLUB_GOLFERS_SUCCESS:
      return {
        ...state,
        golfers: [...state.golfers, ...payload.golfers],
        nextPage: getNextPage(
          [...state.golfers, ...payload.golfers],
          payload.meta,
          state.nextPage
        ),
        jobStatus: jobStatus.SUCCESS,
        fetchNextPage: false,
      };
    case FETCH_CLUB_GOLFERS_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
        fetchNextPage: false,
      };
    case FETCH_CLUB_GOLFERS_NEXT:
      return {
        ...state,
        fetchNextPage: true,
      };
    default:
      return state;
  }
};
