import { initialValues } from "../../screens/post-score/validations/post-hbh-score-validation";
import { convertScoreToRoundSetup } from "../../screens/post-score/helpers/edit-round-stats-helper";

export const initialTotalCombinedScores = (scores) => {
  const combinedTotal = {
    yards: 0,
    pars: 0,
    scores: 0,
    adjScores: 0,
    putts: 0,
    gir: 0,
    acc: 0,
  };
  scores.forEach((score) => {
    const values = initialValues(
      score.courseDetails,
      convertScoreToRoundSetup(score, score.courseDetails),
      score.maximumScores,
      score.hole_details,
      true
    );
    const backSide = values.tees.RatingType === "Back";
    combinedTotal.yards += backSide ? values.backYardage : values.frontYardage;
    combinedTotal.pars += backSide ? values.backPar : values.frontPar;
    combinedTotal.scores += values.totalScore;
    combinedTotal.adjScores += values.totalAdjScore;
    combinedTotal.putts += backSide ? values.backPutts : values.frontPutts;
    combinedTotal.gir += backSide ? values.backGir : values.frontGir;
    combinedTotal.acc += backSide
      ? values.backDrivingAcc
      : values.frontDrivingAcc;
  });
  return combinedTotal;
};
