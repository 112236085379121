import { combineEpics } from "redux-observable";
import {
  FETCH_2020_SCORES,
  fetch2020ScoresSuccess,
  fetch2020ScoresFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";

export const fetch2020Scores = (action$) =>
  action$
    .pipe(ofType(FETCH_2020_SCORES))
    .pipe(switchMap(({ payload }) => getGolferScores(payload)));

const getGolferScores = (payload) =>
  from(ScoresApi.getStatsScores(payload.filter, undefined, false, true))
    .pipe(map((response) => fetch2020ScoresSuccess(response.data.scores)))
    .pipe(catchError((error) => of(fetch2020ScoresFailed(error))));

export default combineEpics(fetch2020Scores);
