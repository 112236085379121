import {
  FETCH_COURSE_HANDICAP,
  fetchCourseHandicapFailed,
  fetchCourseHandicapSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import SupportingCalculationApi from "../../../services/supporting-calculation-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_COURSE_HANDICAP))
    .pipe(switchMap(({ payload }) => fetchCourseHandicap(payload)));

const fetchCourseHandicap = (payload) =>
  from(
    SupportingCalculationApi.getCourseHandicaps(
      payload.golferId,
      payload.courseId,
      payload.playedAt
    )
  )
    .pipe(map((response) => fetchCourseHandicapSuccess(response.data)))
    .pipe(catchError((error) => of(fetchCourseHandicapFailed(error))));
