import React, { Component, Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import {
  fetchCourseDetails,
  fetchCourseDetailsReset,
  fetchCourseHandicap,
  fetchManualCourseHandicap,
  resetManualCourseHandicap,
} from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RoundSetupForm from "./RoundSetupForm";
import HandicapInfoModal from "../../../../shared/components/modals/HandicapInfoModal";
import NoTeesModal from "../Shared/Modals/NoTeesModal";
import NoActiveTeesModal from "../Shared/Modals/NoActiveTeesModal";
import NoSlopeRatingModal from "../../../../shared/components/modals/NoSlopeRatingModal";
import { routes } from "../../../../shared/routes/index";
import { getUserGhinNo } from "../../../../shared/helpers/user-helper";
import { manualCourse } from "../../variables/post-score-variables";
import HbhNotAvailableModal from "../Shared/Modals/HbhNotAvailableModal";
import jobStats from "../../../../shared/variables/job-status";
import Loader from "../../../../shared/components/loaders/Loader";
import {
  hasGenderTeeSets,
  hasGenderSlopeRating,
} from "../../helpers/round-setup-helper";
import { getUserGender } from "../../../../shared/helpers/user-helper";
import moment from "moment";
import { Helmet } from "react-helmet";
import {
  HBH_ROUND_SETUP_TITLE,
  HBH_ROUND_SETUP_DESC,
} from "../../../../shared/variables/meta-seo";
import HbhNotSameDayModal from "../Shared/Modals/HbhNotSameDayModal";

class PostHbhScoreRoundSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoModalOpen: false,
      noTeesModalOpen: false,
      noActiveTeesModalOpen: false,
      noSlopeRatingModalOpen: false,
      hbhNotAvailableModalOpen: false,
      hbhNotSameDayModalOpen: false,
      isManualCourse: false,
    };
  }

  componentDidMount = () => {
    const { fetchCourseDetails, fetchCourseHandicap, match } = this.props;

    if (match.params.courseId === manualCourse) {
      this.setState({ isManualCourse: true });
    } else {
      this.setState({ isManualCourse: false });
      fetchCourseDetails(match.params.courseId);
      fetchCourseHandicap(
        match.params.courseId,
        getUserGhinNo(),
        moment(new Date()).format("YYYY-MM-DD")
      );
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { course } = nextProps;
    if (course && course !== this.props.course) {
      const gender = getUserGender();
      const teeSets = hasGenderTeeSets(course, gender);
      if (!teeSets) {
        this.setState({ noActiveTeesModalOpen: true });
      }

      const slopeRating = hasGenderSlopeRating(course, gender);
      if (teeSets && !slopeRating) {
        this.setState({ noSlopeRatingModalOpen: true });
      }
    }
  }

  render() {
    const {
      course,
      courseHandicap,
      manualCourseHandicap,
      fetchManualCourseHandicap,
      resetManualCourseHandicap,
      fetchCourseHandicap,
      fetchCourseDetailsStatus,
      fetchCourseHandicapStatus,
      useScaling,
      hasHandicapIndex,
    } = this.props;
    const {
      infoModalOpen,
      noTeesModalOpen,
      noActiveTeesModalOpen,
      noSlopeRatingModalOpen,
      isManualCourse,
      hbhNotAvailableModalOpen,
      hbhNotSameDayModalOpen,
    } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>{HBH_ROUND_SETUP_TITLE}</title>
          <meta name="description" content={HBH_ROUND_SETUP_DESC} />
        </Helmet>
        <div className="box">
          <div className="box-head">
            <h3 className="box-title">Round Setup</h3>
          </div>
          {fetchCourseDetailsStatus === jobStats.SUCCESS &&
          fetchCourseHandicapStatus === jobStats.SUCCESS ? (
            <div className="box-body">
              <RoundSetupForm
                course={course}
                trialGolfer={this.props.trialGolfer}
                courseHandicap={courseHandicap}
                manualCourseHandicap={manualCourseHandicap}
                fetchManualCourseHandicap={fetchManualCourseHandicap}
                resetManualCourseHandicap={resetManualCourseHandicap}
                fetchCourseHandicap={fetchCourseHandicap}
                isManualCourse={isManualCourse}
                openInfoModal={() => this.setState({ infoModalOpen: true })}
                alertNoTees={() => this.setState({ noTeesModalOpen: true })}
                alertTeeNoHoles={(teeSetID) =>
                  this.setState({ hbhNotAvailableModalOpen: true, teeSetID })
                }
                openHbhNotSameDayModal={() =>
                  this.setState({ hbhNotSameDayModalOpen: true })
                }
                onSubmit={(values) =>
                  routes.postScoreHbhPost.navigate(values, course)
                }
                useScaling={useScaling}
                hasHandicapIndex={hasHandicapIndex}
              />
            </div>
          ) : (
            <Loader />
          )}
        </div>

        <HandicapInfoModal
          isOpen={infoModalOpen}
          onAbort={() => this.setState({ infoModalOpen: false })}
        />
        <NoActiveTeesModal
          course={course}
          isOpen={noActiveTeesModalOpen}
          onClose={() => this.setState({ noActiveTeesModalOpen: false })}
        />
        <NoSlopeRatingModal
          isOpen={noSlopeRatingModalOpen}
          onClose={() => this.setState({ noSlopeRatingModalOpen: false })}
        />
        <NoTeesModal
          isOpen={noTeesModalOpen}
          onClose={() => this.setState({ noTeesModalOpen: false })}
        />
        <HbhNotAvailableModal
          course={course}
          teeSetID={this.state.teeSetID}
          isOpen={hbhNotAvailableModalOpen}
          onClose={() => this.setState({ hbhNotAvailableModalOpen: false })}
        />
        <HbhNotSameDayModal
          trialGolfer={this.props.trialGolfer}
          isOpen={hbhNotSameDayModalOpen}
          onClose={() => this.setState({ hbhNotSameDayModalOpen: false })}
        />
      </Fragment>
    );
  }

  componentWillUnmount() {
    const { fetchCourseDetailsReset, resetManualCourseHandicap } = this.props;
    resetManualCourseHandicap();
    fetchCourseDetailsReset();
  }
}

PostHbhScoreRoundSetup.propTypes = {
  course: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  fetchCourseDetails: PropTypes.func.isRequired,
  fetchCourseDetailsReset: PropTypes.func.isRequired,
  fetchCourseHandicap: PropTypes.func.isRequired,
  fetchManualCourseHandicap: PropTypes.func.isRequired,
  resetManualCourseHandicap: PropTypes.func.isRequired,
  courseHandicap: PropTypes.object.isRequired,
  manualCourseHandicap: PropTypes.object.isRequired,
  fetchCourseDetailsStatus: PropTypes.string.isRequired,
  fetchCourseHandicapStatus: PropTypes.string.isRequired,
  useScaling: PropTypes.bool.isRequired,
  hasHandicapIndex: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ postScoreReducer, profileReducer }) => {
  const handicapIndex =
    profileReducer.golferProfileFetchReducer.golferProfile?.handicap_index;

  return {
    course: postScoreReducer.courseDetailsFetchReducer.course,
    fetchCourseDetailsStatus:
      postScoreReducer.courseDetailsFetchReducer.fetchCourseDetailsStatus,
    trialGolfer: profileReducer.golferProfileFetchReducer.trialGolfer,
    courseHandicap: postScoreReducer.courseHandicapFetchReducer.courseHandicap,
    fetchCourseHandicapStatus:
      postScoreReducer.courseHandicapFetchReducer.fetchCourseHandicapStatus,
    manualCourseHandicap:
      postScoreReducer.courseHandicapManualFetchReducer.manualCourseHandicap,
    useScaling:
      profileReducer.golferProfileFetchReducer.golferProfile?.use_scaling,
    hasHandicapIndex: handicapIndex != "NH" && handicapIndex != "WD",
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCourseDetails,
      fetchCourseDetailsReset,
      fetchCourseHandicap,
      fetchManualCourseHandicap,
      resetManualCourseHandicap,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostHbhScoreRoundSetup);
