import PostScore from "../../screens/post-score/components/PostScore/PostScore";
import PostTotalScoreContainer from "../../screens/post-score/components/PostTotalScoreContainer/PostTotalScoreContainer";
import SelectCourse from "../../screens/post-score/components/SelectCourse/SelectCourse";
import PostTotalScore from "../../screens/post-score/components/PostTotalScore/PostTotalScore.js";
import PostHbhScore from "../../screens/post-score/components/PostHbhScore/PostHbhScore";
import PostTotalScoreSummary from "../../screens/post-score/components/PostTotalScoreSummary/PostTotalScoreSummary";
import PostHbhScoreContainer from "../../screens/post-score/components/PostHbhScoreContainer/PostHbhScoreContainer";
import PostHbhScoreRoundSetup from "../../screens/post-score/components/PostHbhScoreRoundSetup/PostHbhScoreRoundSetup";
import PostHbhScoreSummary from "../../screens/post-score/components/PostHbhScoreSummary/PostHbhScoreSummary";
import Navigate from "../navigation/navigate";
import DefaultLayout from "../components/layouts/DefaultLayout";
import MyCoursesList from "../../screens/my-courses/components/MyCoursesList";

export default {
  postScore: {
    path: "/post-score",
    component: PostScore,
    exact: false,
    rootPath: true,
    authRequired: true,
    layout: DefaultLayout,
    navigate: () => Navigate.toPath("/post-score"),
  },
  postScoreTotal: {
    path: "/post-score/total",
    component: PostTotalScoreContainer,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  postScoreTotalSelectCourse: {
    path: "/post-score/total/select-course",
    component: SelectCourse,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  postScoreTotalMyCourses: {
    path: "/post-score/total/my-courses",
    component: MyCoursesList,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  postScoreTotalPost: {
    path: "/post-score/total/post/:courseId",
    component: PostTotalScore,
    exact: false,
    rootPath: false,
    authRequired: true,
    navigate: (courseId) =>
      Navigate.toPath(
        "/post-score/total/post/:courseId".replace(":courseId", courseId)
      ),
  },
  postScoreTotalSummary: {
    path: "/post-score/total/summary",
    component: PostTotalScoreSummary,
    exact: false,
    rootPath: false,
    authRequired: true,
    navigate: (score, courseDetails) =>
      Navigate.toPath({
        pathname: "/post-score/total/summary",
        state: { score: score, courseDetails: courseDetails },
      }),
  },
  postScoreHbh: {
    path: "/post-score/hole-by-hole",
    component: PostHbhScoreContainer,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  postScoreHbhSelectCourse: {
    path: "/post-score/hole-by-hole/select-course",
    component: SelectCourse,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  postScoreHbhMyCourses: {
    path: "/post-score/hole-by-hole/my-courses",
    component: MyCoursesList,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  postScoreHbhRoundSetup: {
    path: "/post-score/hole-by-hole/round-setup/:courseId",
    component: PostHbhScoreRoundSetup,
    exact: false,
    rootPath: false,
    authRequired: true,
    navigate: (courseId) =>
      Navigate.toPath(
        "/post-score/hole-by-hole/round-setup/:courseId".replace(
          ":courseId",
          courseId
        )
      ),
  },
  postScoreHbhSummary: {
    path: "/post-score/hole-by-hole/summary",
    component: PostHbhScoreSummary,
    exact: false,
    rootPath: false,
    authRequired: true,
    navigate: (score, courseDetails, isAdvStatsOn) =>
      Navigate.toPath({
        pathname: "/post-score/hole-by-hole/summary",
        state: {
          score: score,
          courseDetails: courseDetails,
          isAdvStatsOn: isAdvStatsOn,
        },
      }),
  },
  postScoreHbhPost: {
    path: "/post-score/hole-by-hole/post",
    component: PostHbhScore,
    exact: false,
    rootPath: false,
    authRequired: true,
    navigate: (roundSetup, courseDetails) =>
      Navigate.toPath({
        pathname: "/post-score/hole-by-hole/post",
        state: { roundSetup: roundSetup, courseDetails: courseDetails },
      }),
  },
};
