import React, { Component, Fragment } from "react";
import {
  userHasCourse,
  userCoursesCount,
} from "../../../shared/helpers/my-courses-helper";
import YesNoModal from "../../../shared/components/modals/YesNoModal";
import { addCourse, hideCourseSuccess } from "../actions";
import { removeCourse } from "../actions";
import { fetchTees } from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Navigate from "../../../shared/navigation/navigate";
import { showIfExists } from "../../../shared/helpers/ui-helper";

class AddCourseListItem extends Component {
  state = {
    showAddModal: false,
    showDeleteModal: false,
    showNoTeesModal: false,
  };

  onCloseDeleteModal = () => this.setState({ showDeleteModal: false });
  onClickRemove = () => this.setState({ showDeleteModal: true });
  onClickAdd = (teeCount) => {
    if (teeCount > 0) this.setState({ showAddModal: true });
    else this.setState({ showNoTeesModal: true });
  };

  loadTees = () => {
    this.props.fetchTees(this.getCourseId(), this.onClickAdd);
  };

  closeNoTeesModal = () => {
    this.setState({ showNoTeesModal: false });
  };

  getCourseId = () => {
    const { course } = this.props;
    const courseId = course.course_id
      ? course.course_id
      : course.CourseID
      ? course.CourseID
      : course.CourseId;
    return courseId;
  };

  onConfirmRemove = () => {
    this.onCloseDeleteModal();
    const { removeCourse } = this.props;
    removeCourse(this.getCourseId());
  };

  onCloseAddModal = () => {
    this.setState({ showAddModal: false });
    if (userCoursesCount() > 19) this.props.alertMax();
    else {
      const { addCourse, hideCourseSuccess } = this.props;
      setTimeout(() => {
        hideCourseSuccess();
      }, 2000);
      addCourse(this.getCourseId());
    }
  };

  onConfirmAdd = () => {
    this.setState({ showAddModal: false });
    if (userCoursesCount() > 19) this.props.alertMax();
    else {
      const { navLink, addCourse, searchPhrase } = this.props;
      addCourse(this.getCourseId());
      const searchPhraseParam = this.isSearchPhraseValid(searchPhrase)
        ? `?searchPhrase=${searchPhrase}`
        : ``;
      Navigate.toPath(`${navLink}${searchPhraseParam}`);
    }
  };

  isSearchPhraseValid = (searchPhrase) =>
    searchPhrase && searchPhrase.length > 2;

  renderStarButton = () => {
    const isMyCourse = userHasCourse(this.getCourseId());
    return (
      <Fragment>
        {isMyCourse ? (
          <span
            className="btn-ic ic_check"
            onClick={this.onClickRemove}
            onKeyDown={this.onClickRemove}
            aria-hidden="true"
          />
        ) : (
          <span
            className="btn-ic ic_c_plus"
            onClick={this.loadTees}
            onKeyDown={this.loadTees}
            aria-hidden="true"
          />
        )}
      </Fragment>
    );
  };

  getLeftName = (course) =>
    course.facility_name || course.FacilityName
      ? course.facility_name || course.FacilityName
      : course.course_name || course.CourseName;

  getRightName = (course) =>
    (course.facility_name || course.FacilityName) &&
    (course.facility_name || course.FacilityName) !==
      (course.course_name || course.CourseName)
      ? course.course_name || course.CourseName
      : "";

  getLocation = (course) => (
    <Fragment>
      {`${showIfExists(course.City)}
      ${showIfExists(course.State, ", ")}
      ${showIfExists(course.State)}`}
    </Fragment>
  );

  render() {
    const { course } = this.props;
    const { showDeleteModal, showAddModal, showNoTeesModal } = this.state;

    return (
      <Fragment>
        <li key={course.id}>
          <span className="item my-courses-item">
            {this.renderStarButton()}
            <div className="item details center my-courses-details">
              <span className="details center">
                <span className="name">
                  {this.getLeftName(course)}
                  <p className="my-courses-location">
                    {this.getLocation(course)}
                  </p>
                </span>
              </span>
              <span className="index">{this.getRightName(course)}</span>
            </div>
          </span>
        </li>
        <YesNoModal
          isOpen={showDeleteModal}
          onAbort={this.onCloseDeleteModal}
          onConfirm={this.onConfirmRemove}
        >
          <p>{`Are you sure you want to remove ${
            course.course_name ? course.course_name : course.CourseName
          }?`}</p>
        </YesNoModal>
        <YesNoModal
          isOpen={showAddModal}
          onAbort={this.onCloseAddModal}
          onConfirm={this.onConfirmAdd}
        >
          <p>Would you like to add a default tee for this course?</p>
        </YesNoModal>
        <YesNoModal
          onConfirm={this.closeNoTeesModal}
          onAbort={this.closeNoTeesModal}
          isNoVisible={false}
          isOpen={showNoTeesModal}
          yesTitle="OK"
        >
          <p>
            This Course does not have any active or rated tees. Please contact
            your club or local association for assistance.
          </p>
        </YesNoModal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ selectMyCoursesReducer }) => {
  return {
    courses: selectMyCoursesReducer.myCoursesFetchReducer.courses,
    tees: selectMyCoursesReducer.teesFetchReducer.tees,
    fetchTeesStatus: selectMyCoursesReducer.teesFetchReducer.fetchTeesStatus,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addCourse,
      fetchTees,
      removeCourse,
      hideCourseSuccess,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddCourseListItem);
