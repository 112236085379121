import React, { Component, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { routes } from "../../../../shared/routes/index";

export default class PostScore extends Component {
  render = () => (
    <Fragment>
      <Switch>
        <Redirect
          exact
          from={routes.postScore.path}
          to={routes.postScoreTotal.path}
        />
        <Route {...routes.postScoreTotal} />
        <Route {...routes.postScoreHbh} />
      </Switch>
    </Fragment>
  );
}

PostScore.propTypes = {
  match: PropTypes.object.isRequired,
};
