import { updateMinors } from "./login";

export const FETCH_MINORS = "FETCH_MINORS";
export const FETCH_MINORS_FAILED = "FETCH_MINORS_FAILED";
export const FETCH_MINORS_SUCCESS = "FETCH_MINORS_SUCCESS";

export const fetchMinors = () => ({ type: FETCH_MINORS });

export const fetchMinorsFailed = () => ({ type: FETCH_MINORS_FAILED });

export function fetchMinorsSuccess(payload) {
  return (dispatch) => {
    dispatch({ type: FETCH_MINORS_SUCCESS, payload });
    dispatch(updateMinors(payload.golfers));
  };
}
