import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import IframeResizer from "iframe-resizer-react";
import { isUserLoggedIn } from "../../../shared/helpers/user-helper";
import { fetchContactProfile } from "../actions";
import jobStatus from "../../../shared/variables/job-status";
import Loader from "../../../shared/components/loaders/Loader";
import { Helmet } from "react-helmet";
import {
  CONTACT_US_TITLE,
  CONTACT_US_DESC,
} from "../../../shared/variables/meta-seo";

class ContactUs extends Component {
  componentDidMount = () => {
    if (isUserLoggedIn()) {
      this.props.fetchContactProfile();
    }
  };

  getFormData() {
    const { profile, golferActive, trialGolfer } = this.props;
    let golferStatus = golferActive ? "Active" : "Inactive";
    let staging = process.env.REACT_APP_API_HOST.indexOf("stage") !== -1;
    let loggedInId = staging ? "232528026905152" : "202502056677352";
    let notLoggedInId = staging ? "232546960571158" : "201904510334140";
    let trialLoggedIn = "241143739253152";
    const howCan =
      new URLSearchParams(window.location.search).get("howCan") || "";
    if (profile && isUserLoggedIn()) {
      return {
        url: `https://form.jotform.com/${
          trialGolfer ? trialLoggedIn : loggedInId
        }?name[first]=${profile.first_name || ""}&name[last]=${
          profile.last_name || ""
        }&ghinNumber=${profile.ghin || ""}&email=${
          profile.email || ""
        }&golfAssociation=${
          profile.association_name || ""
        }&howCan=${howCan}&golferClubAffiliation=${golferStatus}`,
        minHeight: 700,
      };
    }
    return {
      url: `https://form.jotform.com/${notLoggedInId}?howCan=${howCan}`,
      minHeight: 930,
    };
  }

  renderContent() {
    const { fetchStatus } = this.props;
    if (isUserLoggedIn() && fetchStatus !== jobStatus.SUCCESS)
      return <Loader />;

    const { url, minHeight } = this.getFormData();
    return (
      <IframeResizer
        minHeight={minHeight}
        title="Contact Us"
        width="100%"
        src={url}
      />
    );
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{CONTACT_US_TITLE}</title>
          <meta name="description" content={CONTACT_US_DESC} />
        </Helmet>
        <div className="page_container contact">
          <section>
            <div className="box-content">
              <h1>Contact Us</h1>
            </div>
            {this.renderContent()}
          </section>
        </div>
      </Fragment>
    );
  }
}

ContactUs.propTypes = {
  profile: PropTypes.object,
  fetchStatus: PropTypes.string.isRequired,
  fetchContactProfile: PropTypes.func.isRequired,
};

ContactUs.defaultProps = {
  profile: null,
};

const mapStateToProps = ({ contactUsReducer, profileReducer }) => ({
  profile: contactUsReducer.contactProfileFetch.golferProfile,
  fetchStatus: contactUsReducer.contactProfileFetch.fetchContactProfileStatus,
  golferActive: profileReducer.golferProfileFetchReducer.golferActive,
  trialGolfer: profileReducer.golferProfileFetchReducer.trialGolfer,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchContactProfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
