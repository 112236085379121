import {
  FETCH_ABOUT_DETAILS,
  fetchAboutDetailsFailed,
  fetchAboutDetailsSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import AssociationsApi from "../../../services/associations-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_ABOUT_DETAILS))
    .pipe(switchMap(({ payload }) => fetchAboutDetails(payload)));

const fetchAboutDetails = (payload) =>
  from(AssociationsApi.getAssociationSettings(payload.associationId))
    .pipe(
      map((response) =>
        fetchAboutDetailsSuccess(response.data.association_settings)
      )
    )
    .pipe(catchError((error) => of(fetchAboutDetailsFailed(error))));
