import { combineEpics } from "redux-observable";
import {
  FETCH_CHILD_SCORES,
  fetchChildScoresSuccess,
  fetchChildScoresFailed,
  FETCH_MAXIMUM_HOLE_SCORES,
  fetchMaximumHoleScoresSucces,
  fetchMaximumHoleScoresFailed,
  FETCH_SCORE_COURSE_DETAILS,
  fetchScoreCourseDetailsSucces,
  fetchScoreCourseDetailsFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError, flatMap } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";

///////////////////////////////////////////////////////////////////////////////
export const fetchChildScores = (action$) =>
  action$
    .pipe(ofType(FETCH_CHILD_SCORES))
    .pipe(switchMap(({ payload }) => getChildScores(payload)));

const getChildScores = (payload) =>
  from(ScoresApi.getChildScores(payload.scoreId, payload.golferId))
    .pipe(
      map((response) =>
        fetchChildScoresSuccess(response.data.scores, payload.onlyScores)
      )
    )
    .pipe(catchError((error) => of(fetchChildScoresFailed(error))));

///////////////////////////////////////////////////////////////////////////////
export const fetchMaximumHoleScores = (action$) =>
  action$
    .pipe(ofType(FETCH_MAXIMUM_HOLE_SCORES))
    .pipe(flatMap(({ payload }) => getMaximumHoleScores(payload)));

const getMaximumHoleScores = (payload) =>
  from(ScoresApi.getMaximumHoleScores(payload.teeSetId, payload.teeSetSide))
    .pipe(
      map((response) =>
        fetchMaximumHoleScoresSucces(
          response.data.maximum_scores,
          payload.scoreId
        )
      )
    )
    .pipe(catchError((error) => of(fetchMaximumHoleScoresFailed(error))));

///////////////////////////////////////////////////////////////////////////////
export const fetchScoreCourseDetails = (action$) =>
  action$
    .pipe(ofType(FETCH_SCORE_COURSE_DETAILS))
    .pipe(flatMap(({ payload }) => getScoreCourseDetails(payload)));

const getScoreCourseDetails = (payload) =>
  from(ScoresApi.getScoreCourseDetails(payload.courseId))
    .pipe(
      map((response) =>
        fetchScoreCourseDetailsSucces(response.data, payload.scoreId)
      )
    )
    .pipe(catchError((error) => of(fetchScoreCourseDetailsFailed(error))));

export default combineEpics(
  fetchChildScores,
  fetchMaximumHoleScores,
  fetchScoreCourseDetails
);
