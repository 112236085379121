import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAboutDetails } from "../actions";
import jobStatus from "../../../shared/variables/job-status";
import Loader from "../../../shared/components/loaders/Loader";

class AboutGhin extends Component {
  componentDidMount() {
    this.props.fetchAboutDetails(56);
  }

  renderContent() {
    const { settings } = this.props;
    return (
      <div className="box-panel">
        <p className="bigstart">{settings.usga_about_text}</p>
      </div>
    );
  }

  render() {
    const { fetchStatus } = this.props;
    return (
      <Fragment>
        <div className="page_container about">
          <div className="box-content">
            <h1>About GHIN</h1>
          </div>
          {fetchStatus === jobStatus.SUCCESS ? (
            this.renderContent()
          ) : (
            <Loader />
          )}
        </div>
      </Fragment>
    );
  }
}

AboutGhin.propTypes = {
  fetchAboutDetails: PropTypes.func.isRequired,
  fetchStatus: PropTypes.string.isRequired,
  settings: PropTypes.shape({}),
};

AboutGhin.defaultProps = {
  settings: undefined,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchAboutDetails }, dispatch);

const mapStateToProps = ({ aboutGhinReducer }) => ({
  settings: aboutGhinReducer.aboutDetailsReducer.settings,
  fetchStatus: aboutGhinReducer.aboutDetailsReducer.jobStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutGhin);
