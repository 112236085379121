import React, { Component } from "react";
import YesNoModal from "./YesNoModal";

class PccInfoModal extends Component {
  render() {
    return (
      <YesNoModal
        {...this.props}
        className="modal-dialog"
        isNoVisible={false}
        yesTitle="Ok"
      >
        <p>
          Playing Conditions Calculation is applied in the calculation of a
          score differential if the course or weather conditions on a day of
          play significantly impacted players performances.
        </p>
      </YesNoModal>
    );
  }
}

export default PccInfoModal;
