import React, { Component, Fragment } from "react";
import CoursesSearch from "../../select-course/components/CoursesSearch";
import { NavLink } from "react-router-dom";
import { routes } from "../../../shared/routes";
import { hidden } from "../../../shared/helpers/ui-helper";
import CoursesFilter from "../../select-course/components/CoursesFilter";
import jobStatus from "../../../shared/variables/job-status";
import Loader from "../../../shared/components/loaders/Loader";
import NoResultsFound from "../../../shared/components/notices/NoResultsFound";
import { bindActionCreators } from "redux";
import {
  fetchCountriesSelectCourse,
  fetchMyCourses,
  fetchCourses,
} from "../actions";
import { connect } from "react-redux";
import MyCoursesListItems from "./MyCoursesListItems";
import { findExactRoute } from "../../../shared/helpers/route-helper";
import Navigate from "../../../shared/navigation/navigate";

class MyCoursesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPhrase: "",
      country: undefined,
      state: undefined,
    };
  }

  componentDidMount = () => {
    this.props.fetchMyCourses();
    this.props.fetchCountriesSelectCourse();
  };

  fetchCourses = (searchPhrase, country, state) => {
    this.setState({
      searchPhrase: searchPhrase,
      country: country,
      state: state,
    });

    this.props.fetchCourses(searchPhrase, country, state);
  };

  handleSearchPhraseChanged = (search) => {
    const { country, state, searchPhrase } = this.state;

    if (this.isSearchPhraseValid(search)) {
      this.fetchCourses(search, country, state);
    } else if (this.isSearchPhraseValid(searchPhrase)) {
      this.fetchCourses("", country, state);
    }
  };

  handleLocationChanged = (location) =>
    this.fetchCourses(
      this.state.searchPhrase,
      location.country,
      location.courseCode
    );

  isSearchPhraseValid = (searchPhrase) =>
    searchPhrase && searchPhrase.length > 2;

  isFilterVisible = (searchPhrase) => this.isSearchPhraseValid(searchPhrase);

  coursesListEmpty = () => this.props.courses.length > 0;

  isTotalScore = () => {
    const route = findExactRoute(this.props.match.path);
    return route.path === routes.postScoreTotalMyCourses.path;
  };

  getNavLink() {
    if (!this.isHandicapCalculatorPage()) {
      if (this.isTotalScore()) return routes.postScoreTotalPost.path;
      else return routes.postScoreHbhRoundSetup.path;
    } else {
      return routes.handicapCalculatorCalculator.path;
    }
  }

  getTabLinkMyCourses() {
    if (!this.isHandicapCalculatorPage()) {
      if (this.isTotalScore()) return routes.postScoreTotalMyCourses.path;
      else return routes.postScoreHbhMyCourses.path;
    } else {
      return routes.handicapCalculatorMyCourses.path;
    }
  }

  getTabLinkPostScore() {
    if (!this.isHandicapCalculatorPage()) {
      if (this.isTotalScore()) return routes.postScoreTotalSelectCourse.path;
      else return routes.postScoreHbhSelectCourse.path;
    } else {
      return routes.handicapCalculatorSelectCourse.path;
    }
  }

  isHandicapCalculatorPage = () => {
    const route = findExactRoute(this.props.match.path);
    return route.path === routes.handicapCalculatorMyCourses.path;
  };

  render() {
    const { searchPhrase } = this.state;
    const {
      courses,
      fetchCoursesStatus,
      countries,
      searchMode,
      useScaling,
      hasHandicapIndex,
    } = this.props;

    const post_score_page = this.props.match.path.includes("post-score/total");
    return (
      <Fragment>
        <div className="container">
          <section className="post-score">
            <Fragment>
              <div className="box">
                <div className="box-head">
                  <h1 className="box-title">Select Course</h1>
                  <div className="box-actions">
                    <CoursesSearch
                      onChange={(value) =>
                        this.handleSearchPhraseChanged(value)
                      }
                    />
                  </div>
                </div>

                <div>
                  <ul className="tabs-my-courses rounded">
                    <li>
                      <NavLink
                        to={this.getTabLinkPostScore()}
                        activeClassName="is-active"
                      >
                        Recently Played
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={this.getTabLinkMyCourses()}
                        activeClassName="is-active"
                      >
                        My Courses
                      </NavLink>
                    </li>
                  </ul>
                </div>

                <div className="box-body">
                  <div className="item-list courses">
                    <div
                      className={hidden(!this.isFilterVisible(searchPhrase))}
                    >
                      <CoursesFilter
                        countries={countries}
                        onSelect={(selection) =>
                          this.handleLocationChanged(selection)
                        }
                      />
                    </div>
                    {fetchCoursesStatus === jobStatus.PROCESSING ? (
                      <Loader />
                    ) : this.coursesListEmpty() ? (
                      <MyCoursesListItems
                        courses={courses}
                        searchMode={searchMode}
                        navItemLink={this.getNavLink()}
                      />
                    ) : (
                      <NoResultsFound>
                        <p style={{ margin: "30px 0" }}>
                          There are no courses selected. Please add a course to
                          your list.
                        </p>
                      </NoResultsFound>
                    )}
                  </div>
                </div>
                <div className="box-footer">
                  <button
                    className="btn lnk blue no-pad small"
                    onClick={() =>
                      Navigate.toPath(routes.myCoursesSelectCourse.path)
                    }
                    role="link"
                  >
                    Update My Courses
                  </button>
                </div>
                {post_score_page && useScaling && hasHandicapIndex && (
                  <div
                    className="box-footer"
                    style={{ justifyContent: "center" }}
                  >
                    <p>
                      If you played 10-17 holes, you must post your score using
                      the hole-by-hole method.
                    </p>
                  </div>
                )}
              </div>
            </Fragment>
          </section>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ selectMyCoursesReducer, profileReducer }) => {
  const handicapIndex =
    profileReducer.golferProfileFetchReducer.golferProfile?.handicap_index;

  return {
    courses:
      selectMyCoursesReducer.allCoursesFetchReducer.courses.length !== 0
        ? selectMyCoursesReducer.allCoursesFetchReducer.courses
        : selectMyCoursesReducer.myCoursesFetchReducer.courses,
    fetchCoursesStatus:
      selectMyCoursesReducer.allCoursesFetchReducer.courses.length !== 0
        ? selectMyCoursesReducer.allCoursesFetchReducer.fetchCoursesStatus
        : selectMyCoursesReducer.myCoursesFetchReducer.fetchCoursesStatus,
    countries: selectMyCoursesReducer.countriesFetchReducer.countries,
    searchMode:
      selectMyCoursesReducer.allCoursesFetchReducer.fetchCoursesStatus ===
      jobStatus.PROCESSING
        ? selectMyCoursesReducer.myCoursesFetchReducer.searchMode
        : selectMyCoursesReducer.allCoursesFetchReducer.searchMode,
    useScaling:
      profileReducer.golferProfileFetchReducer.golferProfile?.use_scaling,
    hasHandicapIndex: handicapIndex != "NH" && handicapIndex != "WD",
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchMyCourses, fetchCountriesSelectCourse, fetchCourses },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyCoursesList);
