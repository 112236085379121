import React, { Component, Fragment } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { routes } from "../../../shared/routes";
import { setEvent } from "../../../firebaseConfig";
import { getUser } from "../../../shared/helpers/user-helper";

import { GHIN_REWIND_YEAR } from "../year-end-constants";

export default class EndOfYearCampaignImage extends Component {
  componentDidMount() {
    const user = getUser();
    setEvent(user, "Year_End_Campaign_Display", "Year", GHIN_REWIND_YEAR);
  }

  setShareEvent = (app_name) => {
    const user = getUser();
    setEvent(user, "GHIN_Rewind_Share", "Share_Destination", app_name);
  };

  render() {
    const { image } = this.props;
    const image_url = image.image.split("/")[3].split(".")[0];
    const showShareButton =
      window.location.pathname.split("/")[2] !=
      image.image.split("/")[3].split(".")[0];
    if ("errors" in image || image?.image === null)
      return (
        <h3 style={{ textAlign: "center", marginTop: "50%" }}>
          Could not load statistics.
        </h3>
      );
    else
      return (
        <Fragment>
          {showShareButton ? (
            <img
              src={image.image}
              className="end-of-year-image"
              alt="end-of-year-campaign"
            />
          ) : (
            <div className="end-of-year-share-image">
              <img
                src={image.image}
                className="end-of-year-image"
                alt="end-of-year-campaign"
                style={{ maxHeight: "100vh" }}
              />
            </div>
          )}
          {showShareButton && (
            <div className="buttons-container">
              <FacebookShareButton
                url={
                  window.location.origin +
                  routes.shareEndOfYearImage.path +
                  "/" +
                  image_url
                }
                beforeOnClick={() => this.setShareEvent("Facebook")}
                hashtag={"#MyGHINRewind"}
              >
                <FacebookIcon size={50} round={true} className="share-button" />
              </FacebookShareButton>

              <TwitterShareButton
                url={
                  window.location.origin +
                  routes.shareEndOfYearImage.path +
                  "/" +
                  image_url
                }
                title={"#MyGHINRewind"}
                beforeOnClick={() => this.setShareEvent("X")}
              >
                <div className="twitter_icon" />
              </TwitterShareButton>
            </div>
          )}
        </Fragment>
      );
  }
}
