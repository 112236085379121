import BaseApi from "./base-api";
import { objToQuerystring } from "../shared/helpers/querystring-helper";

class SupportingCalculationApi extends BaseApi {
  apiUrl = `${this.baseUrl}/api/v1`;

  getCourseHandicaps = (
    golferId,
    courseId,
    playedAt,
    manualGolferId,
    handicapIndex
  ) => {
    const url = `${this.apiUrl}/course_handicaps.json`;
    let params = { course_id: courseId };
    if (manualGolferId !== undefined) {
      params = { ...params, handicap_index: handicapIndex };
    } else {
      params = { ...params, golfer_id: golferId, played_at: playedAt };
    }

    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getManualCourseHandicaps = (
    golferId,
    courseRating,
    slopeRating,
    nrOfHoles
  ) => {
    const url = `${this.apiUrl}/manual_course_handicap.json`;

    let params = {
      golfer_id: golferId,
      course_rating: courseRating,
      slope_rating: slopeRating,
      number_of_holes: nrOfHoles,
    };

    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getMaxHbhScore = (golferId, teeSetId, teeSetSide, playedAt) => {
    const url = `${this.apiUrl}/maximum_hole_scores.json`;

    let params = {
      golfer_id: golferId,
      tee_set_id: teeSetId,
      tee_set_side: teeSetSide,
      played_at: playedAt,
      include_altered_tees: true,
    };

    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getManualCourseHandicap = (
    handicapIndex,
    courseRating,
    slopeRating,
    par,
    handicapAllowance
  ) => {
    const url = `${this.apiUrl}/manual_course_handicap.json`;

    let params = {
      handicap_index: handicapIndex,
      course_rating: courseRating,
      slope_rating: slopeRating,
      par,
      handicap_allowance: handicapAllowance,
    };

    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getPlayingHandicaps = (golfers) => {
    const url = `${this.apiUrl}/playing_handicaps.json`;

    let params = {
      golfers,
    };
    return this.httpPost(url, params);
  };
}

export default new SupportingCalculationApi();
