import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Login from "./Login";
import ChooseAccount from "./ChooseAccount";
import PreAuthorization from "./PreAuthorization";
import { isUserLoggedIn } from "../../../shared/helpers/user-helper";
import Loader from "../../../shared/components/loaders/Loader";
import { createOAuthReturnUrl } from "../../../shared/helpers/user-helper";
import GolferApi from "../../../services/golfer-api";
import { routes } from "../../../shared/routes";
import Navigate from "../../../shared/navigation/navigate";
import YesNoModal from "../../../shared/components/modals/YesNoModal";

class Auth extends Component {
  state = {
    forceSignIn: false,
    returnUrl: undefined,
    loadingAuthorize: false,
    displayPreAuthorization: false,
    redirectUri: undefined,
    responseError: undefined,
  };

  componentDidMount() {
    const returnUrl = createOAuthReturnUrl(this.props.location.search);
    if (returnUrl) {
      this.setState({ returnUrl });
    } else {
      Navigate.resetToPath(routes.login.path);
    }
  }

  authorizeUser = () => {
    const { returnUrl } = this.state;
    const { userToken, loginWithGhinToken } = this.props;
    this.setState({ loadingAuthorize: true });
    GolferApi.authorizeLoginWithGhin(returnUrl, userToken || loginWithGhinToken)
      .then((response) => {
        if (response?.data?.status === "Pre-authorization") {
          this.setState({
            displayPreAuthorization: true,
            loadingAuthorize: false,
            redirectUri: response?.data?.redirect_uri,
          });
        } else {
          if (response?.data?.redirect_uri) {
            this.redirectAction(response?.data?.redirect_uri);
          }
        }
      })
      .catch((error) => {
        this.receiveAuthorizeOrDenyError(error);
      });
  };

  useAnotherAccount = () => this.setState({ forceSignIn: true });

  denyAuthorize = () => {
    const { returnUrl } = this.state;
    const { userToken, loginWithGhinToken } = this.props;
    this.setState({ loadingAuthorize: true });
    GolferApi.denyAuthorize(returnUrl, userToken || loginWithGhinToken)
      .then((response) => {
        if (response?.data?.redirect_uri) {
          this.redirectAction(response?.data?.redirect_uri);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.redirect_uri) {
          this.redirectAction(error?.response?.data?.redirect_uri);
        } else {
          this.receiveAuthorizeOrDenyError(error);
        }
      });
  };

  confirmAuthorize = () => {
    const { returnUrl } = this.state;
    const { userToken, loginWithGhinToken } = this.props;
    this.setState({ loadingAuthorize: true });
    GolferApi.preAuthorizeLoginWithGhin(
      returnUrl,
      userToken || loginWithGhinToken
    )
      .then((response) => {
        if (response?.data?.redirect_uri) {
          this.redirectAction(response?.data?.redirect_uri);
        }
      })
      .catch((error) => {
        this.receiveAuthorizeOrDenyError(error);
      });
  };

  redirectAction(uri) {
    window.location.href = uri;
  }

  receiveAuthorizeOrDenyError = (error) => {
    this.setState({
      loadingAuthorize: false,
      displayPreAuthorization: false,
    });
    this.receiveResponseError(error?.response?.data?.errors);
  };

  receiveResponseError = (responseError) => this.setState({ responseError });

  closeModalError = () =>
    this.setState({ responseError: undefined, forceSignIn: true });

  renderContent() {
    const { golfers, guardianId } = this.props;
    const { forceSignIn, loadingAuthorize, displayPreAuthorization } =
      this.state;
    if (displayPreAuthorization)
      return (
        <PreAuthorization
          denyAuthorize={this.denyAuthorize}
          confirmAuthorize={this.confirmAuthorize}
        />
      );
    if (loadingAuthorize) return <Loader />;
    if (!isUserLoggedIn() || forceSignIn)
      return <Login authorizeUser={this.authorizeUser} />;
    return (
      <ChooseAccount
        golfer={golfers[0]}
        guardianId={guardianId}
        useAnotherAccount={this.useAnotherAccount}
        authorizeUser={this.authorizeUser}
        receiveResponseError={this.receiveResponseError}
      />
    );
  }

  render() {
    const { responseError } = this.state;
    return (
      <>
        {this.renderContent()}
        <YesNoModal
          onConfirm={this.closeModalError}
          onAbort={this.closeModalError}
          isOpen={!!responseError}
          isYesVisible={true}
          isNoVisible={false}
          yesTitle="Ok"
        >
          <p>{responseError}</p>
        </YesNoModal>
      </>
    );
  }
}

Auth.propTypes = {
  golfers: PropTypes.array,
};

const mapStateToProps = ({ authReducer, loginWithGhinReducer }) => ({
  golfers: authReducer.loginReducer.golfers,
  guardianId: authReducer.loginReducer.guardianId,
  userToken: loginWithGhinReducer.loginReducer.userToken,
  loginWithGhinToken: loginWithGhinReducer.loginReducer.loginWithGhinToken,
});

export default connect(mapStateToProps, null)(Auth);
