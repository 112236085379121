import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import PostHbhTable from "./PostHbhTable";
import AdvancedStats from "./AdvancedStats";
import { store } from "../../../../shared/store";
import {
  initialValues,
  validateAdvStats,
  validateFields,
  validationSchema,
} from "../../validations/post-hbh-score-validation";
import { Formik } from "formik";
import ratingType from "../../../../shared/variables/rating-type";
import PostScoreSubmit from "./PostScoreSubmit";
import numberOfHoles from "../../../../shared/variables/number-of-holes";
import IncompleteScorePostModal from "../Shared/Modals/IncompleteScorePostModal";
import {
  clearAllScores,
  clearAllStats,
  getVisibleHoles,
  scrollHbhSide,
} from "../../helpers/post-hbh-score-helper";
import MobileHeaderMiniScroll from "../Shared/Misc/MobileHeaderMiniScroll";
import EditScoreSubmit from "./EditScoreSubmit";
import { postScoreMode } from "../../variables/post-score-variables";
import IncompleteUpdatedStatsPostModal from "../Shared/Modals/IncompleteUpdatedStatsPostModal";
import jobStatus from "../../../../shared/variables/job-status";
import {
  setGir,
  calculateGirFromParAndScoreAndPutts,
} from "../../validations/post-hbh-score-calculation";

class PostHbhForm extends Component {
  formOptions;
  scoreInputRefs = [];
  puttsInputRefs = [];
  fromikRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      incompleteScorePostModalOpen: false,
      incompleteUpdatedStatsPostModalOpen: false,
    };
  }

  componentDidMount() {
    this.fromikRef.current?.validateForm();
  }

  render() {
    const {
      courseDetails,
      roundSetup,
      maxHbhScore,
      initialHoleValues,
      withAdvancedStats,
      totalCombinedScores,
      combinedScoresForm,
      useScaling,
      hasHandicapIndex,
    } = this.props;
    return (
      <Formik
        ref={this.fromikRef}
        onSubmit={(values) => this.onSubmit(values, true)}
        validate={(values) =>
          this.props.postScoreMode === postScoreMode.POST
            ? validateFields(values, roundSetup, useScaling, hasHandicapIndex)
            : {}
        }
        initialValues={initialValues(
          courseDetails,
          roundSetup,
          maxHbhScore,
          initialHoleValues,
          withAdvancedStats,
          totalCombinedScores,
          combinedScoresForm
        )}
        validationSchema={validationSchema}
      >
        {(options) => {
          this.formOptions = options;
          return this.renderForm(options);
        }}
      </Formik>
    );
  }

  renderForm(options) {
    this.scoreInputRefs = [];
    this.puttsInputRefs = [];
    const { customSubmit } = this.props;
    return (
      <Fragment>
        {this.shouldRenderSide(ratingType.FRONT) && this.renderFront(options)}
        {this.shouldRenderSide(ratingType.BACK) && this.renderBack(options)}
        {customSubmit ? customSubmit(options) : this.renderSubmit(options)}
        {this.renderIncompleteScoreConfirmation(options)}
        {this.renderIncompleteUpdatedStatsConfirmation(options)}
      </Fragment>
    );
  }

  onChangeScore = (hole, options) => {
    this.tryToAutoCompleteGir(hole, options);
  };

  onChangePuts = (hole, options) => {
    this.tryToAutoCompleteGir(hole, options);
  };

  tryToAutoCompleteGir = (hole, options) => {
    if (
      options.values.withAdvancedStats &&
      !hole.gir.manually &&
      hole.putts !== "" &&
      hole.score !== ""
    ) {
      const girValue = calculateGirFromParAndScoreAndPutts(
        hole.par,
        hole.score,
        hole.putts
      );
      if (girValue) {
        setGir(hole, girValue, options);
      }
    }
    this.props.reCalculateStats();
  };

  renderFront = (options) => (
    <>
      <div onScroll={(event) => scrollHbhSide(event, ratingType.FRONT)}>
        <PostHbhTable
          options={options}
          side={ratingType.FRONT}
          roundSide={this.props.roundSetup.tees.RatingType}
          autoFocusScore={this.props.postScoreMode === postScoreMode.POST}
          scoreInputRefs={this.scoreInputRefs}
          showSideTotalsColumn={this.shouldRenderSideTotals()}
          sideTotalsColumnTitle="OUT"
          showTotalsColumn={this.shouldRenderTotals(ratingType.FRONT)}
          isScoreReadOnly={this.props.isScoreReadOnly}
          onChangeScore={this.onChangeScore}
          lastTable={
            this.props.combinedScoresForm && options.values.totalCombinedScores
          }
        />
        <div className={this.getAdvStatsClass(options)}>
          <AdvancedStats
            options={options}
            side={ratingType.FRONT}
            onChangePuts={this.onChangePuts}
            puttsInputRefs={this.puttsInputRefs}
            onReCalculateStats={this.props.reCalculateStats}
            showSideTotalsColumn={this.shouldRenderSideTotals()}
            showTotalsColumn={this.shouldRenderTotals(ratingType.FRONT)}
            lastTable={
              this.props.combinedScoresForm &&
              options.values.totalCombinedScores
            }
          />
        </div>
      </div>
      <br />
      <br />
    </>
  );

  renderBack = (options) => (
    <div onScroll={(event) => scrollHbhSide(event, ratingType.BACK)}>
      {this.props.roundSetup.tees.RatingType === ratingType.TOTAL && (
        <div className="pre-table">
          <MobileHeaderMiniScroll side={ratingType.BACK} />
        </div>
      )}
      <PostHbhTable
        ref={(ref) => (this.backTable = ref)}
        options={options}
        side={ratingType.BACK}
        roundSide={this.props.roundSetup.tees.RatingType}
        autoFocusScore={this.props.postScoreMode === postScoreMode.POST}
        scoreInputRefs={this.scoreInputRefs}
        showSideTotalsColumn={this.shouldRenderSideTotals()}
        sideTotalsColumnTitle="IN"
        showTotalsColumn={this.shouldRenderTotals(ratingType.BACK)}
        isScoreReadOnly={this.props.isScoreReadOnly}
        onChangeScore={this.onChangeScore}
        lastTable={
          (this.props.combinedScoresForm &&
            options.values.totalCombinedScores) ||
          (!this.props.combinedScoresForm &&
            options.nrOfHoles !== numberOfHoles.NINE &&
            options.values.tees.RatingType !== ratingType.BACK)
        }
      />
      <div className={this.getAdvStatsClass(options)}>
        <AdvancedStats
          options={options}
          side={ratingType.BACK}
          onChangePuts={this.onChangePuts}
          puttsInputRefs={this.puttsInputRefs}
          onReCalculateStats={this.props.reCalculateStats}
          showSideTotalsColumn={this.shouldRenderSideTotals()}
          showTotalsColumn={this.shouldRenderTotals(ratingType.BACK)}
          lastTable={
            (this.props.combinedScoresForm &&
              options.values.totalCombinedScores) ||
            (!this.props.combinedScoresForm &&
              options.nrOfHoles !== numberOfHoles.NINE &&
              options.values.tees.RatingType !== ratingType.BACK)
          }
        />
      </div>
    </div>
  );

  renderIncompleteScoreConfirmation = (options) => (
    <IncompleteScorePostModal
      isOpen={this.state.incompleteScorePostModalOpen}
      onAbort={() => this.setState({ incompleteScorePostModalOpen: false })}
      onConfirm={() => {
        this.setState({ incompleteScorePostModalOpen: false });
        this.onSubmit(options.values, false);
      }}
    />
  );

  renderIncompleteUpdatedStatsConfirmation = (options) => (
    <IncompleteUpdatedStatsPostModal
      isOpen={this.state.incompleteUpdatedStatsPostModalOpen}
      onAbort={() =>
        this.setState({ incompleteUpdatedStatsPostModalOpen: false })
      }
      onConfirm={() => {
        this.setState({ incompleteScorePostModalOpen: false });
        this.onSubmit(options.values, false);
      }}
    />
  );

  renderSubmit = (options) =>
    this.props.postScoreMode === postScoreMode.POST ? (
      <PostScoreSubmit
        options={options}
        alertInvalidScore={this.props.alertInvalidScore}
      />
    ) : (
      store.getState().profileReducer.golferProfileFetchReducer
        .golferActive && (
        <EditScoreSubmit
          options={options}
          viewScoreCard={this.props.viewScoreCard}
          onAbort={this.props.onAbort}
        />
      )
    );

  shouldRenderSideTotals = () =>
    this.props.showSideTotalsColumn ||
    this.props.roundSetup.tees.RatingType === ratingType.TOTAL;

  shouldRenderTotals = (side) => {
    if (this.props.showCombinedScoresTotals !== undefined) {
      return this.props.showCombinedScoresTotals;
    }
    const roundSide = this.props.roundSetup.tees.RatingType;
    return (
      roundSide === side ||
      (roundSide === ratingType.TOTAL && side === ratingType.BACK)
    );
  };

  shouldRenderSide = (sideToRender) => {
    const side = this.props.roundSetup.tees.RatingType;
    return side === sideToRender || side === ratingType.TOTAL;
  };

  onSubmit = (values, withIncompleteScoreValidation) => {
    const { postHbhScoreStatus } = this.props;
    if (postHbhScoreStatus !== jobStatus.PROCESSING) {
      if (this.props.postScoreMode === postScoreMode.POST) {
        if (
          this.isFormValidOnPostScoreSubmission(
            values,
            withIncompleteScoreValidation
          )
        ) {
          this.props.onSubmit(values);
        }
      } else {
        if (
          this.isFormValidOnEditScoreSubmission(
            values,
            withIncompleteScoreValidation
          )
        ) {
          this.props.onSubmit(values);
        }
      }
    }
  };

  isFormValidOnEditScoreSubmission = (
    values,
    withIncompleteScoreValidation
  ) => {
    const { alertInvalidScore, roundSetup } = this.props;
    const visibleHoles = getVisibleHoles(values, roundSetup.tees.RatingType);
    let isFormValid = true;

    if (
      values.withAdvancedStats === true &&
      visibleHoles.some((hole) => hole.adjScore && hole.adjScore <= hole.putts)
    ) {
      isFormValid = false;
      alertInvalidScore("Putts cannot be equal to or greater than score.");
    } else if (
      withIncompleteScoreValidation === true &&
      !validateAdvStats(roundSetup, values)
    ) {
      isFormValid = false;
      this.setState({ incompleteUpdatedStatsPostModalOpen: true });
    }

    return isFormValid;
  };

  isFormValidOnPostScoreSubmission = (
    values,
    withIncompleteScoreValidation
  ) => {
    const { alertInvalidScore, roundSetup } = this.props;
    const visibleHoles = getVisibleHoles(values, roundSetup.tees.RatingType);
    let isFormValid = true;

    if (
      values.withAdvancedStats === true &&
      visibleHoles.some(
        (hole) => hole.adjScore !== "" && hole.adjScore <= hole.putts
      )
    ) {
      isFormValid = false;
      alertInvalidScore("Putts cannot be equal to or greater than score.");
    } else if (
      withIncompleteScoreValidation === true &&
      !validateAdvStats(roundSetup, values)
    ) {
      isFormValid = false;
      this.setState({ incompleteScorePostModalOpen: true });
    }

    return isFormValid;
  };

  toggleAdvancedStats = () =>
    this.formOptions.setFieldValue(
      "withAdvancedStats",
      !this.formOptions.values.withAdvancedStats,
      true
    );

  showAdvancedStats = () =>
    this.formOptions.setFieldValue("withAdvancedStats", true, true);

  clearAllScores = () => clearAllScores(this.formOptions);

  clearAllStats = () => clearAllStats(this.formOptions);

  getAdvStatsClass = (options) =>
    options.values.withAdvancedStats ? "" : "hidden-advanced-stats";

  getFormValues = () => this.formOptions.values;

  getFormOptions = () => this.formOptions;

  updateTotalCombinedScores(totalCombinedScores) {
    this.formOptions.values.totalCombinedScores = {
      ...this.formOptions.values.totalCombinedScores,
      ...totalCombinedScores,
    };
    this.forceUpdate();
  }
}

PostHbhForm.defaultProps = {
  initialHoleValues: [],
  postScoreMode: postScoreMode.POST,
  viewScoreCard: false,
  onAbort: () => {},
  withAdvancedStats: false,
  customSubmit: undefined,
  showSideTotalsColumn: false,
  showCombinedScoresTotals: undefined,
  totalCombinedScores: undefined,
  reCalculateStats: () => {},
  combinedScoresForm: false,
  useScaling: false,
  hasHandicapIndex: false,
};

PostHbhForm.propTypes = {
  courseDetails: PropTypes.object.isRequired,
  roundSetup: PropTypes.object.isRequired,
  maxHbhScore: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  alertInvalidScore: PropTypes.func.isRequired,
  isScoreReadOnly: PropTypes.bool.isRequired,
  viewScoreCard: PropTypes.bool.isRequired,
  postScoreMode: PropTypes.string.isRequired,
  initialHoleValues: PropTypes.array.isRequired,
  postHbhScoreStatus: PropTypes.string,
  withAdvancedStats: PropTypes.bool,
  customSubmit: PropTypes.func,
  showSideTotalsColumn: PropTypes.bool,
  showCombinedScoresTotals: PropTypes.bool,
  totalCombinedScores: PropTypes.object,
  reCalculateStats: PropTypes.func,
  combinedScoresForm: PropTypes.bool,
  useScaling: PropTypes.bool,
  hasHandicapIndex: PropTypes.bool,
};

export default PostHbhForm;
