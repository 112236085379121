import React, { Component } from "react";
import PropTypes from "prop-types";

class CoursesTitle extends Component {
  renderManualInput() {
    const { manuallyHandicapInput } = this.props;
    if (!manuallyHandicapInput) return null;
    return (
      <div className="item-list__action">
        <button
          onClick={manuallyHandicapInput}
          className="btn lnk blue no-pad small type-z-button"
        >
          Manually Calculate Handicap
        </button>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="item-list__head">
          <h2 className="item-list__title">{this.props.title}</h2>
        </div>
        {this.renderManualInput()}
      </>
    );
  }
}

CoursesTitle.propTypes = {
  title: PropTypes.string.isRequired,
  manuallyHandicapInput: PropTypes.func,
};

CoursesTitle.defaultProps = {
  manuallyHandicapInput: null,
};

export default CoursesTitle;
