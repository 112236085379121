import BaseApi from "./base-api";
import { objToQuerystring } from "../shared/helpers/querystring-helper";
import { getGolferId } from "../shared/helpers/api-helper";

class CoursesApi extends BaseApi {
  apiUrl = `${this.baseUrl}/api/v1/crsCourseMethods.asmx`;

  getCourses = (name, country, state) => {
    const url = `${this.apiUrl}/SearchCourses.json`;
    let params = {};

    if (name) params = Object.assign(params, { name: name });
    if (country) params = Object.assign(params, { country: country });
    if (state) params = Object.assign(params, { state: state });

    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getCourseDetails = (courseId, includeAlteredTees = false) => {
    const url = `${this.apiUrl}/GetCourseDetails.json`;
    const params = {
      courseId: courseId,
      include_altered_tees: includeAlteredTees,
    };

    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getHomeCourses = () => {
    const url = `${
      this.baseUrl
    }/api/v1/golfers/${getGolferId()}/facility_home_courses.json`;
    return this.httpGet(url);
  };

  getMostRecentCourses = () => {
    const url = `${
      this.baseUrl
    }/api/v1/golfers/${getGolferId()}/golfer_most_recent_courses.json`;
    const params = { scores_to_use: 20, include_altered_tees: true };
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getMyCourses = () => {
    const url = `${
      this.baseUrl
    }/api/v1/golfers/${getGolferId()}/my_courses.json`;
    return this.httpGet(url);
  };

  postMyCourses = (courseId) => {
    const url = `${
      this.baseUrl
    }/api/v1/golfers/${getGolferId()}/my_courses/${courseId}.json`;
    return this.httpPost(url);
  };

  postMyTee = (courseId, teeId) => {
    const url = `${
      this.baseUrl
    }/api/v1/golfers/${getGolferId()}/my_courses/${courseId}.json`;
    const params = { tee_set_id: teeId };
    if (teeId) return this.httpPatch(`${url}?${objToQuerystring(params)}`);
    else return this.httpPatch(url);
  };

  deleteMyCourse = (courseId) => {
    const url = `${
      this.baseUrl
    }/api/v1/golfers/${getGolferId()}/my_courses.json?${objToQuerystring(
      courseId
    )}`;
    return this.httpDelete(url);
  };

  changeCourseOrder = (courses) => {
    const url = `${
      this.baseUrl
    }/api/v1/golfers/${getGolferId()}/my_courses.json`;
    return this.httpPatch(url, courses);
  };
}

export default new CoursesApi();
