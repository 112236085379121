import React, { Component } from "react";
import PropTypes from "prop-types";
import MobileHeaderMiniScroll from "../Shared/Misc/MobileHeaderMiniScroll";

class MobileHeader extends Component {
  render() {
    const { side, onClearAllScores, onClearAllStats, isClearAllStatsVisible } =
      this.props;
    return (
      <div className="pre-table">
        <MobileHeaderMiniScroll side={side} />
        <ul className="table-controls mobile-only">
          <li>
            <button
              className="btn blank medium no-pad"
              onClick={onClearAllScores}
            >
              Clear All Scores
            </button>
          </li>
          {isClearAllStatsVisible && (
            <li>
              <button
                className="btn blank medium no-pad"
                onClick={onClearAllStats}
              >
                Clear All Stats
              </button>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

MobileHeader.propTypes = {
  side: PropTypes.string.isRequired,
  onClearAllScores: PropTypes.func.isRequired,
  onClearAllStats: PropTypes.func.isRequired,
  isClearAllStatsVisible: PropTypes.bool.isRequired,
};

export default MobileHeader;
