import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";

import loginReducer from "./login";
import trialReducer from "./trial";
import homeCoursesReducer from "./home-courses";
import createProfileReducer from "./create-profile";
import resendVerificationReducer from "./resend-verification";
import golferInformationReducer from "./golfer-information";
import emailChangeInformationReducer from "./email-change-information";
import confirmEmailChangeReducer from "./confirm-email-change";
import setPasswordReducer from "./set-password";
import forgotLoginReducer from "./forgot-login";
import acceptTermsReducer from "./accept-terms";
import searchGolferReducer from "./search-golfer";
import fetchMinorsReducer from "./fetch-minors";

const reducers = combineReducers({
  loginReducer: persistReducer(
    {
      key: "authState",
      storage: storage,
    },
    loginReducer
  ),
  homeCoursesReducer: persistReducer(
    {
      key: "homeCourses",
      storage: storage,
    },
    homeCoursesReducer
  ),
  createProfileReducer,
  resendVerificationReducer,
  golferInformationReducer,
  emailChangeInformationReducer,
  confirmEmailChangeReducer,
  setPasswordReducer,
  forgotLoginReducer,
  acceptTermsReducer,
  searchGolferReducer,
  fetchMinorsReducer,
  trialReducer,
});

export default reducers;
