import React, { Component } from "react";
import PropTypes from "prop-types";
import MobileHeaderMiniScroll from "../Shared/Misc/MobileHeaderMiniScroll";
import { getFormattedDate } from "../../../../shared/helpers/date-helper";

class MobileHeader extends Component {
  renderScoreInfo() {
    const { score } = this.props;
    return (
      <>
        <div className="score-info">
          <span className="title">Course: </span> {score.course_display_value}
        </div>
        <div className="score-info">
          <span className="title">Date Played: </span>
          {getFormattedDate(score.played_at)}
        </div>
        <br />
      </>
    );
  }
  render() {
    const { side, onClearAllStats, score, lastScore } = this.props;
    return (
      <>
        <div className="pre-table">
          <MobileHeaderMiniScroll side={side} />
          {!lastScore && (
            <ul className="table-controls mobile-only">
              <li>
                <button
                  className="btn blank medium no-pad"
                  onClick={onClearAllStats}
                >
                  Clear All Stats
                </button>
              </li>
            </ul>
          )}
        </div>
        {score && this.renderScoreInfo()}
      </>
    );
  }
}

MobileHeader.propTypes = {
  side: PropTypes.string.isRequired,
  onClearAllStats: PropTypes.func.isRequired,
  score: PropTypes.object,
  lastScore: PropTypes.bool,
};

MobileHeader.defaultProps = {
  score: null,
  lastScore: false,
};

export default MobileHeader;
