/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  hasAddress,
  getAddressDisplayFields,
} from "../../../../shared/helpers/my-profile-helper";
import EditAddressForm from "./Addresses/EditAddressForm";

class Addresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  onToggleExpand = () =>
    this.setState((prevState) => ({ expanded: !prevState.expanded }));

  onToggleExpandJustOnEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.onToggleExpand();
    }
  };

  onPatchError = (error) => this.props.onShowErrorModal(error);

  render() {
    const { expanded } = this.state;
    const {
      profile: { primary_address, secondary_address },
      countries,
    } = this.props;

    return (
      <div className="accordion-item">
        <div
          className={
            "accordion-item__head " +
            (expanded ? "is-open" : "") +
            "border-focus"
          }
          onClick={this.onToggleExpand}
          tabIndex="0"
          role="button"
          onKeyDown={this.onToggleExpandJustOnEnter}
        >
          <div className="p-row">
            <div className="l-col">
              <span className="accordion-item__label">Address</span>
            </div>
            <div className="r-col">
              <div className="accordion-item__preview">
                {hasAddress(primary_address) && (
                  <>
                    <span className="cat-title">Primary</span>
                    <div className="default">
                      <pre>
                        {getAddressDisplayFields([primary_address.street_1])}
                        {getAddressDisplayFields([primary_address.street_2])}
                        {getAddressDisplayFields([
                          primary_address.city,
                          primary_address.state_name,
                          primary_address.zip,
                        ])}
                        {getAddressDisplayFields(
                          [primary_address.country_name],
                          ""
                        )}
                      </pre>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={"accordion-item__body " + (expanded ? "is-open" : "")}>
          <div className="p-row">
            <div className="l-col blank"></div>
            <div className="r-col">
              <EditAddressForm
                profile={this.props.profile}
                primaryAddress={primary_address}
                secondaryAddress={secondary_address}
                countries={countries}
                onSuccess={this.onToggleExpand}
                onCancelEdit={this.onToggleExpand}
                onError={this.onPatchError}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ golfersReducer }) => ({
  countries: golfersReducer.countriesReducer.countries,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
