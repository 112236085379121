export const GOLFER_INFORMATION = "GOLFER_INFORMATION";
export const GOLFER_INFORMATION_FAILED = "GOLFER_INFORMATION_FAILED";
export const GOLFER_INFORMATION_SUCCESS = "GOLFER_INFORMATION_SUCCESS";

export const golferInformation = (payload) => ({
  type: GOLFER_INFORMATION,
  payload,
});

export const golferInformationFailed = (payload) => ({
  type: GOLFER_INFORMATION_FAILED,
  payload,
});

export const golferInformationSuccess = (payload) => ({
  type: GOLFER_INFORMATION_SUCCESS,
  payload,
});
