import React, { Component } from "react";
import PropTypes from "prop-types";

class NoResultsFound extends Component {
  render = () => <div className="no-results-found">{this.props.children}</div>;
}

NoResultsFound.propTypes = {
  children: PropTypes.any.isRequired,
};

export default NoResultsFound;
