import { checkApiError } from "../../auth/actions";

export const FETCH_COUNTRIES_SELECT_COURSE = "FETCH_COUNTRIES_SELECT_COURSE";
export const FETCH_COUNTRIES_SELECT_COURSE_FAILED =
  "FETCH_COUNTRIES_SELECT_COURSE_FAILED";
export const FETCH_COUNTRIES_SELECT_COURSE_SUCCESS =
  "FETCH_COUNTRIES_SELECT_COURSE_SUCCESS";

export const fetchCountriesSelectCourse = () => ({
  type: FETCH_COUNTRIES_SELECT_COURSE,
});

export function fetchCountriesSelectCourseFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_COUNTRIES_SELECT_COURSE_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchCountriesSelectCourseSuccess = (payload) => ({
  type: FETCH_COUNTRIES_SELECT_COURSE_SUCCESS,
  payload,
});
