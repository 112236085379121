import React, { Component } from "react";
import SmartBanner from "react-smartbanner";
import appConfig from "../../../shared/config/index";
import { isIOS, isTablet } from "react-device-detect";
import navigationHistory from "../../navigation/navigation-history";
import { setEvent } from "../../../firebaseConfig";
import { getUser } from "../../helpers/user-helper";

class SmartBannerWrapper extends Component {
  constructor() {
    super();
    this.state = {
      hideBanner: true,
    };
  }

  componentWillUnmount() {
    this.listenRouteChange();
  }

  componentDidMount() {
    this.checkRoutePath(navigationHistory?.location);
    this.listenRouteChange = navigationHistory?.listen((location) => {
      this.checkRoutePath(location);
    });
  }

  checkRoutePath = (location) => {
    const hideBanner = location?.pathname === "/new-login";
    this.setState({ hideBanner });
  };

  onClickInstall = () => {
    const value = document.getElementsByClassName("smartbanner-button-text")[0]
      .innerText;
    const user = getUser();
    const eventValue = value === "View" ? "install" : "open";
    setEvent(user, "Mobile_Store_Redirect", "Redirect_Type", eventValue);
  };

  render() {
    const displayInterval = 30;
    const bannerConfig =
      isIOS && !isTablet
        ? { ...appConfig.smartBanner, force: "ios" }
        : { ...appConfig.smartBanner };
    if (this.state.hideBanner) return null;
    return (
      <SmartBanner
        daysHidden={displayInterval}
        daysReminder={displayInterval}
        {...bannerConfig}
        onInstall={this.onClickInstall}
      >
        <img
          height="1"
          width="1"
          style={{
            borderStyle: "none",
            position: "fixed",
            visibility: "hidden",
          }}
          alt=""
          src="https://insight.adsrvr.org/track/pxl/?adv=isqkevq&ct=0:z4a2mpi&fmt=3"
        />
      </SmartBanner>
    );
  }
}

export default SmartBannerWrapper;
