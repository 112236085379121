import React, { Component, Fragment } from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";

//  CUSTOM HEAD FOR FULL SCREEN MENU
const customHead = (props) => (
  <components.MenuList {...props}>
    <div className="ds__header">
      {/* eslint-disable-next-line react/prop-types */}
      <button className="ds__button left" onClick={props.selectProps.onCancel}>
        Cancel
      </button>
      {/* eslint-disable-next-line react/prop-types */}
      <div className="ds__title">{props.selectProps.title}</div>
    </div>
    {/* eslint-disable-next-line react/prop-types */}
    <div className="ds__body">{props.children}</div>
  </components.MenuList>
);

class DefaultCustomSelect extends Component {
  selectRef;

  render = () => (
    <Fragment>
      <Select
        {...this.props}
        ref={(ref) => (this.selectRef = ref)}
        defaultValue={this.props.defaultValue}
        options={this.props.options}
        onChange={this.props.onChange}
        placeholder={this.props.placeholder}
        components={{
          MenuList: customHead,
        }}
        title={this.props.title}
        onCancel={() => {
          this.selectRef.blur();
          this.props.onCancel();
        }}
        className={this.props.className}
        classNamePrefix="ds"
        isSearchable={this.props.isSearchable}
        //  menuIsOpen={true}
      />
    </Fragment>
  );
}

DefaultCustomSelect.defaultProps = {
  title: "Filter by",
  className: "default_select full_on_phone",
  isSearchable: false,
  onCancel: () => {},
};

DefaultCustomSelect.propTypes = {
  defaultValue: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.object,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onClear: PropTypes.func,
  onCancel: PropTypes.func,
  isSearchable: PropTypes.bool,
};

export default DefaultCustomSelect;
