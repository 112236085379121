import {
  FETCH_COUNTRIES,
  fetchCountriesSuccess,
  fetchCountriesFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import CountriesStatesApi from "../../../services/countries-states-api";

export default (action$) =>
  action$.pipe(ofType(FETCH_COUNTRIES)).pipe(switchMap(getCountries));

const getCountries = () =>
  from(CountriesStatesApi.get())
    .pipe(map((response) => fetchCountriesSuccess(response.data.countries)))
    .pipe(catchError((error) => of(fetchCountriesFailed(error))));
