export const RESEND_VERIFICATION = "RESEND_VERIFICATION";
export const RESEND_VERIFICATION_FAILED = "RESEND_VERIFICATION_FAILED";
export const RESEND_VERIFICATION_SUCCESS = "RESEND_VERIFICATION_SUCCESS";

export const resendVerification = (payload) => ({
  type: RESEND_VERIFICATION,
  payload,
});

export const resendVerificationFailed = (payload) => ({
  type: RESEND_VERIFICATION_FAILED,
  payload,
});

export const resendVerificationSuccess = (payload) => ({
  type: RESEND_VERIFICATION_SUCCESS,
  payload,
});
