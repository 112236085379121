import React, { Component } from "react";
import PropTypes from "prop-types";

class Memberships extends Component {
  render = () => (
    <div className="profile__memberships">
      <h3>
        {this.props.golferActive
          ? "GHIN Memberships"
          : "Inactive GHIN Memberships"}
      </h3>

      <ul>
        {this.props.memberships.map((membership, key) => (
          <li key={key}>
            <div className="club-logo__container">
              {membership.logo && (
                <img
                  className="club-logo"
                  alt={membership.association_name}
                  src={membership.logo}
                />
              )}
            </div>
            <div className="club__details">
              <div className="assoc">{membership.association_name}</div>
              <div className="club">{membership.club_name}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

Memberships.propTypes = {
  memberships: PropTypes.array.isRequired,
};

export default Memberships;
