import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchEndOfYearCampaign } from "../actions";
import jobStatus from "../../../shared/variables/job-status";
import Loader from "../../../shared/components/loaders/Loader";
import EndOfYearCampaignImage from "./EndOfYearCampaignImage";
import { GHIN_REWIND_YEAR } from "../year-end-constants";
const ghin_rewind = GHIN_REWIND_YEAR + "-rewind";

class EndOfYearCampaign extends Component {
  componentDidMount() {
    if (this.props.location.pathname.split("/")[1] !== ghin_rewind) {
      const id = this.props.location.search.split("=")[1];
      this.props.fetchEndOfYearCampaign(true, id);
    }
  }
  render() {
    const { fetchStatus } = this.props;
    let stats = this.props.stats;
    if (this.props.location.pathname.split("/")[1] === ghin_rewind) {
      const isStaging = process.env.REACT_APP_API_HOST.indexOf("stage") !== -1;
      const baseUrlStats = isStaging
        ? "https://dev-golfer-statistics.hcp2020.com/"
        : "https://prod-golfer-statistics.hcp2020.com/";
      stats = {
        image:
          baseUrlStats + this.props.location.pathname.split("/")[2] + ".jpg",
      };
    }
    return (
      <Fragment>
        {(fetchStatus === jobStatus.SUCCESS || stats !== undefined) &&
        stats?.image !== null &&
        stats?.image !== undefined ? (
          <EndOfYearCampaignImage image={stats} />
        ) : (
          <Loader />
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchEndOfYearCampaign }, dispatch);

const mapStateToProps = ({ endOfYearCampaignReducer }) => {
  return {
    stats: endOfYearCampaignReducer.endOfYearCampaignReducer.year_end_summary,
    fetchStatus: endOfYearCampaignReducer.endOfYearCampaignReducer.jobStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EndOfYearCampaign);
