import React, { Component, Fragment } from "react";

class SmallLoader extends Component {
  render() {
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={`loader-small${this.props.suffix || ""}`} />
        </div>
      </Fragment>
    );
  }
}

export default SmallLoader;
