import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Navigate from "../../../../shared/navigation/navigate";
import LoaderButton from "../../../../shared/components/buttons/LoaderButton";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import jobStatus from "../../../../shared/variables/job-status";
import { golferInformation, setPassword } from "../../actions";
import DisplayStatusModal from "../common/DisplayStatusModal";
import Loader from "../../../../shared/components/loaders/Loader";
import { Formik } from "formik";
import * as Yup from "yup";

class SetPassword extends Component {
  state = {
    createdStatus: 0,
    createdStatusMessage: 0,
    showPassword: false,
  };

  validationSchema = Yup.object({
    password: Yup.string()
      .required("Password must be at least 6 characters")
      .min(6, "Password must be at least 6 characters"),

    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Your password does not match"
    ),
    acceptedTerms: Yup.boolean()
      .required(
        "You need to agree to the Privacy Policy and Terms of Service before continuing."
      )
      .oneOf(
        [true],
        "You need to agree to the Privacy Policy and Terms of Service before continuing."
      ),
  });

  componentDidMount() {
    const {
      match: {
        params: { token },
      },
    } = this.props;
    if (token) {
      this.props.golferInformation({ token });
    } else {
      this.redirectToHome();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { setPasswordStatus } = nextProps;
    if (
      setPasswordStatus !== this.props.setPasswordStatus &&
      setPasswordStatus === jobStatus.FAILED
    ) {
      this.setState({
        createdStatus: 1,
        createdStatusMessage: "The set password token is invalid.",
      });
    }

    if (
      setPasswordStatus !== this.props.setPasswordStatus &&
      setPasswordStatus === jobStatus.SUCCESS
    ) {
      this.setState({
        createdStatus: 2,
        createdStatusMessage: "You have successfully created your profile.",
      });
    }
  }

  redirectToHome = () => Navigate.toPath("/");

  onSubmit = (values, { setSubmitting }) => {
    const {
      match: {
        params: { token },
      },
    } = this.props;
    setSubmitting(false);

    this.props.setPassword({
      password: values.password,
      reset_password_token: token,
      golfer_user_accepted_terms: values.acceptedTerms,
    });
  };

  onToggleShowPassword = () =>
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));

  renderForm(options, user) {
    return (
      <Fragment>
        <div className="create_form">
          <div className="row">
            <div className="col is-full">
              <label className="disable" htmlFor="name">
                Name
              </label>
              <input type="text-disabled" value={user.name} />
              <span className="error">
                {options.touched.name ? options.errors.name : ""}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col is-full">
              <label className="disable" htmlFor="name">
                Email
              </label>
              <input type="text-disabled" value={user.email} />
              <span className="error">
                {options.touched.email ? options.errors.email : ""}
              </span>
            </div>
          </div>
          <br />
          <br />
          <label className="disable">Set Password</label>
          <div className="row">
            <div className="col is-full">
              <label className="black" htmlFor="password">
                Password
              </label>
              <div className="show_password_wrapper">
                <input
                  type={this.state.showPassword ? "text" : "password"}
                  id="password"
                  value={options.values.password}
                  name="password"
                  onBlur={options.handleBlur}
                  onChange={options.handleChange}
                />
                {options.values.password && options.values.password.length > 0 && (
                  <div className="show_password">
                    <button
                      onClick={this.onToggleShowPassword}
                      className="btn lnk no-underline show_button"
                    >
                      {this.state.showPassword ? "HIDE" : "SHOW"}
                    </button>
                  </div>
                )}
              </div>
              <span className="error">
                {options.touched.password ? options.errors.password : ""}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col is-full">
              <label className="black" htmlFor="confirmPassword">
                Confirm Password
              </label>
              <div className="show_password_wrapper">
                <input
                  type={this.state.showPassword ? "text" : "password"}
                  id="confirmPassword"
                  value={options.values.confirmPassword}
                  name="confirmPassword"
                  onBlur={options.handleBlur}
                  onChange={options.handleChange}
                />
                {options.values.confirmPassword &&
                  options.values.confirmPassword.length > 0 && (
                    <div className="show_password">
                      <button
                        onClick={this.onToggleShowPassword}
                        className="btn lnk no-underline show_button"
                      >
                        {this.state.showPassword ? "HIDE" : "SHOW"}
                      </button>
                    </div>
                  )}
              </div>
              <span className="error">
                {options.touched.confirmPassword
                  ? options.errors.confirmPassword
                  : ""}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col is-full">
              <input
                type="checkbox"
                checked={options.values.acceptedTerms}
                onChange={options.handleChange}
                className="radio"
                id="acceptedTerms"
                name="acceptedTerms"
              />
              <label className="terms" htmlFor="acceptedTerms">
                <div>
                  <div
                    className={`radio-icon${
                      options.values.acceptedTerms ? " checked" : ""
                    }`}
                  />
                </div>
                <div>
                  I have read and agree to the{" "}
                  {this.renderUnderlineLink(
                    "https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-privacy-policy.html",
                    "Privacy Policy"
                  )}{" "}
                  and{" "}
                  {this.renderUnderlineLink(
                    "https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-terms-of-service.html",
                    "Terms of Service"
                  )}
                  .
                </div>
              </label>
              <span className="error">
                {options.touched.acceptedTerms
                  ? options.errors.acceptedTerms
                  : ""}
              </span>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col is-2-of-3 h-centered">
              <LoaderButton
                type="submit"
                className="btn fill cardinal"
                onClick={options.handleSubmit}
                disabled={!options.isValid}
                loading={String(
                  this.props.setPasswordStatus === jobStatus.PROCESSING
                )}
              >
                FINISH
              </LoaderButton>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  renderUnderlineLink = (link, title) => (
    <a href={link} className="link" target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  );

  renderContainer() {
    const { user } = this.props;
    return (
      <div className="auth__container">
        <div className="create-profile">
          {
            <Formik
              onSubmit={this.onSubmit}
              validationSchema={this.validationSchema}
            >
              {(options) => this.renderForm(options, user)}
            </Formik>
          }
        </div>
      </div>
    );
  }

  render() {
    const { createdStatus, createdStatusMessage } = this.state;
    const { userErrorInfo, getUserStatus } = this.props;
    return (
      <Fragment>
        <div className="page_container create-profile-page">
          <div className="box-content">
            <h1>Create Profile</h1>
          </div>
          <div className="box-panel center">
            {getUserStatus !== jobStatus.SUCCESS ? (
              <Loader />
            ) : (
              this.renderContainer()
            )}
          </div>
        </div>
        <DisplayStatusModal
          status={createdStatus}
          statusMessage={createdStatusMessage}
          onOk={() =>
            this.setState({
              createdStatus: 0,
            })
          }
        />
        <YesNoModal
          onConfirm={this.redirectToHome}
          onAbort={this.redirectToHome}
          isOpen={!!userErrorInfo}
          isYesVisible={true}
          isNoVisible={false}
          yesTitle="Ok"
        >
          <p>{userErrorInfo}</p>
        </YesNoModal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authReducer }) => ({
  getUserStatus: authReducer.golferInformationReducer.status,
  setPasswordStatus: authReducer.setPasswordReducer.status,
  user: authReducer.golferInformationReducer.user,
  userErrorInfo: authReducer.golferInformationReducer.userError,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ golferInformation, setPassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
