import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProductAlertModal from "./ProductAlertModal";
import { fetchProductAlert, hideProductAlert } from "../actions";
import {
  shouldFetch,
  shouldShowAlert,
} from "../../../shared/helpers/product-alert-helper";

class ProductAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
    };
    this.alertRef = React.createRef();
  }

  onHideAlert = () => {
    this.props.hideProductAlert();
    this.props.setTop(0);
  };

  onCloseInfoModal = () => this.setState({ showInfoModal: false });

  onShowInfoModal = () => this.setState({ showInfoModal: true });

  componentDidMount() {
    const { lastFetch } = this.props;
    const shouldFetchAlert = shouldFetch(lastFetch);
    if (shouldFetchAlert) {
      this.props.fetchProductAlert();
    }
    if (this.alertRef.current) {
      this.props.setTop(this.alertRef.current.offsetHeight);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.alerts !== this.props.alerts) {
      if (this.alertRef.current) {
        this.props.setTop(this.alertRef.current.offsetHeight);
      }
    }
  }

  render() {
    const { showInfoModal } = this.state;
    const { alerts, hiddenAt } = this.props;
    const showAlert = shouldShowAlert(alerts, hiddenAt);

    if (!showAlert) return null;
    const alert = alerts[0];
    return (
      <Fragment>
        <div className="main__alert" id={"alert"} ref={this.alertRef}>
          <div className="icon" />
          <span
            role="button"
            tabIndex="0"
            onClick={this.onShowInfoModal}
            onKeyDown={this.onShowInfoModal}
          >
            {alert.banner_alert_message}
          </span>
          <button className="hide_alert" onClick={this.onHideAlert} />
        </div>
        <ProductAlertModal
          alert={alert}
          visible={showInfoModal}
          onClose={this.onCloseInfoModal}
        />
      </Fragment>
    );
  }
}

ProductAlert.propTypes = {
  alerts: PropTypes.array,
  hiddenAt: PropTypes.number,
  lastFetch: PropTypes.number,
  fetchProductAlert: PropTypes.func.isRequired,
  hideProductAlert: PropTypes.func.isRequired,
  setTop: PropTypes.func,
};

ProductAlert.defaultProps = {
  alerts: [],
  hiddenAt: undefined,
  lastFetch: undefined,
  setTop: undefined,
};

const mapStateToProps = ({ productAlertReducer }) => ({
  alerts: productAlertReducer.productAlertReducer.alerts,
  lastFetch: productAlertReducer.productAlertReducer.lastFetch,
  hiddenAt: productAlertReducer.productAlertReducer.hiddenAt,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchProductAlert, hideProductAlert }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductAlert);
