import GolferLookup from "../../screens/golfer-lookup/GolferLookup";
import Following from "../../screens/golfer-lookup/Following";
import MyClubs from "../../screens/golfer-lookup/MyClubs";
import AllGolfers from "../../screens/golfer-lookup/AllGolfers";
import DefaultLayout from "../components/layouts/DefaultLayout";

export default {
  golferLookup: {
    path: "/golfer-lookup",
    component: GolferLookup,
    exact: false,
    rootPath: true,
    authRequired: true,
    layout: DefaultLayout,
  },
  golferLookupFollowing: {
    path: "/golfer-lookup/following",
    component: Following,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  golferLookupMyClubs: {
    path: "/golfer-lookup/my-clubs",
    component: MyClubs,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
  golferLookupAllGolfers: {
    path: "/golfer-lookup/all-golfers",
    component: AllGolfers,
    exact: false,
    rootPath: false,
    authRequired: true,
  },
};
