import React, { Component, Fragment } from "react";
import { routes } from "../../../shared/routes/index";
import AddCourses from "./AddCourses";

export default class AddCoursesContainer extends Component {
  render() {
    return (
      <Fragment>
        <div className="container">
          <section className="post-score">
            <AddCourses
              {...this.props}
              hasManuallyInput={false}
              navItemLink={routes.selectTee.path}
            />
          </section>
        </div>
      </Fragment>
    );
  }
}
