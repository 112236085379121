import React, { Component } from "react";
import PropTypes from "prop-types";
import jobStatus from "../../../../shared/variables/job-status";

class EditScoreSubmit extends Component {
  render() {
    const { onAbort, onSubmit, postStatus, submitButtonEnabled } = this.props;
    return (
      <div className="row" style={{ justifyContent: "center" }}>
        <button onClick={onAbort} className="btn blank blue">
          Cancel
        </button>
        <button
          type="submit"
          disabled={!submitButtonEnabled || postStatus === jobStatus.PROCESSING}
          onClick={onSubmit}
          className="btn fill cardinal"
        >
          UPDATE ROUND STATS
        </button>
      </div>
    );
  }
}

EditScoreSubmit.propTypes = {
  onAbort: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  postStatus: PropTypes.string.isRequired,
  submitButtonEnabled: PropTypes.bool.isRequired,
};

export default EditScoreSubmit;
