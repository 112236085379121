import React, { Fragment } from "react";
import postScoreLimits from "../variables/post-score-limits";
import { getCapMessage } from "./user-helper";
import { getFormattedDate } from "./date-helper";
import Tooltip from "../components/notices/Tooltip";

export const hidden = (value) => (value === true ? "hidden" : "");

export const hiddenOnTabletsAndAbove = (value) =>
  value === true ? "hide-on-tablet-and-above" : "";

export const active = (value) => (value === true ? "is-active" : "");

export const isEmpty = (value) => value === undefined || value === "";

export const showIfExists = (conditionValue, displayValue = conditionValue) =>
  conditionValue ? displayValue : "";

export const lateExecute = (action, delay = 50) =>
  setTimeout(function () {
    action();
  }, delay);

export const getDisplayScore = (score) => (score === "99.9" ? "NH" : score);

export const getLowHIDate = (golfer, addBrackets = false) => {
  const date = golfer?.low_hi_modified
    ? golfer?.low_hi_modified_date
    : golfer?.low_hi_date;
  if (addBrackets) return date ? `(${getFormattedDate(date)})` : "-";

  return date ? getFormattedDate(date) : "-";
};

export const renderValidationError = (options, fieldName) => (
  <span style={{ color: "red" }}>
    {showIfExists(options.touched[fieldName], options.errors[fieldName])}
  </span>
);

export const overlayModalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

export const endOfYearModalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "1000",
  },
  content: {
    minWidth: "100px",
    marginTop: "3.5%",
  },
};

export const overlayContentModalStyle = (content) => ({
  ...overlayModalStyle,
  content: {
    maxWidth: content,
    maxHeight: content,
  },
});

export const normalizeScoreBarHeight = (score) =>
  ((score + 20) * 100) / (postScoreLimits.eighteenHoles.max + 20);

export const urlWithHttp = (url) =>
  !/^https?:\/\//i.test(url) ? `http://${url}` : url;

export const getScoresPerPage = (componentRef, defaultScoresPerPage) => {
  if (componentRef) {
    const width = componentRef.clientWidth;
    let result = defaultScoresPerPage;

    if (width < 600 && width >= 350) {
      result = 6;
    } else if (width < 350) {
      result = 5;
    }
    return result;
  } else return 0;
};

export const stringToFloat = (value, decimals = 2) =>
  parseFloat(value).toFixed(decimals);

export const stringToFloatWithoutZero = (value, decimals = 1) =>
  stringToFloat(value, decimals).replace(
    /([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,
    "$1"
  );

export const normalizeFloat = (value, multiplicationFactor = 1, decimals = 2) =>
  isNaN(value)
    ? "N/A"
    : parseFloat((parseFloat(value) * multiplicationFactor).toFixed(decimals));

export const normalizeFloatRounded = (
  value,
  multiplicationFactor = 1,
  decimals = 2
) =>
  isNaN(value)
    ? "N/A"
    : Math.round(normalizeFloat(value, multiplicationFactor, decimals));

export const renderSuperChartPercentage = (value) =>
  isNaN(value) ? (
    <Fragment>{value}</Fragment>
  ) : (
    <Fragment>
      {value}
      <sup>%</sup>
    </Fragment>
  );

export const renderChartPercentage = (value) =>
  isNaN(value) ? <Fragment>{value} </Fragment> : <Fragment>{value}% </Fragment>;

export const getCapInfo = (golfer) => {
  if (!golfer || golfer.is_trial) return null;
  const message = getCapMessage(golfer);
  if (message) {
    return (
      <Tooltip
        message={message}
        place={window.innerWidth <= 512 ? "right" : "top"}
      />
    );
  }
  return null;
};

export const inputSearchDelay = 400;
