import { combineReducers } from "redux";
import golferReducer from "./golfer-fetch";
import golferScoresReducer from "./golfer-scores-fetch.js";

const reducers = combineReducers({
  golferReducer,
  golferScoresReducer,
});

export default reducers;
