import { store } from "../store/index";

const getState = () =>
  store.getState().selectMyCoursesReducer.myCoursesFetchReducer;

const getCourses = () => getState().courses;

export const userCoursesCount = () => {
  return getCourses().length;
};

export const userHasCourse = (courseId) => {
  const courseExist = getCourses().filter(
    (c) => c.course_id.toString() === courseId.toString()
  );
  return courseExist.length > 0;
};
