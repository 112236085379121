import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchGolferScores } from "../../actions";
import jobStatus from "../../../../shared/variables/job-status";
import Loader from "../../../../shared/components/loaders/Loader";
import ScoresItem from "./ScoresItem";
import PccInfoModal from "../../../../shared/components/modals/PccInfoModal";
import ReactTooltip from "react-tooltip";
import ScoreLegend from "../../../../shared/components/legends/ScoreLegend";

class Scores extends Component {
  state = {
    showPccInfoModal: false,
  };

  componentDidMount() {
    this.fetchScores();
  }

  fetchScores = () => {
    const { golferId } = this.props;
    this.props.fetchGolferScores(golferId);
  };

  onClosePccInfoModal = () => this.setState({ showPccInfoModal: false });

  onShowPccInfoModal = () => this.setState({ showPccInfoModal: true });

  render9HoleLegend(nineHoleScores) {
    if (nineHoleScores && nineHoleScores.length > 0) {
      return (
        <div className="table-legend__item">
          <span className="legend__item-color faded-red"></span>
          <span className="legend__item-name">
            9-Hole Score waiting to be combined
          </span>
        </div>
      );
    }
    return null;
  }

  renderUnderReviewLegend(recent, revision) {
    const inRecent = recent.some((s) => s.status === "UnderReview");
    const inRevision = revision.some((s) => s.status === "UnderReview");
    if (inRecent.length > 0 || inRevision.length > 0) {
      return (
        <div className="table-legend__item">
          <span className="legend__item-color faded-yellow"></span>
          <span className="legend__item-name">
            Considered In Current Revision
          </span>
        </div>
      );
    }
    return null;
  }

  renderContent() {
    const {
      recentScores,
      revisionScores,
      nineHoleScores,
      hasSameClubId,
      golferId,
      useScaling,
      hasHandicapIndex,
    } = this.props;
    const { showPccInfoModal } = this.state;
    const cols = hasSameClubId ? 6 : 5;
    const width = hasSameClubId ? "" : "keep-full-width";
    const displayRecentScores =
      recentScores.length > 0 || nineHoleScores.length > 0;
    return (
      <Fragment>
        <div className="table-legend__container">
          {this.renderUnderReviewLegend(recentScores, revisionScores)}
          {this.render9HoleLegend(nineHoleScores)}
        </div>
        <div className="hide-on-tablet">
          <div className={`tbl tbl--${cols}cols has-grow ${width}`}>
            <div className="tbl-row">
              <div className="tbl-cell header text-center">Score</div>
              <div className="tbl-cell header text-center">Date</div>
              <div className="tbl-cell header text-center">C.R./Slope</div>
              <div className="tbl-cell header pcc text-center small-cell">
                <span>PCC&nbsp;&nbsp;&nbsp;</span>
                <sup className="trigger-container">
                  <button
                    onClick={this.onShowPccInfoModal}
                    className="btn blank"
                  >
                    <i
                      className="material-icons-outlined"
                      style={{ color: "#00365f", fontSize: "22px" }}
                    >
                      info
                    </i>
                  </button>
                </sup>

                {/*PCC
                <button
                  onClick={this.onShowPccInfoModal}
                  className="info-modal-trigger margin-left"
                >
                  i
                </button>*/}
              </div>
              <div className="tbl-cell header text-center">Differential</div>
              {hasSameClubId && (
                <div className="tbl-cell header grow">Course</div>
              )}
            </div>
          </div>
        </div>
        <ReactTooltip
          id="score-item"
          place="top"
          type="dark"
          effect="float"
          multiline={true}
        />
        {displayRecentScores && (
          <Fragment>
            <h4>Recent Scores</h4>
            <div
              className={`tbl phone__column tbl--${cols}cols has-grow ${width}`}
            >
              {recentScores.map((scr, key) => (
                <ScoresItem
                  golferId={golferId}
                  key={key}
                  score={scr}
                  fontStyle="italic"
                  hasSameClubId={hasSameClubId}
                  useScaling={useScaling}
                  hasHandicapIndex={hasHandicapIndex}
                />
              ))}
            </div>
            {nineHoleScores && nineHoleScores.length > 0 && (
              <Fragment>
                <div
                  className={`tbl phone__column tbl--${cols}cols has-grow ${width}`}
                >
                  {nineHoleScores.map((scr, key) => (
                    <ScoresItem
                      golferId={golferId}
                      key={key}
                      score={scr}
                      fontStyle="italic"
                      nineHole="nineHole"
                      hasSameClubId={hasSameClubId}
                      useScaling={useScaling}
                      hasHandicapIndex={hasHandicapIndex}
                    />
                  ))}
                </div>
              </Fragment>
            )}
          </Fragment>
        )}

        {revisionScores && revisionScores.length > 0 && (
          <Fragment>
            <h4>Revision Scores</h4>
            <div
              className={`tbl phone__column tbl--${cols}cols has-grow ${width}`}
            >
              {revisionScores.map((scr, key) => (
                <ScoresItem
                  golferId={golferId}
                  key={key}
                  score={scr}
                  hasSameClubId={hasSameClubId}
                  useScaling={useScaling}
                  hasHandicapIndex={hasHandicapIndex}
                />
              ))}
            </div>
          </Fragment>
        )}
        <ScoreLegend useScaling={useScaling} />
        <div style={{ marginTop: 20 }} />
        <PccInfoModal
          isOpen={showPccInfoModal}
          onAbort={this.onClosePccInfoModal}
          onConfirm={this.onClosePccInfoModal}
        />
      </Fragment>
    );
  }

  render() {
    const { fetchScoresStatus, useScaling } = this.props;
    return (
      <Fragment>
        <div className="golfer__list">
          {fetchScoresStatus === jobStatus.PROCESSING ||
          useScaling == undefined ? (
            <Loader />
          ) : (
            this.renderContent()
          )}
        </div>
      </Fragment>
    );
  }
}

Scores.propTypes = {
  fetchGolferScores: PropTypes.func.isRequired,
  golferId: PropTypes.string,
  useScaling: PropTypes.bool,
  hasHandicapIndex: PropTypes.bool,
  fetchScoresStatus: PropTypes.string,
  hasSameClubId: PropTypes.bool,
  recentScores: PropTypes.array.isRequired,
  revisionScores: PropTypes.array.isRequired,
  nineHoleScores: PropTypes.array.isRequired,
};

Scores.defaultProps = {
  golferId: 0,
  hasSameClubId: true,
};

const mapStateToProps = ({ golferReducer }) => ({
  useScaling: golferReducer.golferReducer.golfer?.use_scaling,
  hasHandicapIndex:
    golferReducer.golferReducer.golfer?.handicap_index != "NH" &&
    golferReducer.golferReducer.golfer?.handicap_index != "WD",
  recentScores: golferReducer.golferScoresReducer.recentScores,
  revisionScores: golferReducer.golferScoresReducer.revisionScores,
  nineHoleScores: golferReducer.golferScoresReducer.nineHoleScores,
  fetchScoresStatus: golferReducer.golferScoresReducer.jobStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchGolferScores }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Scores);
