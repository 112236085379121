export const SEARCH_GOLFER = "SEARCH_GOLFER";
export const SEARCH_GOLFER_FAILED = "SEARCH_GOLFER_FAILED";
export const SEARCH_GOLFER_SUCCESS = "SEARCH_GOLFER_SUCCESS";

export const searchGolfer = (payload) => ({
  type: SEARCH_GOLFER,
  payload,
});

export const searchGolferFailed = (payload) => ({
  type: SEARCH_GOLFER_FAILED,
  payload,
});

export const searchGolferSuccess = (payload) => ({
  type: SEARCH_GOLFER_SUCCESS,
  payload,
});
