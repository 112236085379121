import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";

class EstimatedHandicapModal extends Component {
  render() {
    const { isOpen, onClose } = this.props;
    return (
      <YesNoModal
        onConfirm={onClose}
        onAbort={onClose}
        isNoVisible={false}
        isOpen={isOpen}
        yesTitle="Ok"
      >
        <p>
          This is an unofficial estimate of your Handicap Index pending further
          adjustments (PCC, ESR) or other changes to your scoring record prior
          to the overnight Handicap Index revision.
        </p>
      </YesNoModal>
    );
  }
}

EstimatedHandicapModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EstimatedHandicapModal;
