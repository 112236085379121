import jobStatus from "../../../shared/variables/job-status";

import {
  ACCEPT_TERMS,
  ACCEPT_TERMS_SUCCESS,
  ACCEPT_TERMS_FAILED,
} from "../actions";

const initialState = {
  status: jobStatus.NOT_STARTED,
  error: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ACCEPT_TERMS:
      return {
        ...state,
        status: jobStatus.PROCESSING,
      };
    case ACCEPT_TERMS_SUCCESS: {
      return {
        ...state,
        status: jobStatus.SUCCESS,
      };
    }
    case ACCEPT_TERMS_FAILED: {
      return {
        ...state,
        status: jobStatus.FAILED,
        error: payload,
      };
    }
    default:
      return state;
  }
};
