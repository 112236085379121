import React, { Component, Fragment } from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";

//  SELECT OUTPUT
const customSingleValue = ({ data }) => (
  <div className="react-icon-select__value">
    {data.icon ? (
      <i className={`material-icons-outlined ${data.color}`}>{data.icon}</i>
    ) : null}
    <span>{data.label}</span>
  </div>
);

customSingleValue.propTypes = {
  data: PropTypes.object,
};

//  SELECT CUSTOM OPTIONS WITH ICONS
const customOption = (props) => {
  const { data, innerProps } = props;
  return (
    <components.Option {...props} {...innerProps}>
      {data.icon ? (
        <i className={`material-icons-outlined ${data.color}`}>{data.icon}</i>
      ) : null}
      <span>{data.label}</span>
    </components.Option>
  );
};

customOption.propTypes = {
  data: PropTypes.object,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
};

//  SELECT ADD CLEAR BUTTON AT END OF OPTIONS LIST
const customMenuList = (props) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      <button
        onClick={props.selectProps.onClear}
        className="react-icon-select__clear"
      >
        Clear
      </button>
    </components.MenuList>
  );
};

customMenuList.propTypes = {
  children: PropTypes.array,
  selectProps: PropTypes.object,
};

class CustomIconSelect extends Component {
  selectRef;

  render() {
    const {
      defaultValue,
      options,
      onChange,
      value,
      onClear,
      golferActive,
      disabled,
    } = this.props;
    return (
      <Fragment>
        <Select
          isDisabled={
            disabled || (golferActive !== undefined ? !golferActive : false)
          }
          ref={(ref) => (this.selectRef = ref)}
          defaultValue={defaultValue}
          options={options}
          value={value}
          components={{
            SingleValue: customSingleValue,
            Option: customOption,
            MenuList: customMenuList,
          }}
          onClear={() => {
            onClear();
            this.selectRef.blur();
          }}
          className={"react-icon-select"}
          classNamePrefix={"react-icon-select"}
          isSearchable={false}
          menuPosition={"absolute"}
          menuPortalTarget={document.body}
          onChange={onChange}
          // openOnFocus
          // menuIsOpen
          // defaultMenuIsOpen={true}
          // closeMenuOnSelect={false}
        />
      </Fragment>
    );
  }
}

CustomIconSelect.propTypes = {
  defaultValue: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.object,
  onClear: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CustomIconSelect;
