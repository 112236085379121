import React, { Component, Fragment } from "react";
import AllGolfersList from "../golfers/components/AllGolfers/AllGolfers";
import GolfersListItem from "./GolfersListItem";
import { Helmet } from "react-helmet";
import {
  GL_ALL_GOLFERS_TITLE,
  GL_ALL_GOLFERS_DESC,
} from "../../shared/variables/meta-seo";

class AllGolfers extends Component {
  renderItem = (golfer, key) => <GolfersListItem key={key} golfer={golfer} />;

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{GL_ALL_GOLFERS_TITLE}</title>
          <meta name="description" content={GL_ALL_GOLFERS_DESC} />
        </Helmet>
        <AllGolfersList renderItem={this.renderItem} />
      </Fragment>
    );
  }
}

export default AllGolfers;
