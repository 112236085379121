import moment from "moment";

export default {
  BY_COURSE: "Scores By Course/Tee",
  BY_COURSE_ID_AND_TEE_SET_ID: "Scores By CourseId/TeeSetId",
  YEAR: `${moment().format("YYYY")} Scores`,
  PAST_YEAR: `${moment().subtract(1, "year").format("YYYY")} Scores`,
  TWO_YEARS_AGO: `${moment().subtract(2, "year").format("YYYY")} Scores`,
  MONTHS_12: "Past 12 Month Scores",
  REVISION: "Revision Scores",
  TOURNAMENT: "Competition Scores",
  RECENT_AND_REVISION_SCORES: "Recent & Revision Scores",
  THREE_YEARS_AGO: `${moment().subtract(3, "year").format("YYYY")} Scores`,
};
