import { combineEpics } from "redux-observable";
import {
  GET_AVERAGE_SCORE_PER_HOLE,
  getAverageScorePerHoleSuccess,
  getAverageScorePerHoleFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";

export const getAverageScorePerHole = (action$) =>
  action$
    .pipe(ofType(GET_AVERAGE_SCORE_PER_HOLE))
    .pipe(switchMap(({ payload }) => getAvgScores(payload)));

const getAvgScores = (payload) =>
  from(ScoresApi.getAverageScorePerHole(payload.golfer_id, payload.tee_set_id))
    .pipe(map((response) => getAverageScorePerHoleSuccess(response.data)))
    .pipe(catchError((error) => of(getAverageScorePerHoleFailed(error))));

export default combineEpics(getAverageScorePerHole);
