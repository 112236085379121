import navigationHistory from "./navigation-history";

class Navigate {
  toPath = (path) => {
    navigationHistory.push(path);
  };

  resetToPath = (path) => {
    window.location.replace(path);
  };

  goBack = () => {
    navigationHistory.goBack();
  };
}

export default new Navigate();
