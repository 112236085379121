import React, { Component, Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import { fetchChildScores } from "../../actions";
import jobStatus from "../../../../shared/variables/job-status";
import Loader from "../../../../shared/components/loaders/Loader";
import { getFormattedDate } from "../../../../shared/helpers/date-helper";
import { orderBy } from "lodash";

class CombinedScoreInfoModal extends Component {
  onAfterOpen = () =>
    this.props.fetchChildScores(this.props.score.id, true, this.props.golferId);

  sortScoresByDate() {
    const { scores } = this.props;
    let sameDate = false;
    let date = undefined;
    scores.forEach((score) => {
      if (!date) {
        date = score.played_at;
      } else if (date && date === score.played_at) {
        sameDate = true;
      }
    });
    if (sameDate) {
      return orderBy(scores, (o) => o.score_day_order, ["asc"]);
    } else {
      return orderBy(scores, (o) => moment(o.played_at).format("YYYY-MM-DD"), [
        "asc",
      ]);
    }
  }

  renderScores() {
    const { hasSameClubId } = this.props;
    const scores = this.sortScoresByDate();
    if (scores.length === 0) {
      return (
        <div className="stats__container">
          <p>No scores available</p>
        </div>
      );
    }
    const dateFormat = hasSameClubId ? "MM/DD/YYYY" : "MM/YYYY";
    return (
      <div className="stats__container">
        <div className="stats__row">
          {scores.map((score, index) => (
            <Fragment key={score.id}>
              <div className="stats__column combined-score">
                <div className="combined-score-info">
                  <span className="title">{`Score ${index + 1}`}:</span>{" "}
                  {score.adjusted_gross_score}
                </div>
                {hasSameClubId && (
                  <div className="combined-score-info">
                    <span className="title">Course:</span>{" "}
                    {score.course_display_value}
                  </div>
                )}
                <div className="combined-score-info">
                  <span className="title">Date Played:</span>{" "}
                  {getFormattedDate(score.played_at, "YYYY-MM-DD", dateFormat)}
                </div>
              </div>
              {index === 0 && <div className="separator" />}
            </Fragment>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { isOpen, onClose, fetchStatus, scores } = this.props;
    return (
      <YesNoModal
        onConfirm={onClose}
        onAbort={onClose}
        isOpen={isOpen}
        isNoVisible={false}
        isYesVisible={scores.length > 0}
        yesTitle="Ok"
        onAfterOpen={this.onAfterOpen}
      >
        {fetchStatus === jobStatus.SUCCESS ? this.renderScores() : <Loader />}
      </YesNoModal>
    );
  }
}

CombinedScoreInfoModal.propTypes = {
  score: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hasSameClubId: PropTypes.bool,
  golferId: PropTypes.string,
};

CombinedScoreInfoModal.defaultProps = {
  hasSameClubId: true,
  golferId: null,
};

const mapStateToProps = (state) => ({
  scores: state.statsReducer.combinedScoresReducer.scores,
  fetchStatus: state.statsReducer.combinedScoresReducer.jobStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchChildScores }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CombinedScoreInfoModal);
