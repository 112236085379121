import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { overlayModalStyle } from "../../../shared/helpers/ui-helper";

class StartTrialModal extends Component {
  componentDidMount = () => Modal.setAppElement(this.props.appElementName);

  render() {
    const { isOpen } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        style={overlayModalStyle}
        className={
          "trial-modal-dialog" +
          (this.props.modalSubTitle ? " modal-footer" : "")
        }
        contentLabel={"Modal"}
      >
        <div
          className={
            "trial-modal-content" +
            (this.props.modalSubTitle ? " modal-footer" : "")
          }
        >
          <button
            className="trial-modal_close"
            onClick={this.props.onCloseModal}
            aria-label="Close"
          >
            ✕
          </button>
          <div className="trial-modal-body">
            <Fragment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className={
                    this.props.icon == "calendar"
                      ? "usga-calendar"
                      : "usga-flag"
                  }
                />
              </div>
            </Fragment>
            <h3
              style={{
                fontSize: "22px",
                fontWeight: "530",
              }}
            >
              {this.props.title}
            </h3>
            <p
              style={{
                fontSize: "17px",
              }}
            >
              {this.props.content}
            </p>
            <div className="actions centered">
              <Fragment>
                <button
                  className={`btn ${
                    this.props.reversedHighlight
                      ? "outline blue"
                      : "fill cardinal"
                  }`}
                  onClick={this.props.onConfirmModal}
                >
                  Sign up
                </button>
              </Fragment>
              <Fragment>
                <button
                  className="lnk white color-focus"
                  onClick={this.props.onCloseModal}
                  style={{
                    textAlign: "center",
                    fontSize: "17px",
                    color: "dodgerblue",
                    fontWeight: "600",
                    background: "white",
                    border: "none",
                    fontFamily: "arial, sans-serif",
                    cursor: "pointer",
                  }}
                >
                  Close
                </button>
              </Fragment>
            </div>
          </div>
          {this.props.modalSubTitle && (
            <div
              style={{
                position: "absolute",
                bottom: "0",
                marginBottom: "4%",
                width: "90%",
                paddingLeft: "10px",
              }}
            >
              <h3
                style={{
                  fontSize: "18px",
                  fontWeight: "530",
                  color: "#13355C",
                }}
              >
                {this.props.modalSubTitle}
              </h3>
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "10px",
                  color: "#13355C",
                }}
              >
                {this.props.modalSubContent}
              </p>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

StartTrialModal.defaultProps = {
  reversedOrder: false,
  reversedHighlight: false,
  yesTitle: "YES",
  isYesVisible: true,
  noTitle: "NO",
  isNoVisible: true,
  onAbort: () => {},
  onConfirm: () => {},
  appElementName: "body",
};

StartTrialModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default StartTrialModal;
