import { combineEpics } from "redux-observable";
import loginEpic from "../../screens/auth/epics/index";
import profileEpic from "../../screens/profile/epics/index";
import golfersEpic from "../../screens/golfers/epics/index";
import postScoreEpic from "../../screens/post-score/epics/index";
import golferEpic from "../../screens/golfer/epics/index";
import handicapCalculatorEpic from "../../screens/handicap-calculator/epics/index";
import recentScoresEpic from "../../screens/recent-scores/epics/epics";
import contactUsEpic from "../../screens/contact-us/epics/index";
import selectCourseEpic from "../../screens/select-course/epics/index";
import statsEpic from "../../screens/stats/epics";
import ghinAssociationsEpic from "../../screens/ghin-associations/epics";
import aboutGhinEpic from "../../screens/about-ghin/epics";
import productAlertEpic from "../../screens/product-alert/epics";
import scores2020Epic from "../../screens/my-card/epics";
import myProfileEpic from "../../screens/my-profile/epics";
import loginWithGhinEpic from "../../screens/sso/epics";
import selectMyCourseEpic from "../../screens/my-courses/epics/index";
import endOfYearCampaignEpic from "../../screens/end-of-year-campaign/epics";

export default combineEpics(
  loginEpic,
  profileEpic,
  golfersEpic,
  postScoreEpic,
  golferEpic,
  handicapCalculatorEpic,
  recentScoresEpic,
  contactUsEpic,
  selectCourseEpic,
  statsEpic,
  ghinAssociationsEpic,
  aboutGhinEpic,
  productAlertEpic,
  scores2020Epic,
  myProfileEpic,
  loginWithGhinEpic,
  selectMyCourseEpic,
  endOfYearCampaignEpic
);
