import React, { Component } from "react";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import PropTypes from "prop-types";

class UnFollowGolferModal extends Component {
  render() {
    const { isOpen, onAbort, onConfirm, golferName } = this.props;
    return (
      <YesNoModal onConfirm={onConfirm} onAbort={onAbort} isOpen={isOpen}>
        <p>{`Are you sure you want to unfollow ${golferName}?`}</p>
      </YesNoModal>
    );
  }
}

UnFollowGolferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  golferName: PropTypes.string.isRequired,
  onAbort: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default UnFollowGolferModal;
