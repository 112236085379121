import jobStatus from "../../../shared/variables/job-status";
import {
  POST_COMBINED_SCORES,
  POST_COMBINED_SCORES_FAILED,
  POST_COMBINED_SCORES_SUCCESS,
  POST_COMBINED_SCORES_RESET,
} from "../actions";

const initialState = {
  scoresPosted: 0,
  jobStatus: jobStatus.NOT_STARTED,
};

const checkForJobStatus = (scoresPosted, scoresLength) => {
  if (scoresPosted === scoresLength) return jobStatus.SUCCESS;
  return jobStatus.PROCESSING;
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case POST_COMBINED_SCORES:
      return {
        ...state,
        jobStatus: jobStatus.PROCESSING,
      };
    case POST_COMBINED_SCORES_SUCCESS:
      return {
        ...state,
        scoresPosted: state.scoresPosted + 1,
        jobStatus: checkForJobStatus(
          state.scoresPosted + 1,
          payload.scoresLength
        ),
      };
    case POST_COMBINED_SCORES_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    case POST_COMBINED_SCORES_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
