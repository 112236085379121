import {
  FETCH_COURSES,
  fetchCoursesFailed,
  fetchCoursesSuccess,
  fetchMyCoursesSuccess,
  fetchMyCoursesFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { isEmpty } from "../../../shared/helpers/ui-helper";
import CoursesApi from "../../../services/courses-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_COURSES))
    .pipe(
      switchMap(({ payload }) =>
        isEmptySearch(payload)
          ? isEmpty(payload.mostRecent)
            ? fetchMyCourses(payload)
            : fetchRecentCourses()
          : fetchCourses(payload)
      )
    );

const fetchCourses = (payload) =>
  from(
    CoursesApi.getCourses(payload.searchPhrase, payload.country, payload.state)
  )
    .pipe(map((r) => fetchCoursesSuccess(r.data.courses)))
    .pipe(catchError((error) => of(fetchCoursesFailed(error))));

const fetchMyCourses = () =>
  from(CoursesApi.getMyCourses())
    .pipe(map((r) => fetchMyCoursesSuccess(r.data.golfer_course_preference)))
    .pipe(catchError((error) => of(fetchMyCoursesFailed(error))));

const fetchRecentCourses = () =>
  from(CoursesApi.getMostRecentCourses())
    .pipe(map((r) => fetchCoursesSuccess(r.data.courses)))
    .pipe(catchError((error) => of(fetchCoursesFailed(error))));

const isEmptySearch = (payload) => isEmpty(payload.searchPhrase);
