import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchGolfer } from "../actions";
import { followGolfer, unFollowGolfer } from "../../golfers/actions";
import Loader from "../../../shared/components/loaders/Loader";
import { getDisplayScore } from "../../../shared/helpers/ui-helper";
import { userExistInFollowed } from "../../../shared/helpers/follow-helper";
import {
  getGolferId,
  golferHasSameClubId,
  isUserLoggedIn,
} from "../../../shared/helpers/user-helper";
import { getCapInfo } from "../../../shared/helpers/ui-helper";
import Scores from "./Scores/Scores";
import jobStatus from "../../../shared/variables/job-status";
import UnableToFindGolferModal from "./Modals/UnableToFindGolferModal";
import { routes } from "../../../shared/routes/index";
import UnFollowGolferModal from "./Modals/UnFollowGolferModal";
import { decrypt, encrypt } from "../../../shared/helpers/encoder";
import { Helmet } from "react-helmet";
import {
  GOLFER_DETAIL_TITLE,
  GOLFER_DETAIL_DESC,
} from "../../../shared/variables/meta-seo";

class Golfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUnFollowGolferModal: false,
      showUnableToFindGolferModal: false,
      isGolferFound: true,
    };
  }

  componentDidMount() {
    const { fetchGolfer, clubId, golferId } = this.props;
    fetchGolfer(decrypt(golferId), clubId);
  }

  onClickFollow = () => {
    const { golfer, followGolfer } = this.props;
    followGolfer(encrypt(getGolferId(golfer)), golfer.club_id);
  };

  renderFollowButton() {
    const { golfer } = this.props;
    const golferId = getGolferId(golfer);

    const isFollowed = userExistInFollowed(golferId, golfer.club_id);
    if (isFollowed) {
      return (
        <button
          className="following active"
          onClick={() => this.setState({ showUnFollowGolferModal: true })}
        >
          Unfollow
        </button>
      );
    }
    return (
      <button className="following" onClick={this.onClickFollow}>
        Follow
      </button>
    );
  }

  componentDidUpdate = (prevProps) => this.evaluateGolferResponse(prevProps);

  evaluateGolferResponse = (prevProps) => {
    if (
      this.props.golferFetchStatus !== prevProps.golferFetchStatus &&
      this.props.golferFetchStatus === jobStatus.SUCCESS &&
      !this.props.golfer
    ) {
      this.setState({
        showUnableToFindGolferModal: true,
        isGolferFound: false,
      });
    }
  };

  getGolferName = () =>
    this.props.golfer
      ? `${this.props.golfer.first_name} ${this.props.golfer.last_name} ${
          this.props.golfer.suffix || ""
        }`
      : "";

  renderGolferDetails() {
    const { golfer } = this.props;
    return (
      <section className="hero__section golfer">
        <div className="golfer-head__container">
          {this.props.golfer ? (
            <Fragment>
              <div className="details">
                <div>
                  <div className="name-row">
                    <div className="name">{this.getGolferName()}</div>
                    {this.renderFollowButton()}
                  </div>
                  <br />
                  <div className="club" style={{ textTransform: "uppercase" }}>
                    {golfer.club_name}
                  </div>
                </div>
                <div className="info-rows">
                  <div className="cap-container">
                    {getCapInfo(golfer)}
                    <div className="info">
                      <div className="score">
                        {getDisplayScore(golfer.handicap_index)}
                      </div>
                      <div className="hi">
                        HANDICAP <br /> INDEX <sup>&reg;</sup>
                      </div>
                      <div className="low">
                        LOW H.I.
                        <br />
                        <small>
                          {golfer.low_hi == "999" ? "NH" : golfer.low_hi}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </section>
    );
  }

  renderModals = () => (
    <Fragment>
      <UnFollowGolferModal
        isOpen={this.state.showUnFollowGolferModal}
        golferName={this.getGolferName()}
        onAbort={() => this.setState({ showUnFollowGolferModal: false })}
        onConfirm={() => {
          this.setState({ showUnFollowGolferModal: false });
          const { golfer, unFollowGolfer } = this.props;
          unFollowGolfer(encrypt(getGolferId(golfer)), golfer.club_id);
        }}
      />
      <UnableToFindGolferModal
        isOpen={this.state.showUnableToFindGolferModal}
        onClose={() => {
          this.setState({ showUnableToFindGolferModal: false });
          isUserLoggedIn()
            ? routes.profile.navigate()
            : routes.login.navigate();
        }}
      />
    </Fragment>
  );

  renderScores = () =>
    this.state.isGolferFound && (
      <div className="container">
        <section className="golfer_lookup_section">
          <div className="section_header">
            <h3>Scores</h3>
          </div>

          <Scores
            golferId={decrypt(this.props.golferId)}
            hasSameClubId={golferHasSameClubId(this.props.golfer)}
          />
        </section>
      </div>
    );

  render = () => (
    <Fragment>
      <Helmet>
        <title>{GOLFER_DETAIL_TITLE}</title>
        <meta name="description" content={GOLFER_DETAIL_DESC} />
      </Helmet>
      {this.renderGolferDetails()}
      {this.renderScores()}
      {this.renderModals()}
    </Fragment>
  );
}

Golfer.propTypes = {
  fetchGolfer: PropTypes.func.isRequired,
  followGolfer: PropTypes.func.isRequired,
  unFollowGolfer: PropTypes.func.isRequired,
  golferFetchStatus: PropTypes.string.isRequired,
  golfer: PropTypes.object,
  golferId: PropTypes.string,
  clubId: PropTypes.string,
};

Golfer.defaultProps = {
  golfer: undefined,
  golferId: 0,
  clubId: 0,
};

const mapStateToProps = (state, ownProps) => ({
  golfer: state.golferReducer.golferReducer.golfer,
  golferFetchStatus: state.golferReducer.golferReducer.jobStatus,
  golfers: state.golfersReducer.followedGolfersReducer.golfers,
  golferId: ownProps.match.params.id,
  clubId: ownProps.match.params.club_id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchGolfer, followGolfer, unFollowGolfer }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Golfer);
