import React, { Component } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import DatePickerInput from "../../../../../shared/components/inputs/DatePickerInput";

class DatePlayed extends Component {
  render() {
    const { options, onDatePlayedChange } = this.props;
    return (
      <div className="row">
        <div className="col is-full">
          <label className="regular black">Date Played</label>
          <DatePicker
            customInput={
              <DatePickerInput readOnly={true} onKeyDown={() => {}} />
            }
            maxDate={new Date()}
            selected={options.values.datePlayed}
            onChange={(e) => {
              options.setFieldValue("datePlayed", e, true);
              if (onDatePlayedChange != undefined) {
                onDatePlayedChange(e);
              }
            }}
          />
        </div>
      </div>
    );
  }
}

DatePlayed.propTypes = {
  options: PropTypes.object.isRequired,
};

export default DatePlayed;
