import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

class ManualCourse extends Component {
  render() {
    const { options, selectCoursePath } = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col is-2-of-4">
            <label className="regular black">Course Name</label>
          </div>
          <div className="col is-2-of-4">
            <div className="selected__controls textRight">
              <NavLink className="selected__control" to={selectCoursePath}>
                Back to Course Selection
              </NavLink>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col is-full">
            <input
              id="manualCourseName"
              name="manualCourseName"
              className="large"
              type="text"
              value={options.values.manualCourseName}
              onBlur={options.handleBlur}
              onChange={options.handleChange}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

ManualCourse.propTypes = {
  options: PropTypes.object.isRequired,
  selectCoursePath: PropTypes.string.isRequired,
};

export default ManualCourse;
