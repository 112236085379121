import {
  FETCH_COURSE_DETAILS,
  fetchCourseDetailsFailed,
  fetchCourseDetailsSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import CoursesApi from "../../../services/courses-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_COURSE_DETAILS))
    .pipe(switchMap(({ payload }) => fetchCourseDetails(payload)));

const fetchCourseDetails = (payload) =>
  from(
    CoursesApi.getCourseDetails(payload.courseId, payload.includeAlteredTees)
  )
    .pipe(map((response) => fetchCourseDetailsSuccess(response.data)))
    .pipe(catchError((error) => of(fetchCourseDetailsFailed(error))));
