import { isNumber } from "../helpers/post-hbh-score-helper";
import ratingType from "../../../shared/variables/rating-type";
import { drivingFairwayHit } from "../variables/post-score-variables";

export const setPutts = (hole, value, options) => {
  options.values.holes[hole.index].putts = value;

  options.values.totalPutts = 0;
  options.values.frontPutts = 0;
  options.values.backPutts = 0;

  options.values.holes.forEach((x) => {
    const value = x.putts;

    if (isNumber(value)) {
      options.values.totalPutts += value;

      x.side === ratingType.FRONT
        ? (options.values.frontPutts += value)
        : (options.values.backPutts += value);
    }
  });

  options.setFieldValue("totalPutts", options.values.totalPutts, true);
};

export const setGir = (hole, value, options, manually = false) => {
  options.values.holes[hole.index].gir = { ...value, manually };

  options.values.totalGir = 0;
  options.values.frontGir = 0;
  options.values.backGir = 0;

  options.values.holes.forEach((x) => {
    const value = x.gir.value;

    const intValue = value === 0 ? 1 : 0;

    options.values.totalGir += intValue;

    x.side === ratingType.FRONT
      ? (options.values.frontGir += intValue)
      : (options.values.backGir += intValue);
  });

  options.setFieldValue("totalGir", options.values.totalGir, true);
};

export const setDrivingAcc = (hole, value, options) => {
  options.values.holes[hole.index].drivingAcc = value;

  options.values.totalDrivingAcc = 0;
  options.values.frontDrivingAcc = 0;
  options.values.backDrivingAcc = 0;

  options.values.holes.forEach((x) => {
    const value = x.drivingAcc.value;

    const intValue = value === drivingFairwayHit ? 1 : 0;

    options.values.totalDrivingAcc += intValue;

    x.side === ratingType.FRONT
      ? (options.values.frontDrivingAcc += intValue)
      : (options.values.backDrivingAcc += intValue);
  });

  options.setFieldValue(
    "totalDrivingAcc",
    options.values.totalDrivingAcc,
    true
  );
};

export const setScore = (hole, options, score) => {
  hole.score = score;
  hole.adjScore = score > hole.maxScore ? hole.maxScore : score;

  calculateScore(options);
  calculateAdjScore(options);

  options.setFieldValue(`holes[${hole.index}].score`, score, true);
};

const calculateScore = (options) => {
  options.values.frontScore = 0;
  options.values.backScore = 0;
  options.values.totalScore = 0;

  options.values.holes.forEach((x) => {
    const value = isNumber(x.score) ? x.score : 0;

    options.values.totalScore += value;

    x.side === ratingType.FRONT
      ? (options.values.frontScore += value)
      : (options.values.backScore += value);
  });
};

const calculateAdjScore = (options) => {
  options.values.frontAdjScore = 0;
  options.values.backAdjScore = 0;
  options.values.totalAdjScore = 0;

  options.values.holes.forEach((x) => {
    const value = isNumber(x.adjScore) ? x.adjScore : 0;

    options.values.totalAdjScore += value;

    x.side === ratingType.FRONT
      ? (options.values.frontAdjScore += value)
      : (options.values.backAdjScore += value);
  });
};

export const getFrontPar = (teeSetInfo) =>
  teeSetInfo.Holes.reduce(
    (total, hole) => (hole.Number < 10 ? total + hole.Par : total),
    0
  );

export const getTotalPar = (teeSetInfo) =>
  teeSetInfo.Holes.reduce((total, hole) => total + hole.Par, 0);

export const getBackPar = (teeSetInfo) =>
  teeSetInfo.Holes.reduce(
    (total, hole) => (hole.Number > 9 ? total + hole.Par : total),
    0
  );

export const calculateGirFromParAndScoreAndPutts = (par, score, putts) => {
  let value = false;
  const diff = score - putts;
  if (par === 3) {
    if (diff <= 1) {
      value = true;
    }
  } else if (par === 4) {
    if (diff <= 2) {
      value = true;
    }
  } else if (par === 5) {
    if (diff <= 3) {
      value = true;
    }
  }
  if (value) {
    return {
      value: 0,
      label: "Yes",
      icon: "done",
      color: "green",
    };
  }
  return null;
};
