import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_CLUBS,
  FETCH_CLUBS_SUCCESS,
  FETCH_CLUBS_FAILED,
  SELECT_CLUB,
} from "../actions";

const initialState = {
  clubs: [],
  selectedClub: undefined,
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CLUBS:
      return {
        ...state,
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_CLUBS_SUCCESS:
      return {
        ...state,
        clubs: payload,
        selectedClub: payload[0],
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_CLUBS_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    case SELECT_CLUB:
      return {
        ...state,
        selectedClub: state.clubs.find((c) => c.id === payload),
      };
    default:
      return state;
  }
};
