import jobStatus from "../../../shared/variables/job-status";

import {
  EMAIL_CHANGE_INFORMATION,
  EMAIL_CHANGE_INFORMATION_SUCCESS,
  EMAIL_CHANGE_INFORMATION_FAILED,
} from "../actions";

const initialState = {
  status: jobStatus.NOT_STARTED,
  user: undefined,
  golfer: undefined,
  emailChangeError: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case EMAIL_CHANGE_INFORMATION:
      return {
        ...state,
        status: jobStatus.PROCESSING,
      };
    case EMAIL_CHANGE_INFORMATION_SUCCESS: {
      return {
        ...state,
        status: jobStatus.SUCCESS,
        user: payload.user,
        golfer: payload.golfer,
      };
    }
    case EMAIL_CHANGE_INFORMATION_FAILED: {
      return {
        ...state,
        emailChangeError:
          "This link is no longer active. Please ensure you have clicked on the link in the most recent email delivered.",
        status: jobStatus.FAILED,
      };
    }
    default:
      return state;
  }
};
