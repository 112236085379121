import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILED,
  SELECT_COUNTRY,
  SELECT_STATE,
} from "../actions";

const initialState = {
  countries: [],
  selectedCountry: undefined,
  selectedState: undefined,
  jobStatus: jobStatus.NOT_STARTED,
};

const getSelectedState = (country, userState) => {
  const defaultState = country.states[0];
  if (!userState) return defaultState;
  const findUserState = country.states.filter((s) => s.code === userState);
  if (findUserState.length > 0) {
    return findUserState[0];
  }
  return defaultState;
};

const getSelectedCountry = (countries, userCountry) => {
  const defaultCountry = countries[0];
  if (!userCountry) return defaultCountry;
  const findUserCountry = countries.filter(
    (c) => c.name === userCountry || c.code === userCountry
  );
  if (findUserCountry.length > 0) {
    return findUserCountry[0];
  }
  return defaultCountry;
};

const getCountries = (countries) => {
  for (var i = 0; i < countries.length; i++) {
    if (countries[i].states.length > 1) {
      countries[i].states.unshift({
        code: "All",
        name: "All States",
      });
    }
  }
  return countries;
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COUNTRIES:
      return {
        ...state,
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: getCountries(payload.countries),
        selectedCountry: getSelectedCountry(
          payload.countries,
          payload.userCountry
        ),
        selectedState: getSelectedState(
          getSelectedCountry(payload.countries, payload.userCountry),
          payload.userState
        ),
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_COUNTRIES_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    case SELECT_COUNTRY:
      return {
        ...state,
        selectedCountry: state.countries.find((c) => c.code === payload.code),
        selectedState: getSelectedState(
          state.countries.find((c) => c.code === payload.code),
          payload.userState
        ),
      };
    case SELECT_STATE:
      return {
        ...state,
        selectedState: state.selectedCountry.states.find(
          (s) => s.code === payload
        ),
      };
    default:
      return state;
  }
};
