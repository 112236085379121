import moment from "moment";

export const getFormattedDate = (
  date,
  sourceFormat = "YYYY-MM-DD",
  destFormat = "MM/DD/YYYY"
) => moment(date, sourceFormat).format(destFormat);

export const convertDateToString = (date, stringFormat = "YYYY-MM-DD") =>
  moment(date).format(stringFormat);

export const dateNowFormat = (stringFormat = "MM/DD/YYYY") =>
  moment().format(stringFormat);

// Returns true if the param date matches
// current date (year, month, day only)
// Returns false otherwise
export const sameAsCurrentDate = (date) => {
  let currentDate = new Date();

  date.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  return currentDate.getTime() == date.getTime();
};
