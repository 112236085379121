import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import DefaultCustomSelect from "../../../../../shared/components/selects/DefaultCustomSelect";
import * as Yup from "yup";

const GENDER_OPTIONS = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
];

class ManuallyForm extends Component {
  validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    handicapIndex: Yup.string()
      .required("Handicap Index is required")
      .matches(/[0-9-+]\d*[.]?[\d]?/g, "value between +9.9 and 54.0")
      .test(
        "len",
        "value between +9.9 and 54.0",
        (val) => val && Number(val) > -9.9 && Number(val) <= 54
      ),
    gender: Yup.object().required("Gender is required"),
  });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { form } = nextProps;
    if (form !== this.props.form) {
      this.formOptions.resetForm();
    }
  }

  renderSubmitButton() {
    const { form, onDelete, addNewForm, addButton } = this.props;
    const className = `btn-ic ic_c_${!addButton ? "minus" : "plus"}`;
    return (
      <button
        type="submit"
        className={className}
        onClick={!addButton ? () => onDelete(form) : () => addNewForm()}
      />
    );
  }

  onChangeField = (field, targetValue) => {
    this.formOptions.setFieldValue(field, targetValue);
    const { form, onChangeFormValue } = this.props;
    setTimeout(() => {
      onChangeFormValue({
        ...form,
        [field]: targetValue,
        touched: this.formOptions.dirty,
        valid: this.formOptions.isValid,
      });
    }, 200);
  };

  onChangeGender = (option) => {
    this.formOptions.setFieldValue("gender", option);
    const { form, onChangeFormValue } = this.props;
    setTimeout(() => {
      onChangeFormValue({
        ...form,
        gender: option,
        touched: this.formOptions.dirty,
        valid: this.formOptions.isValid,
      });
    }, 200);
  };

  renderForm(options) {
    return (
      <Fragment>
        <div className="manually-form add-glf-frm">
          <div className="col margin-top">
            <label className="regular black" htmlFor="name">
              Name
            </label>
            <input
              type="tel"
              id="name"
              value={options.values.name}
              name="name"
              onBlur={options.handleBlur}
              onChange={(e) => this.onChangeField("name", e.target.value)}
              className="large full-width number-input"
            />
            <span className="error">
              {(options.touched.name && options.errors.name) || ""}
            </span>
          </div>

          <div className="col margin-top">
            <label className="regular black" htmlFor="handicapIndex">
              18-Hole Handicap Index
            </label>
            <input
              type="text"
              id="handicapIndex"
              value={options.values.handicapIndex}
              name="handicapIndex"
              onBlur={options.handleBlur}
              className="large full-width"
              onChange={(e) =>
                this.onChangeField("handicapIndex", e.target.value)
              }
            />
            <span className="error">
              {(options.touched.handicapIndex &&
                options.errors.handicapIndex) ||
                ""}
            </span>
          </div>

          <div className="col margin-top">
            <label
              className="regular black"
              htmlFor="par"
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              Rated Tees
            </label>
            <DefaultCustomSelect
              value={options.values.gender}
              onChange={this.onChangeGender}
              placeholder="Select"
              title="Handicap Allowance"
              options={GENDER_OPTIONS}
              className="default_select full_on_phone smallwidth"
              classNamePrefix="ds"
            />
            <span className="error">
              {options.touched.gender && options.errors.gender}
            </span>
          </div>

          <div className="col margin-top w-40">
            {this.renderSubmitButton(options)}
            <span className="error" />
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const { form } = this.props;
    const initialValues = {
      handicapIndex: form?.handicapIndex ?? "",
      name: form?.name ?? "",
      gender: form?.gender ?? "",
    };
    return (
      <Fragment>
        <Formik
          validationSchema={this.validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={initialValues}
          isInitialValid={initialValues}
        >
          {(options) => {
            this.formOptions = options;
            return this.renderForm(options);
          }}
        </Formik>
      </Fragment>
    );
  }
}

export default ManuallyForm;
