import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_CALCULATOR_COURSE,
  FETCH_CALCULATOR_COURSE_SUCCESS,
  FETCH_CALCULATOR_COURSE_FAILED,
  FETCH_CALCULATOR_REMOVE_COURSE,
} from "../actions";
import { removeEmptyTeesFromCourse } from "../../../shared/helpers/transformer-helper";

const initialState = {
  course: undefined,
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CALCULATOR_COURSE:
      return {
        ...state,
        course: initialState.course,
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_CALCULATOR_COURSE_SUCCESS:
      return {
        ...state,
        course: removeEmptyTeesFromCourse(payload),
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_CALCULATOR_COURSE_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    case FETCH_CALCULATOR_REMOVE_COURSE:
      return {
        ...state,
        course: undefined,
      };
    default:
      return state;
  }
};
