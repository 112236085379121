import React from "react";
import PropTypes from "prop-types";

class DatePickerInput extends React.Component {
  componentDidMount() {
    const element = document.getElementById("date-picker");
    const datePicker = document.getElementById("post-score-form");
    element.addEventListener("mousedown", function () {
      element.classList.add("using-mouse");
    });
    datePicker.addEventListener("keydown", function (event) {
      if (event.keyCode === 9) {
        element.classList.remove("using-mouse");
      }
    });
  }

  render = () => (
    <input
      type="text"
      id="date-picker"
      onChange={() => {}}
      readOnly={this.props.readOnly}
      onClick={this.props.onClick}
      value={this.props.value}
      onKeyDown={this.props.onKeyDown}
      aria-label="Select Date"
    />
  );
}

DatePickerInput.defaultProps = {
  onClick: () => {},
};

DatePickerInput.propTypes = {
  onClick: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  value: PropTypes.string,
};

export default DatePickerInput;
