import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../shared/components/modals/YesNoModal";

class ManuallyInputCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manuallyPostScoreWarningIsOpen: false,
    };
  }

  render = () => (
    <Fragment>
      <YesNoModal
        isOpen={this.state.manuallyPostScoreWarningIsOpen}
        onAbort={() =>
          this.setState({
            manuallyPostScoreWarningIsOpen: false,
          })
        }
        onConfirm={this.props.onClick}
        yesTitle="Yes"
        noTitle="No"
      >
        <p>
          Before manually posting a score, have you tried searching for your
          course?
        </p>
      </YesNoModal>
      <button
        className="btn lnk blue no-pad small"
        onClick={() =>
          this.setState({
            manuallyPostScoreWarningIsOpen: true,
          })
        }
        role="link"
      >
        Manually Post Total Score
      </button>
    </Fragment>
  );
}

ManuallyInputCourse.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ManuallyInputCourse;
