import { combineReducers } from "redux";
import authReducer from "../../screens/auth/reducers/index";
import profileReducer from "../../screens/profile/reducers/index";
import golfersReducer from "../../screens/golfers/reducers/index";
import postScoreReducer from "../../screens/post-score/reducers/index";
import golferReducer from "../../screens/golfer/reducers/index";
import handicapCalculatorReducer from "../../screens/handicap-calculator/reducers/index";
import recentScoresReducer from "../../screens/recent-scores/reducers/index";
import contactUsReducer from "../../screens/contact-us/reducers/index";
import selectCourseReducer from "../../screens/select-course/reducers/index";
import statsReducer from "../../screens/stats/reducers/index";
import ghinAssociationsReducer from "../../screens/ghin-associations/reducers";
import aboutGhinReducer from "../../screens/about-ghin/reducers";
import productAlertReducer from "../../screens/product-alert/reducers";
import scores2020Reducer from "../../screens/my-card/reducers";
import myProfileReducer from "../../screens/my-profile/reducers";
import loginWithGhinReducer from "../../screens/sso/reducers";
import selectMyCoursesReducer from "../../screens/my-courses/reducers/index";
import endOfYearCampaignReducer from "../../screens/end-of-year-campaign/reducers";

const rootReducers = combineReducers({
  authReducer,
  profileReducer,
  golfersReducer,
  postScoreReducer,
  golferReducer,
  handicapCalculatorReducer,
  recentScoresReducer,
  contactUsReducer,
  selectCourseReducer,
  statsReducer,
  ghinAssociationsReducer,
  aboutGhinReducer,
  productAlertReducer,
  scores2020Reducer,
  myProfileReducer,
  loginWithGhinReducer,
  selectMyCoursesReducer,
  endOfYearCampaignReducer,
});

export default rootReducers;
