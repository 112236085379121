import { combineEpics } from "redux-observable";
import calculatorCourseFetchEpic from "./calculator-course-fetch";
import calculatorManuallyFetchEpic from "./calculator-manually-fetch";
import calculatorTeeFetchEpic from "./calculator-tee-fetch";
import calculatorHandicapFetchEpic from "./calculator-golfer-handicap-fetch";
import playingHandicapsFetchEpic from "./playing-handicaps-fetch";

export default combineEpics(
  calculatorCourseFetchEpic,
  calculatorTeeFetchEpic,
  calculatorHandicapFetchEpic,
  calculatorManuallyFetchEpic,
  playingHandicapsFetchEpic
);
