import { combineReducers } from "redux";
import storage from "redux-persist/es/storage";
import { persistReducer } from "redux-persist";
import clubsReducer from "./clubs-fetch";
import clubGolfersReducer from "./club-golfers-fetch";
import countriesReducer from "./countries-states-fetch";
import golfersReducer from "./all-golfers-fetch";
import followedGolfersReducer from "./followed-golfers";

const reducers = combineReducers({
  clubsReducer,
  clubGolfersReducer,
  golfersReducer,
  countriesReducer: persistReducer(
    {
      key: "countriesReducer",
      storage: storage,
    },
    countriesReducer
  ),
  followedGolfersReducer: persistReducer(
    {
      key: "followedGolfersReducer",
      storage: storage,
    },
    followedGolfersReducer
  ),
});

export default reducers;
