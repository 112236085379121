import { combineEpics } from "redux-observable";
import {
  FETCH_GOLFER_SCORES,
  fetchGolferScoresSuccess,
  fetchGolferScoresFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";

export const fetchGolferScores = (action$) =>
  action$
    .pipe(ofType(FETCH_GOLFER_SCORES))
    .pipe(switchMap(({ payload }) => getGolferScores(payload)));

const getGolferScores = (payload) =>
  from(ScoresApi.getGolferScores(payload.ghinNumber))
    .pipe(map((response) => fetchGolferScoresSuccess(response.data)))
    .pipe(catchError((error) => of(fetchGolferScoresFailed(error))));

export default combineEpics(fetchGolferScores);
