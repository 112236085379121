import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_ABOUT_DETAILS,
  FETCH_ABOUT_DETAILS_SUCCESS,
  FETCH_ABOUT_DETAILS_FAILED,
} from "../actions";

const initialState = {
  settings: undefined,
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ABOUT_DETAILS:
      return {
        ...state,
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_ABOUT_DETAILS_SUCCESS:
      return {
        ...state,
        settings: payload,
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_ABOUT_DETAILS_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    default:
      return state;
  }
};
