import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_ASSOCIATIONS,
  FETCH_ASSOCIATIONS_SUCCESS,
  FETCH_ASSOCIATIONS_FAILED,
} from "../actions";

const initialState = {
  associations: [],
  jobStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ASSOCIATIONS:
      return {
        ...state,
        associations: [],
        jobStatus: jobStatus.PROCESSING,
      };
    case FETCH_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        associations: payload
          .filter(
            (a) =>
              a.frontend_provider === "GHIN" &&
              a.is_test === false &&
              a.status === "Active"
          )
          .sort((a, b) => a.name.localeCompare(b.name)),
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_ASSOCIATIONS_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    default:
      return state;
  }
};
