import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_COURSES,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_FAILED,
  RESET_ALL_COURSES,
} from "../actions";

const initialState = {
  courses: [],
  fetchCoursesStatus: jobStatus.NOT_STARTED,
  searchMode: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COURSES:
      return {
        ...state,
        courses: [],
        fetchCoursesStatus: jobStatus.PROCESSING,
      };
    case FETCH_COURSES_SUCCESS:
      return {
        ...state,
        courses: payload,
        fetchCoursesStatus: jobStatus.SUCCESS,
        searchMode: true,
      };
    case FETCH_COURSES_FAILED:
      return {
        ...state,
        fetchCoursesStatus: jobStatus.FAILED,
      };
    case RESET_ALL_COURSES:
      return {
        ...state,
        courses: [],
        fetchCoursesStatus: jobStatus.NOT_STARTED,
        searchMode: false,
      };
    default:
      return state;
  }
};
