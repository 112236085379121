import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchGolferProfileHandicapLowhi } from "../actions";
import { getFormattedDate } from "../../../shared/helpers/date-helper";
import jobStatus from "../../../shared/variables/job-status";
import Loader from "../../../shared/components/loaders/Loader";
import { getLowHIDate } from "../../../shared/helpers/ui-helper";
import { getDisplayScore } from "../../../shared/helpers/ui-helper";
import PromoBanner from "../../../shared/components/banners/PromoBanner";
import { Helmet } from "react-helmet";
import {
  H_HISTORY_TITLE,
  H_HISTORY_DESC,
} from "../../../shared/variables/meta-seo";

class ProfileHandicapHistory extends Component {
  componentDidMount() {
    this.props.fetchGolferProfileHandicapLowhi();
  }

  renderHandicap(handicap) {
    return (
      <div className="tbl-row" key={handicap.RevDate}>
        <div className="tbl-cell revision text-center">
          {getFormattedDate(handicap.RevDate)}
        </div>
        <div className="tbl-cell handicap text-center">
          {getDisplayScore(handicap.Display)}
        </div>
      </div>
    );
  }

  renderHandicapLowhi() {
    const { lowhi } = this.props;
    if (!lowhi) return null;
    return (
      <div className="tbl tbl--2cols" style={{ marginBottom: 50 }}>
        <div className="tbl-row">
          <div className="tbl-cell header text-center">
            Low Handicap Index Date
          </div>
          <div className="tbl-cell header text-center">Low Handicap Index</div>
        </div>
        <div className="tbl-row" key={lowhi.low_hi_date}>
          <div className="tbl-cell revision text-center">
            {getLowHIDate(lowhi)}
          </div>
          <div className="tbl-cell handicap text-center">
            {getDisplayScore(lowhi.low_hi_display)}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { handicaps, fetchStatus } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>{H_HISTORY_TITLE}</title>
          <meta name="description" content={H_HISTORY_DESC} />
        </Helmet>
        <div className="container has-adds">
          <section className="golfer_lookup_section">
            <PromoBanner />
            <div className="section_header">
              <h1>Handicap History</h1>
            </div>
            {fetchStatus !== jobStatus.SUCCESS ? (
              <Loader />
            ) : (
              <div className="handicap__list">
                {this.renderHandicapLowhi()}
                <div className="tbl tbl--2cols">
                  <div className="tbl-row">
                    <div className="tbl-cell header text-center">Revision</div>
                    <div className="tbl-cell header text-center">
                      Handicap Index
                    </div>
                  </div>
                  {handicaps.map((score) => this.renderHandicap(score))}
                </div>
              </div>
            )}
          </section>
        </div>
      </Fragment>
    );
  }
}

ProfileHandicapHistory.propTypes = {
  fetchGolferProfileHandicapLowhi: PropTypes.func.isRequired,
  handicaps: PropTypes.array.isRequired,
  lowhi: PropTypes.object,
  fetchStatus: PropTypes.string.isRequired,
};

ProfileHandicapHistory.defaultProps = {
  lowhi: null,
};

const mapStateToProps = ({ profileReducer }) => ({
  handicaps: profileReducer.golferProfileHandicapFetchReducer.handicaps,
  lowhi: profileReducer.golferProfileHandicapLowhiFetchReducer.golfer,
  fetchStatus: profileReducer.golferProfileHandicapFetchReducer.jobStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchGolferProfileHandicapLowhi }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileHandicapHistory);
