import {
  POST_HBH_SCORE,
  postHbhScoreFailed,
  postHbhScoreSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import ScoresApi from "../../../services/scores-api";
import { golferGender, golferId } from "../../../shared/helpers/api-helper";
import teeSetSide from "../../../shared/variables/tee-set-side";
import { convertDateToString } from "../../../shared/helpers/date-helper";
import { drivingFairwayHit } from "../variables/post-score-variables";
import { isEmpty } from "../../../shared/helpers/ui-helper";
import {
  getVisibleHoles,
  appendGirToParams,
} from "../helpers/post-hbh-score-helper";

export default (action$) =>
  action$
    .pipe(ofType(POST_HBH_SCORE))
    .pipe(switchMap(({ payload }) => postHbhScore(payload)));

const postHbhScore = (payload) =>
  from(ScoresApi.postHbhScore(getParams(payload)))
    .pipe(map((r) => postHbhScoreSuccess(r.data.score)))
    .pipe(catchError((error) => of(postHbhScoreFailed(error))));

const getParams = (payload) => ({
  ...golferId(),
  ...golferGender(),
  course_id: payload.courseId,
  tee_set_id: payload.tees.TeeSetRatingId,
  tee_set_side: teeSetSide[getTeeSetRatingType(payload)],
  played_at: convertDateToString(payload.datePlayed),
  score_type: payload.scoreType,
  number_of_holes: getNumberOfHoles(payload),
  override_confirmation: false,
  is_manual: false,
  hole_details: getHoleParams(payload),
});

const getTeeSetRatingType = (payload) => {
  const playedHoles = payload.holes.filter((hole) => hole.score !== "");

  let ratingType = payload.tees.RatingType;
  if (playedHoles.length === 9 && ratingType == "Total")
    ratingType = playedHoles[0].side;

  return ratingType;
};

const getNumberOfHoles = (payload) => {
  const playedHoles = payload.holes.filter((hole) => hole.score !== "");

  if (playedHoles.length === 9) return 9;
  return Number(payload.nrOfHoles);
};

const getHoleParams = (payload) => {
  const visibleHoles = getVisibleHoles(payload, getTeeSetRatingType(payload));

  return visibleHoles.map((hole) => {
    let params = {
      hole_number: hole.nr,
      raw_score: hole.score == "" ? 0 : hole.score,
    };

    if (hole.score == "") {
      params["x_hole"] = true;
    }

    if (payload.withAdvancedStats === true && hole.score !== "") {
      if (!isEmpty(hole.drivingAcc.value)) {
        params = Object.assign(params, {
          DriveAccuracy: hole.drivingAcc.value,
          FairwayHit: hole.drivingAcc.value === drivingFairwayHit,
        });
      }

      if (!isEmpty(hole.putts)) {
        params = Object.assign(params, { putts: hole.putts });
      }

      params = appendGirToParams(params, hole.gir.value);
    }

    return params;
  });
};
