import React, { Component, Fragment } from "react";
import UserMenuLinks from "./UserMenuLinks";
import PropTypes from "prop-types";
import { active } from "../../../../shared/helpers/ui-helper";

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: false };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState((state) => ({
      isToggleOn: !state.isToggleOn,
    }));
  }

  render() {
    const { user, logout } = this.props;
    return (
      <Fragment>
        <div className="user-menu__container">
          <button
            onClick={this.toggleMenu}
            className={`user-menu__trigger border-focus ${active(
              this.state.isToggleOn
            )}`}
            aria-expanded={this.state.isToggleOn}
          >
            <span>{user.player_name}</span>
            <i aria-hidden="true" className="material-icons-outlined more">
              expand_more
            </i>
            <i aria-hidden="true" className="material-icons-outlined less">
              expand_less
            </i>
          </button>
          <div
            className={`user-menu__content ${active(this.state.isToggleOn)}`}
          >
            <UserMenuLinks logout={logout} onClick={() => this.toggleMenu()} />
          </div>
        </div>
      </Fragment>
    );
  }
}

UserMenu.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export default UserMenu;
