import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { getFormattedDate } from "../../../../shared/helpers/date-helper";
import { stringToFloat } from "../../../../shared/helpers/ui-helper";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import PccInfoModal from "../../../../shared/components/modals/PccInfoModal";
import ScaledScoresInfoModal from "../../../../shared/components/modals/ScaledScoresInfoModal.js";
import {
  getAdjustmentsButton,
  getPccFromScoreAdjustments,
  getAdjustmentsInfoMessage,
  getDifferential,
  getDifferentialButton,
  getDifferentialInfoMessage,
} from "../../../../shared/helpers/scores-helper";
import CombinedScoreInfoModal from "../../../stats/components/shared/CombinedScoreInfoModal";

class ScoresItem extends Component {
  courseLabelRef = React.createRef();
  courseCellRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      showAdjustmentsModal: false,
      showDifferentialModal: false,
      refsCreated: false,
      showPccInfoModal: false,
      showCombinedScoresInfoModal: false,
      showScaledScoresInfoModal: false,
    };
  }

  componentDidMount = () => {
    if (this.state.refsCreated === false) {
      this.setState({ refsCreated: true });
    }
  };

  onClosePccInfoModal = () => this.setState({ showPccInfoModal: false });

  onShowPccInfoModal = () => this.setState({ showPccInfoModal: true });

  hasTooltip = () => {
    const margin = 32;
    if (this.courseLabelRef.current && this.courseCellRef.current) {
      return (
        this.courseLabelRef.current.offsetWidth + margin >=
        this.courseCellRef.current.offsetWidth
      );
    }

    return false;
  };

  closeAdjustmentsModal = () => this.setState({ showAdjustmentsModal: false });

  showAdjustmentsModal = () => this.setState({ showAdjustmentsModal: true });

  closeDifferentialModal = () =>
    this.setState({ showDifferentialModal: false });

  showDifferentialModal = () => this.setState({ showDifferentialModal: true });

  renderCombinedScoresInfo() {
    const { score } = this.props;
    if (score.score_type_display_short === "N") {
      return (
        <button
          onClick={() => this.setState({ showCombinedScoresInfoModal: true })}
          className="info-modal-trigger"
          style={{ marginLeft: 5 }}
        >
          i
        </button>
      );
    }
  }

  renderScaledScoresInfo() {
    const { score } = this.props;
    if (score.number_of_played_holes < 18) {
      return (
        <button
          onClick={() => this.setState({ showScaledScoresInfoModal: true })}
          className="info-modal-trigger"
          style={{ marginLeft: 5 }}
        >
          i
        </button>
      );
    }
  }

  renderPlayedHoles() {
    const { score } = this.props;
    if (score.number_of_played_holes > 9 && score.number_of_played_holes < 18) {
      return (
        <span>
          <span className="subscript">{`(${score.number_of_played_holes})`}</span>
        </span>
      );
    }
  }

  render = () => {
    const {
      score,
      hasSameClubId,
      fontStyle,
      nineHole,
      golferId,
      useScaling,
      hasHandicapIndex,
    } = this.props;
    const {
      showAdjustmentsModal,
      showDifferentialModal,
      showCombinedScoresInfoModal,
      showScaledScoresInfoModal,
    } = this.state;
    const modalMessage = getAdjustmentsInfoMessage(score);
    const { showPccInfoModal } = this.state;
    const highlight = score.status === "UnderReview" ? "highlight" : nineHole;
    return (
      <Fragment>
        <div className="tbl-row" style={{ fontStyle }} key={score.id}>
          <div className={`tbl-cell score  text-center ${highlight}`}>
            <span className="cap" data-cap="Score" />
            {score.used ? "* " : ""}
            {score.adjusted_gross_score}
            {useScaling && this.renderPlayedHoles()}
            {score.score_type_display_short}
            {useScaling
              ? hasHandicapIndex && this.renderScaledScoresInfo()
              : this.renderCombinedScoresInfo()}
          </div>
          <div className="tbl-cell date text-center">
            <span className="cap" data-cap="Date" />
            {getFormattedDate(
              score.played_at,
              undefined,
              hasSameClubId ? "MM/DD/YYYY" : "MM/YYYY"
            )}
          </div>
          <div className="tbl-cell rating text-center">
            <span className="cap" data-cap="C.R./Slope" />
            {stringToFloat(score.course_rating, 1)}/{score.slope_rating}
          </div>
          <div className="tbl-cell pcc text-center small-cell">
            <span className="cap">
              <span style={{ float: "left" }}>PCC&nbsp;&nbsp;&nbsp;</span>
              <button onClick={this.onShowPccInfoModal} className="btn blank">
                <i
                  className="material-icons-outlined"
                  style={{ color: "#00365f", fontSize: "22px" }}
                >
                  info
                </i>
              </button>
            </span>
            {getPccFromScoreAdjustments(score)}
          </div>
          <div className="tbl-cell diff text-center">
            <span className="cap" data-cap="Differential" />
            {getDifferential(score, useScaling)}
            {getAdjustmentsButton(score, this.showAdjustmentsModal)}
            {getDifferentialButton(score, this.showDifferentialModal)}
          </div>
          {hasSameClubId && (
            <div
              className="tbl-cell course grow"
              style={{ overflow: "hidden" }}
              ref={this.courseCellRef}
              data-tip={this.getTooltip(score)}
              data-for="score-item"
            >
              <span className="cap" data-cap="Course" />
              <span
                className="overflow-text-ellipsis"
                ref={this.courseLabelRef}
              >
                {this.getCourseName(score)}
              </span>
            </div>
          )}
        </div>
        <YesNoModal
          isOpen={showAdjustmentsModal}
          onAbort={this.closeAdjustmentsModal}
          onConfirm={this.closeAdjustmentsModal}
          className="modal-dialog"
          appElement={document.getElementById("root")}
          isNoVisible={false}
          yesTitle="Ok"
        >
          <p>{modalMessage}</p>
        </YesNoModal>
        <YesNoModal
          isOpen={showDifferentialModal}
          onAbort={this.closeDifferentialModal}
          onConfirm={this.closeDifferentialModal}
          className="modal-dialog"
          appElement={document.getElementById("root")}
          isNoVisible={false}
          yesTitle="Ok"
        >
          {getDifferentialInfoMessage()}
        </YesNoModal>
        <PccInfoModal
          isOpen={showPccInfoModal}
          onAbort={this.onClosePccInfoModal}
          onConfirm={this.onClosePccInfoModal}
        />
        <CombinedScoreInfoModal
          score={score}
          golferId={golferId}
          hasSameClubId={hasSameClubId}
          isOpen={showCombinedScoresInfoModal}
          onClose={() => this.setState({ showCombinedScoresInfoModal: false })}
        />
        <ScaledScoresInfoModal
          isOpen={showScaledScoresInfoModal}
          onClose={() => this.setState({ showScaledScoresInfoModal: false })}
        />
      </Fragment>
    );
  };

  getTooltip = (score) =>
    this.hasTooltip()
      ? `${score.facility_name !== null ? score.facility_name : ""}${
          score.facility_name !== score.course_name
            ? "<br />" + score.course_name
            : ""
        }`
      : "";

  getCourseName = (score) => {
    if (score.score_type === "C") {
      const value = score.course_display_value.split("/");
      return (
        <Fragment>
          {value[0] ? value[0].trim() : ""}
          <br />
          {value[1] ? value[1].trim() : ""}
        </Fragment>
      );
    }
    return (
      <Fragment>
        {score.facility_name}
        {score.facility_name !== score.course_name && (
          <Fragment>
            {score.facility_name !== null && <br />}
            {score.course_name}
          </Fragment>
        )}
      </Fragment>
    );
  };
}

ScoresItem.propTypes = {
  score: PropTypes.shape({}).isRequired,
  fontStyle: PropTypes.string,
  nineHole: PropTypes.string,
  useScaling: PropTypes.bool,
  hasHandicapIndex: PropTypes.bool,
  hasSameClubId: PropTypes.bool.isRequired,
};

ScoresItem.defaultProps = {
  fontStyle: "normal",
  nineHole: "",
};

export default ScoresItem;
