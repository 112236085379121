import {
  CONTACT_PROFILE_FETCH,
  fetchContactProfileSuccess,
  fetchContactProfileFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(CONTACT_PROFILE_FETCH))
    .pipe(switchMap(({ payload }) => fetchContactProfile(payload)));

const fetchContactProfile = () =>
  from(GolferApi.golferProfile())
    .pipe(map((response) => fetchContactProfileSuccess(response.data.golfers)))
    .pipe(catchError(() => of(fetchContactProfileFailed())));
