import { ADD_TEE, addTeeSuccess, addTeeFailed } from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import CoursesApi from "../../../services/courses-api";

export default (action$) =>
  action$
    .pipe(ofType(ADD_TEE))
    .pipe(switchMap(({ payload }) => addTee(payload)));

const addTee = (payload) =>
  from(CoursesApi.postMyTee(payload.course_id, payload.tee_id))
    .pipe(
      map((response) =>
        addTeeSuccess(
          response.data.golfer_course_preference,
          payload.updateCourses
        )
      )
    )
    .pipe(catchError((error) => of(addTeeFailed(error))));
