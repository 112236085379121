import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";
import Navigate from "../../../../../shared/navigation/navigate";
// import { routes } from "../../../../../shared/routes/index";
// import { manualCourse } from "../../../variables/post-score-variables";

class NoActiveTeesModal extends Component {
  onClose = () => {
    const { onClose } = this.props;
    onClose();
    Navigate.goBack();
  };

  // onConfirm = () => {
  //   const { onClose } = this.props;
  //   onClose();
  //   const { course } = this.props;
  //   this.setState({ noActiveTeesModalOpen: false });
  //   const route = routes.postScoreTotalPost.path.replace(
  //     ":courseId",
  //     `${manualCourse}?courseName=${course.CourseName}`
  //   );
  //   Navigate.resetToPath(route);
  // };

  render() {
    const { isOpen } = this.props;
    return (
      <YesNoModal
        onConfirm={this.onClose}
        onAbort={this.onClose}
        isNoVisible={false}
        isOpen={isOpen}
        yesTitle="OK"
      >
        <p>
          This Course does not have any active or rated tees. Please contact
          your club or local association for assistance.
        </p>
      </YesNoModal>
    );
  }
}

NoActiveTeesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NoActiveTeesModal;
