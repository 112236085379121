import ratingType from "../../../shared/variables/rating-type";
import { isEmpty } from "../../../shared/helpers/ui-helper";
import numberOfHoles from "../../../shared/variables/number-of-holes";
import {
  defaultDrivingOption,
  defaultGirOption,
} from "../variables/post-score-variables";

export const getSideHoles = (options, side) =>
  options.values.holes.filter((x) => x.side === side);

export const getSidePutts = (options, side) =>
  side === ratingType.FRONT
    ? options.values.frontPutts
    : options.values.backPutts;

export const getSideGir = (options, side) =>
  side === ratingType.FRONT ? options.values.frontGir : options.values.backGir;

export const getSideYardage = (options, side) =>
  side === ratingType.FRONT
    ? options.values.frontYardage
    : options.values.backYardage;

export const getSideDrivingAcc = (options, side) =>
  side === ratingType.FRONT
    ? options.values.frontDrivingAcc
    : options.values.backDrivingAcc;

export const getSidePar = (options, side) =>
  side === ratingType.FRONT ? options.values.frontPar : options.values.backPar;

export const getSideScore = (options, side) =>
  side === ratingType.FRONT
    ? options.values.frontScore
    : options.values.backScore;

export const getSideAdjScore = (options, side) =>
  side === ratingType.FRONT
    ? options.values.frontAdjScore
    : options.values.backAdjScore;

export const normalizeScore = (hole, event) => {
  let value = event.target.value.match(/\d+/g);

  value = value && value[0] === "0" ? "" : value;

  value = value
    ? value[0].length > 1
      ? value[0].match(/(?!0)\d{1,2}/)
      : value[0].match(/\d{1,2}/)
    : value;

  let retValue = value ? Number(value[0]) : 0;

  if (retValue >= 20) {
    retValue = Math.floor(retValue % 10);
  }
  return retValue !== 0 ? retValue : "";
};

export const focusNextScoreInput = (hole, score, scoreInputRefs, options) => {
  const diff = options.values.tees.RatingType === ratingType.BACK ? 9 : 0;

  if (score && score !== 0 && score !== 1) {
    if (hole.index - diff < scoreInputRefs.length - 1) {
      scoreInputRefs[hole.index - diff + 1].focus();
    } else {
      scoreInputRefs[hole.index - diff].blur();
    }
  }
};

export const focusFirstScoreInput = (holeIndex, scoreInputRefs, options) => {
  const diff = options.values.tees.RatingType === ratingType.BACK ? 9 : 0;
  if (holeIndex - diff < scoreInputRefs.length - 1) {
    scoreInputRefs[holeIndex].focus();
  }
};

export const getScoreMarkupClass = (hole, score) => {
  if (isEmpty(score) || score === hole.par) {
    return "score-markup";
  } else if (score === hole.par - 1) {
    return "score-markup score-circle";
  } else if (score < hole.par - 1) {
    return "score-markup score-double-circle";
  } else if (score === hole.par + 1) {
    return "score-markup score-square";
  } else if (score > hole.par + 1) {
    return "score-markup score-double-square";
  } else {
    return "score-markup";
  }
};

export const getVisibleHoles = (values, side) =>
  side === ratingType.FRONT || side === ratingType.BACK
    ? values.holes.filter((x) => x.side === side)
    : values.holes;

export const isNumber = (value) => typeof value === "number";

export const isBoolean = (value) => typeof value === "boolean";

export const isTeeHolesInfoAvailable = (
  teeSet,
  nrOfHoles = numberOfHoles.EIGHTEEN
) => {
  if (nrOfHoles === numberOfHoles.TEN_SEVENTEEN) {
    nrOfHoles = numberOfHoles.EIGHTEEN;
  }

  return (
    teeSet &&
    teeSet.Holes &&
    teeSet.Holes.length !== 0 &&
    teeSet.Holes.length >= nrOfHoles
  );
};

export const isTeeHolesAllocationAvailable = (teeSet) => {
  let avaliableAllocation = teeSet.Holes.filter(
    (hole) => hole.Allocation !== null && hole.Allocation > 0
  );
  return avaliableAllocation.length === teeSet.Holes.length;
};

export const scrollHbhSide = (event, side) => {
  const scrollValue = event.target.scrollLeft;
  const scrollMaxValue = event.target.scrollWidth - event.target.clientWidth;
  const scrollPercent = (100 * event.target.scrollLeft) / scrollMaxValue;

  const scoreTable = document.getElementById(`post-hbh-score-table-${side}`);
  if (scoreTable) {
    scoreTable.scrollLeft = scrollValue;
  }

  const advStats = document.getElementById(`post-hbh-adv-stats-${side}`);
  if (advStats) {
    advStats.scrollLeft = scrollValue;
  }

  const miniScroll = document.getElementById(`post-hbh-mini-scroll-${side}`);
  const miniScrollSlider = document.getElementById(
    `post-hbh-mini-scroll-slider-${side}`
  );
  if (miniScroll && miniScrollSlider) {
    const miniScrollMaxWidth =
      miniScroll.offsetWidth - miniScrollSlider.offsetWidth;
    const miniScrollSliderPosition = (scrollPercent * miniScrollMaxWidth) / 100;
    miniScrollSlider.style.marginLeft = `${miniScrollSliderPosition}px`;
  }
};

export const clearAllScores = (options) => {
  const { values } = options;
  values.holes.forEach((hole) => {
    hole.score = "";
    hole.adjScore = "";
  });

  values.frontScore = 0;
  values.frontAdjScore = 0;
  values.backScore = 0;
  values.backAdjScore = 0;
  values.totalScore = 0;
  values.totalAdjScore = 0;

  options.setFieldValue("totalScore", 0, true);
};

export const clearAllStats = (options) => {
  const { values } = options;
  values.holes.forEach((hole) => {
    hole.putts = "";
    hole.gir = defaultGirOption;
    hole.drivingAcc = defaultDrivingOption;
  });

  values.frontPutts = 0;
  values.backPutts = 0;
  values.totalPutts = 0;
  values.frontGir = 0;
  values.backGir = 0;
  values.totalGir = 0;
  values.frontDrivingAcc = 0;
  values.backDrivingAcc = 0;
  values.totalDrivingAcc = 0;
};

export const normalizeTeeSetSide = (tee) => {
  switch (tee.RatingType) {
    case ratingType.TOTAL:
      return "All18";
    case ratingType.FRONT:
      return "F9";
    case ratingType.BACK:
      return "B9";
    default:
      return "";
  }
};

export const normalizePutts = (hole, event) => {
  let value = event.target.value.match(/\d+/g);

  value = value
    ? value[0].length > 1
      ? value[0].match(/(?!0)\d{1,2}/)
      : value[0].match(/\d{1,2}/)
    : value;

  let retValue = value ? Number(value[0]) : "";

  if (retValue > 4) {
    retValue = 4;
  }
  return retValue;
};

export const focusNextPuttsInput = (hole, putts, puttsInputRefs, options) => {
  const diff = options.values.tees.RatingType === ratingType.BACK ? 9 : 0;
  const next_hole = options.values.holes.find(
    (h) => h.index > hole.index && h.score
  );

  if (putts !== "") {
    if (hole.index - diff < puttsInputRefs.length - 1 && next_hole) {
      const input = puttsInputRefs[next_hole.index - diff];
      if (input) {
        input.focus();
      }
    } else {
      const input = puttsInputRefs[hole.index - diff];
      if (input) {
        input.blur();
      }
    }
  }
};

export const appendGirToParams = (params, gir) => {
  if (!isEmpty(gir)) {
    params = Object.assign(params, {
      gir_flag: gir === 0 ? true : false,
    });
    if (gir !== 0) {
      params = Object.assign(params, {
        approach_shot_accuracy: gir - 1,
      });
    }
  } else {
    params = Object.assign(params, {
      gir_flag: false,
    });
  }
  return params;
};
