import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";

class IncompleteScorePostModal extends Component {
  render() {
    const { isOpen, onAbort, onConfirm } = this.props;
    return (
      <YesNoModal
        onConfirm={onConfirm}
        onAbort={onAbort}
        isOpen={isOpen}
        yesTitle="Post Anyway"
        noTitle="Cancel"
      >
        <p>
          You are about to post a score without fully completing all stats for
          this round. Incomplete information will impact your overall stats.
        </p>
      </YesNoModal>
    );
  }
}

IncompleteScorePostModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAbort: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.object,
};

export default IncompleteScorePostModal;
