export const postScoreMode = {
  POST: "POST",
  EDIT: "EDIT",
};

export const manualRating = "manual-rating";

export const manualCourse = "manual-course";

export const defaultDrivingOption = {
  value: "",
  label: "",
  icon: "",
  color: "transparent",
};

export const drivingFairwayHit = "fairway-hit";

export const drivingOptions = [
  { value: "fairway-hit", label: "Fairway Hit", icon: "done", color: "green" },
  {
    value: 0,
    label: "Missed Left",
    icon: "arrow_back",
    color: "red",
  },
  {
    value: 1,
    label: "Missed Right",
    icon: "arrow_forward",
    color: "red",
  },
  {
    value: 2,
    label: "Missed Long",
    icon: "arrow_upward",
    color: "red",
  },
  {
    value: 3,
    label: "Missed Short",
    icon: "arrow_downward",
    color: "red",
  },
];

export const defaultGirOption = {
  value: "",
  label: "",
  icon: "",
  color: "transparent",
};

export const girOptions = [
  { value: 0, label: "Green Hit", icon: "done", color: "green" },
  { value: 1, label: "Missed Left", icon: "arrow_back", color: "red" },
  { value: 2, label: "Missed Right", icon: "arrow_forward", color: "red" },
  { value: 3, label: "Missed Long", icon: "arrow_upward", color: "red" },
  { value: 4, label: "Missed Short", icon: "arrow_downward", color: "red" },
];
