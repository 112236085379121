import React, { Component, Fragment } from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";

import { routes } from "../../../shared/routes";
import PromoBanner from "../../../shared/components/banners/PromoBanner";

export default class Stats extends Component {
  render() {
    const editCombinedScoresPath = window.location.pathname.includes(
      "edit-combined-score"
    );
    const viewAvgScorePerHolePath = window.location.pathname.includes(
      "view-avg-score-per-hole"
    );
    const shouldNotRenderButtons =
      window.location.pathname.includes("total-round-summary") ||
      window.location.pathname.includes("hbh-round-summary");
    return (
      <Fragment>
        <PromoBanner />
        <div className="container">
          <section className="stats_section" role="heading" aria-level="1">
            {!shouldNotRenderButtons && (
              <ul className="tabs light">
                <li>
                  <NavLink
                    to={routes.statsScoreHistory.path}
                    className={viewAvgScorePerHolePath ? "is-active" : ""}
                    activeClassName="is-active"
                  >
                    Score History
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routes.statsCharts.path}
                    className={editCombinedScoresPath ? "is-active" : ""}
                    activeClassName="is-active"
                  >
                    Stats
                  </NavLink>
                </li>
              </ul>
            )}
            <Switch>
              <Redirect
                exact
                from={routes.stats.path}
                to={routes.statsScoreHistory.path}
              />
              <Route {...routes.statsScoreHistory} />
              <Route {...routes.statsCharts} />
              <Route {...routes.statsEditCombinedScore} />
              <Route {...routes.viewAvgScorePerHole} />
              <Route {...routes.roundSummaryTotal} />
              <Route {...routes.roundSummaryHbh} />
            </Switch>
          </section>
        </div>
      </Fragment>
    );
  }
}
