import React, { Fragment, Component } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

class Tooltip extends Component {
  state = {
    tooltipVisibile: false,
  };

  afterHide = () => this.setState({ tooltipVisibile: false });

  afterShow = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
      this.setState({ tooltipVisibile: true });
    }
  };

  onClick = () => {
    const { tooltipVisibile } = this.state;
    if (tooltipVisibile) {
      this.reactTooltip.hideTooltip();
    } else {
      ReactTooltip.show();
    }
    this.setState((prevState) => ({
      tooltipVisibile: !prevState.tooltipVisibile,
    }));
  };

  render() {
    const { message, place } = this.props;
    return (
      <Fragment>
        <button
          className="info-modal-trigger white margin-right"
          data-tip={message}
          data-for="profile-info"
          onClick={this.onClick}
        >
          !
        </button>
        <ReactTooltip
          multiline
          id="profile-info"
          ref={(ref) => (this.reactTooltip = ref)}
          place={place}
          type="light"
          effect="float"
          isScroll={true}
          afterShow={this.afterShow}
          afterHide={this.afterHide}
          style={{ fontSize: 10 }}
          className="toolTip"
        />
      </Fragment>
    );
  }
}

Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
  place: PropTypes.string,
};

Tooltip.defaultProps = {
  place: "top",
};

export default Tooltip;
