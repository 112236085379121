import {
  FETCH_FOLLOWED_GOLFERS,
  fetchFollowedGolfersSuccess,
  fetchFollowedGolfersFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import FollowApi from "../../../services/follow-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_FOLLOWED_GOLFERS))
    .pipe(switchMap(({ payload }) => getGolfers(payload)));

const getGolfers = (payload) =>
  from(FollowApi.getFollowedGolfers())
    .pipe(
      map((response) =>
        fetchFollowedGolfersSuccess(response.data.golfers, payload.sortCriteria)
      )
    )
    .pipe(catchError((error) => of(fetchFollowedGolfersFailed(error))));
