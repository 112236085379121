import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import { fetchFollowedGolfers } from "../actions";
import GolfersList from "./GolfersList";
import jobStatus from "../../../shared/variables/job-status";
import Loader from "../../../shared/components/loaders/Loader";
import NoResultsFound from "../../../shared/components/notices/NoResultsFound";

class Following extends Component {
  componentDidMount() {
    const { sortCriteria } = this.props;
    this.props.fetchFollowedGolfers(sortCriteria);
  }

  renderContent() {
    const { golfers, notFoundText } = this.props;
    if (golfers.length > 0) {
      return (
        <GolfersList golfers={golfers} renderItem={this.props.renderItem} />
      );
    }
    return (
      <ul className="lookup__list">
        <li>
          <NavLink className="item header border-focus" to="#" tabIndex="-1">
            <span className="star" />
            <span className="details">
              <span
                className="name abbr"
                data-abbr="Name/Club"
                data-full="Name"
              />
              <span className="club abbr" data-abbr="" data-full="Club" />
            </span>
            <span
              className="index abbr"
              data-abbr="H.I."
              data-full="Handicap Index"
            />
          </NavLink>
        </li>
        <li>
          <NoResultsFound>{notFoundText}</NoResultsFound>
        </li>
      </ul>
    );
  }

  render() {
    const { fetchStatus } = this.props;
    return (
      <Fragment>
        <div className="panel">
          {fetchStatus !== jobStatus.SUCCESS ? (
            <Loader />
          ) : (
            this.renderContent()
          )}
        </div>
      </Fragment>
    );
  }
}

Following.propTypes = {
  fetchFollowedGolfers: PropTypes.func.isRequired,
  golfers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchStatus: PropTypes.string.isRequired,
  renderItem: PropTypes.func.isRequired,
  notFoundText: PropTypes.object.isRequired,
  sortCriteria: PropTypes.string,
};

Following.defaultProps = {
  sortCriteria: undefined,
};

const mapStateToProps = ({ golfersReducer }) => ({
  fetchStatus: golfersReducer.followedGolfersReducer.jobStatus,
  golfers: golfersReducer.followedGolfersReducer.golfers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchFollowedGolfers }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Following);
