import React, { Component } from "react";
import PropTypes from "prop-types";
import BoxHead from "../../../../shared/components/box/BoxHead";

class DesktopHeader extends Component {
  render = () => (
    <div className="box-pre-body">
      <BoxHead title={this.props.title} />
      <ul className="table-controls tablet-and-above">
        <li>
          <button
            className="btn blank medium no-pad"
            onClick={this.props.onClearAllStats}
          >
            Clear All Stats
          </button>
        </li>
      </ul>
    </div>
  );
}

DesktopHeader.propTypes = {
  onClearAllStats: PropTypes.func.isRequired,
};

export default DesktopHeader;
