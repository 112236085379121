import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import scoreType from "../../../../../shared/variables/score-type";
import { renderValidationError } from "../../../../../shared/helpers/ui-helper";

class ScoreType extends Component {
  render() {
    const { options } = this.props;
    return (
      <div className="row">
        <div className="col is-full">
          <label className="regular black">
            <span>Score Type</span>
            {renderValidationError(options, "scoreType")}
          </label>
          <div className="radio_container inline is-full">
            <input
              className="pill"
              type="radio"
              name="scoreType"
              id="home"
              value={scoreType.HOME}
              checked={options.values.scoreType === scoreType.HOME}
              onChange={options.handleChange}
            />
            <label htmlFor="home">Home</label>
            <input
              className="pill"
              type="radio"
              name="scoreType"
              id="away"
              value={scoreType.AWAY}
              checked={options.values.scoreType === scoreType.AWAY}
              onChange={options.handleChange}
            />
            <label htmlFor="away">Away</label>
            <Fragment>
              <input
                className="pill"
                type="radio"
                name="scoreType"
                id="tournament"
                value={scoreType.TOURNAMENT}
                checked={options.values.scoreType === scoreType.TOURNAMENT}
                onChange={options.handleChange}
              />
              <label htmlFor="tournament">Competition</label>
            </Fragment>
          </div>
        </div>
      </div>
    );
  }
}

ScoreType.propTypes = {
  options: PropTypes.object.isRequired,
};

export default ScoreType;
