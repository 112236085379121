import * as Yup from "yup";
import numberOfHoles from "../../../shared/variables/number-of-holes";
import scoreType from "../../../shared/variables/score-type";
import { isEmpty } from "../../../shared/helpers/ui-helper";

export const sharedValidationSchema = Yup.object({
  nrOfHoles: Yup.string(),
  scoreType: Yup.string(),
  datePlayed: Yup.date(),
  frontScore: Yup.number(),
  backScore: Yup.number(),
  totalScore: Yup.number(),
  courseId: Yup.number(),
  tees: Yup.string(),
  isManualRating: Yup.boolean(),
  isManualCourse: Yup.boolean(),
  manualCourseName: Yup.string(),
  manualCourseRating: Yup.number(),
  manualSlopeRating: Yup.number(),
});

export const defaultTeesValue = {
  value: "",
  label: "Select Tees",
};

export const sharedInitialValues = {
  nrOfHoles: numberOfHoles.EIGHTEEN,
  tees: defaultTeesValue,
  scoreType: scoreType.HOME,
  datePlayed: new Date(),
  frontScore: "",
  backScore: "",
  totalScore: "",
  courseId: "",
  isManualRating: false,
  isManualCourse: false,
  manualCourseName: "",
  manualCourseRating: "",
  manualSlopeRating: "",
};

export const validateSharedFields = (values, errors, isManualCourse) => {
  validateRequiredFields(values, errors);

  isManualCourse === true
    ? validateManualCourse(values, errors)
    : validateSelectedCourse(values, errors);

  return errors;
};

const validateRequiredFields = (values, errors) => {
  if (isEmpty(values.nrOfHoles)) {
    errors.nrOfHoles = "Required";
  }

  if (isEmpty(values.scoreType)) {
    errors.scoreType = "Required";
  }

  if (isEmpty(values.datePlayed)) {
    errors.datePlayed = "Required";
  }
};

const validateManualCourse = (values, errors) => {
  if (isEmpty(values.manualCourseName)) {
    errors.manualCourseName = "Course name not specified";
  }

  validateManualRatings(values, errors);
};

const validateSelectedCourse = (values, errors) => {
  if (isEmpty(values.tees.value)) {
    errors.tees = "Tee must be selected when course is not manually added";
  }

  if (values.isManualRating === true) {
    validateManualRatings(values, errors);
  }
};

export const validateManualRatings = (values, errors) => {
  if (isEmpty(values.manualCourseRating)) {
    errors.manualCourseRating = "Required";
  }

  if (
    !isEmpty(values.manualCourseRating) &&
    values.nrOfHoles === numberOfHoles.EIGHTEEN &&
    (values.manualCourseRating < 40.0 || values.manualCourseRating > 90.0)
  ) {
    errors.manualCourseRating = "value between 40.0 and 90.0";
  }

  if (
    !isEmpty(values.manualCourseRating) &&
    values.nrOfHoles === numberOfHoles.NINE &&
    (values.manualCourseRating < 24.0 || values.manualCourseRating > 45.0)
  ) {
    errors.manualCourseRating = "value between 24.0 and 45.0";
  }

  if (isEmpty(values.manualSlopeRating)) {
    errors.manualSlopeRating = "Required";
  }

  if (
    !isEmpty(values.manualSlopeRating) &&
    (values.manualSlopeRating < 55 || values.manualSlopeRating > 155)
  ) {
    errors.manualSlopeRating = "value between 55 and 155";
  }
};
