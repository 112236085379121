/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import PropTypes from "prop-types";

class GolferItem extends Component {
  onSelectGolfer = () => this.props.onSelect(this.props.golfer);

  render() {
    const { golfer, selectedId, golferId, status } = this.props;
    const selected = String(golferId) === String(selectedId);

    return (
      <div onClick={this.onSelectGolfer}>
        <div className="golfer-item-box">
          <div className={`icon${selected ? " checked" : ""}`}>
            <input type="radio" />
          </div>
          <div className="name-wrapper">
            <div className="name">{golfer.player_name + (status || "")}</div>
            <div className="number">
              {`GHIN Number: ${golfer.ghin_number || golfer.id}`}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GolferItem.propTypes = {
  golfer: PropTypes.shape({}).isRequired,
  onSelect: PropTypes.func.isRequired,
  golferId: PropTypes.any.isRequired,
  selectedId: PropTypes.any,
};

GolferItem.defaultProps = {
  selectedId: undefined,
};

export default GolferItem;
