import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import NoResultsFound from "../../../shared/components/notices/NoResultsFound";

class GolfersList extends Component {
  renderEmptyView() {
    return (
      <NoResultsFound>
        <br />
        <p>No golfers found.</p>
        <br />
      </NoResultsFound>
    );
  }
  render() {
    const { golfers, renderItem } = this.props;
    return (
      <Fragment>
        <ul className="lookup__list">
          <li>
            <NavLink tabIndex="-1" className="item header" to="#">
              <span className="star" />
              <span className="details">
                <span
                  className="name abbr"
                  data-abbr="Name/Club"
                  data-full="Name"
                />
                <span className="club abbr" data-abbr="" data-full="Club" />
              </span>
              <span
                className="index abbr"
                data-abbr="H.I."
                data-full="Handicap Index"
              />
            </NavLink>
          </li>
          {golfers && golfers.length > 0
            ? golfers.map((golfer, key) => renderItem(golfer, key))
            : this.renderEmptyView()}
        </ul>
      </Fragment>
    );
  }
}

GolfersList.propTypes = {
  golfers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderItem: PropTypes.func.isRequired,
};

export default GolfersList;
