export const CONFIRM_EMAIL_CHANGE = "CONFIRM_EMAIL_CHANGE";
export const CONFIRM_EMAIL_CHANGE_FAILED = "CONFIRM_EMAIL_CHANGE_FAILED";
export const CONFIRM_EMAIL_CHANGE_SUCCESS = "CONFIRM_EMAIL_CHANGE_SUCCESS";

export const confirmEmailChange = (payload) => ({
  type: CONFIRM_EMAIL_CHANGE,
  payload,
});

export const confirmEmailChangeFailed = (payload) => ({
  type: CONFIRM_EMAIL_CHANGE_FAILED,
  payload,
});

export const confirmEmailChangeSuccess = (payload) => ({
  type: CONFIRM_EMAIL_CHANGE_SUCCESS,
  payload,
});
