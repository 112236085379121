import ChartType from "../variables/score-chart-type";
import { isEmpty } from "lodash";

export const adaptAverageScoreSummaryToDonut = (summary) => {
  if (!summary) return {};
  const stats = { ...summary };
  stats.birdies_or_better_percent = summary?.birdies_or_better_percent / 100;
  stats.pars_percent = summary?.pars_percent / 100;
  stats.bogeys_percent = summary?.bogeys_percent / 100;
  stats.double_bogeys_percent = summary?.double_bogeys_percent / 100;
  stats.triple_bogeys_or_worse_percent =
    summary?.triple_bogeys_or_worse_percent / 100;
  stats.double_bogeys_percent = summary?.double_bogeys_percent / 100;
  return stats;
};

export const adaptAverageAdvancedStatsToChart = (average) => {
  if (!average) return {};
  const stats = { ...average };
  stats.fairway_hits_percent = average?.fairway_hits_percent / 100;
  stats.missed_left_percent = average?.missed_left_percent / 100;
  stats.missed_right_percent = average?.missed_right_percent / 100;
  stats.missed_long_percent = average?.missed_long_percent / 100;
  stats.missed_short_percent = average?.missed_short_percent / 100;
  stats.gir_percent = average?.gir_percent / 100;
  stats.missed_left_approach_shot_accuracy_percent =
    average?.missed_left_approach_shot_accuracy_percent / 100;
  stats.missed_right_approach_shot_accuracy_percent =
    average?.missed_right_approach_shot_accuracy_percent / 100;
  stats.missed_long_approach_shot_accuracy_percent =
    average?.missed_long_approach_shot_accuracy_percent / 100;
  stats.missed_short_approach_shot_accuracy_percent =
    average?.missed_short_approach_shot_accuracy_percent / 100;
  stats.missed_general_approach_shot_accuracy_percent =
    average?.missed_general_approach_shot_accuracy_percent / 100;
  stats.putts_total = average?.putts;
  stats.one_putt_or_better_percent = average?.one_putt_or_better_percent / 100;
  stats.two_putt_percent = average?.two_putt_percent / 100;
  stats.three_putt_or_worse_percent =
    average?.three_putt_or_worse_percent / 100;
  stats.two_putt_or_better_percent = average?.two_putt_or_better_percent / 100;
  stats.up_and_downs_total = average?.up_and_downs_total;
  return stats;
};

export const getScoreStatsType = (stats) => {
  if (!stats || isEmpty(stats)) return ChartType.NO_STATS;
  if (
    (stats.gir_percent && stats.gir_percent) > 0 ||
    (stats.putts_total && stats.putts_total > 0) ||
    (stats.missed_left_percent && stats.missed_left_percent > 0) ||
    (stats.missed_long_percent && stats.missed_long_percent > 0) ||
    (stats.missed_right_percent && stats.missed_right_percent > 0) ||
    (stats.missed_short_percent && stats.missed_short_percent > 0) ||
    (stats.fairway_hits_percent && stats.fairway_hits_percent > 0) ||
    (stats.missed_left_approach_shot_accuracy_percent &&
      stats.missed_left_approach_shot_accuracy_percent > 0) ||
    (stats.missed_long_approach_shot_accuracy_percent &&
      stats.missed_long_approach_shot_accuracy_percent > 0) ||
    (stats.missed_right_approach_shot_accuracy_percent &&
      stats.missed_right_approach_shot_accuracy_percent > 0) ||
    (stats.missed_short_approach_shot_accuracy_percent &&
      stats.missed_short_approach_shot_accuracy_percent > 0)
  ) {
    return ChartType.ADVANCED_STATS;
  }
  return ChartType.STATS;
};
