export const HOME_COURSES = "HOME_COURSES";
export const HOME_COURSES_CLEAR = "HOME_COURSES_CLEAR";
export const HOME_COURSES_FAILED = "HOME_COURSES_FAILED";
export const HOME_COURSES_SUCCESS = "HOME_COURSES_SUCCESS";

export const fetchHomeCourses = () => ({
  type: HOME_COURSES,
});

export const fetchHomeCoursesFailed = () => ({
  type: HOME_COURSES_FAILED,
});

export const clearHomeCourses = () => ({
  type: HOME_COURSES_CLEAR,
});

export const fetchHomeCoursesSuccess = (payload) => {
  let homeCourses = [];
  payload.forEach((p) => {
    if (p.home_courses && p.home_courses.length > 0) {
      homeCourses.push(...p.home_courses);
    }
  });
  return {
    type: HOME_COURSES_SUCCESS,
    payload: homeCourses,
  };
};
