/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";
import { getUserHandicapIndex } from "../../../../shared/helpers/user-helper";
import {
  validateManuallyHCFields,
  getHandicapAllowanceOptions,
} from "../../../../shared/helpers/handicap-calculator-helper";
import jobStatus from "../../../../shared/variables/job-status";
import {
  fetchManuallyCalculator,
  manuallyCalculatorReset,
} from "../../actions";
import HandicapInfoModal from "../../../../shared/components/modals/HandicapInfoModal";
import DefaultCustomSelect from "../../../../shared/components/selects/DefaultCustomSelect";
import { Helmet } from "react-helmet";
import {
  MANUALLY_CALC_H_TITLE,
  MANUALLY_CALC_H_DESC,
} from "../../../../shared/variables/meta-seo";

const HANDICAP_ALLOWANCE_OPTIONS = getHandicapAllowanceOptions();

class ManuallyCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoModal: 0,
    };
  }

  componentDidMount() {
    this.props.manuallyCalculatorReset();
  }

  getUserHandicap() {
    const index = getUserHandicapIndex().match(
      /((0(\.\d{1,2})?)|([1-9-+]\d*(\.\d{1,2})?))/g
    );
    return index ? index[0] : "";
  }

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    this.props.fetchManuallyCalculator(
      values.handicapIndex,
      values.courseRating,
      values.slopeRating,
      values.par,
      values.handicapAllowance.value
    );
  };

  onChangeHandicapAllowance = (option) => {
    this.formOptions.setFieldValue("handicapAllowance", option);
    this.props.manuallyCalculatorReset();
  };

  onChangeField = (field, targetValue) => {
    if (field === "handicapIndex") {
      let value = targetValue.match(/[0-9-+]\d*[.]?[\d]?/g);
      if (targetValue.length > 0) {
        if (value) {
          this.formOptions.setFieldValue("handicapIndex", value[0]);
        } else {
          this.formOptions.setFieldValue("handicapIndex", "");
        }
      } else {
        this.formOptions.setFieldValue("handicapIndex", targetValue);
      }
    } else if (field === "courseRating") {
      let value = targetValue.match(/[1-9]\d*[.]?[\d]?/g);
      value = value ? value[0] : "";
      this.formOptions.setFieldValue("courseRating", value);
    } else {
      let value = targetValue.match(/[1-9]\d*[.]?[\d]?/g);
      value = value ? parseInt(value[0]) : "";
      this.formOptions.setFieldValue(field, value);
    }
    this.props.manuallyCalculatorReset();
  };

  renderForm(options) {
    const { status } = this.props;
    return (
      <Fragment>
        <div className="manually-form hand-form">
          <div className="col margin-top">
            <label className="regular black" htmlFor="handicapIndex">
              18-Hole Handicap Index
            </label>
            <input
              type="text"
              id="handicapIndex"
              value={options.values.handicapIndex}
              name="handicapIndex"
              onBlur={options.handleBlur}
              className="large full-width center"
              onChange={(e) =>
                this.onChangeField("handicapIndex", e.target.value)
              }
            />
            <span className="error">
              {options.touched.handicapIndex
                ? options.errors.handicapIndex
                : ""}
            </span>
          </div>
          <div className="col margin-top">
            <label className="regular black" htmlFor="courseRating">
              Course Rating
            </label>
            <input
              type="tel"
              id="courseRating"
              value={options.values.courseRating}
              name="courseRating"
              onBlur={options.handleBlur}
              onChange={(e) =>
                this.onChangeField("courseRating", e.target.value)
              }
              className="large full-width center number-input"
            />
            <span className="error">
              {options.touched.courseRating ? options.errors.courseRating : ""}
            </span>
          </div>
          <div className="col margin-top">
            <label className="regular black" htmlFor="slopeRating">
              Slope Rating
            </label>
            <input
              type="tel"
              id="slopeRating"
              value={options.values.slopeRating}
              name="slopeRating"
              onBlur={options.handleBlur}
              onChange={(e) =>
                this.onChangeField("slopeRating", e.target.value)
              }
              className="large full-width center"
            />
            <span className="error">
              {options.touched.slopeRating ? options.errors.slopeRating : ""}
            </span>
          </div>
          <div className="col margin-top">
            <label className="regular black" htmlFor="par">
              Par
            </label>
            <input
              type="tel"
              id="par"
              value={options.values.par}
              name="par"
              onBlur={options.handleBlur}
              onChange={(e) => this.onChangeField("par", e.target.value)}
              className="large full-width center"
            />
            <span className="error">
              {options.touched.par ? options.errors.par : ""}
            </span>
          </div>
          <div className="col margin-top">
            <label className="regular black type-alpha" htmlFor="par">
              Handicap Allowance
              <i
                onClick={() => this.setState({ infoModal: 1 })}
                className="material-icons-outlined"
                style={{
                  color: "#00365f",
                  fontSize: "22px",
                }}
              >
                info
              </i>
            </label>
            <DefaultCustomSelect
              value={options.values.handicapAllowance}
              onChange={this.onChangeHandicapAllowance}
              placeholder="Select"
              title="Handicap Allowance"
              options={HANDICAP_ALLOWANCE_OPTIONS}
              className="default_select full_on_phone smallwidth svg-color"
              classNamePrefix="ds"
              aria-label="Handicap Allowance"
            />
            <span className="error">
              {options.touched.handicapAllowance
                ? options.errors.handicapAllowance
                : ""}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col auto h-centered" style={{ marginBottom: 30 }}>
            <button
              disabled={!options.isValid || status === jobStatus.PROCESSING}
              onClick={options.handleSubmit}
              type="submit"
              className="btn fill cardinal"
            >
              Calculate
            </button>
          </div>
        </div>
      </Fragment>
    );
  }

  renderResult() {
    const { manualCourseHandicap, manualPlayingHandicap } = this.props;
    if (!manualCourseHandicap && !manualPlayingHandicap) return null;
    return (
      <div className="row manual-handicap__row">
        {manualCourseHandicap && (
          <div className="col">
            <div className="info relative">
              <span>Your Course Handicap is</span>
              <strong className="large">
                {manualCourseHandicap.course_handicap_display}
              </strong>
              <sup className="trigger-container">
                <button
                  className="btn blank"
                  onClick={() => this.setState({ infoModal: 2 })}
                >
                  <i className="material-icons-outlined">info</i>
                </button>
              </sup>
            </div>
          </div>
        )}

        {manualPlayingHandicap && (
          <div className="col">
            <div className="info relative">
              <span>Your Playing Handicap is</span>
              <strong className="large">
                {manualPlayingHandicap.playing_handicap_display}
              </strong>
              <sup className="trigger-container">
                <button
                  className="btn blank"
                  onClick={() => this.setState({ infoModal: 3 })}
                >
                  <i className="material-icons-outlined">info</i>
                </button>
              </sup>
            </div>
          </div>
        )}
      </div>
    );
  }

  getInfoModalContent() {
    const { infoModal } = this.state;
    switch (infoModal) {
      case 2:
        return {
          title: "Your Course Handicap™",
          message:
            "Indicates the number of strokes you need to play to Par for the selected tees and to adjust hole scores for handicap purposes.",
        };
      case 3:
        return {
          title: "Playing Handicap",
          message:
            "It represents the number of strokes a player gives or receives for the round being played and determined from the unrounded Course Handicap adjusted for any Handicap Allowances or format of play.",
        };
      default:
        //1
        return {
          title: "Handicap Allowance",
          message:
            "The percentage of a Course Handicap recommended to create equity for all players participating in a specific format of play.",
        };
    }
  }

  renderInfoModal() {
    const { infoModal } = this.state;
    let content = this.getInfoModalContent();
    return (
      <HandicapInfoModal
        title={content.title}
        message={content.htmlMessage ? "" : content.message}
        htmlMessage={content.htmlMessage ? content.htmlMessage : ""}
        isOkVisible={true}
        isOpen={infoModal > 0}
        onAbort={() => this.setState({ infoModal: 0 })}
      />
    );
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{MANUALLY_CALC_H_TITLE}</title>
          <meta name="description" content={MANUALLY_CALC_H_DESC} />
        </Helmet>
        <h1>Handicap Calculator</h1>
        <div className="panel">
          <Formik
            onSubmit={this.onSubmit}
            initialValues={{
              handicapIndex: this.getUserHandicap(),
              handicapAllowance: {
                value: HANDICAP_ALLOWANCE_OPTIONS[0].value,
                label: HANDICAP_ALLOWANCE_OPTIONS[0].label,
              },
            }}
            validate={(values) => validateManuallyHCFields(values)}
          >
            {(options) => {
              this.formOptions = options;
              return this.renderForm(options);
            }}
          </Formik>
          {this.renderResult()}
          <div className="infoNote">
            <span className="note">Note: </span>
            If determining a 9-hole Course Handicap, type in the 18-hole
            Handicap Index. Next type in the 9-hole Course Rating, 9-hole Slope
            Rating and 9-hole Par before selecting &quot;Calculate&quot;. A
            9-hole Course Handicap will be calculated from the 18-hole Handicap
            Index when the Course Rating is between 20.0-45.0.
          </div>
        </div>
        {this.renderInfoModal()}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ handicapCalculatorReducer }) => ({
  manualCourseHandicap:
    handicapCalculatorReducer.calculatorManuallyFetchReducer
      .manualCourseHandicap,
  manualPlayingHandicap:
    handicapCalculatorReducer.calculatorManuallyFetchReducer
      .manualPlayingHandicap,
  status: handicapCalculatorReducer.calculatorManuallyFetchReducer.jobStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchManuallyCalculator,
      manuallyCalculatorReset,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ManuallyCalculator);
