/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";

class PreAuthorization extends Component {
  render() {
    const { denyAuthorize, confirmAuthorize } = this.props;
    return (
      <section className="page_container">
        <div className="box">
          <div className="box-head">
            <h3 className="box-title">Authorization Required</h3>
          </div>
          <div className="box-body">
            <div className="form columns">
              <div className="pre_authorization">
                <div className="pre_authorization_body">
                  <p className="title">
                    Authorize <strong className="str">Vendors App</strong> to
                    use your account?
                  </p>
                  <p>This application will receive your:</p>
                  <ul className="items">
                    <li className="item">
                      GHIN Number <em className="em">(for Golfers)</em>
                    </li>
                    <li className="item">
                      Guardian ID{" "}
                      <em className="em">(for Non-Golfing Guardians)</em>
                    </li>
                  </ul>
                  <div className="pre_authorization_footer actions centered">
                    <button
                      className="btn outline blue footer_buttons"
                      onClick={denyAuthorize}
                    >
                      Deny
                    </button>{" "}
                    <button
                      className="btn fill cardinal footer_buttons"
                      onClick={confirmAuthorize}
                    >
                      Authorize
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PreAuthorization;
