import {
  GOLFER_INFORMATION,
  golferInformationSuccess,
  golferInformationFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(GOLFER_INFORMATION))
    .pipe(switchMap(({ payload }) => action(payload)));

const action = (payload) =>
  from(GolferApi.golferInformation(payload))
    .pipe(map((response) => golferInformationSuccess(response.data)))
    .pipe(
      catchError((error) => of(golferInformationFailed(error.response?.data)))
    );
