import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import YesNoModal from "../../../shared/components/modals/YesNoModal";
import { addCourse, removeCourse, fetchTees } from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUserGender } from "../../../shared/helpers/user-helper";
import jobStatus from "../../../shared/variables/job-status";
import Loader from "../../../shared/components/loaders/Loader";
import EditTeesList from "./EditTeesList";

class CourseListItem extends Component {
  state = {
    showDeleteModal: false,
  };

  onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false });
    this.props.onToggleExpandCancel();
  };

  onClickRemove = (event) => {
    event.stopPropagation();
    this.setState({ showDeleteModal: true });
    this.props.onToggleExpandCancel();
  };

  onConfirmRemove = () => {
    this.onCloseDeleteModal();
    const { course, removeCourse } = this.props;
    const courseId = course.course_id ? course.course_id : course.CourseID;
    removeCourse(courseId);
  };

  renderMinusButton = () => {
    return (
      <Fragment>
        <span
          className="ic_c_minus"
          onClick={this.onClickRemove}
          onKeyDown={this.onClickRemove}
          aria-hidden="true"
        />
      </Fragment>
    );
  };

  getLeftName = (course) =>
    course.facility_name || course.FacilityName
      ? course.facility_name || course.FacilityName
      : course.course_name || course.CourseName;

  getRightName = (course) =>
    (course.facility_name || course.FacilityName) &&
    (course.facility_name || course.FacilityName) !==
      (course.course_name || course.CourseName)
      ? course.course_name || course.CourseName
      : "";

  onToggleExpandItem = (event) => {
    const { onToggleExpand } = this.props;
    onToggleExpand(Number(event.currentTarget.id));
  };

  render() {
    const {
      course,
      provided,
      innerRef,
      searchMode,
      tees,
      fetchTeesStatus,
      onToggleExpandCancel,
      expanded,
      trialGolfer,
    } = this.props;
    const { showDeleteModal } = this.state;
    const itemClassName = !searchMode ? "no-search" : "";
    const gender = getUserGender();
    const filteredTees = tees.filter((t) => t.Gender === gender);
    return (
      <Fragment>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <li
          key={course.id}
          id={course.course_id}
          {...provided.draggableProps}
          ref={innerRef}
          onClick={this.onToggleExpandItem}
        >
          <span className={`item my-courses-item ${itemClassName}`}>
            {!searchMode && (
              <span className="drag" {...provided.dragHandleProps} />
            )}
            {this.renderMinusButton()}
            <div className="item details center my-courses-details">
              <span className="details center">
                <span className="name">
                  {this.getLeftName(course)}
                  <p className="my-courses-location">{course.tee_name}</p>
                </span>
              </span>
              <span className="index">{this.getRightName(course)}</span>
            </div>
          </span>
        </li>
        <div className={"accordion-body " + (expanded ? "is-open" : "")}>
          {fetchTeesStatus === jobStatus.PROCESSING ? (
            <Loader />
          ) : (
            <EditTeesList
              tees={filteredTees}
              trialGolfer={trialGolfer}
              course={course}
              onClickCancel={onToggleExpandCancel}
              fetchTeesStatus={fetchTeesStatus}
              updateCourses={this.props.updateCourses}
            />
          )}
        </div>
        <YesNoModal
          isOpen={showDeleteModal}
          onAbort={this.onCloseDeleteModal}
          onConfirm={this.onConfirmRemove}
        >
          <p>{`Are you sure you want to remove ${this.getLeftName(
            course
          )}?`}</p>
        </YesNoModal>
      </Fragment>
    );
  }
}

CourseListItem.propTypes = {
  course: PropTypes.object.isRequired,
  addCourse: PropTypes.func.isRequired,
  removeCourse: PropTypes.func.isRequired,
};

const mapStateToProps = ({ selectMyCoursesReducer, profileReducer }) => {
  return {
    tees: selectMyCoursesReducer.teesFetchReducer.tees,
    fetchTeesStatus: selectMyCoursesReducer.teesFetchReducer.fetchTeesStatus,
    trialGolfer: profileReducer.golferProfileFetchReducer.trialGolfer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addCourse, removeCourse, fetchTees }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CourseListItem);
