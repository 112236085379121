import React, { Component } from "react";
import Navigate from "../../../../shared/navigation/navigate";
import { routes } from "../../../../shared/routes";

export default class MembershipAutoRenewalButton extends Component {
  goToPage = () => Navigate.toPath(routes.membershipAutoRenewal.path);
  render() {
    return (
      <div className="accordion-item">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className="accordion-item__head border-focus my-courses-button"
          onClick={this.goToPage}
          tabIndex="0"
          role="button"
        >
          <div className="p-row">
            <div className="l-col">
              <span className="accordion-item__label">
                Membership Auto-Renewal
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
