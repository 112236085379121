import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getDisplayScore } from "../../../shared/helpers/ui-helper";
import { getLowHIDate } from "../../../shared/helpers/ui-helper";
import PropTypes from "prop-types";
import { dateNowFormat } from "../../../shared/helpers/date-helper";

class MyCardUserInfo extends Component {
  getCurrentYearScores() {
    const { scores2020 } = this.props;
    return scores2020 && scores2020.length > 0 ? scores2020.length : "-";
  }

  render() {
    const { user, golfer } = this.props;

    return (
      <Fragment>
        <div className="my-card__head">
          <div className="my-card-head__row border-bot padding-top-bot">
            <div className="my-card-head__col">
              <div className="my-card-head__cell">
                <span className="my-card-head__label">Name:</span>
                {`${user?.player_name}`}
              </div>
              <div className="my-card-head__cell">
                <span className="my-card-head__label">Club:</span>
                {golfer?.primary_club_name || golfer?.club_name}
              </div>
              <div className="my-card-head__cell">
                <span className="my-card-head__label">Association:</span>
                {golfer?.primary_golf_association_name ||
                  golfer?.golf_association_name}
              </div>
              <div className="my-card-head__cell">
                <span className="my-card-head__label">Handicap Index:</span>
                {getDisplayScore(user?.hi_display)}
              </div>
            </div>
            <div className="my-card-head__col">
              <div className="my-card__logo">
                <span className="usga"></span>
                <span className="ghin"></span>
                <img
                  className="my-card__sentry-logo"
                  src="/img/logos/sentry_01.png"
                  alt="Sentry"
                />
              </div>
            </div>
          </div>
          <div className="my-card-head__row padding-top-bot">
            <div className="my-card-head__col border-right">
              <div className="my-card-head__cell">
                <span className="my-card-head__label">Low H.I. :</span>
                {user?.low_hi_display}
                {getLowHIDate(user, true)}
              </div>
              <div className="my-card-head__cell">
                <span className="my-card-head__label">
                  Scores Posted ({dateNowFormat("YYYY")}):
                </span>
                {this.getCurrentYearScores()}
              </div>
            </div>
            <div className="my-card-head__col">
              <div className="my-card-head__cell">
                <span className="my-card-head__label">Ghin Number:</span>
                {user?.ghin}
              </div>
              <div className="my-card-head__cell">
                <span className="my-card-head__label">Current Date:</span>
                {dateNowFormat()}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

MyCardUserInfo.propTypes = {
  scores: PropTypes.arrayOf(PropTypes.shape({})),
  scores2020: PropTypes.arrayOf(PropTypes.shape({})),
};

MyCardUserInfo.defaultProps = {
  scores: [],
  scores2020: [],
};

const mapStateToProps = ({ profileReducer, authReducer }) => ({
  user: profileReducer.golferProfileFetchReducer.golferProfile,
  golfer: authReducer.loginReducer.user,
});

export default connect(mapStateToProps, null)(MyCardUserInfo);
