import { store } from "../store/index";

const getHomeCoursesState = () =>
  store.getState().authReducer.homeCoursesReducer;

const getHomeCourses = () =>
  (getHomeCoursesState() && getHomeCoursesState().courses) || [];

export const belongHomeCoureses = (courseId) => {
  if (!courseId) return false;
  return getHomeCourses().some(
    (c) => c.course_id.toString() === courseId.toString()
  );
};
