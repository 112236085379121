import React, { Component, Fragment } from "react";
import { Bling as GPT } from "react-gpt";

GPT.enableSingleRequest();

export default class PromoBanner extends Component {
  render() {
    return (
      <Fragment>
        <div id="div-gpt-ad-1539376483266-0" className="promo_google_banner">
          <GPT
            adUnitPath="/182885412/ghin.com_ROS"
            sizeMapping={[
              { viewport: [750, 0], slot: [728, 90] },
              { viewport: [1280, 0], slot: [970, 90] },
            ]}
          />
        </div>
      </Fragment>
    );
  }
}
