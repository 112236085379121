import React, { Component } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";

class HbhNotSameDayModal extends Component {
  onClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { isOpen } = this.props;
    return (
      <YesNoModal
        onConfirm={this.onClose}
        onAbort={this.onClose}
        isNoVisible={false}
        isOpen={isOpen}
        yesTitle="OK"
      >
        <p>
          Please post scores on the day of play.
          {!this.props.trialGolfer &&
            " Doing so will ensure an up-to-date Handicap Index."}
        </p>
      </YesNoModal>
    );
  }
}

HbhNotSameDayModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HbhNotSameDayModal;
