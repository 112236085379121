import { searchType } from "../../../shared/variables/pagination";
import { checkApiError } from "../../auth/actions";

export const FETCH_GOLFERS = "FETCH_GOLFERS";
export const FETCH_GOLFERS_FAILED = "FETCH_GOLFERS_FAILED";
export const FETCH_GOLFERS_SUCCESS = "FETCH_GOLFERS_SUCCESS";
export const RESET_GOLFERS = "RESET_GOLFERS";

export const fetchGolfers = (
  country,
  state,
  params,
  page = 1,
  type = searchType.NEW_SEARCH
) => ({
  type: FETCH_GOLFERS,
  payload: { country, state, params, page, type },
});

export function fetchGolfersFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_GOLFERS_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchGolfersSuccess = (golfers, page, type) => ({
  type: FETCH_GOLFERS_SUCCESS,
  payload: { golfers, page, type },
});

export const resetGolfers = () => ({
  type: RESET_GOLFERS,
});
