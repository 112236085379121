import jobStatus from "../../../shared/variables/job-status";
import {
  HOME_COURSES,
  HOME_COURSES_CLEAR,
  HOME_COURSES_SUCCESS,
  HOME_COURSES_FAILED,
} from "../actions";

const initialState = {
  courses: [],
  fetchStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HOME_COURSES:
      return {
        ...state,
        fetchStatus: jobStatus.PROCESSING,
      };
    case HOME_COURSES_SUCCESS: {
      return {
        ...state,
        courses: state.courses.concat(payload),
        fetchStatus: jobStatus.SUCCESS,
      };
    }
    case HOME_COURSES_FAILED:
      return {
        ...state,
        fetchStatus: jobStatus.FAILED,
      };
    case HOME_COURSES_CLEAR:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
