import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchCourses,
  fetchRecentCourses,
  resetAllCourses,
} from "../actions/index";
import Loader from "../../../shared/components/loaders/Loader";
import jobStatus from "../../../shared/variables/job-status";
import NoResultsFound from "../../../shared/components/notices/NoResultsFound";
import { routes } from "../../../shared/routes";
import CoursesSearch from "../../select-course/components/CoursesSearch";
import CoursesFilter from "../../select-course/components/CoursesFilter";
import { hidden } from "../../../shared/helpers/ui-helper";
import { fetchCountriesSelectCourse } from "../../select-course/actions";
import AddCoursesList from "./AddCoursesList";
import { resetAddedCourses } from "../actions/index";
import Navigate from "../../../shared/navigation/navigate";
import CoursesTitle from "../../select-course/components/CoursesTitle";

class AddCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPhrase: "",
      redirected: false,
      country: undefined,
      state: undefined,
      maxCoursesAlert: false,
    };
  }
  componentDidMount = () => {
    this.props.resetAllCourses();
    this.props.fetchCountriesSelectCourse();
    if (this.props.location.search.includes("searchPhrase")) {
      const urlParams = new URLSearchParams(this.props.location.search);
      const searchPhrase = urlParams.get("searchPhrase");
      const redirected = urlParams.get("redirected");
      const { country, state } = this.state;
      this.setState({ searchPhrase: searchPhrase, redirected: redirected });
      this.fetchCourses(searchPhrase, country, state);
    } else {
      this.props.fetchRecentCourses();
    }
  };

  componentDidUpdate = () => {
    if (this.state.redirected) this.setState({ redirected: false });
  };

  fetchCourses = (searchPhrase, country, state) => {
    this.setState({
      searchPhrase: searchPhrase,
      country: country,
      state: state,
    });

    this.props.fetchCourses(searchPhrase, country, state);
  };

  showAlertMaxCourses = () => {
    this.setState({
      maxCoursesAlert: true,
    });
    setTimeout(() => {
      this.hideAlertMaxCourses();
    }, 2000);
  };

  hideAlertMaxCourses = () => {
    this.setState({
      maxCoursesAlert: false,
    });
  };

  handleSearchPhraseChanged = (search) => {
    const { country, state, searchPhrase } = this.state;
    this.setState({
      searchPhrase: search,
    });
    if (this.isSearchPhraseValid(search)) {
      this.fetchCourses(search, country, state);
    } else if (this.isSearchPhraseValid(searchPhrase)) {
      this.props.fetchRecentCourses();
    }
  };

  handleLocationChanged = (location) =>
    this.fetchCourses(
      this.state.searchPhrase,
      location.country,
      location.courseCode
    );

  isSearchPhraseValid = (searchPhrase) =>
    searchPhrase && searchPhrase.length > 2;

  isFilterVisible = (searchPhrase) => this.isSearchPhraseValid(searchPhrase);

  goToMyCoursesPage = () => {
    this.props.resetAddedCourses();
    this.props.resetAllCourses();
    Navigate.toPath(routes.myCoursesSelectCourse.path);
  };

  render() {
    const { searchPhrase, redirected } = this.state;
    const {
      fetchCoursesStatus,
      countries,
      navItemLink,
      searchMode,
      allCourses,
      addedCourses,
      showAddAlert,
    } = this.props;
    return (
      <Fragment>
        <div className="box" id="handicap_calculator">
          <div className="box-head my-courses-head">
            <h1 className="box-title">Add Courses</h1>
            <div className="box-actions my-courses-actions">
              <CoursesSearch
                onChange={(value) => this.handleSearchPhraseChanged(value)}
                {...(redirected ? { value: searchPhrase } : {})}
              />
            </div>
          </div>
          <div className="box-body">
            <div className="item-list courses">
              <CoursesTitle
                title="RECENTLY PLAYED COURSES"
                manuallyHandicapInput={false}
              />
              <div className={hidden(!this.isFilterVisible(searchPhrase))}>
                <CoursesFilter
                  countries={countries}
                  onSelect={(selection) =>
                    this.handleLocationChanged(selection)
                  }
                />
              </div>
              {fetchCoursesStatus === jobStatus.PROCESSING ? (
                <Loader />
              ) : allCourses.length > 0 ? (
                <AddCoursesList
                  navLinkPath={navItemLink}
                  courses={allCourses}
                  alertMax={this.showAlertMaxCourses}
                  searchMode={searchMode}
                  searchPhrase={searchPhrase}
                />
              ) : searchPhrase ? (
                <NoResultsFound>
                  <p style={{ margin: "30px 0" }}>No courses found.</p>
                </NoResultsFound>
              ) : (
                <NoResultsFound>
                  <p style={{ margin: "30px 0" }}>
                    Please enter the course name to start searching.
                  </p>
                </NoResultsFound>
              )}
            </div>
          </div>
          {/* Alert & Buttons */}
          <div className="row-bottom-fixed">
            {showAddAlert && (
              <div className="alert alert-primary" role="alert">
                Course Added!
              </div>
            )}
            {this.state.maxCoursesAlert && (
              <div className="alert alert-primary" role="alert">
                Maximum courses number reached
              </div>
            )}
            <div className="btn-wrapper">
              <button
                className="btn fill cardinal auto has_badge"
                onClick={this.goToMyCoursesPage}
              >
                <span className="badge">{addedCourses.length}</span> &nbsp; Back
                to My Courses
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ selectMyCoursesReducer }) => {
  return {
    allCourses: selectMyCoursesReducer.allCoursesFetchReducer.courses,
    fetchCoursesStatus:
      selectMyCoursesReducer.allCoursesFetchReducer.fetchCoursesStatus,
    searchMode: selectMyCoursesReducer.myCoursesFetchReducer.searchMode,
    countries: selectMyCoursesReducer.countriesFetchReducer.countries,
    addedCourses: selectMyCoursesReducer.myCoursesFetchReducer.addedCourses,
    showAddAlert: selectMyCoursesReducer.myCoursesFetchReducer.showAddAlert,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRecentCourses,
      fetchCourses,
      fetchCountriesSelectCourse,
      resetAddedCourses,
      resetAllCourses,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddCourses);
