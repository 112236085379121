import React, { Component, Fragment } from "react";
import CoursesTitle from "../../select-course/components/CoursesTitle";
import { connect } from "react-redux";
import { fetchTees } from "../actions";
import { bindActionCreators } from "redux";
import jobStatus from "../../../shared/variables/job-status";
import Loader from "../../../shared/components/loaders/Loader";
import TeesList from "./TeesList";
import NoResultsFound from "../../../shared/components/notices/NoResultsFound";
import { fetchMyCourses } from "../actions";
import { getUserGender } from "../../../shared/helpers/user-helper";

class SelectTee extends Component {
  componentDidMount() {
    const { courseId } = this.props;
    this.props.fetchTees(courseId);
    this.props.fetchMyCourses();
  }

  teesListEmpty = () => this.props.tees.length > 0;

  courseName = () => {
    const { courses, courseId } = this.props;
    const course = courses.filter(
      (c) => c.course_id.toString() === courseId.toString()
    )[0];
    const name =
      course?.course_name === course?.facility_name
        ? course?.course_name
        : course?.facility_name + " " + course?.course_name;
    return name;
  };

  render() {
    const {
      courseId,
      tees,
      fetchTeesStatus,
      courses,
      fetchCoursesStatus,
      extraParam,
    } = this.props;
    const gender = getUserGender();
    const filteredTees = tees.filter((t) => t.Gender === gender);
    return (
      <Fragment>
        <div className="box">
          <div className="box-head">
            <h1 className="box-title">My Courses</h1>
          </div>

          {fetchCoursesStatus === jobStatus.PROCESSING ? (
            <Loader />
          ) : (
            <div className="box-body">
              <div className="item-list courses">
                <CoursesTitle title="Select default tee" />
                <div className="item-list__action">
                  <p className="course-title">{this.courseName()}</p>
                </div>
              </div>
              {fetchTeesStatus === jobStatus.PROCESSING &&
              !this.teesListEmpty() ? (
                <Loader />
              ) : this.teesListEmpty() ? (
                <TeesList
                  tees={filteredTees}
                  trialGolfer={this.props.trialGolfer}
                  courseId={courseId}
                  courses={courses}
                  extraParam={extraParam}
                />
              ) : (
                <NoResultsFound>
                  <p style={{ margin: "30px 0" }}>No tees found</p>
                </NoResultsFound>
              )}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    courseId: ownProps.match.params.courseId,
    trialGolfer: state.profileReducer.golferProfileFetchReducer.trialGolfer,
    tees: state.selectMyCoursesReducer.teesFetchReducer.tees,
    fetchTeesStatus:
      state.selectMyCoursesReducer.teesFetchReducer.fetchTeesStatus,
    courses: state.selectMyCoursesReducer.myCoursesFetchReducer.courses,
    fetchCoursesStatus:
      state.selectMyCoursesReducer.myCoursesFetchReducer.fetchCoursesStatus,
    extraParam: ownProps.location.search,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchTees, fetchMyCourses }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectTee);
