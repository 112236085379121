import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCourses } from "../actions/index";
import CoursesList from "./CoursesList";
import ManuallyInputCourse from "./ManuallyInputCourse";
import CoursesSearch from "./CoursesSearch";
import CoursesFilter from "./CoursesFilter";
import { fetchCountriesSelectCourse } from "../actions";
import Loader from "../../../shared/components/loaders/Loader";
import jobStatus from "../../../shared/variables/job-status";
import { hidden } from "../../../shared/helpers/ui-helper";
import NoResultsFound from "../../../shared/components/notices/NoResultsFound";
import { NavLink } from "react-router-dom";

class SelectCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPhrase: "",
      country: undefined,
      state: undefined,
    };
  }

  componentDidMount = () => {
    this.fetchCourses();
    this.props.fetchCountriesSelectCourse();
  };

  fetchCourses = (searchPhrase, country, state) => {
    this.setState({
      searchPhrase: searchPhrase,
      country: country,
      state: state,
    });

    this.props.fetchCourses(searchPhrase, country, state);
  };

  getCoursesTitle = () =>
    !this.isSearchPhraseValid(this.state.searchPhrase)
      ? "Recently played Courses"
      : "";

  handleSearchPhraseChanged = (search) => {
    const { country, state, searchPhrase } = this.state;

    if (this.isSearchPhraseValid(search)) {
      this.fetchCourses(search, country, state);
    } else if (this.isSearchPhraseValid(searchPhrase)) {
      this.fetchCourses("", country, state);
    }
  };

  handleLocationChanged = (location) =>
    this.fetchCourses(
      this.state.searchPhrase,
      location.country,
      location.courseCode
    );

  isSearchPhraseValid = (searchPhrase) =>
    searchPhrase && searchPhrase.length > 2;

  isFilterVisible = (searchPhrase) => this.isSearchPhraseValid(searchPhrase);

  coursesListEmpty = () => this.props.courses.length > 0;

  render() {
    const { searchPhrase } = this.state;
    const {
      courses,
      fetchCoursesStatus,
      countries,
      hasManuallyInput,
      manuallyHandicapInput,
      navItemLink,
      onManuallyInputCourse,
      tabLinkMyCourses,
      tabLinkPostCourse,
      useScaling,
      hasHandicapIndex,
    } = this.props;

    const post_score_page = this.props.match.path.includes("post-score/total");
    return (
      <Fragment>
        <div className="box">
          <div className="box-head">
            <h1 className="box-title">Select Course</h1>
            <div className="box-actions">
              <CoursesSearch
                onChange={(value) => this.handleSearchPhraseChanged(value)}
              />
            </div>
          </div>
          <div>
            <ul className="tabs-my-courses rounded">
              <li>
                <NavLink to={tabLinkPostCourse} activeClassName="is-active">
                  Recently Played
                </NavLink>
              </li>
              <li>
                <NavLink to={tabLinkMyCourses} activeClassName="is-active">
                  My Courses
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="box-body">
            <div className="item-list courses">
              <div className={hidden(!this.isFilterVisible(searchPhrase))}>
                <CoursesFilter
                  countries={countries}
                  onSelect={(selection) =>
                    this.handleLocationChanged(selection)
                  }
                />
              </div>
              {fetchCoursesStatus === jobStatus.PROCESSING ? (
                <Loader />
              ) : this.coursesListEmpty() ? (
                <CoursesList navLinkPath={navItemLink} courses={courses} />
              ) : (
                <NoResultsFound>
                  <p style={{ margin: "30px 0" }}>No courses found</p>
                </NoResultsFound>
              )}
            </div>
          </div>
          {hasManuallyInput && (
            <div className="box-footer">
              <ManuallyInputCourse
                onClick={onManuallyInputCourse}
                trialGolfer={this.props.trialGolfer}
              />
            </div>
          )}
          {manuallyHandicapInput && (
            <div className="box-footer">
              <div className="item-list__action">
                <button
                  onClick={manuallyHandicapInput}
                  className="btn lnk blue no-pad small type-z-button"
                >
                  Manually Calculate Handicap
                </button>
              </div>
            </div>
          )}
          {post_score_page && useScaling && hasHandicapIndex && (
            <div className="box-footer" style={{ justifyContent: "center" }}>
              <p>
                If you played 10-17 holes, you must post your score using the
                hole-by-hole method.
              </p>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

SelectCourse.defaultProps = {
  onManuallyInputCourse: () => {},
};

SelectCourse.propTypes = {
  courses: PropTypes.array.isRequired,
  fetchCoursesStatus: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  fetchCourses: PropTypes.func.isRequired,
  fetchCountriesSelectCourse: PropTypes.func.isRequired,
  hasManuallyInput: PropTypes.bool.isRequired,
  manuallyHandicapInput: PropTypes.func,
  navItemLink: PropTypes.string.isRequired,
  onManuallyInputCourse: PropTypes.func.isRequired,
  useScaling: PropTypes.bool,
  hasHandicapIndex: PropTypes.bool,
};

SelectCourse.defaultProps = {
  manuallyHandicapInput: null,
};

const mapStateToProps = ({ selectCourseReducer, profileReducer }) => {
  const handicapIndex =
    profileReducer.golferProfileFetchReducer.golferProfile?.handicap_index;

  return {
    courses: selectCourseReducer.coursesFetchReducer.courses,
    fetchCoursesStatus:
      selectCourseReducer.coursesFetchReducer.fetchCoursesStatus,
    countries: selectCourseReducer.countriesFetchReducer.countries,
    trialGolfer: profileReducer.golferProfileFetchReducer.trialGolfer,
    useScaling:
      profileReducer.golferProfileFetchReducer.golferProfile?.use_scaling,
    hasHandicapIndex: handicapIndex != "NH" && handicapIndex != "WD",
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchCourses, fetchCountriesSelectCourse }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectCourse);
