import jobStatus from "../../../shared/variables/job-status";
import {
  FETCH_COURSE_HANDICAP,
  FETCH_COURSE_HANDICAP_SUCCESS,
  FETCH_COURSE_HANDICAP_FAILED,
} from "../actions";

const initialState = {
  courseHandicap: { tee_sets: [] },
  fetchCourseHandicapStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COURSE_HANDICAP:
      return {
        ...state,
        courseHandicap: initialState.courseHandicap,
        fetchCourseHandicapStatus: jobStatus.PROCESSING,
      };
    case FETCH_COURSE_HANDICAP_SUCCESS:
      return {
        ...state,
        courseHandicap: payload,
        fetchCourseHandicapStatus: jobStatus.SUCCESS,
      };
    case FETCH_COURSE_HANDICAP_FAILED:
      return {
        ...state,
        fetchCourseHandicapStatus: jobStatus.FAILED,
      };
    default:
      return state;
  }
};
