import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { createFilter } from "react-select";
import DefaultCustomSelect from "../../../../shared/components/selects/DefaultCustomSelect";
import {
  fetchCountries,
  selectCountry,
  selectState,
  fetchGolfers,
  resetGolfers,
} from "../../actions";
import { searchType } from "../../../../shared/variables/pagination";
import { inputSearchDelay } from "../../../../shared/helpers/ui-helper";

class HeaderFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastNameOrGhin: "",
      firstName: "",
      selectedOptionsCountry: undefined,
      page: 1,
    };
    this.inputTimeout = 0;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedState } = nextProps;
    if (this.props.selectedState !== selectedState) {
      this.setState({ page: 1 }, this.fetchGolfers);
      nextProps.resetGolfers();
    }
  }

  componentWillUnmount() {
    this.props.resetGolfers();
  }

  fetchGolfers = (searchType = undefined) => {
    const { selectedCountry, selectedState } = this.props;
    const { lastNameOrGhin, firstName, page } = this.state;
    let params = {};

    if (lastNameOrGhin && lastNameOrGhin.length > 0) {
      if (isNaN(lastNameOrGhin)) {
        params.last_name = lastNameOrGhin;
      } else {
        params.golfer_id = lastNameOrGhin;
      }
    }

    if (firstName && firstName.length > 0) {
      params.first_name = firstName;
    }

    this.props.fetchGolfers(
      selectedCountry.code,
      selectedState.code,
      params,
      page,
      searchType
    );
  };

  handleChangeSearch = ({ target: { value } }) => {
    this.setState({ lastNameOrGhin: value, page: 1 });
    if (this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.fetchGolfers();
    }, inputSearchDelay);
  };

  handleChangeCountry = (selectedOptionsCountry) => {
    this.props.selectCountry(selectedOptionsCountry.value);
    this.setState({ selectedOptionsCountry, page: 1 });
  };

  handleChangeState = (selectedOptionsState) => {
    this.setState({ page: 1 });
    this.props.selectState(selectedOptionsState.value);
  };

  handleChangeFirstName = ({ target: { value } }) => {
    const { lastNameOrGhin } = this.state;
    if (lastNameOrGhin) {
      // cancel prev search and clean lastNameOrGhin
      this.setState({ firstName: value }, this.fetchGolfers);
    } else {
      this.setState({ firstName: value });
    }
  };

  countriesOptions = () => {
    const { countries } = this.props;
    return countries.map((country) => ({
      value: country.code,
      label: country.name,
    }));
  };

  statesOptions = () => {
    const { selectedCountry } = this.props;
    if (selectedCountry) {
      return selectedCountry.states.map((state) => ({
        value: state.code,
        label: state.name,
      }));
    }
    return [];
  };

  getSelectedState = () => {
    const { selectedState } = this.props;
    if (selectedState) {
      return {
        value: selectedState.code,
        label: selectedState.name,
      };
    }
    return undefined;
  };

  getSelectedCountry = () => {
    const { selectedOptionsCountry } = this.state;
    if (selectedOptionsCountry) return selectedOptionsCountry;
    const { selectedCountry } = this.props;
    if (selectedCountry) {
      return {
        value: selectedCountry.code,
        label: selectedCountry.name,
      };
    }
    return undefined;
  };

  getNextPage = () => {
    const { page } = this.state;
    const nextPage = page + 1;
    this.setState({ page: nextPage }, () =>
      this.fetchGolfers(searchType.NEXT_PAGE)
    );
  };

  render() {
    const countries = this.countriesOptions();
    const states = this.statesOptions();
    const { lastNameOrGhin, firstName } = this.state;
    const selectFilter = { matchFrom: "start" };
    return (
      <Fragment>
        <div className="section_header filters expand">
          <div className="form-group">
            <label>Country</label>
            <DefaultCustomSelect
              isSearchable
              filterOption={createFilter(selectFilter)}
              onChange={this.handleChangeCountry}
              value={this.getSelectedCountry()}
              options={countries}
              title="Select Country"
              aria-label="Select Country"
            />
          </div>
          <div className="form-group">
            <label>State/Territory</label>
            <DefaultCustomSelect
              isSearchable
              filterOption={createFilter(selectFilter)}
              onChange={this.handleChangeState}
              value={this.getSelectedState()}
              options={states}
              title="Select State"
              aria-label="Select State"
            />
          </div>
          <div className="form-group">
            <label>First Name (optional)</label>
            <input
              value={firstName}
              className="full-width full-height"
              type="text"
              name="first-name"
              onChange={this.handleChangeFirstName}
              placeholder=""
              aria-label="Enter First Name"
            />
          </div>
          <div className="form-group">
            <div className="search-container">
              <input
                type="text"
                name="search"
                onChange={this.handleChangeSearch}
                id="search"
                className="search"
                placeholder="ENTER LAST NAME OR GHIN #"
                value={lastNameOrGhin}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

HeaderFilter.propTypes = {
  selectCountry: PropTypes.func.isRequired,
  selectState: PropTypes.func.isRequired,
  fetchGolfers: PropTypes.func.isRequired,
  resetGolfers: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCountry: PropTypes.shape({}),
  selectedState: PropTypes.shape({}),
};

HeaderFilter.defaultProps = {
  selectedCountry: undefined,
  selectedState: undefined,
};

const mapStateToProps = ({ golfersReducer }) => ({
  countries: golfersReducer.countriesReducer.countries,
  selectedCountry: golfersReducer.countriesReducer.selectedCountry,
  selectedState: golfersReducer.countriesReducer.selectedState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchCountries, selectCountry, selectState, fetchGolfers, resetGolfers },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(HeaderFilter);
