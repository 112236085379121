import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { routes } from "../../../../shared/routes/index";

class AddGolfer extends Component {
  render() {
    const { courseId, addedGolfers, showSuccesAlert } = this.props;
    return (
      <Fragment>
        <div className="container">
          <section className="golfer_lookup_section">
            <div className="section_header patched">
              <h3>Add Golfers</h3>
              <ul className="tabs rounded">
                <li>
                  <NavLink
                    to={routes.handicapCalculatorAddGolferFollwing.path.replace(
                      ":courseId",
                      courseId
                    )}
                    activeClassName="is-active"
                  >
                    Following
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routes.handicapCalculatorAddGolferMyClubs.path.replace(
                      ":courseId",
                      courseId
                    )}
                    activeClassName="is-active"
                  >
                    My Clubs
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routes.handicapCalculatorAddGolferAllGolfers.path.replace(
                      ":courseId",
                      courseId
                    )}
                    activeClassName="is-active"
                  >
                    All Golfers
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routes.handicapCalculatorAddGolferManually.path.replace(
                      ":courseId",
                      courseId
                    )}
                    activeClassName="is-active"
                  >
                    Manually Input
                  </NavLink>
                </li>
              </ul>
            </div>

            <Switch>
              <Redirect
                exact
                from={routes.handicapCalculatorAddGolfer.path}
                to={routes.handicapCalculatorAddGolferFollwing.path}
              />
              <Route {...routes.handicapCalculatorAddGolferFollwing} />
              <Route {...routes.handicapCalculatorAddGolferMyClubs} />
              <Route {...routes.handicapCalculatorAddGolferAllGolfers} />
              <Route {...routes.handicapCalculatorAddGolferManually} />
            </Switch>

            {/* Alert & Buttons */}
            <div className="row-bottom-fixed">
              {showSuccesAlert && (
                <div className="alert alert-primary" role="alert">
                  Golfer Added!
                </div>
              )}
              <div className="btn-wrapper">
                <NavLink
                  className="btn fill cardinal auto has_badge"
                  to={routes.handicapCalculatorCalculator.path.replace(
                    ":courseId",
                    courseId
                  )}
                >
                  <span className="badge">{addedGolfers.length}</span> &nbsp;
                  Back to Calculator
                </NavLink>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    );
  }
}

AddGolfer.propTypes = {
  courseId: PropTypes.string.isRequired,
  addedGolfers: PropTypes.array.isRequired,
  showSuccesAlert: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  addedGolfers:
    state.handicapCalculatorReducer.calculatorGolfersReducer.addedGolfers,
  showSuccesAlert:
    state.handicapCalculatorReducer.calculatorGolfersReducer.showSuccesAlert,
  courseId: ownProps.match.params.courseId,
});

export default connect(mapStateToProps, null)(AddGolfer);
