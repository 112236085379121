import React, { Component, Fragment } from "react";
import RecentScoresContainer from "./RecentScoresContainer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchGolferProfile } from "../actions";
import { bindActionCreators } from "redux";
import StartTrialModal from "../../../shared/components/modals/StartTrialModal";
import { Bling as GPT } from "react-gpt";
import Memberships from "./Memberships";
import ScoresApi from "../../../services/scores-api";
import GolferApi from "../../../services/golfer-api";
import MemberSlides from "./MemberSlides";
import ProfileDetails from "./ProfileDetails";
import { isUserLoggedIn } from "../../../shared/helpers/user-helper";
import { Helmet } from "react-helmet";
import phoneImage from "../../../assets/img/phones.png";
import { getUser } from "../../../shared/helpers/user-helper";
import { setEvent } from "../../../firebaseConfig";
import {
  HOMEPAGE_TITLE,
  HOMEPAGE_DESC,
} from "../../../shared/variables/meta-seo";
import { routes } from "../../../shared/routes";
import EndOfYearCampaignModal from "../../end-of-year-campaign/components/EndOfYearCampaignModal";
import InactiveGolferModal from "../../end-of-year-campaign/components/InactiveGolferModal";
import { GHIN_REWIND_YEAR } from "../../end-of-year-campaign/year-end-constants";
import { fetchEndOfYearCampaign } from "../../end-of-year-campaign/actions";
import { isMobile } from "react-device-detect";

GPT.enableSingleRequest();

class Profile extends Component {
  state = {
    isModalOn: false,
    hasScores: true,
    trialModalOpen: false,
    modalTitle: "",
    modalContent: "",
    modalSubTitle: "",
    modalSubContent: "",
    icon: "",
  };

  closeModal = () => this.setState({ isModalOn: false });

  setModalOn = () => {
    this.setState({ isModalOn: true });
  };

  onConfirmModal = () => {
    this.setState({ trialModalOpen: false });
    window.open(
      "https://getahandicap.usga.org/v2app/#/golfer-registration/sign-up?name=Trial"
    );
  };

  onCloseModal = () => {
    this.setState({ trialModalOpen: false });
  };

  componentDidMount = () => {
    if (isUserLoggedIn()) {
      this.props.fetchGolferProfile();
    }

    if (this.props.match.path === routes.endOfYear.path) {
      if (isMobile && this.props.golferActive && !this.props.trialGolfer)
        window.location.replace("com.ghin://" + GHIN_REWIND_YEAR + "overview");

      if (!isMobile) {
        this.setModalOn();
      }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.stats !== this.props.stats &&
      !("errors" in nextProps.stats) &&
      this.props.match.path === routes.endOfYear.path
    ) {
      this.setModalOn();
    }

    ScoresApi.getRecentScores(1, 1).then((r) => {
      if (r.data.total_count == 0) this.setState({ hasScores: false });
    });

    if (this.props.currentUser.ghin_number) {
      GolferApi.checkTrial(this.props.currentUser.ghin_number, "homepage").then(
        (r) => {
          if (r.data.days_left != -1 && r.data.messages) {
            this.setState({
              modalTitle: r.data.messages[0].headline,
              modalContent: r.data.messages[0].copy,
              modalSubContent: r.data.messages[0].sub_copy,
              modalSubTitle: r.data.messages[0].sub_headline,
              icon: r.data.messages[0].icon,
              trialModalOpen: true,
            });
          }
        }
      );
    }
  }

  render() {
    const { memberships, golferActive, trialGolfer } = this.props;
    const { isModalOn } = this.state;
    let year_end_summary_modal = null;

    if (this.props.golferActive) {
      year_end_summary_modal = (
        <EndOfYearCampaignModal isOpen={isModalOn} onClose={this.closeModal}>
          <iframe
            title="End of year campaign"
            width="100%"
            height="100%"
            src={routes.endOfYearCampaign.path}
          />
        </EndOfYearCampaignModal>
      );
    } else {
      year_end_summary_modal = (
        <InactiveGolferModal
          isOpen={isModalOn}
          onClose={this.closeModal}
        ></InactiveGolferModal>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>{HOMEPAGE_TITLE}</title>
          <meta name="description" content={HOMEPAGE_DESC} />
        </Helmet>
        <section
          className={
            trialGolfer
              ? "hero__section profile trial"
              : "hero__section profile"
          }
        >
          <div
            className={
              trialGolfer
                ? "profile-head__container trial"
                : "profile-head__container"
            }
          >
            <ProfileDetails
              profile={this.props.profile || this.props.currentUser}
              golferActive={golferActive}
            />
            {!trialGolfer && (
              <Memberships
                memberships={memberships}
                golferActive={golferActive}
              />
            )}
            {trialGolfer && (
              <div className="overlay">
                <h1 className="overlay-title">Unlock more with a membership</h1>
                <div className="overlay-image-cover">
                  <img
                    src={phoneImage}
                    alt="Phones"
                    style={{
                      display: "block", // This removes the space below the image
                      width: "100%", // This makes the image responsive
                      position: "absolute",
                      top: "0",
                    }}
                  ></img>
                </div>
                <p className="overlay-subtext">
                  Get a Handicap Index® by joining your local Allied Golf
                  Association (AGA). You’ll also get access to GHIN features
                  including advanced GPS and golfer lookup.
                </p>
                <button
                  className="btn fill cardinal"
                  onClick={() => {
                    const user = getUser();
                    setEvent(
                      user,
                      "Trial_Sign_Up_Click",
                      "Screen_Name",
                      "Homepage"
                    );
                    window.open(
                      "https://getahandicap.usga.org/v2app/#/golfer-registration/sign-up?name=Trial"
                    );
                  }}
                >
                  SIGN UP FOR A HANDICAP INDEX
                </button>
              </div>
            )}
          </div>
        </section>
        {this.state.hasScores && (
          <div className="content has-aside">
            <main className="main">
              {!trialGolfer && <MemberSlides memberships={memberships} />}
              <RecentScoresContainer />
              {/*<TournamentsAndEvents />*/}
            </main>
            <aside className="aside">
              <div
                id="div-gpt-ad-1539376335575-0"
                className="promo_homepage_banner"
              >
                <GPT
                  adUnitPath="/182885412/ghin.com_Homepage"
                  slotSize={[300, 250]}
                />
              </div>
            </aside>
          </div>
        )}
        <StartTrialModal
          title={this.state.modalTitle}
          content={this.state.modalContent}
          subtitle={this.state.modalSubTitle}
          subcontent={this.state.modalSubContent}
          icon={this.state.icon}
          isOpen={this.state.trialModalOpen}
          onConfirmModal={this.onConfirmModal}
          onCloseModal={this.onCloseModal}
        />
        {year_end_summary_modal}
      </Fragment>
    );
  }
}

Profile.propTypes = {
  profile: PropTypes.object.isRequired,
  memberships: PropTypes.array.isRequired,
  fetchGolferProfile: PropTypes.func.isRequired,
  fetchEndOfYearCampaign: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  profileReducer,
  authReducer,
  endOfYearCampaignReducer,
  recentScoresReducer,
}) => ({
  profile: profileReducer.golferProfileFetchReducer.golferProfile,
  currentUser: authReducer.loginReducer.user,
  memberships: profileReducer.golferProfileFetchReducer.golferMemberships,
  golferActive: profileReducer.golferProfileFetchReducer.golferActive,
  trialGolfer: profileReducer.golferProfileFetchReducer.trialGolfer,
  scores: recentScoresReducer.recentScoresFetchReducer.recentScores,
  stats: endOfYearCampaignReducer.endOfYearCampaignReducer.year_end_summary,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchGolferProfile,
      fetchEndOfYearCampaign,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
