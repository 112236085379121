import jobStatus from "../../../shared/variables/job-status";
import {
  RECENT_SCORES_FETCH,
  RECENT_SCORES_FETCH_FAILED,
  RECENT_SCORES_FETCH_SUCCESS,
} from "../actions";

const initialState = {
  recentScores: [],
  fetchRecentScoresStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RECENT_SCORES_FETCH:
      return {
        ...state,
        recentScores: initialState.recentScores,
        fetchRecentScoresStatus: jobStatus.PROCESSING,
      };
    case RECENT_SCORES_FETCH_FAILED:
      return {
        ...state,
        fetchRecentScoresStatus: jobStatus.FAILED,
      };
    case RECENT_SCORES_FETCH_SUCCESS:
      return {
        ...state,
        recentScores: payload,
        fetchRecentScoresStatus: jobStatus.SUCCESS,
      };
    default:
      return state;
  }
};
