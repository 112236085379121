import { combineReducers } from "redux";
import endOfYearCampaignReducer from "./end-of-year-fetch";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";

const reducers = combineReducers({
  endOfYearCampaignReducer: persistReducer(
    {
      key: "endOfYearCampaignReducer",
      storage: storage,
    },
    endOfYearCampaignReducer
  ),
});

export default reducers;
